import { motion } from 'framer-motion';
import { BsFillGrid3X3GapFill, BsGrid1X2Fill, BsGridFill } from "react-icons/bs";
import { FaHome, FaSearch, FaTags, FaThLarge, FaTshirt } from 'react-icons/fa'; // Added icons for Wearables, NFTs, and Browse All
import { IoMdArrowDropdownCircle } from "react-icons/io";

import { IoFilter, IoList } from "react-icons/io5";
import styled from 'styled-components';
export const lightTheme = {
  background: `
    background: -webkit-linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    background: -o-linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    background: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    color: #333332;
  `,
};

export const darkTheme = {
  background: `
    background: -webkit-linear-gradient(to top, #283E51, #0A2342);
    background: linear-gradient(to right, #434343 0%, black 100%);
    background: -o-linear-gradient(to top, #283E51, #0A2342);
    background: -webkit-linear-gradient(to right, #434343 0%, black 100%);
    background: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
    color: #FAF4E5;
  `,
};




export const SubNavbarSearchContainer = styled(motion.div)`
  position: absolute;
  top: ${({ browseMode }) => (browseMode ? '22.5%' : 'auto')};
  bottom: ${({ browseMode }) => (browseMode ? 'auto' : '2.5%')};
  margin-left: ${({ browseMode }) => (browseMode ? '7.5%' : '18.75%')};
  transform: translate(-50%, 0%);
  height: ${({ browseMode }) => (browseMode ? '37.5px' : '35px')};
  background-color: ${({ browseMode, isDarkMode }) => 
    browseMode ? 'none' : (!isDarkMode ? darkTheme.background : lightTheme.background)};

  display: flex;
  justify-content: center;

  z-index: 99;
  border-radius: 30px;
  align-items: center;
  padding: 0 10px;
  @media (max-width: 600px) {
    left: 50%;
    margin-left: 0;
    transform: translate(-50%, 0%) !important;
    width: 80%;
  }
`;

export const MobileSubNavbar = styled(motion.div)`
  position: absolute;
 // top: ${({ browseMode }) => (browseMode ? '22%' : 'auto')};
  //bottom: ${({ browseMode }) => (browseMode ? 'auto' : '2.5%')};
  //margin-left: ${({ browseMode }) => (browseMode ? '7.5%' : '18.75%')};
  transform: translate(-50%, 0%);

  height: ${({ browseMode }) => (browseMode ? '37.5px' : '35px')};

  width: ${({ browseMode }) => (browseMode ? '40%' : '17.5%')};
  background-color: ${({ isDarkMode }) => !isDarkMode ? darkTheme.background : lightTheme.background};

  color: ${({ isDarkMode }) => isDarkMode ? darkTheme.color : lightTheme.color};
  display: flex;
  justify-content: space-around;
  z-index: 99;
  border-radius: 30px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
  align-items: center;
  padding: 0 10px;
  left: 50%;
    margin-left: 0;
    transform: translate(-50%, 0%) !important;
    width: 80%;
    @media (max-width: 600px) {
      height: 0;
   // width: 0;
  // width: ${({ browseMode }) => (browseMode ? '40%' : '80%')};
  }
`;


export const MobileSubNavbarContainer = styled(motion.div)`
  position: absolute;
  //bottom: ${({ browseMode }) => (browseMode ? 'auto' : '2.5%')};
  margin-left: ${({ browseMode }) => (browseMode ? '7.5%' : '18.75%')};
  transform: translate(-50%, 0%);
  top: ${({ browseMode }) => (browseMode ? '0%' : 'initial')};


  height: ${({ browseMode }) => (browseMode ? '37.5px' : '35px')};

  max-width: ${({ browseMode }) => (browseMode ? '70%' : '50%')};
  //background-color: ${({ isDarkMode }) => !isDarkMode ? darkTheme.background : lightTheme.background};

  color: ${({ isDarkMode }) => isDarkMode ? darkTheme.color : lightTheme.color};
 
  display: flex;
  justify-content: space-around;
  z-index: 99;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
  align-items: center;
  padding: 0 10px;
  left: 50%;
    margin-left: 0;
    transform: translate(-50%, 0%) !important;
    width: 80%;
    font-size: 1rem;

    

`;



export const SubNavbarContainer = styled(motion.div)`
  position: absolute;

  top: ${({ browseMode }) => (browseMode ? '20%' : 'auto')};
  bottom: ${({ browseMode }) => (browseMode ? 'auto' : '2.5%')};
  margin-left: ${({ browseMode }) => (browseMode ? '2.5%' : '20%')};
  transform: translate(-50%, 0%);
  height: ${({ browseMode }) => (browseMode ? '37.5px' : '35px')};
  background-color: ${({ browseMode, isDarkMode }) => 
    browseMode ? 'none' : (isDarkMode ? lightTheme.background : darkTheme.background)};

  display: flex;
  justify-content: center;
 // background-color: ${({isDarkMode}) => (isDarkMode ? '#fff' : '#333')};
  z-index: 99;
  border-radius: 30px;
  align-items: center;
  padding: 0 10px;
  @media (max-width: 600px) {
    left: 50%;
    margin-left: 0;
    transform: translate(-50%, 0%) !important;
    width: 80%;
  }
`;


export const SubNavbarWrap = styled(motion.div)`
  position: relative;
  min-width: 45px !important;
  height: ${({ browseMode }) => (browseMode ? '37.5px' : '35px')};
 // background-color: ${({ isDarkMode }) => !isDarkMode ? darkTheme.background : lightTheme.background};

  //color: ${({ isDarkMode }) => isDarkMode ? darkTheme.color : lightTheme.color};
  display: flex;
  justify-content: center;
text-align: center;
  z-index: 99;
  border-radius: 10px;
  //box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
  align-items: center;
  padding: 0 10px;

  margin-right: 20px;
  background-color: ${({isDarkMode}) => (isDarkMode ? '#fff' : '#333')};
  @media (max-width: 600px) {
  //  position: absolute;

     // height: 0;
   // top: 0;
    max-width: 15px;
    min-width: 15px;
  }
`;


export const SubNavbarWrapMobile = styled(motion.div)`
  position: relative;

  height: ${({ browseMode }) => (browseMode ? '37.5px' : '35px')};
 background-color: initial;

  //color: ${({ isDarkMode }) => isDarkMode ? darkTheme.color : lightTheme.color};
  display: flex;
  justify-content: center;
text-align: center;
  z-index: 9;
  border-radius: 10px;
  //box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
  align-items: center;
  background-color: ${({isDarkMode}) => (isDarkMode ? '#fff' : '#333')};
  background-color: ${({isDarkMode}) => (isDarkMode ? '#fff' : '#000')};
  background-color: ${({ browseMode, isDarkMode }) => 
    browseMode ? 'none' : (!isDarkMode ? darkTheme.background : lightTheme.background)};
  @media (max-width: 600px) {

    max-width: 45px;
    min-width: 45px;
  }
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  flex: 1;
  margin-right: 1rem;
  width: 350px;
  @media (max-width: 600px) {
    position: absolute;
   
    min-width: 50%; /* Adjust width to 50% when window width is 600px or less */
    margin: 0;
    //margin-right: 0.5rem;
    
  }
`;
export const NavItemWrapper = styled.div`
display: flex;
position: absolute;
right: 0px;
@media (max-width: 600px) {
  right: 20px;
    
  }
`
export const NavbarItem = styled.div`
  margin: 0 15px;
  padding: 0 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  font-size: 10px;
  letter-spacing: -1px;
  &.company-info {
    display: flex;
    align-items: center; // Align items in a row
  }

  &:after {
    content: '';
    position: absolute;
    height: 20px;
    width: 1px;
    background: white;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    color: yellow;
    text-shadow: 0 0 5px yellow;
  }

  @media (max-width: 600px) {
    margin: 0 10px;
    padding: 0 5px;
    flex-direction: column;
    font-size: 1em;
    &:after {
      //display: none;
    }
  }
`;

export const NavbarItemLast = styled(NavbarItem)`
  &:after {
    display: none;
  }
`;


export const BrowseCollectionButton = styled.div`
  margin: 0 0px;
  padding: 0 0px;
  min-width: 200px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  font-size: 10px;
  letter-spacing: -1px;
  z-index: 99;

  &.company-info {
    display: flex;
    align-items: center; // Align items in a row
  }



  &:hover {
    color: yellow;
    text-shadow: 0 0 5px yellow;
  }
  @media (max-width: 600px) {
  
  }

`;

export const FaTshirtIcon = styled(FaTshirt)`
  margin-right: 8px; /* Add space between icon and text */
  font-size: 20px;
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;

export const FaTagsIcon = styled(FaTags)`
  margin-right: 8px; /* Add space between icon and text */
  font-size: 20px;
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;

export const FaThLargeIcon = styled(FaThLarge)`
  margin-right: 0px; /* Add space between icon and text */
  font-size: 18px;
  margin-left: 10px;

  @media (max-width: 600px) {
    margin-left: 0;
    font-size: 12px;
    display: block;
    margin-left: 10px;
  }
`;

export const FaHomeIcon = styled(FaHome)`
  margin-right: 8px; /* Add space between icon and text */
  font-size: 20px;
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;









export const BsGridFillIcon = styled(BsGridFill)`
 // margin-right: 8px; /* Add space between icon and text */
  font-size: 20px;
 margin-right: -8px; 
 margin-left: -8px; 
 color: ${({isDarkMode}) => (isDarkMode ? '#333' : '#fff')};
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;

export const BsFillGrid3X3GapFillIcon = styled(BsFillGrid3X3GapFill)`
 // margin-right: 8px; /* Add space between icon and text */
  font-size: 20px;
 margin-right: -8px; 
 margin-left: -8px; 
 color: ${({isDarkMode}) => (isDarkMode ? '#333' : '#fff')};
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;


export const IoDropIcon = styled(IoMdArrowDropdownCircle)`
 // margin-right: 8px; /* Add space between icon and text */
  font-size: 20px;
 margin-right: -8px; 
 margin-left: -8px; 
 color: ${({isDarkMode}) => (isDarkMode ? '#333' : '#fff')};
  @media (max-width: 600px) {

    display: block;
  }
`;

export const BsGrid1X2FillIcon = styled(BsGrid1X2Fill)`
  font-size: 20px;
 margin-right: -8px; 
 margin-left: -8px; 
 color: ${({isDarkMode}) => (isDarkMode ? '#333' : '#fff')};
//  margin-right: 8px; /* Add space between icon and text */
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;

export const IoListIcon = styled(IoList)`
 // margin-right: 8px; /* Add space between icon and text */
  font-size: 28px;
 margin-right: -8px; 
 margin-left: -8px; 
 color: ${({isDarkMode}) => (isDarkMode ? '#333' : '#fff')};
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;

export const IoFilterIcon = styled(IoFilter)`
  position: absolute;
  top: 50%;
  font-size: 20px;
 // left: 0;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${({isDarkMode}) => (isDarkMode ? '#333' : '#fff')};
`;

export const IconText = styled.span`
  display: none;
  @media (max-width: 600px) {
    display: block;
    font-size: 0.5em;
    text-align: center;
  }
`;

export const IconTextMobile = styled.span`
    font-size: 1.15em;
    margin-right: 10px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 10px 0px 10px 40px; /* Add space for the icon */
  border: none;
  border-radius: 5px;
  background: transparent; /* Make the background always transparent */
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')}; /* Text color */
  ::placeholder {
    color: ${({ isDarkMode }) => (isDarkMode ? '#bbb' : '#888')}; /* Placeholder color */
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 600px) {
    padding: 10px 0px 10px 60px; /* Add space for the icon */
  }
`;

export const SearchIcon = styled(FaSearch)`
  position: absolute;
  top: 50%;
  left: 10px;
  cursor: pointer;
  transform: translateY(-50%);
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')}; /* Icon color */
  &:hover {
    filter: drop-shadow(0 0 5px white);
  }
  @media (max-width: 600px) {
    left: 38px;
  }
`;

export const BrowseButton = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#eee')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  cursor: pointer;
  &:hover {
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ccc')};
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover > div {
    display: block;
  }

  &::after {
    content: '';
    position: absolute;
    top: 20px; /* Adjust as needed to protrude the square */
    right: -20.5px; /* Adjust as needed to overflow the square */
    width: 45px; /* Square width */
    height: 20px; /* Square height */
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    z-index: 1; /* Ensure it's visible above other elements */
    display: ${({ isSubNavbarDropdownOpen }) => (isSubNavbarDropdownOpen ? 'block' : 'none')}; /* Conditionally display */
  }
`;

export const Dropdown = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 5px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#eee')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  z-index: 9999;
    width: 200px;
  @media (max-width: 600px) {
    width: 200px;
  }

`;

export const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ccc')};
  }
`;
