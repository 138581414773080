// Import necessary libraries and components
import React from 'react';
import { FaGavel, FaMoneyBillTransfer, FaTag, FaTags } from 'react-icons/fa6';
import { IoDiamondSharp } from "react-icons/io5";
import Select, { components } from 'react-select';
import { blockchainIconMap } from '../../ClosetData/Data';
export const categoryIconMap = {
  Back: "https://img.icons8.com/color/48/backpack.png",
  Background: "https://img.icons8.com/color/48/sunrise.png",
  Beard: "https://img.icons8.com/color/48/long-beard.png",
  Catalyst: "https://img.icons8.com/color/48/crystal.png",
  EyeAccessories: "https://img.icons8.com/color/48/sun-glasses.png",
  Eyes: "https://img.icons8.com/3d-fluency/94/eyes-1.png",
  Hair: "https://img.icons8.com/color/48/woman-combing-hair.png",
  Mouth: "https://img.icons8.com/color/48/smiling-mouth.png",
  Pants: "https://img.icons8.com/color/48/jeans.png",
  Skin: "https://img.icons8.com/color/48/skull.png",
  Clothing: "https://img.icons8.com/color/48/t-shirt.png",
  Expression: "https://img.icons8.com/color/48/happy.png",
  Gender: "https://img.icons8.com/color/48/unisex-skin-type-4.png",
  Glove: "https://img.icons8.com/color/48/infinity-gauntlet.png",
  Hand: "https://img.icons8.com/color/48/hand.png",
  Hat: "https://img.icons8.com/color/48/german-hat.png",
  Mustache: "https://img.icons8.com/color/48/mustache.png",
  Necklace: "https://img.icons8.com/color/48/necklace.png",
  Smoke: "https://img.icons8.com/color/48/smoking.png",
  Smoking: "https://img.icons8.com/color/48/smoker.png",
};
export const renderIconWithLabel = (category, trait) => {
  const CategoryIcon = categoryIconMap[category];
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {CategoryIcon && (
        <img
          src={CategoryIcon} // Use the URL here as the src attribute of img
          alt={`${category} icon`}
          style={{ width: '16px', height: '16px', marginRight: '5px' }}
        />
      )}
      {trait}
    </span>
  );
};


// Define themes for styling
const darkTheme = {
  background: '#1c1c1c',
  color: '#ffffff',
};

const lightTheme = {
  background: '#ffffff',
  color: '#000000',
};

export const categoryColors = {
  Back: 'red',
  Background: 'blue',
  Beard: 'brown',
  Catalyst: 'purple',
  EyeAccessories: 'cyan',
  Eyes: 'green',
  Hair: 'yellow',
  Mouth: 'pink',
  Pants: 'navy',
  Skin: 'orange',
  Clothing: 'violet',
  Expression: 'teal',
  Gender: 'magenta',
  Glove: 'grey',
  Hand: 'maroon',
  Hat: 'olive',
  Mustache: 'tan',
  Necklace: 'gold',
  Smoke: 'silver',
  Smoking: 'silver',
};

// Define a status-to-icon map
export const statusIconMap = {
  'All': FaTags,
  'Listed': FaTag,
  'On auction': FaGavel,
  'New': IoDiamondSharp,
  'Has offers': FaMoneyBillTransfer,
};

// Custom SingleValue component for Status Select
// Custom SingleValue component for Status Select
export const customStatusSingleValue = (props) => {
  const { data, selectProps } = props;
  const Icon = statusIconMap[data.label]; // Get the icon for the selected status

  const color = selectProps.isDarkMode ? darkTheme.color : lightTheme.color;

  return (
    <components.SingleValue {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '8px',
          fontWeight: 'bold',
          color: color, // Apply theme-based color
        }}
      >
        {Icon && (
          <Icon
            style={{
              width: '12px',
              height: '12px',
              marginRight: '5px',
              color: color, // Apply theme-based color
            }}
          />
        )} {/* Icon in theme color */}
        {data.label}
      </div>
    </components.SingleValue>
  );
};


// Custom Option component for Status Select
export const customStatusOption = (props) => {
  const { data, innerRef, innerProps, isFocused, isSelected, selectProps } = props;
  const Icon = statusIconMap[data.label]; // Get the corresponding icon for the status

  // Determine if this is the first option
  const isFirstOption = selectProps.options[0].value === data.value;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        padding: '5px 5px',
        fontSize: '8px',
        backgroundColor: isFocused ? '#eee' : '#fff',
        color: isSelected ? 'goldenrod' : '#000',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        borderTop: isFirstOption ? 'none' : `1px solid ${selectProps.isDarkMode ? darkTheme.color : '#ddd'}`, // Add border except for first option
      }}
    >
      {Icon && (
        <Icon
          style={{
            marginRight: '8px',
            color: isSelected ? 'goldenrod' : (selectProps.isDarkMode ? darkTheme.color : lightTheme.color),
          }}
        />
      )}
      {data.label}
    </div>
  );
};



// Status options with labels
export const statusOptions = [
  { value: 'All', label: 'All' },
  { value: 'Listed', label: 'Listed' },
  { value: 'On Auction', label: 'On auction' },
  { value: 'New', label: 'New' },
  { value: 'Has Offers', label: 'Has offers' },
];


// Custom Option component for Blockchain Select
export const customBlockchainOption = (props) => {
  const { data, innerRef, innerProps, isFocused, isSelected, selectProps } = props;

  // Determine if this is the first option
  const isFirstOption = selectProps.options[0].value === data.value;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        padding: '2px 3px',
        fontSize: '8px',
        backgroundColor: isFocused
          ? selectProps.isDarkMode ? '#555' : '#eee'
          : selectProps.isDarkMode ? darkTheme.background : lightTheme.background,
        color: isSelected ? 'goldenrod' : selectProps.isDarkMode ? darkTheme.color : lightTheme.color,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderTop: isFirstOption ? 'none' : `1px solid ${selectProps.isDarkMode ? darkTheme.color : '#ddd'}`, // Add border except for first option
      }}
    >
      <img
        src={data.icon}
        alt={`${data.label} icon`}
        style={{ width: '16px', height: '16px', marginRight: '8px' }}
      />
      {data.label}
    </div>
  );
};


// Blockchain options with icons
// CustomStyles.js
// Blockchain options with icons
export const blockchainOptions = Object.keys(blockchainIconMap).map((key) => ({
  value: key,
  label: key.toUpperCase(),
  icon: blockchainIconMap[key],
}));
// ... [Other imports and definitions]

// CustomStyles.js or CustomOption.js

export const CustomOption = (props) => {
  const { data, innerRef, innerProps, isFocused, isSelected, selectProps } = props;
  const Icon = categoryIconMap[data.label]; // Get the corresponding icon for the trait

  // Determine if this is the first option
  const isFirstOption = selectProps.options[0].value === data.value;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        padding: '2px 4px',
        fontSize: '8px',
        backgroundColor: isSelected
          ? 'rgba(218, 165, 32, 0.1)' // Subtle goldenrod highlight
          : isFocused
            ? selectProps.isDarkMode ? '#555' : '#eee'
            : selectProps.isDarkMode ? darkTheme.background : lightTheme.background,
        color: isSelected ? 'goldenrod' : selectProps.isDarkMode ? darkTheme.color : lightTheme.color,
        fontWeight: 'bold',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: isFirstOption ? 'none' : `1px solid ${selectProps.isDarkMode ? darkTheme.color : '#ddd'}`, // Add border except for first option
        transition: 'background-color 0.2s, color 0.2s',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {Icon && (
          <img
            src={Icon}
            alt={`${data.label} icon`}
            style={{
              width: '16px',
              height: '16px',
              marginRight: '8px',
              objectFit: 'contain',
            }}
          />
        )}
        {data.label}
      </div>
      {/* Custom Checkbox */}
      <span
        style={{
          display: 'inline-block',
          width: '12px',
          height: '12px',
          border: `0.5px solid ${isSelected ? 'goldenrod' : selectProps.isDarkMode ? darkTheme.color : lightTheme.color}`,
          borderRadius: '2px',
          backgroundColor: isSelected ? 'goldenrod' : 'transparent',
          transition: 'background-color 0.2s, border-color 0.2s',
          position: 'relative',
        }}
      >
        {isSelected && (
          <svg
            viewBox="0 0 12 12"
            width="12px"
            height="12px"
            style={{ fill: 'white', position: 'absolute', top: '0', left: '0', borderRadius: '2px',  border: `0.5px solid ${isSelected ? 'goldenrod' : selectProps.isDarkMode ? darkTheme.color : lightTheme.color}`, }}
          >
            <path d="M4.1 8.3L1.8 6l.8-.8 1.5 1.5 3.5-3.5.8.8L4.1 8.3z" />
          </svg>
        )}
      </span>
    </div>
  );
};



// Custom SingleValue component for Blockchain Select
export const customSingleValue = (props) => {
  const { data } = props;
  const { isDarkMode } = props.selectProps; // Pass isDarkMode from selectProps

  return (
    <components.SingleValue {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '8px',
          fontWeight: 'bold',
        }}
      >
        <img
          src={data.icon}
          alt={`${data.label} icon`}
          style={{ width: '10px', height: '10px', marginRight: '8px' }}
        />
        {data.label}
      </div>
    </components.SingleValue>
  );
};



// Custom styles for react-select
export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.isDarkMode ? darkTheme.background : lightTheme.background,
    color: state.selectProps.isDarkMode ? lightTheme.color : darkTheme.color,
    borderRadius: '4px',
    borderColor: state.selectProps.isDarkMode ? lightTheme.color : darkTheme.color,
    minHeight: '10px',
    height: state.selectProps.isTraitsVisible ? '10px' : '10px',
    boxShadow: 'none',
    padding: '0px 4px',
    margin: '0px 0px',
    boxShadow: '3px 3px 3px 3px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    '&:hover': {
      borderColor: state.selectProps.isDarkMode ? lightTheme.color : darkTheme.color,
    },
    width: 'auto',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0 0px',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    height: state.selectProps.isTraitsVisible ? '10px' : '10px',
    display: 'flex',
    alignItems: 'center',
    width: '80px'
  //  width: state.selectProps.isMobile ? '150px' : '100px', // Increased width for better visibility
  }),
  
  input: (provided, state) => ({
    ...provided,
    margin: '0',
    padding: '0',
    height: state.selectProps.isTraitsVisible ? '20px' : '20px',
    fontSize: '8px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    margin: '0',
    padding: '0',
    fontSize: '8px',
    color: 'black',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: '0',
    padding: '0',
    fontSize: '8px',
    lineHeight: '10px',
    color: state.selectProps.isDarkMode ? lightTheme.color : darkTheme.color,
    textTransform: 'uppercase',
    textShadow: '0.5px 0.5px 1px rgba(0,0,0,0.25)',
    textAlign: 'center',
    fontWeight: 'bold',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    display: 'none', // Hide the multiValue since we don't want the "X"
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '10px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '0 0px',
    height: '22.5px',
    color: state.selectProps.isDarkMode ? darkTheme.color : lightTheme.color,
  }),
  clearIndicator: () => ({
    display: 'none', // Remove the "X" button
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.isDarkMode ? darkTheme.background : lightTheme.background,
    color: state.selectProps.isDarkMode ? lightTheme.color : darkTheme.color,
    zIndex: 1000,
    maxHeight: state.selectProps.isMobile ? '80px' : '200px', // Adjust height based on device
    overflowY: 'auto', // Enable scrolling if content exceeds height
    overflowX: 'hidden',
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: state.selectProps.isMobile ? '80px' : '200px', // Adjust maxHeight for mobile
    overflowY: 'auto',
    overflowX: 'hidden',
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '2px 4px',
    fontSize: '8px',
    backgroundColor: state.isFocused
      ? state.selectProps.isDarkMode ? '#555' : '#eee'
      : state.selectProps.isDarkMode ? darkTheme.background : lightTheme.background,
    color: state.isSelected
      ? 'goldenrod'
      : state.selectProps.selectedTraits[state.data.value] &&
        state.selectProps.selectedTraits[state.data.value].length > 0
      ? categoryColors[state.data.value] || (state.selectProps.isDarkMode ? lightTheme.color : darkTheme.color)
      : 'gray',
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    width: '120px',
    // Remove duplicate width assignment
  }),
};

// Custom SingleValue component for Categories Select
export const CustomSingleValue = (props) => {
  const { data } = props;
  const { isDarkMode, selectedTraits = {} } = props.selectProps;
  const category = data.value;
  const traitsSelected =
    selectedTraits[category] && selectedTraits[category].length > 0;

  return (
    <components.SingleValue {...props}>
      <span
        style={{
          color: traitsSelected
            ? categoryColors[category]
            : 'gray',
          textTransform: 'uppercase',
          textShadow: '0.5px 0.5px 1px rgba(0,0,0,0.25)',
          fontSize: '8px',
          lineHeight: '10px',
          textAlign: 'center',
          width: '100%',
          display: 'block',
          fontWeight: 'bold',
        }}
      >
        {props.children}
      </span>
    </components.SingleValue>
  );
};

  // Custom SingleValue component for Traits Select
// Custom SingleValue component for Traits Select
export const customTraitsSingleValue = (props) => {
  const { data } = props;
  const Icon = categoryIconMap[data.value]; // Get the icon for the selected trait category
  const { isDarkMode, selectedTraits } = props.selectProps; // Access isDarkMode and selected traits

  return (
    <components.SingleValue {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: 'auto',
          fontSize: '8px',
          fontWeight: 'bold', // Make text bold
          textTransform: 'uppercase', // Make text uppercase
          color: selectedTraits[data.value]?.length > 0
            ? categoryColors[data.value] || (isDarkMode ? lightTheme.color : darkTheme.color) // Change color
            : 'gray', // Default color
        }}
      >
        {Icon && (
          <img
            src={Icon}
            alt={`${data.label} icon`}
            style={{
              width: '10px', // Resized to fit the placeholder
              height: '10px',
              marginRight: '5px',
              objectFit: 'contain', // Ensure the icon fits within the container
            }}
          />
        )}
        {data.label}
      </div>
    </components.SingleValue>
  );
};
// Custom Option component for Traits Select with Uppercase, Bold, and Color Change
export const CustomCategoryOption = (props) => {
  const { innerProps, innerRef, data, isFocused } = props;
  const { isDarkMode, selectedTraits = {} } = props.selectProps; 

  const category = data.value;
  const traitsSelected = selectedTraits[category] && selectedTraits[category].length > 0;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        padding: '2px 4px',
        fontSize: '8px',
        fontWeight: 'bold', // Bold text
        textTransform: 'uppercase', // Uppercase text
        color: traitsSelected ? categoryColors[category] : 'gray', // Change color
        backgroundColor: isFocused ? (isDarkMode ? '#555' : '#eee') : isDarkMode ? darkTheme.background : lightTheme.background,
        textAlign: 'center',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {renderIconWithLabel(category, data.label)}
    </div>
  );
};

  
  
// Define statusCustomStyles without overriding singleValue color
export const statusCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.isDarkMode ? darkTheme.background : lightTheme.background,
    borderRadius: '4px',
    borderColor: state.selectProps.isDarkMode ? lightTheme.color : darkTheme.color,
    minHeight: '20px',
    height: '20px',
    boxShadow: 'none',
    width: 'auto',
    padding: '0 2px',
    boxShadow: '3px 3px 3px 3px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    '&:hover': {
      borderColor: state.selectProps.isDarkMode ? lightTheme.color : darkTheme.color,
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0 2px',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0',
    padding: '0',
    height: '20px',
    fontSize: '8px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    margin: '0',
    padding: '0',
    fontSize: '8px',
    color: 'black',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bold',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    // No color styling to prevent conflicts with customStatusSingleValue
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '20px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '0 2px',
    height: '22.5px',
    color: state.selectProps.isDarkMode ? darkTheme.color : lightTheme.color,
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: '0 2px',
    height: '20px',
    color: state.selectProps.isDarkMode ? lightTheme.color : darkTheme.color,
    cursor: 'pointer',
  }),
};

// Custom Input component for Traits Select to show "SEARCH" when menu is open
export const CustomInput = (props) => {
  const { selectProps, ...rest } = props;
  const placeholder = selectProps.menuIsOpen ? "SEARCH" : selectProps.placeholder;

  return <components.Input style={{width: '100vw', height: 'auto', fontSize: '8px'}} {...props} placeholder={placeholder} />;
};
  

// Custom styles for blockchain/price Selects
export const blockchainPriceCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.isDarkMode ? darkTheme.background : lightTheme.background,
    //color: state.selectProps.isDarkMode ? darkTheme.color : darkTheme.color,
    borderRadius: '4px',
    borderColor: state.selectProps.isDarkMode ? lightTheme.color : darkTheme.color,
    minHeight: '20px', // Set min-height to 20px for blockchain and price selects
    height: '20px', // Set height to 20px for blockchain and price selects
    boxShadow: 'none',
    width: 'auto',
    padding: '0 2px',
    boxShadow: '3px 3px 3px 3px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    '&:hover': {
      borderColor: state.selectProps.isDarkMode ? lightTheme.color : darkTheme.color,
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0 2px',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    height: '20px', // Set height to 20px for value container
    display: 'flex',
    alignItems: 'center',
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0',
    padding: '0',
    height: '20px', // Set height to 20px for input field
    fontSize: '8px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    margin: '0',
    padding: '0',
    fontSize: '8px',
    color: 'black',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bold',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: '0',
    padding: '0',
    fontSize: '8px',
    lineHeight: '10px',
    color: state.selectProps.isDarkMode ? darkTheme.color : lightTheme.color,
    textTransform: 'uppercase',
    textShadow: '0.5px 0.5px 1px rgba(0,0,0,0.25)',
    textAlign: 'center',
    fontWeight: 'bold',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '20px', // Set height to 20px for indicators
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '0 2px',
    height: '22.5px', // Set height to 20px for dropdown indicator
    color: state.selectProps.isDarkMode ? darkTheme.color : lightTheme.color,
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: '0 2px',
    height: '20px', // Set height to 20px for clear indicator
    color: state.selectProps.isDarkMode ? lightTheme.color : darkTheme.color,
    cursor: 'pointer',
  }),
  
};



// BlockchainSelect.js

const BlockchainSelect = ({ selectedBlockchain, handleBlockchainChange, isDarkMode }) => (
  <Select
    isDarkMode={isDarkMode}
    options={blockchainOptions}
    value={blockchainOptions.find(option => option.value === selectedBlockchain.value)}
    onChange={handleBlockchainChange}
    placeholder="Select Blockchain"
    components={{ Option: customBlockchainOption, SingleValue: customSingleValue }}
    styles={blockchainPriceCustomStyles}
    isSearchable
  />
);

export default BlockchainSelect;
