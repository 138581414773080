
import { motion } from 'framer-motion';
import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";
import { MdCancel } from 'react-icons/md';

import styled from 'styled-components';

export const CarouselContainer = styled.div`
    display: flex;
    overflow: hidden;
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
  `;
  export const LeftButton = styled(FaCircleChevronLeft)`
      font-size: 3rem;
      left: 2.5%;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      //color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333e48')};
      color: #333e48;
      @media screen and (max-width: 768px) {
 
        font-size: 2rem;
    }
      
  `
    export const RightButton = styled(FaCircleChevronRight)`
         font-size: 3rem;
      position: absolute;
      cursor: pointer;
      right: 2.5%;
      top: 50%;
      transform: translateY(-50%);
     // color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333e48')};
      color: #333e48;
      @media screen and (max-width: 768px) {
 
 font-size: 2rem;
}
    `

export const CarouselItem = styled(motion.div)`
position: absolute;
width: 575px;
left: 50%;
top: 10%;
transform: translate(-50%);
    flex: 0 0 100%;
    box-sizing: border-box;
 
  `;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 16px; /* Adjust as needed */
`;

export const ImageWrap = styled.div`
  width: 300px; /* Set to the desired width */
  height: 300px; /* Set to the desired height */
  overflow: hidden; /* Ensure the image doesn't overflow the container */
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;


 export const Title = styled.h2`
        font-size: 1rem;
      //filter: drop-shadow(-0.5px .75px 0.2px #000000) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05));
      filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(-0.5px .75px 0.2px #ffffff) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05))' : 'drop-shadow(-0.5px .75px 0.2px #000000) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05))')};
      color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333e48')} !important;
      color: #000 !important;
      //color: #fff;
    font-weight: bold;
    position: relative;
    bottom: 25px;
  `;


export const Description = styled.div`
    position: relative;
    bottom: 25px;
    //left: 10px;

    color: ${({ orbitControlsEnabled, isDarkMode }) =>
    orbitControlsEnabled ? '#fff' : isDarkMode ? '#000' : '#fff'};
   // color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
   color: #000;
  `;

export const PopUpContainer = styled(motion.div)`
    box-sizing: border-box;
    position: fixed;
    width: 800px;
    height: 550px;
    left: 50%;
    transform: translateX(-50%);
    top: 125px;
   z-index: 999999999;
    backdrop-filter: blur(10px);
    border-radius: 70%;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);

    color: #fff;

    @media screen and (max-width: 1200px) {
       zoom: 0.9;
  
  

    }
    @media screen and (max-width: 960px) {
       zoom: 0.8;
       width: 700px;
    }

    @media screen and (max-width: 768px) {
 
      top: 25%;
        zoom: 0.7;
         }

    @media screen and (max-width: 550px) {
       zoom: 0.65;
  
       width: 650px;

    }
    @media screen and (max-width: 550px) {
       zoom: 0.6;
  
    }

`
export const ExitIcon = styled(MdCancel)` 
 cursor: pointer;
 // background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  border-radius: 100%;

 // transform: translate(10px, 10px) rotate()(180deg);
  transition: transform 3300ms ease-in-out;
  font-size: 32px; /* Adjust the font size as per your requirements */
//z-index: -10000000;
transform-origin: center;
display: flex;
  justify-content: center;
  align-items: center;
    //display: flex;
    &:hover {
   

}

`;
export const PExit = styled(motion.div)`
    position: absolute;

    right: 2.5%;
    transition: transform 30ms ease-in-out;
    top: 2.5%;
    cursor: pointer;
    transform-origin: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
   color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
 

   &:hover {
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    //background-color: #000;
    //transform: translate(10px, 10px) rotate()(360deg);
      //right: -5px;
      //filter: drop-shadow(0 0 10px white);
  }
  &:hover ${ExitIcon} {
    transform-origin: center;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   // background-color: #000;
   // transform:  rotateZ(180deg);
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      filter: drop-shadow(0 0 5px white);
  }


`

export const ExitIconWrap = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center; /* Set the transform origin to the center */

cursor: pointer;
  `




