import { motion } from 'framer-motion';
import { BiNetworkChart } from "react-icons/bi";
import { FaClipboardList } from 'react-icons/fa';
import { HiDocumentText } from 'react-icons/hi';
import { MdVerified } from 'react-icons/md';
import { RiGitRepositoryPrivateFill } from 'react-icons/ri';
import { Link as LinkR } from 'react-router-dom';
import styled from 'styled-components';


///
/// Coming Soon Tooltip Elements
///




export const ComingSoon = styled.div`
  width: 140px;
  height: 60px;
  position: absolute;
  
  top: 50%;
  transform: translate(-10%, 0%) scale(0.75);
  border-style: none;
  object-fit: cover; /* Ensure the image fits the container without overflowing */
  cursor: pointer;
  pointer-events: none;
  border:0;
  padding: 5px;
  pointer-events: none;

  @media screen and (max-width: 1200px) {
  
    transform: scale(0.65);
  }
  @media screen and (max-width: 960px) {

    transform: scale(0.55);
  }
  @media screen and (max-width: 768px) {
   
    transform: scale(0.45);
  }
  &:hover {
  }
`;

export const ComingSoonCont = styled.div`




//top: 15%;
pointer-events: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 999999999;
  text-decoration: none;
  &:hover {
    box-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  }
`;

export const LogoName = styled(motion.div)`
  display: flex;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);

  float: left;

  border-style: none;
  position: absolute;
  border:0;
  pointer-events: none;


  
  z-index: 2;
  left: 0;


  transform-origin: right center; /* Set the transform origin to the left edge */
  //transform: scale(0.85);


`;

export const LogoName2 = styled(motion.div)`
  display: flex;
  align-items: center;
  -webkit-perspective:700px;
  perspective: 700px;
  border-style: none;
  left: 52%;
  transform:  translate(-50%, -50%);
  pointer-events: none;
  float: left;
  position: relative;


  z-index: 3;
  //left: 30px;
  transform-origin: left center; /* Set the transform origin to the left edge */
  //transform: scale(0.85);

`;
export const PopUpText = styled.div`
  height: 100%;
  width: 240px;
  border-style: none;
  cursor: pointer;

  border-style: none;

`;

export const PopUpText2 = styled(motion.div)`
text-align: center;
justify-content: center;
align-items: center;
//position: absolute;
font-size: 1.75rem;
font-weight: 500;
margin-top: 7.5%;
//left: 50%;
//transform: translate(-50%, 50%);
  border-style: none;
  cursor: pointer;
  letter-spacing: -2px;
  border-style: none;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
`;

export const PopUpBox = styled(motion.div)` 
  position: absolute;
  //transform: scale(0.7);
  margin-top: -40%;
  border-style: none;
  left: 1.85%;

  //visibility: hidden;

  //background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};

    //color: #fff;
   // background-color: rgba(0, 0, 0, 0.8);
    width: 240px;
    padding: 8px 8px;
    height: 75px;
    padding: 5px 5px;
    border-radius: 20px;
   // z-index: 9999999999999999999999;
    white-space: nowrap;


  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 48%;
    //transform: translateX(-50%);
    //top: -10px;
    top: 75.2px;
    position: absolute;
    white-space: nowrap;
    z-index: 1;
    border: 10px solid;
    background: initial;
   // transform: rotate(-225deg);
   transform: rotate(180deg);

   border-color: ${({ isDarkMode }) => (isDarkMode ? 'transparent transparent rgba(250, 250, 250, 0.8)' : 'transparent transparent rgba(0, 0, 0, 0.8)')};

  }

  @media screen and (max-width: 768px) {
   // zoom: 0.5;
   opacity: 0;
}

`;

export const PopUpCard = styled.div`
  position: relative;

  z-index: 9999999999999;
`;


export const FooterContainer = styled(motion.footer)`
    width: 100vw;
    height: 200px;
    z-index: 999999;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 0 2rem 0;
    bottom: 0;
    left: 0;
    position: fixed;
    background: #FFFFFF;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1); // Add box shadow here

    @media screen and (max-width: 850px) {
        padding: 0;
    }
`;



export const FooterMainContainer = styled.div`
   // margin-top: -50px;
   transform: scale(0.8);
    justify-content: center;
    align-items: center;
    margin-left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 1400px) {
       transform: scale(0.9);

    margin-left: 0;
    transform: 0;
    }
  
  
  @media screen and (max-width: 1200px) {
    transform: scale(0.7);

    margin-left: 0;
    transform: 0;
    }
  

`

export const FooterWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;
    max-width: 1200px;
 
    justify-content: center;

`;

export const FooterRow = styled.div`
    display: grid;
    grid-auto-columns: 1fr 1fr 1fr;
    margin-top: 50px;
    gap: 100px;
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col3 col2 col1'` : `'col1 col2 col3'`)};

    @media screen and (max-width: 1024px) {
       
        height: 100vh;
        //margin-top: -300px;

    }

`;


export const FooterLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 265px;
    width: 250px;
    gap: 25px;
    left: 0px;
    grid-area: col1;
    @media screen and (max-width: 1024px) {
        position: absolute;
        left: -60%;
       top: 70%;
        //transform: translateX(-50%);
        
        

    }

`
export const FooterTopLine = styled.hr`
    box-sizing: border-box;
    display: none;
    width: 100vw;
    height: 1px;
    transform: translate(-50%, 0px);

    left: 50%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    position: absolute;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    @media screen and (max-width: 1400px) {
      zoom: 0.9;
      margin-top: -200px;
    }
  
  
  @media screen and (max-width: 1200px) {
  zoom: 0.7;
    }
    @media screen and (max-width: 1024px) {
        position: absolute;
        transform: translate(-50%, -1825px);
        zoom: 0.25;
    }
`
export const FooterBottomLine = styled.hr`
    box-sizing: border-box;
    position: absolute;
    box-sizing: border-box;
    //transform: translate(-50%, 150px);

    //top: 14px;
    bottom: 40px;
    width: 100vw;
    left: 0;
    height: 1px;
    //top: 150%;

    @media screen and (max-width: 1024px) {
        bottom: 30px;
    }
`

export const FooterLeftHead = styled.div`
   
    width: 258px;
    height: 19.23px;
    left: 59px;
  

    font-family: "inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

    color: #218207;
`
export const FooterLeftAddress = styled.div`
    
    left: 6.64%;
    right: 72.16%;


    font-family: "inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    color: #242121;
`
export const FooterLeftNumber = styled.div`
   
    left: 6.64%;
    right: 85.14%;
  


    font-family: "inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    color: #218207;
    cursor: pointer;
      
  &:hover {
    text-shadow: 0 0 0.25rem rgba(0, 255, 1, 0.5),
      -0.125rem -0.125rem 1rem rgba(33, 130, 7, 0.5),
      0.125rem 0.125rem 1rem rgba(31, 82, 16, 0.5);
  }
`
export const FooterLeftEmail = styled.div`
    
    left: 6.64%;
    right: 80.95%;


    font-family: "inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    color: #242121;
`
export const FooterLeftButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 46px 18px 24px;
    isolation: isolate;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    cursor: pointer;
    height: 40px;
    left: 59px;
    right: 1221px;

    background: #218207;
    border-radius: 80px;
    &:not([disabled]):focus,
  &:not([disabled]):hover {
    box-shadow: 0 0 0.25rem rgba(0, 255, 1, 0.5),
      -0.125rem -0.125rem 1rem rgba(33, 130, 7, 0.5),
      0.125rem 0.125rem 1rem rgba(31, 82, 16, 0.5);
  }
`

export const FooterMidContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px 0px 0px;



    width: 200px;
    height: 265px;
    grid-area: col2;
    gap: 50px;
        

    @media screen and (max-width: 1024px) {
        width: 330px;
        height: 330px;
        display: grid;
        grid-auto-columns: 1fr 1fr;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -15%;
        zoom: 1.1;
        
 
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    }

`
export const FooterMidHead = styled.p`
`
export const FooterRightContainer = styled.div`
    
    width: 409px;
    height: 366px;
    right: 0px;
    margin-left: 75%;
    grid-area: col3;
    @media screen and (max-width: 1024px) {
        
       // margin-left: 35%;
       position: absolute;

       left: -50%;
       top: 65%;
        //transform: translateX(-50%);
          
        //transform: translateX(-50%);
    }

 
`
export const FooterLinksWrapper = styled.li`
    height: 100%;
   // width: 240px;
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    transform: translateX(-50%) ;
      top: 15%;

  @media screen and (max-width: 1024px) {
      // width: 450px;
        top: 15%;
       left: 82.5%;
       transform: translateX(-50%) ;
      zoom: 0.9;
       }

       @media screen and (max-width: 768px) {
        display: none;
      transform: translateX(-50%) scale(0.65);
  
    }

`;



export const FooterIconWrap = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 23px;
    width: 133px;
    left: 641px;
    top: 173px;
    border-radius: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    

`
export const FooterIcon = styled(LinkR)`
    position: absolute;
    width: 366px;
    height: 339px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;


`

export const FooterText = styled(LinkR)`
    
    color: #3D4438;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    white-space: nowrap;
    text-align: left;
    margin-top: 5px;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        color: #218207;
      
    }
    &:active {
        color: #218207;
    }


`
export const FooterContainerWrapper = styled.div`
  
    align-items: center;
    justify-content: center;
    width: 100vw;


  


`;















export const FooterBottomSection = styled.div`
    display: flex;
    position: relative;
    z-index: 99999;
    width: 100vw;

    font-family: "inter";
    height: 200px;
   // margin-top: 200px;
    transform: translate(0%);
    //left: 50%;
    @media screen and (max-width: 850px) {
 
        zoom: 0.6;
      
        transform: translate(-10%,-50%);
    }
`


export const SocialMediaWrap = styled.div`
    position: absolute;
    left: 71.5%;
    display: flex;
    width: 35%;
    transform: translateY(-25%) scale(1.05);
    top: 45%;
    @media screen and (max-width: 1024px) {
        width: 100%;
        min-width: 500px;
       top: 60%;
       left: 50%;
       transform: translateX(-50%) scale(0.80) ;
      //zoom: 0.5;
       }

       @media screen and (max-width: 768px) {
        transform: translateX(-50%) scale(0.7);
  
    }
 
`;

export const SocialMediaText = styled.p`
     position: absolute;
     text-transform: uppercase;
     white-space: nowrap;
    font-weight: 900;
    color: #4a4a4a;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#4a4a4a')};
 // margin-bottom: 10px;
    left: 67.5%;
    font-size: 2rem;
    display: flex;
    width: 25px;
    transform: translateY(-25%);
    top: 0%;
    @media screen and (max-width: 1024px) {
        width: 100%;
       top: 45%;
       left: 50%;
       transform: translateX(-50%) ;
       display: none;
      //zoom: 0.5;
       }
 
`;

export const SocialLogo = styled.img`
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
  


   
    
`;

export const SocialLogoWrap = styled(LinkR)`
  
    height: 40px;
    width: 40px;
    margin-left: 10px;
    display: flex;
  



    
`;

export const FooterBottomLeftContainer = styled.div`



 
 


`
export const FooterInfo = styled.div`

  @media screen and (max-width: 1024px) {
       
        
zoom: 0.7;
    
       }
       @media screen and (max-width: 768px) {
    
        zoom: 0.7;
  
    }
`;
export const WebsiteRights = styled.small`
  
    width: 250px;
    height: 17px;
    position: relative;
    white-space: nowrap;

    font-family: "inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* or 177% */

    display: flex;
    align-items: center;

    color: #70767A;
   
`;

export const WebsiteTermsPolicy = styled.small`

    width: 100px;
    height: 17px;
    //left: 17.5%;
    //position: relative;
    font-family: "inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* or 177% */
  
    display: flex;
    align-items: center;
    color: #70767A;
`

export const SocialIconSection = styled.div`
   
    width: 420.56px;
    height: 38px;
    left: 0px;
    
 
`

export const SocialIconText = styled.div`
    position: absolute;
    left: 58%;
    right: 34.46%;
    top: 98.96%;
    bottom: 0.36%;


    font-family: "inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;

    color: #000000;
`

export const SocialIcon = styled.div`
    position: absolute;
    left: 67.66%;
    right: 30.34%;
    top: 98.97%;
    bottom: 0.44%;
`


export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  

 
`;

export const SocialIconLink = styled.a`
    color: #fff;
    font-size: 24px;
  
`;

export const VizIco = styled.img`
    
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    display: flex;
    height: 250px;
    width: 250px;
    margin-bottom: 100px;
    transform: translate(90%, 30%);
    @media screen and (max-width: 520px) {
        transform: scale(0.8);
        }
    
`;

export const VizSet = styled.div`
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;

    justify-content: center;
    grid-template-columns: 1fr 1fr;
    display: table-cell;
    vertical-align: middle;
    gap: 75px;
    align-items: center;
    

`;


///
///
///

export const Privacy = styled(RiGitRepositoryPrivateFill)`
    transition: all 0.2s ease-in-out;
      font-size: 1.25rem;
    color: #fff;
    top: 6px;
    white-space: nowrap;
    padding: 2px 2px;
    position: relative;
    border-radius: 100%;
    box-sizing: border-box;
    //color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
 
    @media screen and (max-width: 1024px) {
        top: 15px;
        font-size: 2.5rem;
    }
    @media screen and (max-width: 768px) {
        font-size: 1.5rem;
    }

`

export const Terms = styled(HiDocumentText)`
    transition: all 0.2s ease-in-out;
    font-size: 1.25rem;
    color: #fff;
    white-space: nowrap;
    top: 6px;
    padding: 2px 2px;
    position: relative;
    border-radius: 100%;
    box-sizing: border-box;
    //color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 1024px) {
  top: 15px;
  font-size: 2.5rem;
           }
           @media screen and (max-width: 768px) {
        font-size: 1.5rem;
    }

`


export const Verified = styled(MdVerified)`
    font-size: 24px; 
    color: #68ceff;
    transform: translateY( 6px);
    @media screen and (max-width: 768px) {
      transform: translateY( 12px) scale(0.75);
  
    }
    

`


export const RoundedSocialButtons = styled(motion.div)`
    text-align: center;
@media screen and (max-width: 1024px) {
    position: absolute;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    min-width: 500px;

       }


  
`;

export const SocialButton = styled(motion.a)`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
  transform: scale(0.85) rotate(0deg); /* Initial state */

  &:hover,
  &:focus {
    transform: scale(0.85) rotate(360deg); /* Rotate on hover */
  }
`;

// Define other styled components here...

export const FacebookButton = styled(SocialButton)`
  background: #3b5998;
  &:hover,
  &:focus {
    color: #3b5998;
    background: #fefefe;
    border-color: #3b5998;
  }
`;

export const TwitterButton = styled(SocialButton)`
  background: #55acee;
  &:hover,
  &:focus {
    color: #55acee;
    background: #fefefe;
    border-color: #55acee;
  }
`;

export const LinkedinButton = styled(SocialButton)`
  background: #007bb5;
  &:hover,
  &:focus {
    color: #007bb5;
    background: #fefefe;
    border-color: #007bb5;
  }
`;

export const RedditButton = styled(SocialButton)`
  background: #ff5700; /* Reddit's color */
  &:hover,
  &:focus {
    color: #ff5700;
    background: #fefefe;
    border-color: #ff5700;
  }
`;

export const YoutubeButton = styled(SocialButton)`
  background: #bb0000; /* YouTube's color */
  &:hover,
  &:focus {
    color: #bb0000;
    background: #fefefe;
    border-color: #bb0000;
  }
`;

export const DiscordButton = styled(SocialButton)`
  background: #5865f2; /* Discord's color */
  &:hover,
  &:focus {
    color: #5865f2;
    background: #fefefe;
    border-color: #5865f2;
  }
`;

export const XButton = styled(SocialButton)`
  background: #000; /* X's (formerly Twitter) color */
  &:hover,
  &:focus {
    color: #000;
    background: #fefefe;
    border-color: #000;
  }
`;
export const InstagramButton = styled(SocialButton)`
  background: #125688;
  &:hover,
  &:focus {
    color: #125688;
    background: #fefefe;
    border-color: #125688;
  }
`;



export const TikTokButton = styled(SocialButton)`
  background: #000; /* TikTok's color */
  &:hover,
  &:focus {
    color: #000;
    background: #fefefe;
    border-color: #000;
  }
`;



///
///
///



export const BottomLeftContainer = styled.div`
   position: absolute;
  left: 15%;
  bottom: 0;
  pointer-events: all;
  cursor: pointer;
 
  width: 100%;
  padding: 0;

  @media screen and (max-width: 1024px) {
          bottom: 20%;
    }
`;

export const FullWidthText = styled.p`
  white-space: nowrap;
   position: absolute;
   left: 5%;
 // bottom: 0%;
top: 73%;
  font-size: 12px;
  line-height: 1.5em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  & b {
    font-weight: bold;
  }
  @media screen and (max-width: 1024px) {
          //transform: scale(0.7);
          transform:  scale(1);
      top: 80%;
      left: 5%;
        
    }
    @media screen and (max-width: 768px) {
      transform:  scale(0.8);
      top: 80%;
      left: 0%;
  
    }
`;

export const FullWidthText2 = styled.p`
  white-space: nowrap;
   position: absolute;
   left: 20%;
  bottom: 0;
  font-size: 12px;
  line-height: 1.5em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  & b {
    font-weight: bold;
  }
  @media screen and (max-width: 1024px) {
          //transform: scale(0.7);
          zoom: 0.85;
    }
`;

export const CenteredTitle = styled.p`
  font-family: 'Antonio', sans-serif;
  //flex: 1 1 0%;
   position: absolute;

  font-size: 16px;
  font-weight: 700;
  line-height: 1em;
  text-align: center;
  color: black;
  letter-spacing: -0.5px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  top: 71.5%;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  @media screen and (max-width: 1024px) {
          left: 50%;
          transform: translate(-50%, 15px) scale(0.85);
          bottom: 0%;
        
    }
    @media screen and (max-width: 768px) {
      transform: translate(-50%, 15px) scale(0.75);
  
    }
`;

export const BottomRightContainer = styled.div`
  display: flex;
  width: nowrap;
   position: absolute;
   top: 80%;
  left: 75%;
  @media screen and (max-width: 1024px) {
          left: 87.5%;
          bottom: 2.5%;
         // left: 50%;
          transform: translate(-50%, -20px) scale(0.85);
         // bottom: 35px;
         top: 85%;
          width: 205px;
    }
    @media screen and (max-width: 768px) {
      left: 85%;
      width: 200px;
      top: 87.5%;
      transform: translate(-50%, -20px) scale(0.8);
  
    }
`;



export const BottomLink = styled.a`
  flex: 1 1 0%;
  font-size: 12px;
  line-height: 1em;
  text-align: right;
  color: black;
  width: nowrap;
  text-decoration: none;
  width: 125px;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
   // color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};


    ${Privacy} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
      color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    
      filter: drop-shadow(2px 2px 2px white);
    }
    ${Terms}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      filter: drop-shadow(2px 2px 2px white);
    }

  
  }
  @media screen and (max-width: 1024px) {
  top: 45px;
  font-size: 10px;
           }
`;





///
/// Footer Wallet and Closet Buttons
///





export const TextSpan = styled.span`
    color: #fff;
    padding: 0px 0px 0px 15px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    @media screen and (max-width: 1024px) {

    }
`



export const ButtonText = styled.div`
   position: absolute; /* Added position: relative */
   transition: transform 0.2s ease-in-out;
   // transform: ; /* Adjust the value as per your requirements */
    padding: 0px 0px 0px 0px;
    //transform: matrix(0,0,0,0,0,0);
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%) matrix(1, 0, 0, .8, 0, 0);
    @media screen and (max-width: 1024px) {
    
    }
`



const black = "#000";
const white = "#fff";
const silver = "#c0c0c0";

export const SidebarButtonWrap = styled.div`
   position: absolute;
   width: 100%;
    top: 65%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 3em;
    justify-content: center;
    align-items: center;
    left: 50%;

`
export const Closet = styled(motion.a)`
 width: 100%;
 position: relative;
 z-index: -1;
 cursor: pointer;


      
`;

export const Wallet = styled(motion.a)`
margin-top: 15px;
cursor: pointer;
//width: 90%;
   & :hover + ${PopUpBox} {

  }

      
`;

export const ClosetIcon = styled(BiNetworkChart)`
  //background-color: #fff;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   
  //border-radius: 100%;
  padding: 8px; /* Adjust the padding as per your requirements */
  margin-top: 5px;
  margin-left: 20px;
  transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
  font-size: 40px; /* Adjust the font size as per your requirements */

    //display: flex;
    &:hover {
   // background-color: #000;

      filter: drop-shadow(0 0 10px white);

}
@media screen and (max-width: 1024px) {
  /*
      margin-left: 20%;
       padding: 0px;
       border-radius: 0%;
     background-color: none;
     transform: translate(-50%, 12.5px) matrix(1, 0, 0, 1.2, 0, 0);
     background: none;
     */
    }
`;



export const WalletIcon = styled(FaClipboardList)`
  //background-color: #fff;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   
  //border-radius: 100%;
  padding: 8px; /* Adjust the padding as per your requirements */
  margin-top: 5px;
  margin-left: 20px;
  transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
  font-size: 40px; /* Adjust the font size as per your requirements */

    //display: flex;
    &:hover {
   // background-color: #000;

      filter: drop-shadow(0 0 10px white);

}
@media screen and (max-width: 1024px) {
  /*
      margin-left: 20%;
       padding: 0px;
       border-radius: 0%;
     background-color: none;
     transform: translate(-50%, 12.5px) matrix(1, 0, 0, 1.2, 0, 0);
     background: none;
     */
    }
`;

export const BubblyButton = styled.button`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -1px;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
      display: inline-block;
      //zoom: 1.25;
      -webkit-appearance: none;
      appearance: none;


      background-color: ${({ isDarkMode }) => (isDarkMode ? white : black)};
      
      border-radius: 4px;
      border: none;
      cursor: pointer;
      position: relative;
      transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
      box-shadow: 0 2px 25px rgba(192, 192, 192, 0.5);
      width: 200px;
      height: 50px;
      &:focus {
        outline: 0;
      }



      &:active {

        transform: scale(0.9);
        background-color: ${black};
        box-shadow: 0 2px 25px rgba(192, 192, 192, 0.2);
  }
`;







export const AnimateBubbles = styled(BubblyButton)`


    cursor: pointer;
    display: inline-block;
   
    width: 240px;
    height: 65px;
    border-radius: 90px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */
    background-color: #000;
    color: #000;



    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

  /*
  &:before {
    background-color: initial;
    background-image: ${({ isDarkMode }) =>
      isDarkMode
        ? 'linear-gradient(#A9A9A9 0, rgba(0, 0, 0, 0) 100%)' // Black background
        : 'linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%)' // Original white background
    };
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
  }


*/
  
  &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${ClosetIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      color: #fff;

      filter: drop-shadow(0 0 10px white);
      //filter: drop-shadow(2px 2px 2px white);
    }
    ${WalletIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      color: #fff;

      filter: drop-shadow(0 0 10px white);
      //filter: drop-shadow(2px 2px 2px white);
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }
  }


  @media screen and (max-width: 1024px) {
    /*
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    */
    &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${WalletIcon} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : 'none')};
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${ClosetIcon} {
      /* styles for the container when button is hovered */
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }

  }
  }
  @media screen and (max-width: 1024px) {
          //transform: scale(0.7);
          //top: 10px;
       
          //left: 75%;
    }



`;


export const FooterLink = styled.div`
   
  
    font-size: 1.5rem;
    background-color: #000;
    color: #000;
    width: 250px;
    height: 40px;
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
   // border-radius: 10px;
    border-radius: 50px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */




   position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;


  &:hover  {
        transition: all 0.2s ease-in-out;
        backdrop-filter: blur(5px);

    ${ClosetIcon} {
      /* styles for the container when button is hovered
     // background-color: #000;
     color: ${({ isDarkMode }) => (isDarkMode ? `#fff` : `#000`)};
      border-radius: 15px;
      background-color: ${({ isDarkMode }) => (isDarkMode ? `#000` : `#fff`)};
      
      */
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
      filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};

    }
    ${WalletIcon} {
      /* styles for the container when button is hovered

      color: ${({ isDarkMode }) => (isDarkMode ? `#fff` : `#000`)};
border-radius: 15px;
      background-color: ${({ isDarkMode }) => (isDarkMode ? `#000` : `#fff`)};
 */
      //background-color: #000;
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
      filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};


    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     // filter: drop-shadow(0px 0px 10px black);
     box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};


    }
  }
 
    
`;





///
/// Sidebar Buttons
///









///
/// FOoter Newsletter Elements
///

export const Container = styled(motion.div)`
  //background-color: white;
  text-align: left;
  width: 380px;
  height: 150px;
  //border-radius: 50px;
  //margin: 35px auto;
left: 5%;
  position: absolute;
  top: 0%;
  @media screen and (max-width: 1024px) {
   // transform: translateX(-50%) scale(0.9);
//zoom: 0.9;
}

      @media screen and (max-width: 768px) {
      transform: translateX(-50%) scale(0.75);
      left: 50%;
      margin-top: -15px;
     // left: 10%;

    }
    @media only screen and (max-width: 500px) {
      margin-top: -30px;
   // margin: 0 15px;
   transform: translateX(-50%) scale(0.65);
  }

`;

export const Image = styled.img`
  width: 50%;
  height: auto;
  margin-top: 45px;

  @media only screen and (max-width: 500px) {
    margin-top: 75px;
  }
`;

export const Title = styled.h1`
  text-transform: uppercase;
  font-weight: 900;
  color: #4a4a4a;
  margin-bottom: 5px;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#4a4a4a')};
  @media only screen and (max-width: 500px) {
    margin-bottom: 5px;
    font-size: 1.5rem;
  }
`;

export const Description = styled.p`
  font-size: 1.1rem;

  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#636363')};
  margin-top: 0px;

  @media only screen and (max-width: 500px) {
  
  }
`;

export const FormBox = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0px;
  margin-top: -10px;

  @media only screen and (max-width: 500px) {
    margin-left: 50%;
    margin-top: 5px;
    transform: translateX(-50%);
    display: grid;
    width: 390px;
    align-items: left;
  justify-content: left;
    //margin-top: 25px;
  }
`;

export const InputMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 300px;
  //padding: 10px 25px;

  background-color: #ebebeb;
  border-radius: 10px; 
  margin-right: 10px;
  @media only screen and (max-width: 600px) {
    //margin-right: 30px;
    //margin-top: 10px;
    width: 390px !important;
    
 
  }
`;

export const Input = styled.input`
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  color: #000;
  font-size: 1rem;

  ::placeholder {
    color: #a2a2a2;
  }

`;

export const InputIcon = styled.svg`
  width: 25px;
  height: auto;
  fill: #000;
  margin-right: 10px;
`;

export const SubmitButton = styled.button`
 // background: linear-gradient(45deg, #fb4764, #f88d5f) no-repeat;
  background: linear-gradient(45deg, #888, #000) no-repeat;
  border: none;
  outline: none;
  margin-right: 30px;
  //padding: 10px 15px;
  height: 35px;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 10px;

  @media only screen and (max-width: 600px) {
    //margin-right: 30px;
    margin-top: 10px;
    width: 390px;
    
 
  }
`;