import { Environment, Sparkles } from '@react-three/drei';
import React, { Suspense, useRef } from 'react';
import styled from 'styled-components';
import Preloader from '../Preloader/CompanyPreloaderThree';
import World from './MasterPlan3D';
import BackgroundColorSetter from './MasterPlanEffects/ColorBackground';
import { SelectiveBloom } from './MasterPlanEffects/SelectiveBloom';
import './Plan.css';
const Text1 = styled.p`
  /* styles */
`;

const FixedContainer = styled.div`
  /* styles */
`;

function MasterPlan({ isDarkMode, setDaoPopup, project }) {
  const isMobile = window.innerWidth <= 600;
  const bloomIntensity = isMobile ? 0.05 : isDarkMode ? 1 : 0.35;
  const lights = useRef([]);
  const meshes = useRef([]);

  return (
    <>
       <Suspense fallback={isMobile ? <Preloader /> : null}>
        {/* Move DOM elements outside the Canvas */}
        {/* If needed, place DOM elements here */}
        <SelectiveBloom isMobile={isMobile} intensity={bloomIntensity}>
          {/* Canvas content starts here */}
          {/* Do not include DOM elements or styled-components inside this block */}
          <group>
          <BackgroundColorSetter isDarkMode={isDarkMode} />

            <World
              setDaoPopup={setDaoPopup}
              style={{ zIndex: '-9999999' }}
              className="theatre-controls-container"
              isDarkMode={isDarkMode}
              isMobile={isMobile}
              project={project}
              mannequinKey={'mannequin'}
              lights={lights}
              meshes={meshes}
            />

            {/* Remove nested EffectComposer */}
            {/* Add additional effects in SelectiveBloom if necessary */}

            {isMobile ? (
              <Sparkles
                frustumCulled={false}
                //size={5}
                scale={20}
                position={[0, 1, 0]}
                count={50}
                opacity={0.15}
                speed={0.5}
                size={isDarkMode ? '5' : '25'}
                color={isDarkMode ? '#eee' : '#000'}
              />
            ) : (
              <Sparkles
                frustumCulled={false}
                //size={10}
                scale={20}
                position={[0, 1, 0]}
                count={200}
                opacity={0.15}
                speed={0.2}
                size={isDarkMode ? '10' : '100'}
                color={isDarkMode ? '#eee' : '#000'}
              />
            )}

            <Environment preset="studio" blur={1} />
          </group>
        </SelectiveBloom>
      </Suspense>
    </>
  );
}

export default MasterPlan;
