import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FaDiscord, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import styled, { css, keyframes } from 'styled-components';
import icon from '../../images/w3oBlackLogo.jpg';
import {
  BottomContainer,
  BottomLine,
  BottomLink,
  BottomRightContainer,
  BottomSection,
  ButtonContainer,
  ButtonText,
  CenteredTitle,
  DaoContainer,
  DaoIcon,
  DiscordButton,
  FullWidthText,
  InstagramButton,
  PrepImg,
  PrepImgWrap,
  Privacy,
  RoundedSocialButtons,
  SocialMediaWrap,
  Terms,
  TextSpan,
  TikTokButton,
  TopLine,
  UtilitiesBottomBtnWrap,
  UtilitiesGuideContainer,
  UtilitiesGuideHeader,
  UtilitiesGuideSubtitle,
  UtilitiesGuideTextWrap,
  Verified,
  Whitelist,
  WhitelistIcon,
  XButton,
  YoutubeButton
} from './UtilitiesElements';

import HowItWorksElements from "./UtilitiesGuideData";
const W3O = styled.img`

 // margin-left: 15px;
  width: 30px;
  height: 30px;
  border-radius: 100%;

  position: relative;
  top: 130px;
  left: 34px;

    

`
// Define the keyframes for the animation
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;


// Base animation styles that can be reused
const animationStyles = css`
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  animation: ${fadeInUp} 0.5s ease-in-out forwards;
`;

// Styled component for the timeline
const VerticalTimelineStyled = styled.div`
  position: relative;
  margin: 0 auto;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 7.5%;
    transform: translateX(-50%);
    border: 0;
    background-image: linear-gradient(to right, #000, #fff, #888);
    height: 100%;
    width: 2px;
  }

  @media screen and (max-width: 768px) {
     
       
    &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 7.5%;
    transform: translateX(-50%);
    border: 0;
    background-image: linear-gradient(to right, #000, #fff, #888);
    height: 100%;
    width: 2px;
  }

   
    }
`;

// Styled component for the timeline element
const VerticalTimelineElementStyled = styled.div`
  position: relative;
  margin: 4em 0;
  opacity: 0; // Initially hidden

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 5%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 0 3px #fff, inset 0 2px 0 rgba(0, 0, 0, .08), 0 2px 0 3px rgba(0, 0, 0, .05);
  }

  animation-fill-mode: forwards;

  &.visible {
    animation: ${fadeInUp} 0.5s ease-in-out forwards;
  }

  @media screen and (max-width: 768px) {
     
       
          height: 350px;
       
     
       
          &:before {
    content: '';
    position: absolute;
    top: 37.25%;
    left: 5%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 0 3px #fff, inset 0 2px 0 rgba(0, 0, 0, .08), 0 2px 0 3px rgba(0, 0, 0, .05);
  }

        
         }
`;

function observeElements() {
  const elements = document.querySelectorAll('.animate-on-scroll');

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    },
    {
      threshold: 0.1 // Trigger when 10% of the element is in view
    }
  );

  elements.forEach((element) => {
    observer.observe(element);
  });
}



function UtilitiesGuide({trans,   setDaoPopup,  daoPopup, isUtilitiesOpen,  setWhitelistPopup, isDarkMode}) {

  const [isMobile, setIsMobile] = useState(false);
  const [showBottomSection, setShowBottomSection] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const mobile = Boolean(userAgent.match(/Android|iPhone|iPad|iPod|Windows Phone|webOS/i));
    const screenWidth = window.innerWidth;
    setIsMobile(mobile && screenWidth <= 600);
    setShowBottomSection(screenWidth <= 600);

    // Observe window resizing
    const handleResize = () => {
      setShowBottomSection(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    observeElements(); // Start observing elements for animation

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const contentStyle = {
    width: '600px',
    height: '200px',
    marginBottom: '50px',
    marginTop: '0px',
    background: 'none',
    boxShadow: 'none',
 
  };

  // Apply a media query for smaller screens
  if (window.innerWidth <= 768) {
    contentStyle.width = '500px'; // Adjust the width for smaller screens
    contentStyle.height = '300px';
    contentStyle.marginBottom = '100px';
    contentStyle.marginTop = '150px';
  //contentStyle.top = '-325px';
  }
  if (window.innerWidth <= 1024) {
    contentStyle.marginBottom = '100px';
    contentStyle.marginTop = '0px';
  }

  

  return (
    <>
  <TopLine size= "10"/>
    <UtilitiesGuideContainer id="timelineElement" >


            <VerticalTimelineStyled
  
            >
        
        {HowItWorksElements.map((element, index) => {
          //let isWorkIcon = element.icon === "work";
          



          return (

      
            <VerticalTimelineElementStyled
               // ref={utilitiesGuideRef} 
              contentStyle={contentStyle}
             
              className={isMobile ? "" : "animate-on-scroll"} // Apply animate-on-scroll only on non-mobile devices
     

    
          
       
             // contentArrowStyle={ {  display: `none` } }
              
            >
      <W3O src={icon} />
            <PrepImgWrap isDarkMode={isDarkMode} trans={element.trans}>
              <PrepImg loading="lazy" src={element.img}/>
            </PrepImgWrap>
            <UtilitiesGuideTextWrap  >
              <UtilitiesGuideHeader       
   isDarkMode={isDarkMode} text={element.text}  >{element.Header}</UtilitiesGuideHeader>
             

              <UtilitiesGuideSubtitle  isDarkMode={isDarkMode} text={element.text} className="per" id="description">{element.description}</UtilitiesGuideSubtitle>
              </UtilitiesGuideTextWrap>
         
            </VerticalTimelineElementStyled>   
          );
        })}
      </VerticalTimelineStyled>

      {/*!isMobile && <SymbolCanvas isUtilitiesOpen={isUtilitiesOpen} setWhitelistPopup={setWhitelistPopup} />*/}
      <UtilitiesBottomBtnWrap>
      <motion.div whileHover={{ scale: 1.1, transition: { duration: 0.05 } }}  >
      <DaoContainer  
                   
                   //variants={itemVariants}
                   //initial={{ x: -150, opacity: 0}}
                  // initial={{ x: -150, opacity: 0}}
                  //whileHover={{ scale: 1.05, transformOrigin: "center" }}
      isDarkMode={isDarkMode}>
          
                   
                                    <ButtonContainer    onClick={() => {setDaoPopup(true) }} isDarkMode={isDarkMode}  smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>  
                                      <ButtonText>
                                        <TextSpan isDarkMode={isDarkMode}>Join THE TEAM</TextSpan>  
                                        <DaoIcon isDarkMode={isDarkMode}  />
                                      </ButtonText> 
                                    </ButtonContainer>
                                
                                 </DaoContainer>
                                 </motion.div>





                                 <motion.div whileHover={{ scale: 1.1, transition: { duration: 0.05 } }}  >
      <Whitelist  
                   
                     //variants={itemVariants}
                     //initial={{ x: -150, opacity: 0}}
                    // initial={{ x: -150, opacity: 0}}
                    //whileHover={{ scale: 1.05, transformOrigin: "center" }}
        isDarkMode={isDarkMode}>
                
       
                                      <ButtonContainer     onClick={() => {setWhitelistPopup(true) }} isDarkMode={isDarkMode}  smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>  
                                        <ButtonText>
                                          <TextSpan isDarkMode={isDarkMode}>Get Whitelisted</TextSpan>  
                                          <WhitelistIcon isDarkMode={isDarkMode}  />
                                        </ButtonText> 
                                      </ButtonContainer>
                              
                                   </Whitelist>
                                   </motion.div>
                                   </UtilitiesBottomBtnWrap>
                                   <SocialMediaWrap  
                     
                     isDarkMode={isDarkMode}
                          >
                          
                                <RoundedSocialButtons      >
                        
                            <XButton   href="https://twitter.com/web3outfitters" target="_blank">
                              <FaXTwitter />
                            </XButton>
          
                            <DiscordButton   href="https://discord.gg/qSk7XKdtHa" target="_blank" >
                              <FaDiscord />
                            </DiscordButton>
          
                            <InstagramButton    href="https://www.instagram.com/web3outfitters" target="_blank">
                              <FaInstagram />
                            </InstagramButton>
          
                            <YoutubeButton  href="https://www.youtube.com/@web3outfitters" target="_blank">
                              <FaYoutube />
                            </YoutubeButton>          
          
                            <TikTokButton href="https://www.tiktok.com/web3outfitters" target="_blank" >
                              <FaTiktok />
                            </TikTokButton>
          
                                </RoundedSocialButtons>
                                
                          </SocialMediaWrap>

                          
      <BottomContainer>



              <FullWidthText isDarkMode={isDarkMode}>
                  <b> Creative Collab <span style={{fontSize: `12px`}}>©</span> {new Date().getFullYear()}  </b>
                  <br />
                  Create. Collab. Together.
              </FullWidthText>

            

        <CenteredTitle isDarkMode={isDarkMode}>AUTHENTICATED FASHION<Verified/></CenteredTitle>
        <BottomRightContainer isDarkMode={isDarkMode}> 
      <BottomLink isDarkMode={isDarkMode} href="/Tos"><Terms isDarkMode={isDarkMode}/> Terms of Service</BottomLink>{' '}
    <BottomLink isDarkMode={isDarkMode} href="/PrivacyPolicy"><Privacy isDarkMode={isDarkMode}/> Privacy</BottomLink>{' '}
        </BottomRightContainer>
  
        </BottomContainer>
        {showBottomSection && <BottomSection/>}
    <BottomLine size= "10"/>
    </UtilitiesGuideContainer>
    </>
  );
}

export default UtilitiesGuide;





