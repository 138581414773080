import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import styled from 'styled-components';
import InfoPopup from '../InfoCardPopup/InfoPopup';
import MobileFeaturedCard from '../MobileFeaturedCard';
import {
  Col1, Col2, Col3,
  CollectionHeaderWrapper,
  FeaturedMainCard,
  MainCard, MainCollectionSubheader, MainCollectionTitle, MainDescription,
  MainImage, MainOwner,
  MainPrice, MainTitle,
  PlusButton,
  RarityMark,
  SmallText,
  ThreeColumnSection,
  ViewDropsButton
} from './CardElements.js';

export const GridItemWrapper = styled.div`
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;

export const GridItemWrapper2 = styled.div`
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 600px) {
    margin-top: 60px;
    display: flex;
    justify-content: center;
  }
`;

const AnimatedCollectionWrapper = styled(motion.div)`
  //width: ${({ isMobile, shouldAdjustForFilter }) => (isMobile ? '100%' : shouldAdjustForFilter ? '70%' : '90%')};
  float: left;
  height: 85vh;
  transform: translateX(-50%) !important;
  left: ${({ shouldAdjustForFilter }) => (shouldAdjustForFilter ? '62.5%' : '50%')}; /* Adjust left when shouldAdjustForFilter is true */
  bottom: 0;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;

  @media (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;
  }
`;

export const MainCardView = ({ isDarkMode, tempGeneralData, isFilterVisible, shouldAdjustForFilter }) => {
  const isMobile = window.innerWidth <= 600;
  const [selectedCard, setSelectedCard] = useState(null);

  const featuredMain = tempGeneralData[0];
  const otherMains = tempGeneralData.slice(1);

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleClosePopup = () => {
    setSelectedCard(null);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'blur-background') {
      handleClosePopup();
    }
  };

  const columnCount = window.innerWidth <= 600 ? 2 : window.innerWidth <= 900 ? 3 : shouldAdjustForFilter ? 5 : 6;
  const columnWidth = isMobile ? window.innerWidth / 2 : window.innerWidth * (shouldAdjustForFilter ? 0.7 : 0.9) / columnCount;
  const totalRowCount = Math.ceil((otherMains.length + 2) / columnCount); // Calculate total rows needed
  const rowHeight = isMobile ? 260 : 420;

  return (
    <>
      {selectedCard && (
        <InfoPopup
          selectedCard={selectedCard}
          handleClosePopup={handleClosePopup}
          handleOutsideClick={handleOutsideClick}
          isDarkMode={isDarkMode}
        />
      )}
      <AnimatedCollectionWrapper
        isMobile={isMobile}
        shouldAdjustForFilter={shouldAdjustForFilter}
        initial={{ width: '90%' }}
        animate={{ width: isMobile ? '100%' : (shouldAdjustForFilter ? '75%' : '90%') }}

        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        <Grid
          columnCount={columnCount}
          columnWidth={columnWidth}
          height={window.innerHeight}
          rowCount={totalRowCount}
          rowHeight={rowHeight}
          width={window.innerWidth}
          style={{ marginTop: isMobile ? '50px' : '0px' }}
        >
          {({ columnIndex, rowIndex, style }) => {
            let index = rowIndex * columnCount + columnIndex;

            if (rowIndex === 0 && columnIndex === 0) {
              // Render the header in the first row
              return (
                <GridItemWrapper key="header">
                  <CollectionHeaderWrapper isMobile={isMobile}>
                    <MainCollectionTitle isDarkMode={isDarkMode}>Our Collection</MainCollectionTitle>
                    <MainCollectionSubheader isDarkMode={isDarkMode}>
                      Explore the latest and greatest
                    </MainCollectionSubheader>
                  </CollectionHeaderWrapper>
                </GridItemWrapper>
              );
            }

            if (rowIndex === 1 && columnIndex === 0) {
              // Render the featured card in the second row
              return isMobile ? (
                <div style={{ marginTop: '0' }} key="mobile-featured">
                  <MobileFeaturedCard isDarkMode={isDarkMode} tempGeneralData={tempGeneralData} />
                </div>
              ) : (
                <GridItemWrapper key="featured">
                  <FeaturedMainCard isDarkMode={isDarkMode}>
                    <MainImage src={featuredMain.logo} alt="Featured Main Logo" />
                    <MainTitle>{featuredMain.header}</MainTitle>
                    <MainDescription>{featuredMain.name}</MainDescription>
                    <MainDescription>{featuredMain.description}</MainDescription>
                    <ThreeColumnSection>
                      <Col1>
                        <MainPrice>{featuredMain.ethPrice}</MainPrice>
                        <MainDescription>
                          Owner: <a href={`https://etherscan.io/address/${featuredMain.owner}`} style={{ color: 'lightblue' }}>{featuredMain.owner}</a>
                        </MainDescription>
                      </Col1>
                      <Col2>
                        <MainDescription>Last Sold: {featuredMain.lastSold}</MainDescription>
                        <MainDescription>Listed Time: {featuredMain.listedTime}</MainDescription>
                      </Col2>
                      <Col3>
                        <MainDescription>RARITY <RarityMark rarity={featuredMain.rarityLevel} isDarkMode={isDarkMode}>#{featuredMain.rarity}</RarityMark></MainDescription>
                        <MainDescription>Floor Difference: {featuredMain.floorDifference}</MainDescription>
                      </Col3>
                    </ThreeColumnSection>
                    <ViewDropsButton isDarkMode={isDarkMode}>View Latest Drops</ViewDropsButton>
                  </FeaturedMainCard>
                </GridItemWrapper>
              );
            }

            // Adjust index for the main cards
            index -= 2 * columnCount; // Subtract the two header rows

            if (index >= 0 && index < otherMains.length) {
              const main = otherMains[index];
              return isMobile ? (
                <GridItemWrapper2 key={main.id} style={style}>
                  <MainCard isMobile={isMobile} isDarkMode={isDarkMode}>
                    <MainImage src={main.logo} alt="Main Logo" />
                    <div>
                      <span>
                        <SmallText>RARITY <RarityMark rarity={main.rarityLevel} isDarkMode={isDarkMode}>#{main.rarity}</RarityMark></SmallText>
                        <MainPrice>{main.ethPrice}</MainPrice>
                        <MainDescription style={{ fontSize: '0.8em', color: '#999' }}>Last sale: {main.lastSold}</MainDescription>
                        <PlusButton onClick={() => handleCardClick(main)}>+</PlusButton>
                      </span>
                    </div>
                  </MainCard>
                </GridItemWrapper2>
              ) : (
                <GridItemWrapper2 key={main.id} style={{ ...style, marginTop: '-225px' }}>
                  <MainCard isMobile={isMobile} isDarkMode={isDarkMode}>
                    <MainImage src={main.logo} alt="Main Logo" />
                    <div>
                      <span>
                        <MainOwner>Owner: <a href={`https://etherscan.io/address/${main.owner}`} style={{ color: 'lightblue' }}>{main.owner}</a></MainOwner>
                        <SmallText>RARITY <RarityMark rarity={main.rarityLevel} isDarkMode={isDarkMode}>#{main.rarity}</RarityMark></SmallText>
                        <MainPrice>{main.ethPrice}</MainPrice>
                        <MainDescription style={{ fontSize: '0.8em', color: '#999' }}>Last sale: {main.lastSold}</MainDescription>
                        <SmallText>{main.listedTime}</SmallText>
                      </span>
                    </div>
                    <PlusButton onClick={() => handleCardClick(main)}>+</PlusButton>
                  </MainCard>
                </GridItemWrapper2>
              );
            }

            return null; // Return null for empty cells
          }}
        </Grid>
      </AnimatedCollectionWrapper>
    </>
  );
};
