import { motion } from 'framer-motion';
import { HiPlusCircle } from "react-icons/hi2";
import styled from 'styled-components';

// Grid container for Main cards
// Grid container for Main cards
export const MainGrid = styled(motion.div)`
  position: relative;
  display: grid;
  gap: 20px;
  padding: 50px;
  margin-top: 0vh;
  justify-content: center; /* Center the grid items */

  grid-template-columns: repeat(4, 1fr); /* 4 columns for MainCardView */

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr); /* 3 columns when width is <= 1200px */
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns when width is <= 900px */
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr); /* 1 column when width is <= 600px */
    justify-items: center; /* Center items horizontally within the grid */
  }
`;

// Individual Main card
// Individual Main card
export const MainCard = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#fff')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  border-radius: 10px;
  overflow: hidden;
  max-width: 240px;
  grid-column: span ${({ isMobile }) => (isMobile ? '0' : '1')};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;  /* Add relative positioning to control overflow of the image */

  &:hover {
    box-shadow: 0px 2.5px 7.5px rgba(0, 0, 0, 0.3);
    border-radius: 10px; /* Ensure the shadow remains rounded */
  }

  & > img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px 10px 0 0; /* Ensure border-radius is applied during hover */
    transition: transform 0.3s ease-in-out, border-radius 0.3s ease-in-out; /* Add transition to border-radius */

    &:hover {
      transform: scale(1.05);
      border-radius: 10px 10px 0 0; /* Maintain border-radius during hover */
    }
  }

  & > div {
    padding: 0px 10px;
    margin-top: -5px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px; /* Content div takes up 30% of the MainCard height */
    font-size: small;
  }

  @media screen and (max-width: 600px) {
    min-width: 175px;
    margin: 0 auto; /* Center card on mobile */
    margin-top: 0px;
    max-height: 275px;
    border-radius: 10px;
    margin-top: 10px;
    & > img {
      height: 200px; /* Image takes up 70% of the MainCard height */
      object-fit: cover;
      border-radius: 10px 10px 0 0; /* Ensure border-radius is applied on mobile */
      bottom: 5%;

      margin-top: -40px;
      position: relative;
      &:hover {
        transform: scale(1.05);
        border-radius: 10px 10px 0 0; /* Maintain border-radius during hover on mobile */
      }
    }

    & > div {
      padding: 0px 5px;
      height: 100px; /* Content div takes up 30% of the MainCard height */
    }
  }
`;


export const MainCard2 = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#fff')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ddd')};
  border-radius: 10px;

  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  max-height: 500px;
  max-width: 90%;
  @media screen and (max-width: 600px) {

    max-width: 350px;
    margin: 0 auto; /* Center card on mobile */
    //margin-top: 0px;
    max-height: 400px;
  }
`;
// Featured Main card
export const FeaturedMainCard = styled(MainCard2)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  //margin-top: 160px;
  width: 100%;
  min-width: 100%;
  max-height: 400px;
    & > img {
      width: 100%;
      height: 50%;
      min-height: 100px;
      overflow: hidden; /* Prevent overflow */
    }
`;



// Three-column layout for FeaturedMainCard details
export const ThreeColumnSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 50px 250px; /* First value for vertical gap, second for horizontal gap */
  position: relative;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

export const Col1 = styled.div`
  display: flex;
  flex-direction: column;

  
`;

export const Col2 = styled.div`
  display: flex;
  flex-direction: column;

  
`;

export const Col3 = styled.div`
  display: flex;
  flex-direction: column;

  
`;

// Styled components for card elements
export const MainImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
`;

export const MainTitle = styled.h3`
  margin: 10px 0;
  font-size: 1.2em;
`;

export const MainDescription = styled.p`
  color: ${({ isDarkMode }) => (isDarkMode ? '#ddd' : '#666')};
`;

export const MainPrice = styled.p`
  font-weight: bold;
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

export const MainOwner = styled.p`
  font-size: 0.9em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#aaa' : '#888')};
`;

// Title for the Main collection
export const CollectionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: ${({ isMobile }) => isMobile ? '12.5vh' : '12.5vh'};
  white-space: nowrap;
  margin-left: 5%;
  @media screen and (max-width: 600px) {
   // grid-template-columns: 1fr;
   // max-width: 300px;
   font-size: 0.8rem;

  }
`;

export const MainCollectionTitle = styled.h2`
  font-size: 2em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  text-align: left;
  margin: 0;
  white-space: nowrap;
`;

export const MainCollectionSubheader = styled.h3`
  font-size: 1.5em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#aaa' : '#666')};
  text-align: left;
  margin: 0;
  white-space: nowrap;
`;

export const ViewDropsButton = styled.button`
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ddd')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 0px;
`;

export const SmallText = styled.span`
  font-size: 0.8em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#ccc' : '#999')};
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
`;

export const PlusButton= styled(HiPlusCircle)`
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  border: none;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  cursor: pointer;
  position: relative;
  bottom: 5px;
  left: 85%;

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 600px) {
    width: 15px;
    height: 15px;
    position: absolute;
  }
`;

export const MainPopup = styled.div`
  background: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#fff')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  border-radius: 10px;
  padding: 20px;
  width: 70vw;
  height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
`;

export const BlurBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 9999;
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 99999999999;
`;

export const RarityMark = styled.mark`
  margin: 0 0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  font-size: 0.6em;
  background-image: ${({ rarity }) => {
    switch (rarity) {
      case 'legendary':
        return 'linear-gradient(to right, rgba(255, 225, 0, 0.1), rgba(255, 225, 0, 0.7) 4%, rgba(255, 180, 0, 0.3))';
      case 'epic':
        return 'linear-gradient(to right, rgba(255, 180, 0, 0.1), rgba(255, 127, 0, 0.7) 4%, rgba(255, 127, 0, 0.3))';
      case 'rare':
        return 'linear-gradient(to right, rgba(255, 127, 0, 0.1), rgba(255, 64, 0, 0.7) 4%, rgba(255, 64, 0, 0.3))';
      case 'uncommon':
        return 'linear-gradient(to right, rgba(255, 64, 0, 0.1), rgba(255, 0, 0, 0.7) 4%, rgba(255, 0, 0, 0.3))';
      case 'common':
        return 'linear-gradient(to right, rgba(255, 0, 0, 0.1), rgba(192, 192, 192, 0.7) 4%, rgba(192, 192, 192, 0.3))';
      default:
        return 'linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.7) 4%, rgba(255, 255, 255, 0.3))';
    }
  }};
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
`;
