// JoinTeamButton.js
import React, { useRef, useState } from 'react';
import { CircleBubbles } from '../../Global/GlobalElements';

export const JoinTeamButton = ({ isDarkMode, setDaoPopup }) => {
  const [buttonHovered, setButtonHovered] = useState(false);
  
  const buttonRef = useRef();
  const [svgAnimationDone, setSvgAnimationDone] = useState(false);

  const handleSvgAnimationEnd = () => {
    setSvgAnimationDone(true);
  };
  // State to hold the position offsets
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  // Function to handle cursor movement
  const handleMouseMove = (e) => {
    if (!buttonRef.current) return;

    const rect = buttonRef.current.getBoundingClientRect();
    const distanceX = e.clientX - (rect.left + rect.width / 2);
    const distanceY = e.clientY - (rect.top + rect.height / 2);
    const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);

    const proximity = 150; // Adjust the proximity as needed

    if (distance < proximity) {
      // Calculate the attraction effect
      const maxOffset = 20; // Maximum offset in pixels
      const dx =
        (-distanceX / distance) *
        ((proximity - distance) * (maxOffset / proximity));
      const dy =
        (-distanceY / distance) *
        ((proximity - distance) * (maxOffset / proximity));

      setOffset({ x: dx, y: dy });
    } else {
      setOffset({ x: 0, y: 0 });
    }
  };

  return (
    <div
      onMouseMove={handleMouseMove}
      onMouseLeave={() => {
        setOffset({ x: 0, y: 0 });
        setButtonHovered(false);
        setSvgAnimationDone(false); // Reset SVG animation state when mouse leaves
      }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        transform: 'translateX(-50%)',
        left: '50%',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <CircleBubbles
        ref={buttonRef}
        isDarkMode={isDarkMode}
        onClick={() => setDaoPopup(true)}
        className={buttonHovered ? 'animate' : ''}
        onMouseEnter={() => setButtonHovered(true)}
        onMouseLeave={() => setButtonHovered(false)}
        style={{
          transform: `translate(${offset.x}px, ${offset.y}px)`,
        }}
      >


        {/* SVG for curved rotating text */}
        <svg viewBox="0 0 240 240">
          <defs>
            <path
              id="textPath"
              d="
                M120,120
                m-100,0
                a100,100 0 1,1 200,0
                a100,100 0 1,1 -200,0
              "
            />
          </defs>
          <text
            fill={isDarkMode ? '#000' : '#fff'}
            fontSize="16"
            fontWeight="bold"
          >
            <textPath
              href="#textPath"
              startOffset="50%"
              textAnchor="middle"
            >
              JOIN TEAM • JOIN TEAM • JOIN TEAM • JOIN TEAM • JOIN TEAM • JOIN TEAM •
            </textPath>
          </text>
        </svg>
      </CircleBubbles>

    </div>
  );
};
