// FilterVertElements.js

import { motion } from 'framer-motion';
import { IoMdArrowDropdownCircle } from "react-icons/io";
import Slider from 'react-slider';
import styled from 'styled-components';

// Themes for light and dark modes
export const lightTheme = {
  background: `
    background: -webkit-linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    background: -o-linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    background: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    color: #333332;
  `,
};

export const darkTheme = {
  background: `
    background: -webkit-linear-gradient(to top, #283E51, #0A2342);
    background: linear-gradient(to right, #434343 0%, black 100%);
    background: -o-linear-gradient(to top, #283E51, #0A2342);
    background: -webkit-linear-gradient(to right, #434343 0%, black 100%);
    background: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
    color: #FAF4E5;
  `,
};

// FilterVertElements.js

// FilterVertElements.js

export const FilterContainer = styled(motion.div)`
  position: absolute;
  width: ${({ isMobile }) => (isMobile ? '100vw' : '21.5vw')};
  height: ${({ isMobile }) => (isMobile ? '100vh' : '73.5vh')};
  top: ${({ isMobile }) => (isMobile ? '0' : 'initial')};
  margin-left: ${({ isMobile }) => (isMobile ? 'initial' : '2.5%')};
  bottom: 0;
  transform: translateX(-50%);
  //padding: 40px 20px 100px 20px;
  background-color: ${({ isDarkMode }) =>
    !isDarkMode ? darkTheme.background : lightTheme.background};
  color: ${({ isDarkMode }) => (isDarkMode ? darkTheme.color : lightTheme.color)};
  display: flex;
  flex-direction: column;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow-y: auto; /* Added to make contents scrollable */
  z-index: 99999;
  @media (max-width: 600px) {
    border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  }
`;

export const FilterContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px; /* Adjust padding as needed */
  bottom: 0;
`;

// FilterVertElements.js

export const CategoryContainer = styled.div`
  border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ccc')};
  border-radius: 5px;
  margin-bottom: 10px;
`;

// FilterVertElements.js

export const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  background-color: ${({ isSelected, isDarkMode }) =>
    isSelected
      ? isDarkMode
        ? '#4b3b0a' /* Dark gold for dark mode */
        : '#fff8dc' /* Light gold for light mode */
      : isDarkMode
      ? '#444'
      : '#f9f9f9'};
`;


export const CategoryInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const CategoryIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

// FilterVertElements.js

export const CategoryName = styled.span`
  font-weight: bold;
  color: ${({ isSelected }) => (isSelected ? '#FFD700' : 'silver')}; /* Gold when selected, silver by default */
`;


export const CategoryCount = styled.span`
  margin-left: 5px;
  color: ${({ isDarkMode }) => (isDarkMode ? '#aaa' : '#666')};
`;

export const DropdownArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: ${({ isExpanded, isDarkMode }) =>
    isExpanded ? 'none' : `6px solid ${isDarkMode ? '#fff' : '#000'}`};
  border-bottom: ${({ isExpanded, isDarkMode }) =>
    isExpanded ? `6px solid ${isDarkMode ? '#fff' : '#000'}` : 'none'};
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s;
`;

export const TraitsList =  styled(motion.div)`
  max-height: 200px;
  overflow-y: auto;
  border-top: 0.25px solid ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ccc')};
  //border-bottom: 0.05px;
`;

// FilterVertElements.js

export const TraitItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 0.2px solid ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ccc')};
  color: ${({ isSelected, isDarkMode }) =>
    isSelected
      ? 'silver' // Silver when selected
      : isDarkMode
      ? 'white'   // White in dark mode
      : 'black'};  // Black in light mode
  background-color: ${({ isSelected, isDarkMode }) =>
    isSelected
      ? '#fff8dc' // Light gold when selected
      : isDarkMode
      ? '#333'    // Dark background in dark mode
      : 'white'};  // White background in light mode
`;



// FilterVertElements.js

export const CheckboxWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  opacity: 0;
  cursor: pointer;
  margin: 0;
`;


export const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ checked }) => (checked ? 'gold' : 'initial')};
  border: 0.25px solid ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  border-radius: 3px;
  position: relative;
  pointer-events: none; /* Allow clicks to pass through */

  &:after {
    content: '';
    position: absolute;
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    left: 5px;
    top: 1px;
    width: 3px;
    height: 8px;
    border: solid ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;


export const Checkbox = ({ checked, onChange, isDarkMode }) => (
  <CheckboxWrapper>
    <HiddenCheckbox checked={checked} onChange={onChange} />
    <StyledCheckbox checked={checked} isDarkMode={isDarkMode} />
  </CheckboxWrapper>
);


// {export const Checkbox = styled.input`
//   width: 16px;
//   height: 16px;
//   accent-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
//   cursor: pointer;
// `;}

export const TraitName = styled.span`
  flex-grow: 1;
`;

export const TraitSymbol = styled.img`
  width: 16px;
  height: 16px;
  margin-left: auto;
`;

export const FilterWrapper = styled(motion.div)`
  width: 80%;
  height: 100%;
  position: absolute;
  top: 12.5%;
  left: 50%;
  transform: translateX(-50%);
`;

export const FilterSection = styled.div`
  margin-bottom: 20px;
  color: ${({ isDarkMode }) => isDarkMode ? lightTheme.color : darkTheme.color};
  position: relative;
`;
// FilterVertElements.js

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky; /* Keeps it at the top within the scrollable container */
  top: 0;
  width: 100%;
  background-color: ${({ isDarkMode }) =>
    isDarkMode ? lightTheme.background : darkTheme.background};
  //padding: 10px 20px;
  height: 50px;
  border-bottom: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ccc')};
  z-index: 1;
  @media (max-width: 600px) {
    padding: 65px;
  }
`;


export const FilterHeader = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

  //top: 60%;
  @media (max-width: 600px) {
 
    top: 60%;
  }
`;



export const FilterTitle = styled.h3`
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 1rem;
  text-align: center;
  color: ${({ isDarkMode }) => isDarkMode ? lightTheme.color : darkTheme.color};
`;

// FilterVertElements.js

export const DropdownContainer = styled.div`
  position: relative;
  min-width: 100px;
  border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ccc')};
  border-radius: 5px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#444' : '#fff')};
  cursor: pointer;
  margin: 0 auto;
`;

export const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: space-between;
`;

export const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#444' : '#fff')};
  border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ccc')};
  border-radius: 5px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
`;

export const DropdownItem = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#555' : '#eee')};
  }
`;




export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const PriceRangeInput = styled.input`
  padding: 5px;
  max-width: 120px;
  height: 8px;
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 0.25rem;
  text-align: center;
  border: none;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#f0f0f0')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  appearance: none;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ccc')};
  }
`;

export const RarityRangeInput = styled.input`
  padding: 5px;
  max-width: 120px;
  height: 8px;
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 0.25rem;
  text-align: center;
  border: none;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#f0f0f0')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  appearance: none;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ccc')};
  }
`;

export const ApplyButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: gold;
  color: black;
  cursor: pointer;
  margin-top: 10px;
  width: 48%;

  &:hover {
    background-color: darkorange;
  }
`;

export const DefaultButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  cursor: pointer;
  margin-top: 10px;
  width: 48%;

  &:hover {
    background-color: darkgray;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 65px;
`;

export const InlineContainer = styled.div`


  @media (max-width: 600px) {
  //  display: initial;
  //align-items: initial;
 // justify-content: initial;
  }
`;

export const MinMaxContainer = styled.div`
  position: relative;
  min-width: 50px;
  height: 30px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  padding: 0 10px;
  margin-right: 20px;
`;

export const MinMaxWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  &:hover > div {
    display: block;
  }
`;

export const StyledSlider = styled(Slider)`
  width: 200px;
  height: 30px;

  .track {
    background: ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ccc')};
    height: 30px;
    border-radius: 20px;
  }
  
  .thumb {
    background: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    width: 30px;
    height: 30px;
    border-radius: 80%;
    cursor: pointer;
  }
  
  .thumb:hover {
    box-shadow: 0 0 10px ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  }
`;

export const MinMaxInput = styled.input`
  padding: 5px;
  max-width: 50px;
  height: 20px;
  border-radius: 10px;
  font-size: ${({ value }) => (value.length > 5 ? '0.7rem' : '0.8rem')}; /* Dynamically shrink font */
  text-align: center;
  border: none;
  background-color: transparent;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  appearance: none;
  
  &:focus {
    outline: none;
    border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ccc')};
  }

  ::-webkit-input-placeholder {
    -webkit-appearance: none; 
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
  }    
`;

export const IoDropIcon = styled(IoMdArrowDropdownCircle)`
  font-size: 20px;
  margin-right: -8px;
  margin-left: -8px;
  color: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#fff')};
`;

// Export all components


