import { motion } from 'framer-motion';
import { BsTools } from 'react-icons/bs';
import { GiShoppingBag } from 'react-icons/gi';
import { IoArrowBackCircle } from "react-icons/io5";
import { RiTeamFill } from 'react-icons/ri';
import { TbSwitch2 } from 'react-icons/tb';
import { Link as LinkR } from 'react-router-dom';

import styled, { keyframes } from 'styled-components';
export const ComingSoon = styled.div`
  width: 140px;
  height: 60px;
  position: absolute;
  
  top: 50%;
  transform: translate(-10%, 0%) scale(0.75);
  border-style: none;
  object-fit: cover; /* Ensure the image fits the container without overflowing */
  cursor: pointer;
  pointer-events: none;
  border:0;
  padding: 5px;
  pointer-events: none;

  @media screen and (max-width: 1200px) {
  
    transform: scale(0.65);
  }
  @media screen and (max-width: 960px) {

    transform: scale(0.55);
  }
  @media screen and (max-width: 768px) {
   
    transform: scale(0.45);
  }
  &:hover {
  }
`;

export const ComingSoonCont = styled.div`




//top: 15%;
pointer-events: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 999999999;
  text-decoration: none;
  &:hover {
    box-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  }
`;

export const LogoName = styled(motion.div)`
  display: flex;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);

  float: left;

  border-style: none;
  position: absolute;
  border:0;
  pointer-events: none;


  
  z-index: 2;
  left: 0;


  transform-origin: right center; /* Set the transform origin to the left edge */
  //transform: scale(0.85);


`;

export const LogoName2 = styled(motion.div)`
  display: flex;
  align-items: center;
  -webkit-perspective:700px;
  perspective: 700px;
  border-style: none;
  left: 52%;
  transform:  translate(-50%, -50%);
  pointer-events: none;
  float: left;
  position: relative;


  z-index: 3;
  //left: 30px;
  transform-origin: left center; /* Set the transform origin to the left edge */
  //transform: scale(0.85);

`;
export const PopUpText = styled.div`


`;
export const PopUpText2 = styled(motion.div)`
text-align: center;
justify-content: center;
align-items: center;
//position: absolute;
font-size: 1.75rem;
font-weight: 500;
margin-top: 7.5%;
//left: 50%;
//transform: translate(-50%, 50%);
  border-style: none;
  cursor: pointer;
  letter-spacing: -2px;
  border-style: none;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
`;


export const PopUpBox = styled(motion.div)` 

  position: fixed;
  
  margin-top: -70%;
  margin-left: 65%;
  border-style: none;

  //visibility: hidden;

  //background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};

    //color: #fff;
   // background-color: rgba(0, 0, 0, 0.8);
    width: 230px;
    padding: 8px 8px;
    height: 75px;
    padding: 5px 5px;
    border-radius: 20px;
    z-index: 9999999999999;
    white-space: nowrap;


  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 45%;
    //transform: translateX(-50%);
    //top: -10px;
    top: 75px;
    position: absolute;
    white-space: nowrap;
    z-index: 9999999999999;
    border: 10px solid;
    background: initial;
   // transform: rotate(-225deg);
   transform: rotate(180deg);


   border-color: ${({ isDarkMode }) => (isDarkMode ? 'transparent transparent rgba(250, 250, 250, 0.8)' : 'transparent transparent rgba(0, 0, 0, 0.8)')};

  }

  @media screen and (max-width: 1024px) {
   // zoom: 0.5;
   opacity: 0;
   display: none;
   visibility: hidden;
}

`;

export const PopUpCard = styled.div`
  position: relative;

  z-index: 9999999999999;
`;
export const OverlayContainer = styled.div`
   
   width: 100%;
    justify-content: center;
    align-items: center;
    display: grid;
    //background: #FFFFFF;
    position: fixed;
   // padding: 0 50px;
   // overflow: hidden;
    //overflow: hidden;
    z-index: 99999;
   
    bottom: 10%;
    left: 0;
    right: 0;
    @media screen and (max-width: 870px) {
          //transform: scale(0.85);
          //zoom: 0.85;
          bottom: 11%;
    }
  
 // box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for a subtle effect */
`;

export const OverlayButtonWrapper = styled.div`
   
   display: flex;
    
    z-index: 1;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    justify-content: center;
 
    @media screen and (max-width: 1024px) {
      display: block;
    }

`
export const OverlayButtonRow = styled.div`


`
export const OverlaySwitchSpan = styled.span`
  
    position: absolute;
    margin-left: -20%;
    transform: translateX(-5%);

   
    color: black;
    margin-top: 10px;
    font-family: 'Inter';
    font-weight: 900;
    font-size: 12px;
    line-height: 26px;
    @media screen and (max-width: 1024px) {
  
      margin-left: 15%;
    }
    /* or 81% */
`

export const OverlaySwitchSpan2 = styled.span`
    
    white-space: nowrap;
    position: absolute;
    margin-left: 105%;
    transform: translateX(-5%);

   
    color: black;
    margin-top: 4px;
    color: black;
    font-family: 'Inter';
    font-weight: 900;
    font-size: 12px;
    line-height: 26px;
    @media screen and (max-width: 1024px) {
  
        margin-left: 68.5%;
}
    /* or 81% */
`
export const ModelSwitch = styled(motion.div)`
   position: absolute;
   left: 50%;


   transform: translateX(-50%) scale(0.95);
   & :hover + ${PopUpBox} {

  }
  @media screen and (max-width: 1024px) {
    transform: translateX(-50%) scale(0.65);
    margin-top: 0.5%;
      }
      
`;

export const Shop = styled(motion.div)`
  position: absolute;
  right: 7.5%;
  transform: scale(0.85);
  & :hover + ${PopUpBox} {
  
  }

    
  @media screen and (max-width: 1024px) {
      //width: 175px;
      transform: scale(0.75);
        right: 10%;

   }
`;

export const YourCloset = styled.div`
  & :hover + ${PopUpBox} {



  }
    display: flex;
    position: absolute;
    top: 40%;
    left: 75%;
    margin-right: -50%;
    z-index: 99999;
    @media screen and (max-width: 1024px) {
        align-items: center;
        display:block;
        margin: auto;
        margin-top: 175px;
    }
`;

export const Utilities = styled(motion.div)`
  position: absolute;
  left: 7.5%;
  transform: scale(0.85);
  & :hover + ${PopUpBox} {



  }
    @media screen and (max-width: 1024px) {
      //width: 175px;
      transform: scale(0.75);
        left: 10%;

   }
`;


export const UtilitiesIcon = styled(BsTools)`
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   
   border-radius: 50%;
   padding: 8px; /* Adjust the padding as per your requirements */
   margin-top: 5px;
   margin-left: 20px;
   transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
   font-size: 40px; /* Adjust the font size as per your requirements */
   
     &:hover {
 
 
 }
 @media screen and (max-width: 1024px) {
   border-radius: initial;
   padding: initial; /* Adjust the padding as per your requirements */
   margin-top: initial;
   margin-left: initial;
   font-size: initial; 
    position: absolute;
    
      left: 50%;
      top: 50%;
      background: none;
      transform: translate(-50%, -50%) matrix(1.5, 0, 0, 1.7, 0, 0);
      background: none;
     }
`;

export const ShopIcon = styled(GiShoppingBag)`
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   
   border-radius: 50%;
   padding: 8px; /* Adjust the padding as per your requirements */
   margin-top: 5px;
   margin-left: 20px;
   transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
   font-size: 40px; /* Adjust the font size as per your requirements */
   
     &:hover {
 
 
 }
 @media screen and (max-width: 1024px) {
   border-radius: initial;
   padding: initial; /* Adjust the padding as per your requirements */
   margin-top: initial;
   margin-left: initial;
   font-size: initial; 
    position: absolute;
    
      left: 50%;
      top: 50%;
      background: none;
      transform: translate(-50%, -50%) matrix(1.5, 0, 0, 1.7, 0, 0);
      background: none;
     }
`;

export const ModelSwitchIcon = styled(TbSwitch2)`
  //background-color: #fff;

  border-radius: 100%;
  padding: 8px; /* Adjust the padding as per your requirements */
  margin-top: 5px;
  margin-left: 20px;
  transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
  font-size: 40px; /* Adjust the font size as per your requirements */
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   
    //display: flex;
    &:hover {
    background-color: #000;

      filter: drop-shadow(0 0 10px white);

}
`;

export const MeatTeam = styled(RiTeamFill)`
  //background-color: #fff;
  border-radius: 100%;
  padding: 8px; /* Adjust the padding as per your requirements */
  margin-top: 5px;
  margin-left: 20px;
  transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
  font-size: 40px; /* Adjust the font size as per your requirements */
  color: #000;
  
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  color: ${({ orbitControlsEnabled, isDarkMode }) =>
    orbitControlsEnabled ? '#fff' : isDarkMode ? '#000' : '#fff'};
    //display: flex;
    &:hover {
    background-color: #000;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0 0 10px white);

}
`;

export const TextSpan2 = styled.span`
    color: #fff;
    padding: 10px 0px 30px 15px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    color: ${({ orbitControlsEnabled, isDarkMode }) =>
    orbitControlsEnabled ? '#fff' : isDarkMode ? '#000' : '#fff'};
`


export const TextSpan = styled.span`
    color: #fff;
    padding: 10px 0px 30px 15px;
    color: ${({ orbitControlsEnabled, isDarkMode }) =>
    orbitControlsEnabled ? 'initial' : isDarkMode ? '#000' : '#fff'};
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};

    @media screen and (max-width: 1024px) {
          //display: none;
          color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
          position: absolute;
          white-space: nowrap;
          top: 100%;
          left: 50%;
         
          padding: 0px 0px 0px 0px;
          transform: translate(-50%, 100%)  scale(0.8);
          align-items: center;
          text-align: center;
          justify-content: center;
    }
`

export const ButtonText = styled.div`
   position: relative; /* Added position: relative */
   transition: transform 0.2s ease-in-out;
   // transform: ; /* Adjust the value as per your requirements */
    padding: 5px 0px 0px 0px;
    //transform: matrix(0,0,0,0,0,0);

    transform: translateY(-12px) matrix(1, 0, 0, .8, 0, 0);
    @media screen and (max-width: 1024px) {
      padding: 25px 0px 0px 0px;
    transform: matrix(1, 0, 0, .8, 0, 0);
    }
`
export const ButtonText2 = styled.div`
   position: relative; /* Added position: relative */
   transition: transform 0.2s ease-in-out;
   // transform: ; /* Adjust the value as per your requirements */
    padding: 5px 0px 0px 0px;
    //transform: matrix(0,0,0,0,0,0);

    transform: translateY(-12px) matrix(1, 0, 0, .8, 0, 0);

`
export const Button = styled(LinkR)`
    display: inline-block;
    width: 240px;
    height: 65px;
    backdrop-filter: blur(10px);
    border-radius: 90px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */

    color: #000;



  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${UtilitiesIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(2px 2px 2px white);
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }

    ${ShopIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(2px 2px 2px white);
    }

    ${ModelSwitchIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(2px 2px 2px white);
    }
  }


  @media screen and (max-width: 1024px) {
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${UtilitiesIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(0px 0px 0px white);
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }

    ${ShopIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(0px 0px 0px white);
    }

    ${ModelSwitchIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(0px 0px 0px white);
    }
  }
  }


`;

export const slideInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

// Wrapper component for the buttons
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${slideInAnimation} 0.5s ease-in-out forwards;
`;

export const Button2 = styled(LinkR)`
    height: 5px;
    color: #fff;
    width: 240px;
    padding: 40px;
    backdrop-filter: blur(10px);
    margin-left: 50%;
    transform: translateX(-50%);
    border-radius: 90px;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
    padding: ${({big}) => (big ? '10px 40px' : '20px 30px')} ;
    text-decoration: none;
    outline: none;
    border: none;
    cursor: pointer;
   // display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 81% */

    display: flex;
    align-items: center;

    color: #000;
    z-index: 99999;
&:hover {
    transition: all 0.2s ease-in-out;

    backdrop-filter: blur(5px);
    //background: ${({primary}) => (primary ? '#fff' : '#01BF71')};
    color: ${({dark}) => (dark ? '#fff' : '#fff')};
}
@media screen and (max-width: 1024px) {
      display: block;
      justify-content: center;
      font-size: 0;
      width: 5px;
      padding: 10px;
      
    }
`;



//Mobile join Team button:

//Mobile join Team button:


const black = "#000";
const white = "#fff";
const silver = "#c0c0c0";

export const Body = styled.body`
  font-size: 16px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  text-align: center;

  z-index: 999999999999;
  background-color: ${silver};
`;

export const BubblyButton = styled.button`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
      display: inline-block;
      //zoom: 1.25;
      -webkit-appearance: none;
      appearance: none;


       background: ${({ orbitControlsEnabled, isDarkMode }) =>
    orbitControlsEnabled ? 'initial' : isDarkMode ? white : black};
    box-shadow: ${({ orbitControlsEnabled }) => (orbitControlsEnabled ? 'initial' : '0 2px 25px rgba(192, 192, 192, 0.5)')};
      
      border-radius: 4px;
      border: none;
      cursor: pointer;
      position: relative;
      transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
     // box-shadow: 0 2px 25px rgba(192, 192, 192, 0.5);
      width: 200px;
      height: 50px;
      &:focus {
        outline: 0;
      }

      &:before,
      &:after {
        position: absolute;
        content: '';
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all ease-in-out 0.5s;
        background-repeat: no-repeat;
      }

      &:before {
        display: none;
        top: -120%;
        margin-top: -20px;
      //  margin-left: 30px;
        zoom: 1.25;
        transform: scale(1.5);
        background-image:
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, transparent 20%, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 30%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, transparent 10%, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 15%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%);
        background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
      }

      &:after {
        display: none;
        zoom: 1.25;
       // margin-left: 30px;
        transform: scale(1.5);
        bottom: -120%;
        margin-top: 0px;
        background-image:
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, transparent 10%, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 15%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%);
        background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
      }
      &:active {

        transform: scale(0.9);
        background-color: ${black};
        box-shadow: 0 2px 25px rgba(192, 192, 192, 0.2);
  }
`;





export const animate = keyframes`
  0% {
    top: -30px;
    left: 20px;
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    top: -30px;
    left: 20px;
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    top: -30px;
    left: 20px;
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
`;

export const AnimateBubbles = styled(BubblyButton)`

  
    cursor: pointer;
    display: inline-block;
   
    width: 240px;
    height: 65px;
    border-radius: 90px;
    box-sizing: border-box;
    ///box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);

    box-shadow: ${({ orbitControlsEnabled }) => (orbitControlsEnabled ? 'initial' : '10px 0px 20px 0px rgba(0, 0, 0, 0.2)')};
      
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */

    color: #000;



  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
/*
  &:before {
    background-color: initial;
    background-image: ${({ isDarkMode }) =>
  isDarkMode
    ? 'linear-gradient(#000 0, rgba(0, 0, 0, 0) 100%)' // Black background
    : 'linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%)' // Original white background
};
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
  }

*/

  
  &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
   // box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    box-shadow: ${({ orbitControlsEnabled }) => (orbitControlsEnabled ? 'initial' : 'rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px')};
    transform: scale(1.05);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${ShopIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      color: #fff;
      //filter: drop-shadow(2px 2px 2px white);
    }
    ${UtilitiesIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      //filter: drop-shadow(2px 2px 2px white);
    }
    ${TextSpan2} {
      /* styles for the container when button is hovered */
     // background-color: #000;

      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
      filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};

    }
    ${ModelSwitchIcon} {
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    }
    ${MeatTeam} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
     // filter: drop-shadow(2px 2px 2px white);
      color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    }
  }


  @media screen and (max-width: 1024px) {
    /*
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    */
    &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${ShopIcon} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : 'none')};
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${UtilitiesIcon} {
      /* styles for the container when button is hovered */
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }
    ${ModelSwitchIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(0px 0px 0px white);
    }
  }
  }
  @media screen and (max-width: 1024px) {
          //transform: scale(0.7);
          //top: 10px;
       
          //left: 75%;
    }

  
  &.animate {
    &:before {
      display: block;
      animation: ${animate} ease-in-out 0.75s forwards;
    }
    &:after {
      display: block;
      animation: ${animate} ease-in-out 0.75s forwards;
    }
  }

`;


export const BubblyButton2 = styled(motion.button)`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
      display: inline-block;
      //zoom: 1.25;
      -webkit-appearance: none;
      appearance: none;

      background-color: #000;
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      //background-color: ${({ isDarkMode }) => (isDarkMode ? white : black)};
      
      border-radius: 4px;
      border: none;
      cursor: pointer;
      position: relative;
      transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
      //box-shadow: 0 2px 25px rgba(192, 192, 192, 0.5);
      width: 200px;
      height: 50px;
      
      &:focus {
        outline: 0;
      }

      &:before,
      &:after {
        position: absolute;
        content: '';
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all ease-in-out 0.5s;
        background-repeat: no-repeat;
      }




`;
export const AnimateBubbles2 = styled(BubblyButton2)`



    cursor: pointer;
    display: inline-block;
   
    width: 240px;
    height: 65px;
    border-radius: 90px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */

    color: #000;



  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  /*
  &:before {
    background-color: initial;
    background-image: ${({ isDarkMode }) =>
      isDarkMode
        ? 'linear-gradient(#A9A9A9 0, rgba(0, 0, 0, 0) 100%)' // Black background
        : 'linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%)' // Original white background
    };
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
  }
*/


&:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${ShopIcon} {
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      //filter: drop-shadow(2px 2px 2px white);
    }
    ${UtilitiesIcon} {
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    }
    ${TextSpan}{
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};

    }
    ${ModelSwitchIcon} {
      /* styles for the container when button is hovered */
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};

    }
  }


  @media screen and (max-width: 1024px) {
    width: 55px;
    height: 55px;
    align-items: center;
    justify-content: center;
    background-color: #000;
    display: flex;
    color: #fff;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${ShopIcon} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : 'none')};
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${UtilitiesIcon} {
      /* styles for the container when button is hovered */
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }
    ${ModelSwitchIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(0px 0px 0px white);
    }
  }
  }
  @media screen and (max-width: 1024px) {
          //transform: scale(0.7);
          top: 10px;
       
          //left: 75%;
    }

  
  &.animate {
    &:before {
      display: block;
      animation: ${animate} ease-in-out 0.75s forwards;
    }
    &:after {
      display: block;
      animation: ${animate} ease-in-out 0.75s forwards;
    }
  }
`;
export const LeftButtonWrapper = styled(motion.div)`
    position: fixed;
    left: 2.5%;
    transform:scale(0.5) !important;
    @media screen and (max-width: 600px) {
          //transform: scale(0.7);
          margin-top: 5%;
          left: 0%;
          transform: translateX(-20%) scale(0.5) !important;
          //left: 75%;
    }

`
export const MeetTeam = styled(motion.div)`
   position: relative;
   display: flex;
   //left: 49.25%;

   //top: -50px;
    left: 125px;

      
`;
export const TeamIcon = styled(RiTeamFill)`
  background-color: #fff;
  border-radius: 100%;
  padding: 8px; /* Adjust the padding as per your requirements */
  margin-top: 5px;
  margin-left: 20px;
  transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
  font-size: 40px; /* Adjust the font size as per your requirements */
  color: #000;
    //display: flex;
    &:hover {
    background-color: #000;

      filter: drop-shadow(0 0 10px white);

}
  
`;




///
///  Inspect Model
///



export const GoBack = styled(IoArrowBackCircle)`
   
   opacity: 1;
   z-index: 99999999999999;
   font-size: 40px;
   width: 55px;
    height: 55px;
    align-items: center;
    justify-content: center;
    //background-color: #000;
    //border-radius: 50%;
    padding: 8px;
    color: #fff;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    // background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    
    color: ${({ orbitControlsEnabled, isDarkMode }) =>
    orbitControlsEnabled ? '#fff' : isDarkMode ? '#000' : '#fff'};
  &:hover {

    }
    @media screen and (max-width: 1024px) {
 

    } 
`
export const GoBackWrap = styled(motion.div)`
   position: fixed;
    left:  2.5%;
  // transform: translateX(-100%);
   //top: -50px;
   display: grid;
   &:hover  {
  
    ${GoBack} {
      zoom: 1.02;
     filter: drop-shadow(0 0 5px yellow);
    }

  }

`



