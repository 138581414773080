import React, { useEffect, useState } from 'react';
import { FaCaretDown, FaCaretUp, FaEye, FaGem, FaGlobe, FaInfoCircle } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import symbol from '../../../../../images/w3oBlackLogo.png';
import {
  AnimateBubbles,
  CompanyContainer,
  CompanyLink,
  CompanySymbol,
  DiamondButton,
  DiamondColorWrap,
  DiamondCount,
  DropdownMenu,
  DropdownToggle,
  HorzLine,
  NavbarItem,
  NavbarItemLast,
  ProjectName,
  SubNavbar,
  SubscriberCount,
  VerifiedSymbol,
  WebsiteLabel
} from "./NavbarComponents";

const dropdownVariants = {
  hidden: { opacity: 0, y: 20, filter: 'blur(15px)' },
  visible: { 
    opacity: 1, 
    y: 0, 
    filter: 'blur(0px)', 
    transition: { duration: 0.05, staggerChildren: 0.05 }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20, filter: 'blur(15px)' },
  visible: { opacity: 1, y: 0, filter: 'blur(0px)', transition: { duration: 0.3 } }
};

const navbarVariants = {
  hidden: { width: 0, opacity: 0 },
  visible: { 
    width: '95%', 
    opacity: 1, 
    transition: { 
      duration: 1, 
      ease: 'easeInOut', 
      when: 'beforeChildren', 
      staggerChildren: 0.2 
    } 
  }
};

const itemPopVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } }
};

export const MainSubNavbar = ({ isDarkMode, handleFollowClick, handleDiamondClick, subscribers, diamonds, isSubscribed, isDiamondClicked, showAbout, setShowAbout }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDiamondClickWithAnimation = (event) => {
    if (!isDiamondClicked) {
      const target = event.currentTarget.querySelector('.animate-bubbles');
      target.classList.add('animate');
      setTimeout(() => {
        target.classList.remove('animate');
      }, 750); // duration of the animation
    }
    handleDiamondClick();
  };

  const handleProfileImageClick = () => {
    const newImageUrl = prompt("Please enter the URL of the new profile image:");
    if (newImageUrl) {
      document.getElementById('company-symbol').src = newImageUrl;
    }
  };

  const CollectionName = "Verified NFT Collection";
  const CompanyName = "Web³ Outfitters";

  return (
    <>
      {isMobile ? (
        <SubNavbar isMobile={isMobile} isDarkMode={isDarkMode}>
          <NavbarItemLast isDarkMode={isDarkMode} className="company-info">
            <CompanyContainer style={{ position: 'relative', display: 'flex' }} >
              <CompanySymbol 
                id="company-symbol" 
                src={symbol} 
                alt="Symbol" 
                style={{ cursor: 'pointer', borderRadius: '100%' }} 
                onClick={handleProfileImageClick} 
              />
              <VerifiedSymbol />
            </CompanyContainer>
            <ProjectName isDarkMode={isDarkMode}>
              <CompanyLink isDarkMode={isDarkMode}  href="#collection" style={{ cursor: 'pointer' }}>
                {CollectionName}
              </CompanyLink>
              <MdVerified style={{ marginLeft: '5px', color: '#68ceff' }} />
            </ProjectName>
            <ProjectName isDarkMode={isDarkMode} style={{ marginLeft: '20px' }}>
              <CompanyLink isDarkMode={isDarkMode}  href="#company" style={{ cursor: 'pointer' }}>
                {CompanyName}
              </CompanyLink>
              <MdVerified style={{ marginLeft: '5px', color: '#68ceff' }} />
            </ProjectName>
          </NavbarItemLast>

          <DropdownMenu
            isDarkMode={isDarkMode}
            initial="hidden"
            animate={isDropdownOpen ? "visible" : "hidden"}
            variants={dropdownVariants}
            isOpen={isDropdownOpen}
          >
            <NavbarItemLast isDarkMode={isDarkMode} variants={itemVariants} className="company-info" onClick={() => setShowAbout(true)}>
              <FaInfoCircle /> About
            </NavbarItemLast>
            <HorzLine variants={itemVariants} />
            <NavbarItemLast isDarkMode={isDarkMode} variants={itemVariants}>
              <WebsiteLabel isDarkMode={isDarkMode}><FaGlobe /> </WebsiteLabel>
              <CompanyLink href="https://companywebsite.com" target="_blank" rel="noopener noreferrer" isDarkMode={isDarkMode}>
                companywebsite.com
              </CompanyLink>
            </NavbarItemLast>
            <HorzLine variants={itemVariants} />
            <NavbarItemLast isDarkMode={isDarkMode} variants={itemVariants}>
              <FaEye /> VIEWS
              <SubscriberCount isSubscribed={isSubscribed}>{subscribers} Views</SubscriberCount>
            </NavbarItemLast>
            <HorzLine variants={itemVariants} />
            <NavbarItemLast isDarkMode={isDarkMode} variants={itemVariants} onClick={handleDiamondClickWithAnimation}>
              <DiamondButton className="animate-bubbles" isDiamondClicked={isDiamondClicked}><FaGem /></DiamondButton>
              DIAMONDS
              <DiamondCount isDiamondClicked={isDiamondClicked}>{diamonds}</DiamondCount>
            </NavbarItemLast>
          </DropdownMenu>

          <DropdownToggle onClick={toggleDropdown}>
            {isDropdownOpen ? <FaCaretUp /> : <FaCaretDown />}
          </DropdownToggle>
        </SubNavbar>
      ) : (
        <SubNavbar
          isDarkMode={isDarkMode}
          initial={isMobile ? false : "hidden"} // Skip animation if mobile
  animate={isMobile ? false : "visible"} 
          variants={navbarVariants}
          isMobile={isMobile}
        >
          <NavbarItem isDarkMode={isDarkMode} className="company-info"   initial={isMobile ? false : "hidden"} // Skip animation if mobile
  animate={isMobile ? false : "visible"}  variants={itemPopVariants}>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <CompanySymbol 
                id="company-symbol" 
                src={symbol} 
                alt="Symbol" 
                style={{ height: '50px', width: '50px', cursor: 'pointer', borderRadius: '100%' }} 
                onClick={handleProfileImageClick} 
              />
              <VerifiedSymbol />
            </div>
            <ProjectName isDarkMode={isDarkMode}>
              <CompanyLink isDarkMode={isDarkMode} href="#collection" style={{ cursor: 'pointer' }}>
                {CollectionName}
              </CompanyLink>
              <MdVerified style={{ marginLeft: '5px', color: '#68ceff' }} />
            </ProjectName>
          </NavbarItem>
          <NavbarItem isDarkMode={isDarkMode} className="company-info"   initial={isMobile ? false : "hidden"} // Skip animation if mobile
  animate={isMobile ? false : "visible"}  variants={itemPopVariants}>
            <ProjectName isDarkMode={isDarkMode}>
              <CompanyLink isDarkMode={isDarkMode} href="#company" style={{ cursor: 'pointer' }}>
                {CompanyName}
              </CompanyLink>
              <MdVerified style={{ marginLeft: '5px', color: '#68ceff' }} />
            </ProjectName>
          </NavbarItem>
          <NavbarItem isDarkMode={isDarkMode}   initial={isMobile ? false : "hidden"} // Skip animation if mobile
  animate={isMobile ? false : "visible"}  variants={itemPopVariants} onClick={() => setShowAbout(true)}>
            <FaInfoCircle /> About
          </NavbarItem>
          <NavbarItem isDarkMode={isDarkMode}   initial={isMobile ? false : "hidden"} // Skip animation if mobile
  animate={isMobile ? false : "visible"}  variants={itemPopVariants}>
            <WebsiteLabel isDarkMode={isDarkMode}><FaGlobe /></WebsiteLabel>
            <CompanyLink href="https://companywebsite.com" target="_blank" rel="noopener noreferrer" isDarkMode={isDarkMode}>
              companywebsite.com
            </CompanyLink>
          </NavbarItem>
          <NavbarItem isDarkMode={isDarkMode}   initial={isMobile ? false : "hidden"} // Skip animation if mobile
  animate={isMobile ? false : "visible"}  variants={itemPopVariants}>
            <FaEye /> VIEWS
            <SubscriberCount isSubscribed={isSubscribed}>{subscribers} Views</SubscriberCount>
          </NavbarItem>
          <NavbarItemLast isDarkMode={isDarkMode}   initial={isMobile ? false : "hidden"} // Skip animation if mobile
  animate={isMobile ? false : "visible"}  variants={itemPopVariants} onClick={handleDiamondClickWithAnimation}>
            <AnimateBubbles className="animate-bubbles" isDiamondClicked={isDiamondClicked}>
              <DiamondColorWrap isDiamondClicked={isDiamondClicked}><FaGem /></DiamondColorWrap>
            </AnimateBubbles>
            <DiamondColorWrap isDiamondClicked={isDiamondClicked}>DIAMONDS</DiamondColorWrap>
            <DiamondCount isDiamondClicked={isDiamondClicked}>{diamonds}</DiamondCount>
          </NavbarItemLast>
        </SubNavbar>
      )}
    </>
  );
};
