import { MeshRefractionMaterial, useGLTF } from "@react-three/drei"
import { Canvas, useFrame, useLoader } from "@react-three/fiber"
import { BallCollider, CylinderCollider, RigidBody } from "@react-three/rapier"
import { AnimatePresence } from 'framer-motion'
import { Suspense, useEffect, useRef, useState } from "react"
import * as THREE from "three"
import { RGBELoader } from 'three-stdlib'
import { BlurredBackgroundLight } from "../../Global/GlobalElements"
import UnderlayPopUp from "../Underlay"
import { CarouselContainer, CarouselItem, Description, ExitIcon, ExitIconWrap, Image, ImageWrap, LeftButton, PExit, PopUpContainer, RightButton, Title, } from "./PopUpElements"
THREE.ColorManagement.legacyMode = false
const baubleMaterial = new THREE.MeshLambertMaterial({ color: "#c0a0a0", emissive: "red" })
const capMaterial = new THREE.MeshStandardMaterial({ metalness: 0.75, roughness: 0.15, color: "#8a492f", emissive: "#600000", envMapIntensity: 20 })
const sphereGeometry = new THREE.SphereGeometry(1, 28, 28)
const baubles = [...Array(50)].map(() => ({ scale: [2, 2, 2, 2, 1.25][Math.floor(Math.random() * 5)] }))






function Bauble({ vec = new THREE.Vector3(), scale, r = THREE.MathUtils.randFloatSpread }) {
  const model = useRef()
  const { nodes, materials } = useGLTF('/diamond.glb')
  const api = useRef(null)
  const texture = useLoader(RGBELoader, 'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/peppermint_powerplant_2_1k.hdr')
    // Check if the browser is Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  // Optional config

  useFrame((state, delta) => {
    delta = Math.min(0.1, delta)
    if (api.current) {
      api.current.applyImpulse(
        vec
          .copy(api.current.translation())
          .normalize()
          .multiply({ x: -50 * delta * scale, y: -150 * delta * scale, z: -50 * delta * scale }),
      )
    }
  })
  return (
    <RigidBody linearDamping={0.75} angularDamping={0.15} friction={0.2} position={[r(20), r(20) - 25, r(20) - 10]} ref={api} colliders={false} dispose={null}>
      <BallCollider args={[scale]} />
      <CylinderCollider rotation={[Math.PI / 2, 0, 0]} position={[0, 0, 1.2 * scale]} args={[0.15 * scale, 0.275 * scale]} />
     
      <mesh geometry={nodes.pCone1_DiamondOutside_0.geometry} material={materials.DiamondOutside} />
 
      {!isSafari && (
          <MeshRefractionMaterial envMap={texture} toneMapped={false} />
        )}
    </RigidBody>
  )
}








function Pointer({ vec = new THREE.Vector3() }) {
  const ref = useRef()
  useFrame(({ mouse, viewport }) => {
    vec.lerp({ x: (mouse.x * viewport.width) / 2, y: (mouse.y * viewport.height) / 2, z: 0 }, 0.2)
    ref.current?.setNextKinematicTranslation(vec)
  })
  return (
    <RigidBody position={[100, 100, 100]} type="kinematicPosition" colliders={false} ref={ref}>
      <BallCollider args={[2]} />
    </RigidBody>
  )
}

export default function PopUp({ trigger, setTrigger, buttonPopup, isDarkMode, props }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const images = [
    {
      id: 1,
      title: 'Mint the Token',
      description: 'Minting the token is the process of buying the NFT',
      src: 'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3o+-+Apparel+Info/Mint+the+Token.png',
    },
    {
      id: 2,
      title: 'Claim the Wearable',
      description: 'Utilize our platform to trade your NFT and redeem the physical wearable',
      src: 'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3o+-+Apparel+Info/Claim+Wearable.png',
    },
    {
      id: 3,
      title: 'Receive Digital Collectible',
      description: 'The Original NFT will be burned, but you will receive a new NFT that serves as a digital certificate of authenticity (DCRT). This NFT DCRT will also give you unique utility, like earning tokens daily that can be used to purchase merchandise on our platform',
      src: 'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3o+-+Apparel+Info/Receive+Digital+Collectible.png',
    },
  ];

  const popUpRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        // Clicked outside the UtilitiesContainer, close the pop-up
        setTrigger(false)
      }
    };

    // Add event listener when the pop-up is open
    if (trigger) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Remove event listener when the pop-up is closed
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [trigger, setTrigger]);

  return trigger ? (
    <>
    <BlurredBackgroundLight isDarkMode={isDarkMode}/>
    <PopUpContainer ref={popUpRef}  initial={{ opacity: 0 }}
    animate={{ opacity: 1, transition: { duration: 1 } }}
    exit={{ opacity: 0, transition: { duration: 1 } }}>
    <CarouselContainer>
    <LeftButton onClick={handlePrev} isDarkMode={isDarkMode}/>
      <AnimatePresence initial={false} custom={currentIndex}>
        <CarouselItem
          key={currentIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
              <ImageWrap>
            <Image src={images[currentIndex].src} alt={`Image ${currentIndex + 1}`} />
          </ImageWrap>
          <Title   isDarkMode={isDarkMode}  >{images[currentIndex].title}</Title>
    
          <Description  isDarkMode={isDarkMode}  >{images[currentIndex].description}</Description>
        </CarouselItem>
      </AnimatePresence>
      <RightButton onClick={handleNext} isDarkMode={isDarkMode}/>
    </CarouselContainer>
       <UnderlayPopUp buttonPopup={buttonPopup}/>
   
    <Suspense fallback={null}>
  <Canvas
    shadows
    gl={{ alpha: true, stencil: false, depth: false, antialias: false }}
    camera={{ position: [0, 0, 20], fov: 32.5, near: 1, far: 100 }}
    onCreated={(state) => (state.gl.toneMappingExposure = 1.5)}>

  </Canvas>
  </Suspense>
  <PExit className='close-btn' onClick={() =>setTrigger(false)}>
<ExitIconWrap
        whileHover={{ rotate: 180, transition: { duration: 0.25 } }}
        whileTap={{ rotate: 180, transition: { duration: 0.25 } }}
      >
          <ExitIcon whileHover={{ color: isDarkMode ? '#000' : '#fff', backgroundColor: isDarkMode ? '#fff' : '#000' }} />
      </ExitIconWrap>
    </PExit>
  </PopUpContainer>
  </>
  ) : null;
}

