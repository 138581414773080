// SpotlightModel.js

import { useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { Vector3 } from 'three';
export function SpotlightModel({ isDarkMode, lights, meshes, mannequinRef, isMobile, ...props }) {
  const { nodes } = useGLTF('/w3oSpotlight.glb');
  const light = useRef();
  const meshRef = useRef();
  const vec = new Vector3();
  const viewport = useThree((state) => state.viewport);
 // Reference to the mesh you want to apply bloom
 const bloomMeshRef = useRef();

  // Choose the glow material based on isDarkMode
  const glowMaterial = useMemo(() => {
    return new THREE.MeshBasicMaterial({
      color: isDarkMode ? new THREE.Color(8.0, 8.5, 10) : new THREE.Color(10, 9.5, 4.0),
      toneMapped: false,
      emissive: isDarkMode ? "yellow" : "blue",
      emissiveIntensity: isMobile ? 1 : 10, 
    });
  }, [isDarkMode]);

  useEffect(() => {
    if (bloomMeshRef.current) {
      bloomMeshRef.current.layers.enable(1); // Assign to bloom layer
    }
  }, []);
  // Add a moving spotlight effect with mannequin focus and slight mouse sensitivity


  return (
    <group {...props} dispose={null} scale={0.1}>
      <group rotation={[-Math.PI / 2, 0, -1.572]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh castShadow receiveShadow geometry={nodes.defaultMaterial002_1.geometry} material={nodes.defaultMaterial002_1.material} />
    
            <mesh   ref={bloomMeshRef} castShadow receiveShadow geometry={nodes.defaultMaterial002_2.geometry} material={glowMaterial}
            // layers={0}
              />
  
          <mesh ref={meshRef} castShadow receiveShadow geometry={nodes.defaultMaterial002_3.geometry} material={nodes.defaultMaterial002_3.material} />
     </group>
      </group>
    </group>
  );
}

useGLTF.preload('/w3oSpotlight.glb');
