import { MeshReflectorMaterial, Plane, useAnimations, useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { editable as e } from "@theatre/r3f";
import React, { useEffect, useRef } from 'react';
import Lights from './MasterPlanEffects/Lights';
export function WhiteMannequin({props, meshes, isMobile, lights, mannequinKey}) {
  const group = useRef();
  const mannequinRef = useRef();

  
  const { nodes, materials, animations } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/web3-mannequin-white.glb');
  const { actions } = useAnimations(animations, group);
  const { scene, camera } = useThree();
  useEffect(() => {
    const actionName = Object.keys(actions)[0]; // Assuming the first action is the one you want to play
    if (actions[actionName]) {
      actions[actionName].setEffectiveTimeScale(0.6);
      actions[actionName].reset().fadeIn(0.5).play();
    }
  }, [actions]);
  // Traverse only the mannequinRef and disable tone mapping for its materials
  // Clone materials to prevent shared material issues

  return (
    <e.group ref={group} theatreKey= {mannequinKey}  {...props} dispose={null}>
                {/* Render Sky outside of EffectComposer to avoid applying post-processing effects to it */}
                <Plane
       args={[1.5, 15, 1]}
        rotation-x={-Math.PI / 2}
        rotation-y={Math.PI /256}
        position={[0, 0, 0]}
        scale={[0.5, 0.25, 0.5]}
      >
        <MeshReflectorMaterial
     resolution={1024}
          blur={[200, 20]}
          mirror={5}
          mixBlur={0.05}
          mixStrength={15}
          transparent
          opacity={0.06}
          color="#555"
          metalness={5}
          roughness={1}
        />
      </Plane>
      
      <Lights isMobile={isMobile} lights={lights} meshes={meshes} mannequinRef={mannequinRef}   rotation={[0, 0, 0]} radius={40} isDarkMode={false} mannequinPosition={[0, 0, 0]} />
      <group       ref={mannequinRef}  name="Scene">
        <group      name="Armature" rotation={[Math.PI / 2, 0, 0]} position={[0, 0.025, 0]} scale={0.009}>
          <primitive object={nodes.mixamorigHips} />
        </group>
        <skinnedMesh
          name="base_female_Material21892_0"
          geometry={nodes.base_female_Material21892_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21892_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
                
        />
        <skinnedMesh
          name="base_female_Material21898_0"
          geometry={nodes.base_female_Material21898_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21898_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="base_female_Material21905_0"
          geometry={nodes.base_female_Material21905_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21905_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="base_female_Material21911_0"
          geometry={nodes.base_female_Material21911_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21911_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="base_female_Material21915_0"
          geometry={nodes.base_female_Material21915_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21915_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="base_female_Material21919_0"
          geometry={nodes.base_female_Material21919_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21919_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="base_female_Material21924_0"
          geometry={nodes.base_female_Material21924_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21924_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="base_female_Material21930_0"
          geometry={nodes.base_female_Material21930_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21930_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_FABRIC_2_FRONT_2088_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_FABRIC_2_FRONT_2088_0.geometry}
          material={materials['Material.001']}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_FABRIC_2_FRONT_2088_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_Fabric190897_FRONT_4566_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_Fabric190897_FRONT_4566_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_Fabric190897_FRONT_4566_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_Fabric195589_FRONT_4605_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_Fabric195589_FRONT_4605_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_Fabric195589_FRONT_4605_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_hoodie_FRONT_2083_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_hoodie_FRONT_2083_0.geometry}
          material={materials['Material.001']}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_hoodie_FRONT_2083_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_Material190826_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_Material190826_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_Material190826_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_Material190827_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_Material190827_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_Material190827_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_Material195518_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_Material195518_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_Material195518_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_Material195519_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_Material195519_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_Material195519_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="skimask_2002_large_0002"
          geometry={nodes.skimask_2002_large_0002.geometry}
          material={materials.hoodie_FRONT_2083}
          skeleton={nodes.skimask_2002_large_0002.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
        <skinnedMesh
          name="Travis_x_Scott_Cactus_Jack_x_Air_Jordan_4_High_Retro_mat_00_ma"
          geometry={nodes.Travis_x_Scott_Cactus_Jack_x_Air_Jordan_4_High_Retro_mat_00_ma.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.Travis_x_Scott_Cactus_Jack_x_Air_Jordan_4_High_Retro_mat_00_ma.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          
        />
      </group>
    </e.group>
  );
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/web3-mannequin-white.glb');
