/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import React from 'react'

export default function RedShirt(props) {
  const { nodes, materials } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/Left+Shirt.glb')
  return (
    <group scale={2} {...props} dispose={null}>
      <group scale={[11.479, 11.479, 12.94]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane003.geometry}
          material={materials['tshirtonhanger3.003']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane003_1.geometry}
          material={materials['tshirtonhanger3.004']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/Left+Shirt.glb')