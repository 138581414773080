import { extend, useFrame, useThree } from '@react-three/fiber';
import { useEffect, useMemo } from 'react';
import * as THREE from 'three';
import { EffectComposer, RenderPass, ShaderPass, UnrealBloomPass } from 'three-stdlib';

extend({ EffectComposer, UnrealBloomPass, RenderPass, ShaderPass });

export function SelectiveBloom({ children, isMobile, intensity = 1 }) {
    const { gl, scene, camera, size } = useThree();
  
    const bloomLayer = new THREE.Layers();
    bloomLayer.set(1); // Layer 1 for bloom
  
    const materials = {};
    const darkMaterial = new THREE.MeshBasicMaterial({ color: 'black' });
  
    const [bloomComposer, finalComposer] = useMemo(() => {
      // Render passes
      const renderScene = new RenderPass(scene, camera);
  
      // Bloom pass
      const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(size.width, size.height),
        intensity,
        0.2,
        0.45
      );
  
      const bloomComposer = new EffectComposer(gl);
      bloomComposer.renderToScreen = false;
      bloomComposer.addPass(renderScene);
      bloomComposer.addPass(bloomPass);
  
      // Final composer
      const finalPass = new ShaderPass(
        new THREE.ShaderMaterial({
          uniforms: {
            baseTexture: { value: null },
            bloomTexture: { value: bloomComposer.renderTarget2.texture },
          },
          vertexShader: /* glsl */ `
            varying vec2 vUv;
            void main() {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
          `,
          fragmentShader: /* glsl */ `
            uniform sampler2D baseTexture;
            uniform sampler2D bloomTexture;
            varying vec2 vUv;
            void main() {
              gl_FragColor = texture2D( baseTexture, vUv ) + texture2D( bloomTexture, vUv );
            }
          `,
        }),
        'baseTexture'
      );
      finalPass.needsSwap = true;
  
      const finalComposer = new EffectComposer(gl);
      finalComposer.addPass(renderScene);
      finalComposer.addPass(finalPass);
  
      return [bloomComposer, finalComposer];
    }, [gl, scene, camera, size, intensity]);
  
    useEffect(() => {
      gl.autoClear = false;
    }, [gl]);
  
    // Darken non-bloomed objects
    const darkenNonBloomed = (obj) => {
      if (obj.isMesh && bloomLayer.test(obj.layers) === false) {
        materials[obj.uuid] = obj.material;
        obj.material = darkMaterial;
      }
    };
  
    // Restore materials
    const restoreMaterials = (obj) => {
      if (materials[obj.uuid]) {
        obj.material = materials[obj.uuid];
        delete materials[obj.uuid];
      }
    };
  
    useFrame(() => {
      // Render bloom layer
      scene.traverse(darkenNonBloomed);
      bloomComposer.render();
      scene.traverse(restoreMaterials);
  
      // Render the entire scene, including bloom layer
      gl.clearDepth();
      finalComposer.render();
    }, 1);
  
    return <>{children}</>;
  }
  