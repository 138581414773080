import { a } from "@react-spring/three";
import { Html, useCursor, useGLTF } from '@react-three/drei';
import { Select } from "@react-three/postprocessing";
import React, { useEffect, useRef, useState } from 'react';
import { ItemDetailsCardHTML } from "../3DItemComponents/ItemHtmlCard";
// Closet Item Imports
import { EnvMobile } from "../../../../components/Environments/Environments";
import Chain from "../../ClosetItems/Chain";
import FloorBag from "../../ClosetItems/FloorBag";
import GlassBalls from "../../ClosetItems/GlassBalls";
import Hat from "../../ClosetItems/Hat";
import MiddleBag from "../../ClosetItems/MiddleBag";
import MiddleShirt from "../../ClosetItems/MiddleShirt";
import Mirror from "../../ClosetItems/Mirror";
import Perfume from "../../ClosetItems/Perfume";
import RedShirt from "../../ClosetItems/RedShirt";
import RightShirt from "../../ClosetItems/RightShirt";
import Shoes from "../../ClosetItems/Shoes";
import TopBag from "../../ClosetItems/TopBag";
import {
  CircleIndicator,
  CircleIndicatorContainer
} from '../3DItemComponents/ItemElements';
import { itemDetailsList } from "../ClosetData/Data";

// Extend the Three.js namespace to include TextGeometry

const fadeInScaleAnimation = {
  hidden: { opacity: 0, scale: 0, filter: 'blur(15px)' },
  visible: { opacity: 1, scale: 1, filter: 'blur(0)' },
};

export const itemModels = {
  Chain: Chain,
  FloorBag: FloorBag,
  GlassBalls: GlassBalls,
  Hat: Hat,
  MiddleBag: MiddleBag,
  MiddleShirt: MiddleShirt,
  Mirror: Mirror,
  Perfume: Perfume,
  RedShirt: RedShirt,
  RightShirt: RightShirt,
  Shoes: Shoes,
  TopBag: TopBag,
};

export default function W3oDefaultClosetMobile({
  BLOOM_LAYER,
  isSelected,
  isDarkMode,
  setCurrentIndex,
  perfSucks,
  orbitControlsEnabled,
  setOrbitControlsEnabled,
  setIsSelected,
  onClose,
  currentIndex,
  mobile3Dcollecion,
  isMobile,
  browseMode,
  setBrowseMode,
  setHoveredGroup,
  hoveredGroup,
  selectedObject,
  setSelectedObject,
  hoveredItemDetails,
  setHoveredItemDetails,
  lastClickedItemName,
  setLastClickedItemName,
  props
}) {
  const group = useRef();

  const ClothesRef = useRef();
  const MirrorRef = useRef();
  const TopBagRef = useRef();
  const MiddleBagRef = useRef();
  const FloorBagRef = useRef();
  const GlassBallsRef = useRef();
  const MerchMiddleHatRef = useRef();
  const MerchMiddleRef = useRef();
  const MerchTopRef = useRef();
  const ShoesRef = useRef();
  const RedShirtRef = useRef();
  const PerfumeRef = useRef();
  const ChainRef = useRef();
  const HatRef = useRef();
  const MiddleShirtRef = useRef();
  const RightShirtRef = useRef();
  const hoveredGroupRef = useRef();

  const handleHover = (group, objectRef) => {
    setHoveredGroup(group);
    document.body.style.cursor = 'pointer';

    let details;

    switch (group) {
      case 'Mirror':
        details = {
          header: 'Sample Closet',
          name: 'Mirror',
          description: 'Reflect your style with elegance'
        };
        hoveredGroupRef.current = MirrorRef.current;
        break;

      case 'TopBag':
        details = {
          header: 'Sample Closet',
          name: 'Top Bag',
          description: 'Carry your essentials in style'
        };
        hoveredGroupRef.current = TopBagRef.current;
        break;
      case 'Perfume':
        details = {
          header: 'Sample Closet',
          name: 'Perfume',
          description: 'Scent that defines your presence'
        };
        hoveredGroupRef.current = PerfumeRef.current;
        break;

      case 'RedShirt':
        details = {
          header: 'Sample Closet',
          name: 'Red Shirt',
          description: 'Bold and stylish fashion choice'
        };
        hoveredGroupRef.current = RedShirtRef.current;
        break;

      case 'MiddleShirt':
        details = {
          header: 'Sample Closet',
          name: 'Middle Shirt',
          description: 'Comfort and style in one'
        };
        hoveredGroupRef.current = MiddleShirtRef.current;
        break;

      case 'RightShirt':
        details = {
          header: 'Sample Closet',
          name: 'Right Shirt',
          description: 'Perfect fit for any occasion'
        };
        hoveredGroupRef.current = RightShirtRef.current;
        break;

      case 'MiddleBag':
        details = {
          header: 'Sample Closet',
          name: 'Middle Bag',
          description: 'Stylish bag for every day'
        };
        hoveredGroupRef.current = MiddleBagRef.current;
        break;

      case 'FloorBag':
        details = {
          header: 'Sample Closet',
          name: 'Floor Bag',
          description: 'Trendy and spacious bag'
        };
        hoveredGroupRef.current = FloorBagRef.current;
        break;

      case 'GlassBalls':
        details = {
          header: 'Sample Closet',
          name: 'Glass Balls',
          description: 'Decorate with chic glass balls'
        };
        hoveredGroupRef.current = GlassBallsRef.current;
        break;

      case 'Hat':
        details = {
          header: 'Sample Closet',
          name: 'Hat',
          description: 'Top off your look with flair'
        };
        hoveredGroupRef.current = MerchMiddleHatRef.current;
        break;

      case 'Chain':
        details = {
          header: 'Sample Closet',
          name: 'Chain',
          description: 'Add a touch of class'
        };
        hoveredGroupRef.current = MerchTopRef.current;
        break;

      case 'Shoes':
        details = {
          header: 'Sample Closet',
          name: 'Shoes',
          description: 'Step out in ultimate style'
        };
        hoveredGroupRef.current = ShoesRef.current;
        break;

      default:
        details = null;
    }

    setHoveredItemDetails(details);
  };

  const handleUnhover = (objectRef) => {
    if (hoveredGroup) {
      setHoveredGroup(null);
      setHoveredItemDetails(null);
      document.body.style.cursor = 'default';

      if (objectRef.current) {
        objectRef.current.traverse((child) => {
          if (child.isMesh) {
            child.layers.disable(BLOOM_LAYER);
          }
        });
      }
    }
  };

  const handleItemClick = (itemName) => {
    const index = itemDetailsList.findIndex(item => item.group === itemName);
    if (index !== -1) {
      setCurrentIndex(index);
      setHoveredGroup(null); // Clear the hover state
      setHoveredItemDetails(null); // Clear the hovered item details
      setIsSelected(true); // Set the selected item name
      setSelectedObject(itemName);
      setOrbitControlsEnabled(false);
      // Check if the clicked item is the same as the last clicked item
      if (itemName === lastClickedItemName) {
        setIsSelected(false);
        setHoveredGroup(null); // Clear the hover state
        setHoveredItemDetails(null); // Clear the hovered item details
        //homeToggle();
        if (isSelected === false) {
          setIsSelected(true);
          setIsSelected(itemName); // Set the selected item name
          setSelectedObject(itemName);
        }

        if (browseMode === true) {
          setIsSelected(true);
          setIsSelected(itemName); // Set the selected item name
          setSelectedObject(itemName);
          setBrowseMode(false)
        }

      } else {
        setIsSelected(true);
        setIsSelected(itemName); // Set the selected item name
        setSelectedObject(itemName);
        setBrowseMode(false);
      }

      // Update the last clicked item name
      setLastClickedItemName(itemName);
    }
  };

  const handlePointerOver = (e, name, objectRef) => {
   // e.stopPropagation();
    if (!isSelected || selectedObject !== name) {
      setSelectedObject(name);
      handleHover(name, objectRef);
      document.body.style.cursor = `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0zMC41IDE2QzMwLjUgMjQuMDA4MSAyNC4wMDgxIDMwLjUgMTYgMzAuNUM3Ljk5MTg3IDMwLjUgMS41IDI0LjAwODEgMS41IDE2QzEuNSA3Ljk5MTg3IDcuOTkxODcgMS41IDE2IDEuNUMyNC4wMDgxIDEuNSAzMC41IDcuOTkxODcgMzAuNSAxNloiIHN0cm9rZT0iZ29sZCIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwZGVnKSIvPgo8cGF0aCBkPSJNNiA5QzcLjY1Njg1IDkgOS43NjU2ODUgOSAyQzcgNCAuMjUzMTUgMyA2IDNEMiAzIDMuNTY4MSAyIDkgNiAxN2w1IDEyIDIuNDAzMTUgMTguNDIxNTU3QzYgMyA3LjY1Njg1IDkuMzQzMTUgNiA5WiIgZmlsbD0iZ29sZCIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwZGVnKSIvPgo8L3N2Zz4K"), auto`;
    }
  };

  const handlePointerOut = (e, objectRef) => {
    e.stopPropagation();

    // If the item is hovered but not selected, unhover it
    if (!isSelected || (isSelected && selectedObject !== objectRef.current?.name)) {
      setSelectedObject(null);
      handleUnhover(objectRef);
      document.body.style.cursor = 'default';
    }
  };

  const { nodes, materials } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/w3oUserDefaultClosetMobile.glb');
  const [hovered, set] = useState(false);
  useCursor(hovered, `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjUgMTZDMzAuNSAyNC4wMDgxIDI0LjAwODEgMzAuNSAxNiAzMC41QzcuOTkxODcgMzAuNSAxLjUgMjQuMDA4MSAxLjUgMTZDMS41IDcuOTkxODcgNy45OTE4NyAxLjUgMTYgMS41QzI0LjAwODEgMS41IDMwLjUgNy45OTE4NyAzMC41IDE2WiIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNiA5QzcuNjU2ODUgOSA5IDcuNjU2ODUgOSA2QzkgNC4zNDMxNSA3LjY1Njg1IDMgNiAzQzQuMzQzMTUgMyAzIDQuMzQzMTUgMyA2QzMgNy42NTY4NSA0LjM0MzE1IDkgNiA5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="), auto`);

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.querySelectorAll('.circle-indicator').forEach(indicator => {
        indicator.style.animation = `${fadeInScaleAnimation} 0.5s ease-in-out forwards`;
      });
    }, 3000); // Delay of 3 seconds after the model is loaded

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      {!isSelected && !browseMode && (
        <Html
          center
          style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }}
          zIndexRange={[100, 0]}
        >
          <ItemDetailsCardHTML
            itemDetailsList={itemDetailsList}
            isDarkMode={isDarkMode}
            hoveredGroup={hoveredGroup}
            details={hoveredItemDetails}
            objectRef={hoveredGroupRef}
          />
        </Html>
      )}
  
      <group scale={.13} {...props} dispose={null}>
        {/* Non-interactive meshes */}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.FloorTop.geometry}
          material={materials['Material.071']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Walls.geometry}
          material={materials['Material.076']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Flooring.geometry}
          material={materials['PaletteMaterial001.008']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.ClothesRod.geometry}
          material={materials['PaletteMaterial001.008']}
        />
  
        {/* FloorBag */}
        <Select
          enabled={
            selectedObject === 'FloorBag' ||
            (isSelected && itemDetailsList[currentIndex]?.group === 'FloorBag')
          }
        >
          <a.group
            name="FloorBag"
            ref={FloorBagRef}
            onPointerOver={(e) => handlePointerOver(e, 'FloorBag', FloorBagRef)}
            onPointerOut={(e) => handlePointerOut(e, FloorBagRef)}
            onClick={() => handleItemClick('FloorBag')}
          >
            <Html position={[-3.94, 0.983, 5.037]} zIndexRange={[100, 0]}>
              <CircleIndicatorContainer>
                <CircleIndicator
                  isSelected={
                    selectedObject === 'FloorBag' ||
                    (isSelected && itemDetailsList[currentIndex]?.group === 'FloorBag')
                  }
                  animate={{
                    scale:
                      selectedObject === 'FloorBag' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'FloorBag')
                        ? [1.2, 1.2]
                        : 1,
                    rotate:
                      selectedObject === 'FloorBag' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'FloorBag')
                        ? 360
                        : 0,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                  }}
                  className="circle-indicator"
                />
              </CircleIndicatorContainer>
            </Html>
            <group
              position={[-3.94, 0.983, 5.037]}
              rotation={[Math.PI, -0.859, Math.PI]}
              scale={0.03}
            >
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.bag_bag_0010.geometry}
                material={materials.PaletteMaterial001}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.bag_bag_0010_1.geometry}
                material={materials['material.002']}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.bag_bag_0010_2.geometry}
                material={materials['belt.002']}
              />
            </group>
          </a.group>
        </Select>
  
        {/* MiddleBag */}
        <Select
          enabled={
            selectedObject === 'MiddleBag' ||
            (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleBag')
          }
        >
          <a.group
            name="MiddleBag"
            ref={MiddleBagRef}
            onPointerOver={(e) => handlePointerOver(e, 'MiddleBag', MiddleBagRef)}
            onPointerOut={(e) => handlePointerOut(e, MiddleBagRef)}
            onClick={() => handleItemClick('MiddleBag')}
          >
            <Html position={[-8.5, 4.5, 7.5]} zIndexRange={[100, 0]}>
              <CircleIndicatorContainer>
                <CircleIndicator
                  isSelected={
                    selectedObject === 'MiddleBag' ||
                    (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleBag')
                  }
                  animate={{
                    scale:
                      selectedObject === 'MiddleBag' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleBag')
                        ? [1.2, 1.2]
                        : 1,
                    rotate:
                      selectedObject === 'MiddleBag' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleBag')
                        ? 360
                        : 0,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                  }}
                  className="circle-indicator"
                />
              </CircleIndicatorContainer>
            </Html>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.MiddleBag.geometry}
              material={materials['map_BagWomen_01.006']}
            />
          </a.group>
        </Select>
  
        {/* Perfume */}
        <Select
          enabled={
            selectedObject === 'Perfume' ||
            (isSelected && itemDetailsList[currentIndex]?.group === 'Perfume')
          }
        >
          <a.group
            name="Perfume"
            ref={PerfumeRef}
            onPointerOver={(e) => handlePointerOver(e, 'Perfume', PerfumeRef)}
            onPointerOut={(e) => handlePointerOut(e, PerfumeRef)}
            onClick={() => handleItemClick('Perfume')}
          >
            <Html position={[8, 5, -9.5]} zIndexRange={[100, 0]}>
              <CircleIndicatorContainer>
                <CircleIndicator
                  isSelected={
                    selectedObject === 'Perfume' ||
                    (isSelected && itemDetailsList[currentIndex]?.group === 'Perfume')
                  }
                  animate={{
                    scale:
                      selectedObject === 'Perfume' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'Perfume')
                        ? [1.2, 1.2]
                        : 1,
                    rotate:
                      selectedObject === 'Perfume' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'Perfume')
                        ? 360
                        : 0,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                  }}
                  className="circle-indicator"
                />
              </CircleIndicatorContainer>
            </Html>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Perfume.geometry}
              material={materials['PaletteMaterial004.003']}
            />
          </a.group>
        </Select>
  
        {/* TopBag */}
        <Select
          enabled={
            selectedObject === 'TopBag' ||
            (isSelected && itemDetailsList[currentIndex]?.group === 'TopBag')
          }
        >
          <a.group
            name="TopBag"
            ref={TopBagRef}
            onPointerOver={(e) => handlePointerOver(e, 'TopBag', TopBagRef)}
            onPointerOut={(e) => handlePointerOut(e, TopBagRef)}
            onClick={() => handleItemClick('TopBag')}
          >
            <Html position={[-8.5, 11.25, 7]} zIndexRange={[100, 0]}>
              <CircleIndicatorContainer>
                <CircleIndicator
                  isSelected={
                    selectedObject === 'TopBag' ||
                    (isSelected && itemDetailsList[currentIndex]?.group === 'TopBag')
                  }
                  animate={{
                    scale:
                      selectedObject === 'TopBag' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'TopBag')
                        ? [1.2, 1.2]
                        : 1,
                    rotate:
                      selectedObject === 'TopBag' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'TopBag')
                        ? 360
                        : 0,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                  }}
                  className="circle-indicator"
                />
              </CircleIndicatorContainer>
            </Html>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.TopBag.geometry}
              material={materials['bag1.006']}
            />
          </a.group>
        </Select>
  
        {/* RedShirt */}
        <Select
          enabled={
            selectedObject === 'RedShirt' ||
            (isSelected && itemDetailsList[currentIndex]?.group === 'RedShirt')
          }
        >
          <a.group
            name="RedShirt"
            ref={RedShirtRef}
            onPointerOver={(e) => handlePointerOver(e, 'RedShirt', RedShirtRef)}
            onPointerOut={(e) => handlePointerOut(e, RedShirtRef)}
            onClick={() => handleItemClick('RedShirt')}
          >
            <Html position={[-7, 6.5, -4.5]} zIndexRange={[100, 0]}>
              <CircleIndicatorContainer>
                <CircleIndicator
                  isSelected={
                    selectedObject === 'RedShirt' ||
                    (isSelected && itemDetailsList[currentIndex]?.group === 'RedShirt')
                  }
                  animate={{
                    scale:
                      selectedObject === 'RedShirt' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'RedShirt')
                        ? [1.2, 1.2]
                        : 1,
                    rotate:
                      selectedObject === 'RedShirt' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'RedShirt')
                        ? 360
                        : 0,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                  }}
                  className="circle-indicator"
                />
              </CircleIndicatorContainer>
            </Html>
            <group position={[0.388, 1.356, -3.593]} scale={[11.479, 11.479, 12.94]}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Plane003.geometry}
                material={materials['tshirtonhanger3.010']}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Plane003_1.geometry}
                material={materials['tshirtonhanger3.011']}
              />
            </group>
          </a.group>
        </Select>
  
        {/* MiddleShirt */}
        <Select
          enabled={
            selectedObject === 'MiddleShirt' ||
            (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleShirt')
          }
        >
          <a.group
            name="MiddleShirt"
            ref={MiddleShirtRef}
            onPointerOver={(e) => handlePointerOver(e, 'MiddleShirt', MiddleShirtRef)}
            onPointerOut={(e) => handlePointerOut(e, MiddleShirtRef)}
            onClick={() => handleItemClick('MiddleShirt')}
          >
            <Html position={[-6.5, 8.75, -5.35]} zIndexRange={[100, 0]}>
              <CircleIndicatorContainer>
                <CircleIndicator
                  isSelected={
                    selectedObject === 'MiddleShirt' ||
                    (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleShirt')
                  }
                  animate={{
                    scale:
                      selectedObject === 'MiddleShirt' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleShirt')
                        ? [1.2, 1.2]
                        : 1,
                    rotate:
                      selectedObject === 'MiddleShirt' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleShirt')
                        ? 360
                        : 0,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                  }}
                  className="circle-indicator"
                />
              </CircleIndicatorContainer>
            </Html>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Cylinder004_Material_06042.geometry}
              material={materials['default.006']}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Cylinder004_Material_06042_1.geometry}
              material={materials['tshirtonhanger3.002']}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Cylinder004_Material_06042_2.geometry}
              material={materials.PaletteMaterial002}
            />
          </a.group>
        </Select>
  
        {/* RightShirt */}
        <Select
          enabled={
            selectedObject === 'RightShirt' ||
            (isSelected && itemDetailsList[currentIndex]?.group === 'RightShirt')
          }
        >
          <a.group
            name="RightShirt"
            ref={RightShirtRef}
            onPointerOver={(e) => handlePointerOver(e, 'RightShirt', RightShirtRef)}
            onPointerOut={(e) => handlePointerOut(e, RightShirtRef)}
            onClick={() => handleItemClick('RightShirt')}
          >
            <Html position={[-6, 11, -6.5]} zIndexRange={[100, 0]}>
              <CircleIndicatorContainer>
                <CircleIndicator
                  isSelected={
                    selectedObject === 'RightShirt' ||
                    (isSelected && itemDetailsList[currentIndex]?.group === 'RightShirt')
                  }
                  animate={{
                    scale:
                      selectedObject === 'RightShirt' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'RightShirt')
                        ? [1.2, 1.2]
                        : 1,
                    rotate:
                      selectedObject === 'RightShirt' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'RightShirt')
                        ? 360
                        : 0,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                  }}
                  className="circle-indicator"
                />
              </CircleIndicatorContainer>
            </Html>
            <group position={[0.388, 1.356, -4.95]} scale={[11.479, 11.479, 12.94]}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Plane005.geometry}
                material={materials['tshirtonhanger3.004']}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Plane005_1.geometry}
                material={materials.PaletteMaterial002}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Plane005_2.geometry}
                material={materials['tsh3.008']}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Plane005_3.geometry}
                material={materials['PaletteMaterial001.008']}
              />
            </group>
          </a.group>
        </Select>
  
        {/* Mirror */}
        <Select
          enabled={
            selectedObject === 'Mirror' ||
            (isSelected && itemDetailsList[currentIndex]?.group === 'Mirror')
          }
        >
          <a.group
            name="Mirror"
            ref={MirrorRef}
            onPointerOver={(e) => handlePointerOver(e, 'Mirror', MirrorRef)}
            onPointerOut={(e) => handlePointerOut(e, MirrorRef)}
            onClick={() => handleItemClick('Mirror')}
          >
            <Html position={[-2.5, 2.25, -7.75]} zIndexRange={[100, 0]}>
              <CircleIndicatorContainer>
                <CircleIndicator
                  isSelected={
                    selectedObject === 'Mirror' ||
                    (isSelected && itemDetailsList[currentIndex]?.group === 'Mirror')
                  }
                  animate={{
                    scale:
                      selectedObject === 'Mirror' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'Mirror')
                        ? [1.2, 1.2]
                        : 1,
                    rotate:
                      selectedObject === 'Mirror' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'Mirror')
                        ? 360
                        : 0,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                  }}
                  className="circle-indicator"
                />
              </CircleIndicatorContainer>
            </Html>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Chain004.geometry}
              material={materials['PaletteMaterial003.003']}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Chain004_1.geometry}
              material={materials['PaletteMaterial001.008']}
            />
          </a.group>
        </Select>
  
        {/* Hat */}
        <Select
          enabled={
            selectedObject === 'Hat' ||
            (isSelected && itemDetailsList[currentIndex]?.group === 'Hat')
          }
        >
          <a.group
            name="Hat"
            ref={HatRef}
            onPointerOver={(e) => handlePointerOver(e, 'Hat', HatRef)}
            onPointerOut={(e) => handlePointerOut(e, HatRef)}
            onClick={() => handleItemClick('Hat')}
          >
            <Html position={[4.85, 5.25, -8.5]} zIndexRange={[100, 0]}>
              <CircleIndicatorContainer>
                <CircleIndicator
                  isSelected={
                    selectedObject === 'Hat' ||
                    (isSelected && itemDetailsList[currentIndex]?.group === 'Hat')
                  }
                  animate={{
                    scale:
                      selectedObject === 'Hat' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'Hat')
                        ? [1.2, 1.2]
                        : 1,
                    rotate:
                      selectedObject === 'Hat' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'Hat')
                        ? 360
                        : 0,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                  }}
                  className="circle-indicator"
                />
              </CircleIndicatorContainer>
            </Html>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Hat.geometry}
              material={materials['PaletteMaterial001.008']}
            />
          </a.group>
        </Select>
  
        {/* GlassBalls */}
        <Select
          enabled={
            selectedObject === 'GlassBalls' ||
            (isSelected && itemDetailsList[currentIndex]?.group === 'GlassBalls')
          }
        >
          <a.group
            name="GlassBalls"
            ref={GlassBallsRef}
            onPointerOver={(e) => handlePointerOver(e, 'GlassBalls', GlassBallsRef)}
            onPointerOut={(e) => handlePointerOut(e, GlassBallsRef)}
            onClick={() => handleItemClick('GlassBalls')}
          >
            <Html position={[7, 12.25, -10.5]} zIndexRange={[100, 0]}>
              <CircleIndicatorContainer>
                <CircleIndicator
                  isSelected={
                    selectedObject === 'GlassBalls' ||
                    (isSelected && itemDetailsList[currentIndex]?.group === 'GlassBalls')
                  }
                  animate={{
                    scale:
                      selectedObject === 'GlassBalls' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'GlassBalls')
                        ? [1.2, 1.2]
                        : 1,
                    rotate:
                      selectedObject === 'GlassBalls' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'GlassBalls')
                        ? 360
                        : 0,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                  }}
                  className="circle-indicator"
                />
              </CircleIndicatorContainer>
            </Html>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.GlassBalls.geometry}
              material={materials['PaletteMaterial001.008']}
            />
          </a.group>
        </Select>
  
        {/* Shoes */}
        <Select
          enabled={
            selectedObject === 'Shoes' ||
            (isSelected && itemDetailsList[currentIndex]?.group === 'Shoes')
          }
        >
          <a.group
            name="Shoes"
            ref={ShoesRef}
            onPointerOver={(e) => handlePointerOver(e, 'Shoes', ShoesRef)}
            onPointerOut={(e) => handlePointerOut(e, ShoesRef)}
            onClick={() => handleItemClick('Shoes')}
          >
            <Html position={[7, 3.25, -9]} zIndexRange={[100, 0]}>
              <CircleIndicatorContainer>
                <CircleIndicator
                  isSelected={
                    selectedObject === 'Shoes' ||
                    (isSelected && itemDetailsList[currentIndex]?.group === 'Shoes')
                  }
                  animate={{
                    scale:
                      selectedObject === 'Shoes' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'Shoes')
                        ? [1.2, 1.2]
                        : 1,
                    rotate:
                      selectedObject === 'Shoes' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'Shoes')
                        ? 360
                        : 0,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                  }}
                  className="circle-indicator"
                />
              </CircleIndicatorContainer>
            </Html>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Chain2505.geometry}
              material={materials['blinn1SG.008']}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Chain2505_1.geometry}
              material={materials['PaletteMaterial005.003']}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Chain2505_2.geometry}
              material={materials['PaletteMaterial006.003']}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Chain2505_3.geometry}
              material={materials.PaletteMaterial003}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Chain2505_4.geometry}
              material={materials.PaletteMaterial001}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Chain2505_5.geometry}
              material={materials['PaletteMaterial001.008']}
            />
          </a.group>
        </Select>
  
        {/* Chain */}
        <Select
          enabled={
            selectedObject === 'Chain' ||
            (isSelected && itemDetailsList[currentIndex]?.group === 'Chain')
          }
        >
          <a.group
            name="Chain"
            ref={ChainRef}
            onPointerOver={(e) => handlePointerOver(e, 'Chain', ChainRef)}
            onPointerOut={(e) => handlePointerOut(e, ChainRef)}
            onClick={() => handleItemClick('Chain')}
          >
            <Html position={[7.15, 7.25, -8.5]} zIndexRange={[100, 0]}>
              <CircleIndicatorContainer>
                <CircleIndicator
                  isSelected={
                    selectedObject === 'Chain' ||
                    (isSelected && itemDetailsList[currentIndex]?.group === 'Chain')
                  }
                  animate={{
                    scale:
                      selectedObject === 'Chain' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'Chain')
                        ? [1.2, 1.2]
                        : 1,
                    rotate:
                      selectedObject === 'Chain' ||
                      (isSelected && itemDetailsList[currentIndex]?.group === 'Chain')
                        ? 360
                        : 0,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                  }}
                  className="circle-indicator"
                />
              </CircleIndicatorContainer>
            </Html>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Chain2508.geometry}
              material={materials.PaletteMaterial003}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Chain2508_1.geometry}
              material={materials['PaletteMaterial001.008']}
            />
          </a.group>
        </Select>
  
        {/* Non-interactive meshes continued */}
        {/* Add any remaining non-interactive meshes here */}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder004_Material_0137.geometry}
          material={materials['Material.094']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder004_Material_0137_1.geometry}
          material={materials['Material.095']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder004_Material_0137_2.geometry}
          material={materials['Material.096']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder004_Material_0137_3.geometry}
          material={materials['cupbord2.016']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder004_Material_0137_4.geometry}
          material={materials.PaletteMaterial001}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder004_Material_0137_5.geometry}
          material={materials['Material.076']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder004_Material_0137_6.geometry}
          material={materials['Material.077']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder004_Material_0137_7.geometry}
          material={materials['cupbord2.012']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane035.geometry}
          material={materials['Material.075']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane035_1.geometry}
          material={materials['Material.076']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane035_2.geometry}
          material={materials['PaletteMaterial001.008']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane129.geometry}
          material={materials['cupbord2.012']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane129_1.geometry}
          material={materials['cupbord2.016']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane129_2.geometry}
          material={materials['PaletteMaterial001.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane129_3.geometry}
          material={materials.PaletteMaterial001}
        />
      </group>
      <EnvMobile BLOOM_LAYER={BLOOM_LAYER} orbitControlsEnabled={orbitControlsEnabled} perfSucks={perfSucks} />
    
    </>
  );
  
                }
  useGLTF.preload(
    'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/w3oUserDefaultClosetMobile.glb'
  );
    