/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import React, { useRef } from 'react'

export default function TopBag(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/Top+Bag.glb')
  return (
    <group scale={3.5}  ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Top_Bag.geometry} material={materials['bag1.001']} />
    </group>
  )
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/Top+Bag.glb')
