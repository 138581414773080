import { a } from "@react-spring/three";
import { useFrame } from "@react-three/fiber";
import React, { useRef } from 'react';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaInfoCircle } from "react-icons/fa";
import icon from '../../../../images/w3oNameHorzBlack.png';
import icon2 from '../../../../images/w3oNameHorzWhite.png';
import { itemModels } from "../Closet/W3oUserDefaultCloset";
import {
  About,
  CanvasWrapper,
  Details,
  ExpandedBottomCardMobile,
  InfoIcon,
  Tab,
  TabContainer,
  TitleDescriptionMobile,
  Traits
} from './ItemElements';

const RotatingModel = ({ hoveredItem, scale }) => {
  const ModelComponent = itemModels[hoveredItem];
  const ref = useRef();

  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.y += 0.01; // Adjust rotation speed as needed
    }
  });

  return ModelComponent ? (
    <a.group scale={scale} ref={ref}>
      <ModelComponent />
    </a.group>
  ) : null;
};

export const ItemDetailsCanvasContent = ({ hoveredItem, scale }) => {
  return (
    <>

      <group scale={2}>
        <RotatingModel hoveredItem={hoveredItem} scale={scale} />
      </group>
    </>
  );
};

export const ItemDetailsCardExpandedMobile = ({
  details = [],
  setHoveredGroup,
  setHoveredItemDetails,
  setSelectedObject,
  isDarkMode,
  onClose,
  onNext,
  onPrev,
  showInfoPopup,
  setShowInfoPopup,
  currentIndex = 0,
  activeTab,
  setActiveTab
}) => {
  const logoSrc = isDarkMode ? icon : icon2;
  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
  };
  if (!details || details.length === 0) return null;

  const currentDetail = details[currentIndex];
  if (!currentDetail) return null;

  const toggleInfoPopup = () => {
    setShowInfoPopup(!showInfoPopup);
  };

  const reducedPaddingStyle = {
    padding: '0 20px'
  };

  // Define the clipping paths using inset() with round corners


  return (
    <>
      <ExpandedBottomCardMobile
        showInfoPopup={showInfoPopup}
        isDarkMode={isDarkMode}

        transition={{ duration: 0.2, ease: 'easeInOut' }}
        //style={{ overflow: 'hidden' }} // Ensure content is clipped
      >
        <CanvasWrapper style={{ width: '100vw', height: '225px', marginTop: '-25px', marginBottom: '20px' }}>
          <FaArrowAltCircleLeft
            onClick={() => {
              setHoveredGroup(null);
              setSelectedObject(null);
              setHoveredItemDetails(null);
              onPrev();
             // toggleInfoPopup();
            }}
            style={{
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              fontSize: '2rem',
              cursor: 'pointer',
              color: isDarkMode ? '#000' : '#fff',
              zIndex: 2
            }}
          />
          <FaArrowAltCircleRight
            onClick={() => {
              setHoveredGroup(null);
              setSelectedObject(null);
              setHoveredItemDetails(null);
              onNext();
            }}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              fontSize: '2rem',
              cursor: 'pointer',
              color: isDarkMode ? '#000' : '#fff',
              zIndex: 2
            }}
          />
          <InfoIcon
            onClick={toggleInfoPopup}
            showInfoPopup={showInfoPopup}
            isDarkMode={isDarkMode}
          >
            <FaInfoCircle />
          </InfoIcon>


        </CanvasWrapper>
        <TitleDescriptionMobile variants={childVariants}>{currentDetail.name}</TitleDescriptionMobile>
        {showInfoPopup && (
          <div style={{ zIndex: 1, margin: 0, padding: 0 }}>
    <div style={{ margin: 0, padding: 0 }}>

            <TabContainer isDarkMode={isDarkMode}>
              <Tab isActive={activeTab === 'about'} isDarkMode={isDarkMode} onClick={() => setActiveTab('about')}>
                About
              </Tab>
              <Tab isActive={activeTab === 'traits'} isDarkMode={isDarkMode} onClick={() => setActiveTab('traits')}>
                Traits
              </Tab>
              <Tab isActive={activeTab === 'details'} isDarkMode={isDarkMode} onClick={() => setActiveTab('details')}>
                Details
              </Tab>
            </TabContainer>

            {activeTab === 'about' && (
              <div style={reducedPaddingStyle}>
                <About currentDetail={currentDetail} isDarkMode={isDarkMode} />
              </div>
            )}
            {activeTab === 'traits' && (
              <div style={reducedPaddingStyle}>
                <Traits currentDetail={currentDetail} isDarkMode={isDarkMode} />
              </div>
            )}
            {activeTab === 'details' && (
              <div style={reducedPaddingStyle}>
                <Details currentDetail={currentDetail} isDarkMode={isDarkMode} />
              </div>
            )}
          </div>
          </div>
        )}
      </ExpandedBottomCardMobile>
    </>
  );
};
