import { motion } from 'framer-motion';
import React from 'react';
import { BsFillGrid3X3GapFill, BsGrid1X2Fill, BsGridFill } from 'react-icons/bs';
import { IoList } from 'react-icons/io5';
import { DropdownItem, DropdownWrapper } from "./SearchComponents";

const LayoutDropdown = ({ isDarkMode, isNavItemDropdownOpen, handleViewStateChange }) => {
  return (
    <motion.div
      initial={{ scaleX: 0 }} // Initially, the width is scaled to 0 (hidden)
      animate={{ scaleX: isNavItemDropdownOpen ? 1 : 0 }} // Expand from center if dropdown is open
      transition={{ duration: 0.3, originX: 0.5 }} // Duration of the animation and origin set to center
      style={{
        position: 'fixed',
        top: '100%',
        backgroundColor: isDarkMode ? '#fff' : '#111',
        borderRadius: '5px',
        padding: '5px 0px',
        boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
        zIndex: '999',
        overflow: 'hidden',
        right: '-12%', // Keep the dropdown at the right position
        transformOrigin: 'center', // Make sure the transform origin is set to center
      }}
    >
      <DropdownWrapper 
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '150px',
        }}
      >
        <DropdownItem 
          onClick={() => handleViewStateChange(0)}
          style={{ 
            padding: '0px 10px', 
            textAlign: 'center', 
            borderRight: isDarkMode ? '1px solid black' : '1px solid white',
            color: isDarkMode ? '#000' : '#fff'
          }}
        >
          <BsGrid1X2Fill />
        </DropdownItem>
        <DropdownItem 
          onClick={() => handleViewStateChange(1)}
          style={{ 
            padding: '0px 10px', 
            textAlign: 'center', 
            borderRight: isDarkMode ? '1px solid black' : '1px solid white',
            color: isDarkMode ? '#000' : '#fff'
          }}
        >
          <BsGridFill />
        </DropdownItem>
        <DropdownItem 
          onClick={() => handleViewStateChange(2)}
          style={{ 
            padding: '0px 10px', 
            textAlign: 'center', 
            borderRight: isDarkMode ? '1px solid black' : '1px solid white',
            color: isDarkMode ? '#000' : '#fff'
          }}
        >
          <BsFillGrid3X3GapFill />
        </DropdownItem>
        <DropdownItem 
          onClick={() => handleViewStateChange(3)}
          style={{ 
            padding: '0px 10px', 
            textAlign: 'center',
            fontSize: '20px',
            color: isDarkMode ? '#000' : '#fff'
          }}
        >
          <IoList />
        </DropdownItem>
      </DropdownWrapper>
    </motion.div>
  )
}

export default LayoutDropdown;
