import React from 'react';
import symbol from '../../images/w3oBlackLogo.png';

import icon from '../../images/w3oNameHorzBlack.png';
import {
  BottomContainer,
  CenteredContainer,
  CenteredTitle,
  ComingSoonLeft,
  ComingSoonRight,
  FullWidthContainer,
  FullWidthText,
  LeftSpacer,
  MiddleContainer,
  OverlayContainer,
  TitleContainer,
  TitleIcon,
  TitleText,
  UnderlayBody,
  UnderlayContainer,
  Verified
} from './UnderlayElements';
function UnderlayPopUp({isDarkMode, buttonPopup, props}) {
  return (
    <UnderlayBody>
    <div>

  
 <UnderlayContainer>
          <TitleContainer>
             <TitleText  src={icon} alt="Icon" width="250" height="250"/>
            <div style={{ flex: "1 1 0%", display: "flex", gap: "2em" }}></div>
            <TitleIcon  src={symbol}  />
      
          </TitleContainer>
          <MiddleContainer />
          <CenteredContainer>
            <LeftSpacer />
          </CenteredContainer>
          <div style={{ height: 10 }} />
   {!buttonPopup &&   (  <FullWidthContainer>
            <ComingSoonLeft>COMING</ComingSoonLeft>
            <LeftSpacer />
            <ComingSoonRight>SOON</ComingSoonRight>
          </FullWidthContainer>)}
          <MiddleContainer />

          <BottomContainer>
            <FullWidthText>
            <b> Creative Collab <span style={{fontSize: `12px`}}>©</span> {new Date().getFullYear()}  </b>
             <br />
              Create. Collab. Together.
            </FullWidthText>
               
            <OverlayContainer>
        <CenteredTitle>AUTHENTICATED FASHION<Verified/></CenteredTitle>
        </OverlayContainer>
          </BottomContainer>
   
        </UnderlayContainer>
    
      {/* ... other content of your app */}

  
    </div>
    </UnderlayBody>
  );
};

export default UnderlayPopUp;
