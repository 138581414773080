import { useProgress } from '@react-three/drei';
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

// Styled components for the preloader container
const PreloaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent; // Make the background transparent
  flex-direction: column;
  z-index: 10000;
`;

// Styled progress bar
const ProgressBar = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  background-color: black; // Black background of the progress bar itself
  border-radius: 50%;
`;

// Styled SVG circle
const Circle = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
`;

const CircleBackground = styled.circle`
  fill: none;
  stroke: #333;
  stroke-width: 10;
`;

const CircleProgress = styled.circle`
  fill: none;
  stroke: #a0522d; // Burnt orange color
  stroke-width: 10;
  stroke-linecap: round;
  stroke-dasharray: 314;
  stroke-dashoffset: ${({ progress }) => 314 - (314 * progress) / 100};
  transition: stroke-dashoffset 0.5s ease;
`;

// Styled for the number in the center
const ProgressNumber = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: white;
`;

// Motion for stage text animation
const StageText = styled(motion.div)`
  margin-top: 20px;
  font-size: 1.5em;
  color: black;
`;

export default function Preloader() {
  const { progress } = useProgress();

  // Determine the stage based on progress
  let stageText = '';
  if (progress < 25) {
    stageText = 'Fetching Designs';
  } else if (progress < 50) {
    stageText = 'Loading Wardrobe';
  } else if (progress < 75) {
    stageText = 'Painting Rooms';
  } else if (progress < 100) {
    stageText = 'Finalizing Lights';
  } else {
    stageText = 'Finalizing Lights';
  }

  return (
    <PreloaderContainer>
      <ProgressBar>
        <Circle width="120" height="120">
          <CircleBackground cx="60" cy="60" r="50" />
          <CircleProgress cx="60" cy="60" r="50" progress={progress} />
        </Circle>
        <ProgressNumber>{Math.floor(progress)}%</ProgressNumber> {/* Centered progress number */}
      </ProgressBar>

      {/* Stage text with motion for smooth animation */}
      <StageText
        initial={{ opacity: 0, y: 20, filter: 'blur(5px)' }}
        animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
        exit={{ opacity: 0, y: -20, filter: 'blur(5px)' }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        {stageText}
      </StageText>
    </PreloaderContainer>
  );
}
