// ListViewMobile.js

import React, { useRef, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import InfoPopupMobile from '../InfoCardPopup/InfoPopupMobile';
import {
  ListDescription,
  ListImage,
  ListOwner,
  ListPrice,
  ListTitle,
  PlusButton,
  RarityMark,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableWrapper,
} from './ListMobileElements';

export const ListViewMobile = ({ isDarkMode, tempGeneralData, isFilterVisible }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const listRef = useRef();

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleClosePopup = () => {
    setSelectedCard(null);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'blur-background') {
      handleClosePopup();
    }
  };

  // Define the column headers
  const columnHeaders = [
    'Item', // Image + NFT #
    'Rarity',
    'Listing Price',
    'Floor Difference',
    'Last Sold',
    'Owner',
    'Listed Time',
    'Action', // Add funds button
  ];

  const columnWidths = [
    '150px', // Width for Item column
    '100px', // Width for Rarity column
    '120px', // Width for Listing Price column
    '130px', // Width for Floor Difference column
    '100px', // Width for Last Sold column
    '150px', // Width for Owner column
    '120px', // Width for Listed Time column
    '80px',  // Width for Action column
  ];

  return (
    <>
      {selectedCard && (
        <InfoPopupMobile
          selectedCard={selectedCard}
          handleClosePopup={handleClosePopup}
          handleOutsideClick={handleOutsideClick}
          isDarkMode={isDarkMode}
        />
      )}

      <TableWrapper>
        <TableVirtuoso
          ref={listRef}
          data={tempGeneralData}
          style={{ height: 'calc(100vh - 50px)', width: '100%', zIndex: '999' }}
          fixedHeaderContent={() =>
            columnHeaders.map((header, idx) => (
              <TableHeader
                key={idx}
                sticky={idx === 0}
                isDarkMode={isDarkMode}
                width={columnWidths[idx]}
                isFirst={idx === 0}
              >
                {header}
              </TableHeader>
            ))
          }
          itemContent={(index, main) => [
            <TableCell
              key={`item-${index}`}
              sticky={true}
              isDarkMode={isDarkMode}
              width={columnWidths[0]}
             // style={{zIndex: 2}}
            >
              <ListImage src={main.logo} alt="NFT" />
              <ListTitle>{main.name}</ListTitle>
            </TableCell>,
            <TableCell style={{zIndex: -1}} key={`rarity-${index}`} isDarkMode={isDarkMode} width={columnWidths[1]}>
              <RarityMark rarity={main.rarityLevel}>{main.rarity}</RarityMark>
            </TableCell>,
            <TableCell style={{zIndex: -1}} key={`listingPrice-${index}`} isDarkMode={isDarkMode} width={columnWidths[2]}>
              <ListPrice>{main.ethPrice}</ListPrice>
            </TableCell>,
            <TableCell  style={{zIndex: -1}}key={`floorDifference-${index}`} isDarkMode={isDarkMode} width={columnWidths[3]}>
              <ListDescription>{main.floorDifference}</ListDescription>
            </TableCell>,
            <TableCell style={{zIndex: -1}} key={`lastSold-${index}`} isDarkMode={isDarkMode} width={columnWidths[4]}>
              <ListDescription>{main.lastSold}</ListDescription>
            </TableCell>,
            <TableCell style={{zIndex: 1}} key={`owner-${index}`} isDarkMode={isDarkMode} width={columnWidths[5]}>
              <ListOwner>{main.owner}</ListOwner>
            </TableCell>,
            <TableCell style={{zIndex: 1}} key={`listedTime-${index}`} isDarkMode={isDarkMode} width={columnWidths[6]}>
              <ListDescription>{main.listedTime}</ListDescription>
            </TableCell>,
            <TableCell style={{zIndex: 1}} key={`action-${index}`} isDarkMode={isDarkMode} width={columnWidths[7]}>
              <PlusButton onClick={() => handleCardClick(main)} isDarkMode={isDarkMode} />
            </TableCell>,
          ]}
          components={{
            Table: Table,
            TableHead: TableHead,
            TableBody: TableBody,
            TableRow: TableRow,
            TableCell: TableCell,
            Scroller: TableWrapper, // Use your custom TableWrapper for scrolling
          }}
        />
      </TableWrapper>
    </>
  );
};

export default ListViewMobile;
