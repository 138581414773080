import { motion } from 'framer-motion';
import { FaTimes } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';
import styled, { css } from 'styled-components';
//Close Icon


// COLORS
const softOrange = '#F4A259';
const tomatoRed = '#F25C66';
const mediumBlue = '#1E272D';

export const Body = styled.body`
  background-color: ${mediumBlue};
`;

export const ExitIcon = styled(MdCancel)` 
 cursor: pointer;
 // background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  border-radius: 100%;

 // transform: translate(10px, 10px) rotate()(180deg);
  transition: transform 3300ms ease-in-out;
  font-size: 32px; /* Adjust the font size as per your requirements */
//z-index: -10000000;
transform-origin: center;
display: flex;
  justify-content: center;
  align-items: center;
    //display: flex;
    &:hover {
   

}

`;
export const PExit = styled(motion.div)`
    position: absolute;

    right: 2.5%;
    transition: transform 30ms ease-in-out;
    top: 2.5%;
    cursor: pointer;
    transform-origin: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999999;
   color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
 

   &:hover {
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    //background-color: #000;
    //transform: translate(10px, 10px) rotate()(360deg);
      //right: -5px;
      //filter: drop-shadow(0 0 10px white);
  }
  &:hover ${ExitIcon} {
    transform-origin: center;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   // background-color: #000;
   // transform:  rotateZ(180deg);
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      filter: drop-shadow(0 0 5px white);
  }


`

export const ExitIconWrap = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center; /* Set the transform origin to the center */

cursor: pointer;
&:focus {
                    outline: 0 !important;
                    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none !important;
                    -moz-user-select: none !important;
                    -ms-user-select: none !important;
                    user-select: none !important; 
                    box-shadow: none !important;
                    border: none !important;
  outline: none;
                }
  `
//Shorter Exit Icon


export const CloseContainer = styled.div`

  width: 48px;
  height: 48px;
  position: absolute;
  top: 3.5rem;

right: 7.5rem;

    background: transparent;
 z-index: 9999999;
 @media screen and (max-width: 768px) {
          //transform: scale(0.7);
          zoom: 0.75;
          right: 0.75rem;
          top: 0.75rem;
          
 
    }

`;

export const LeftRight = styled.div`
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;

  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;

  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
`;

export const RightLeft = styled.div`
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;

  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
`;

export const Label = styled.label`
 color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  font-family: Helvetica, Arial, sans-serif;
 
  font-style: normal;
    font-weight: 700;
    line-height: 20px;
  font-size: 8px !important;
display: block !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease-in;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  
`;

export const Close = styled.div`
  margin: 60px 0 0 5px;
  position: absolute;
`;

export const CloseContainerHover = styled(CloseContainer)`
  &:hover ${LeftRight} {
    transform: rotate(-45deg);
    background-color: ${tomatoRed};
  }

  &:hover ${RightLeft} {
    transform: rotate(45deg);
    background-color: ${tomatoRed};
  }

  &:hover ${Label} {
    opacity: 1;
  }
`;


export const CloseIcon = styled(FaTimes)`
    color: #fff;
    cursor: pointer;
  
`;



 

export const sharedStyles = css`
  //background-color: #eee;

  height: 30px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 5px 0;
  /*
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
  */
`;

export const BlurredOverlay = styled.div`
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25); /* Adjust the opacity as needed */
  backdrop-filter: blur(10px); /* Adjust the blur intensity as needed */

  background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(100, 100, 100, .7)')};
  backdrop-filter: ${({ isDarkMode }) => (isDarkMode ? 'blur(10px)' : 'blur(15px)')};
  z-index: 9999999999999999; /* Make sure it's above other content */
`;


export const DaoFormContainer = styled(motion.div)`
  position: absolute;
  display: flex;
  color: #fff;
  z-index: 9999999999999999;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 0 20px;
 // left: 50%;
  top: 0; /* Set top to 0 to align with the top of its container */
  //transform: translateX(-50%); /* Only translate horizontally */
  background-repeat: repeat;
  background-size: cover;
  //
  @media screen and (max-width: 768px) {
    padding: 0 0;
    overflow: hidden;
  }

`;

export const DaoFormForm = styled.form`
  width: 60%;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  transition: 0.3s ease-in-out;
  max-height: 100%;
  max-width: 1200px;
  width: 1000px;
  padding: 40px;
  backdrop-filter: blur(10px);
  background-repeat: repeat;
  border-radius: 30px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  transform: translate(0, 5%);
  overflow-y: auto; // Enable vertical scrolling
  overflow-x: hidden; // Disable horizontal scrolling

  // Hide scrollbars in Webkit-based browsers
  ::-webkit-scrollbar {
    width: 0px; // Hide scrollbar in Webkit browsers
    height: 0px; // Hide horizontal scrollbar
  }

  // Hide scrollbars in IE, Edge, and Firefox
  -ms-overflow-style: none;  // IE and Edge
  scrollbar-width: none;  // Firefox

  @media screen and (max-width: 768px) {
    max-width: initial;
    width: 95%;
    padding: 30px;
    border-radius: 30px;
    top: 50%;
    position: fixed;
    transform: translate(0, -50%);
  }
`;




export const DaoFormInput = styled.input`
  display: block;
  width: 100%;
  
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};


  ${sharedStyles}
`;

export const DaoFormHeader = styled.h1`

font-size: 48px;
text-transform: uppercase;
font-weight: 900;
transform: translateY(-37.5px);
//top: -20px;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};

  @media screen and (max-width: 768px) {
          //transform: scale(0.7);
          font-size: 32px;

          transform: translateY(-20px);
        
 
    }
`

export const DaoFormLabel = styled.label`
  text-transform: uppercase;
  display: block; // This will place each label on a new line
  margin-bottom: 10px; // Adjust space between each label as needed
  cursor: pointer; // Changes the cursor to indicate it's selectable
  font-size: 16.5px; // Font size for the entire label text
  font-weight: 600;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  transform: translateY(10px);
  // Targeting the text next to the input, not the input itself
  // Assuming you want the font smaller than the surrounding text
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16.5px;
  font-weight: 600;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  text-align: left; // Align the text to the left
  max-width: 95%;
  overflow: auto;
  &:not(input) {
    font-size: 13.5px; // Smaller font size for the label text
  }

  // Styles for the radio input
  input[type="radio"] {
    margin-right: 10px; // Space between radio button and label text
    // You can't change the font size of a radio button, but you can scale it
    transform: scale(1);
  }
  @media screen and (max-width: 768px) {
    font-size: 12px; // Font size for the entire label text
  font-weight: 600;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  transform: translateY(10px);
  // Targeting the text next to the input, not the input itself
  // Assuming you want the font smaller than the surrounding text
  &:not(input) {
    white-space: nowrap;
    font-size: 7.5px; // Smaller font size for the label text
  }



    
 
    }
`;

export const DaoFormLegend = styled.legend`

text-transform: uppercase;
font-size: 16.5px;
font-weight: 800;
color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
display: block; // This will place each label on a new line
  margin-bottom: 10px; // Adjust space between each label as needed

  @media screen and (max-width: 768px) {
    font-size: 12px; // Font size for the entire label text

    margin-bottom: 0px;
    
 
    }
`

export const DaoFormTextArea = styled.textarea`
  //background-color: #eee;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  
  min-height: 100px;
  resize: none;
  ${sharedStyles}
`;

export const DaoFormTextInput = styled.textarea`
  //background-color: #eee;
  width: 100%;

  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 10px;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  
 // min-height: 100px;
  resize: none;
  ${sharedStyles}
`;
export const DaoFormButton = styled.input`
  display: block;
  background-color: #f7797d;
  color: #fff;
  font-size: 0.9rem;
  border: 0;
  border-radius: 5px;
  height: 40px;
  width: 100px;
  padding: 0 20px;
  margin-top: 50px;
 // right: 5%;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;

  // Disable the focus outline for aesthetic reasons, if desired
  &:focus {
    outline: none;
  }

`;

export const DaoFormFieldSet = styled.fieldset`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 20px 0;
  max-width: 100%;
  display: grid; // Use grid layout for alignment
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); // Create columns that fit the container width
  grid-gap: 10px; // Space between grid items

  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};

  
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};


  legend {
    padding: 0 10px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};

  }
  label {
    padding-right: 20px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};

  }
  input {
    margin-right: 10px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};

  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr; // Single column layout for mobile view
    text-align: left;
    align-items: left;
    justify-content: left;
  }
`;

export const DaoFormError = styled.div`
  color: #ff6b6b; // Just an example error color
  display: block; // Ensures the error message appears on a new line

  font-weight: 800;
  font-size: 10px;
  text-transform: uppercase;
  margin: 0 20px 20px 0;
  margin-top: 5px;
  z-index: 99999999;
`;



export const initialState = {
  //text inputs
  name: '',
  twitter: '',
  discord: '',
  email: '',
  motivation: '',
  experience: '',
  portfolio: '',
  message: '',

  //radio inputs
  communication: '',
  deadlines: '',
  collaboration: '',
  blockchain: '',
  feedback: '',
  hours: '',

  //checkbox inputs
  expertise: []
};


// ... existing styled components ...

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const ProgressBall = styled(motion.div)`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: ${props => props.isCurrent ? '#ADD8E6' : props.isActive ? '#90EE90' : 'gray'}; // Light blue and light green
  // Ensure the scale returns to normal after the animation
  transition: background-color 0.3s ease;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Button = styled.button`
  padding: 10px 15px;
  background-color: #f7797d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;
