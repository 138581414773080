export const plan1 = {
  title: "Pre-Launch",
  subHeader: "Preparations",
  id: "plan1",
  items: [
      "Assemble The Avengers (Develop Our Team)",
      "Iron Out Concept",
      "Develop Brand Identity",
      "Go Legit",
      "Develop All Physical And Digital Prototypes",
      "Finalize Merch Provider Partnerships",
      "Build The Website And Socials",
      "Proof Of Concept Team Merch Drop"
  ],
  imageUrl: "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oRoadmap/plan1.png",
  isImageLeft: false,
};

export const plan2 = {
  title: "Post",
  subHeader: "Launch",
  id: "plan2",
  items: [
      "Finalize/Showcase Products & Services",
      "Brand Awareness & Social Media Campaign",
      "The DAO Begins (Interviews & Applications)",
      "DAO NFT Membership Drop",
      "Develop Partnerships",
      "Launch Website",
      "Token Drop",
  ],
  imageUrl: "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oRoadmap/plan2.png",
  isImageLeft: true,
};

export const plan3 = {
  title: "Early Operations",
  subHeader: " and Expansion",
  id: "plan3",
  items: [
      "Web 3 Outfitters Official Closet Drop",
      "Web 3 Outfitters First Official Web Tag’d Merch Drop",
      "Partnership Merch Drops",
      "Partnership Closet Drops",
      "Begin DAO Merch Development"
  ],
  imageUrl: "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oRoadmap/plan3.png",
  isImageLeft: false,
};

export const plan4 = {
  title: "Growth and ",
  subHeader: "Innovation",
  id: "plan4",
  items: [
      "DAO Merch Drop",
      "Public Closet NFT Drop",
      "Begin Virtual Shop Development (Metaverse)",
      "Begin Marketplace Development",
      "Explore New Creative Avenues and Partnerships"
  ],
  imageUrl: "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oRoadmap/plan4.png",
  isImageLeft: true,
};

export const plan5 = {
  title: "Scaling and ",
  subHeader: "Domination",
  id: "plan5",
  items: [
      "Launch Virtual Shop",
      "Launch Marketplace",
      "Connect Ecosystem",
      "Become a Leading Force in Web 3 Apparel and Fashion"
  ],
  imageUrl: "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oRoadmap/plan5.png",
  isImageLeft: false,
};
