import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa'; // Import react icon for the "GO BACK" arrow
import {
  AnimateBubbles,
  Button,
  ButtonText2, InspectModel, ModelSwitch,
  OverlayButtonRow, OverlayButtonWrapper, OverlayContainer, TextSpan2
} from './CustomClosetOverlayElements';

/*
const MAINNET_RPC_URL = "https://goerli.infura.io/v3/7c9d1c18f6244605b8d57b8dd612aa7e";
const injected = injectedModule();
const walletConnect = walletConnectModule();
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: false });

const onboard = Onboard({
  wallets: [
      injected,
      walletConnect,
      coinbaseWalletSdk
  ],
  chains: [
    {
      id: '0x5',
      token: 'gETH',
      label: 'Goerli Testnet',
      rpcUrl: MAINNET_RPC_URL
    }
  ]
});
*/

export function CustomClosetOverlay({
  mobile3Dcollecion, setMobile3Dcollecion, setPageSwitch, pageSwitch, togglePageSwitch, setIsMouseVisible,
  onEffectChange, orbitControlsEnabled, setOrbitControlsEnabled, buttonPopup, toggleOrbitControls, toggleUtilities,
  isDarkMode, setModelSwitch, modelSwitch, buttonPopup2, buttonPopup3, setButtonPopup, setButtonPopup2, setButtonPopup3, props
}) {
  const [buttonHovered, setButtonHovered] = useState(false);
  
  const handleButtonClick = () => {
    setOrbitControlsEnabled(false); // Reset orbitControlsEnabled on button click
    setModelSwitch(!modelSwitch);  // Toggle the model switch state
  };

  const handleClick = () => {
    toggleOrbitControls();
  };

  return (
    <OverlayContainer>
      <OverlayButtonWrapper>
        <OverlayButtonRow>
          <AnimatePresence>
            {/* Button when orbitControlsEnabled is false */}
            {!orbitControlsEnabled && (
              <motion.div
                key="inspectModel"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                <ModelSwitch>
                  <AnimateBubbles
                    isDarkMode={isDarkMode}
                    primary="true"
                    dark="true"
                    fontBig="true"
                    className={buttonHovered ? 'animate' : ''}
                    onClick={handleClick}
                    onMouseEnter={() => setButtonHovered(true)}
                    onMouseLeave={() => setButtonHovered(false)}
                  >
                    <ButtonText2>
                      <TextSpan2 isDarkMode={isDarkMode} style={{ marginLeft: '0px' }}>
                        INSPECT MODEL
                      </TextSpan2>
                      <InspectModel orbitControlsEnabled={orbitControlsEnabled} isDarkMode={isDarkMode} />
                    </ButtonText2>
                  </AnimateBubbles>
                </ModelSwitch>
              </motion.div>
            )}

            {/* Button when orbitControlsEnabled is true */}
            {orbitControlsEnabled && (
              <motion.div
                key="goBack"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1.5 }}  // Pop effect
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 2.5, ease: "easeInOut" }}
                style={{background: 'none', backgroundColor: 'none'}}
              >
                <ModelSwitch isDarkMode={isDarkMode}     onClick={handleClick}>
                  <Button
                    isDarkMode={isDarkMode}
                    primary="true"
                    dark="true"
                    fontBig="true"
                 
                  >
                    <ButtonText2 isDarkMode={isDarkMode} >
                   {/* Add arrow icon when "GO BACK" is shown */}
                      <TextSpan2 isDarkMode={isDarkMode} style={{ marginRight: '20px', color: 'white' }}>
                      <FaArrowLeft style={{color: 'white', marginTop: '0px', fontSize: '12px'}}/>  GO BACK
                      </TextSpan2>
              
                    </ButtonText2>
                  </Button>
                </ModelSwitch>
              </motion.div>
            )}
          </AnimatePresence>
        </OverlayButtonRow>
      </OverlayButtonWrapper>
    </OverlayContainer>
  );
}

export default CustomClosetOverlay;
