// InfoPopupMobile.js

import React, { useState } from 'react';
import { FaEthereum, FaEye, FaHeart, FaUser } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import {
    About,
    ActivityTableMobile,
    BidsTableMobile,
    BlurBackground,
    CloseIcon,
    DescriptionGridMobile,
    GraphPlaceholder,
    InfoPopupContainer,
    InfoPopupDescription,
    InfoPopupImage,
    InfoPopupOwner,
    InfoPopupPrice,
    InfoPopupTitle,
    ListedDetailsMobile,
    OwnerLinkMobile,
    PriceHistoryMobile,
    RarityMark,
    SectionContent,
    SectionTitle,
    SmallText,
    StatsColumnMobile,
    StatsRowMobile,
    TabMobile,
    Table,
    TableData,
    TableHead,
    TableHeader,
    TableRow,
    TabsContainerMobile,
    Traits,
    TraitsSectionMobile,
    TraitsTitle
} from './InfoPopupElements';

const InfoPopupMobile = ({
  selectedCard,
  handleClosePopup,
  handleOutsideClick,
  isDarkMode,
}) => {
  const [activeTab, setActiveTab] = useState('overview');

  return (
    <>
      <BlurBackground id="blur-background" onClick={handleOutsideClick} />
      <InfoPopupContainer isDarkMode={isDarkMode}>
        <CloseIcon onClick={handleClosePopup}>&times;</CloseIcon>

        <InfoPopupTitle>
          {selectedCard.header} <MdVerified />
        </InfoPopupTitle>

        <SmallText isDarkMode={isDarkMode}>
          RARITY{' '}
          <RarityMark rarity={selectedCard.rarityLevel} isDarkMode={isDarkMode}>
            #{selectedCard.rarity}
          </RarityMark>
        </SmallText>

        <InfoPopupOwner>
          Owner:{' '}
          <OwnerLinkMobile
            href={`https://etherscan.io/address/${selectedCard.owner}`}
            isDarkMode={isDarkMode}
          >
            {selectedCard.owner}
          </OwnerLinkMobile>
        </InfoPopupOwner>

        <InfoPopupImage src={selectedCard.logo} alt="Main Logo" />

        <StatsRowMobile>
          <StatsColumnMobile>
            <FaEye /> Views: {selectedCard.views}
          </StatsColumnMobile>
          <StatsColumnMobile>
            <FaHeart /> Favorites: {selectedCard.favorites}
          </StatsColumnMobile>
          <StatsColumnMobile>
            <FaUser />{' '}
            <OwnerLinkMobile
              href={`/pfps/${selectedCard.pfps}`}
              isDarkMode={isDarkMode}
            >
              PFPs
            </OwnerLinkMobile>
          </StatsColumnMobile>
        </StatsRowMobile>

        <TabsContainerMobile isDarkMode={isDarkMode}>
          <TabMobile
            isActive={activeTab === 'overview'}
            isDarkMode={isDarkMode}
            onClick={() => setActiveTab('overview')}
          >
            Overview
          </TabMobile>
          <TabMobile
            isActive={activeTab === 'bids'}
            isDarkMode={isDarkMode}
            onClick={() => setActiveTab('bids')}
          >
            Bids
          </TabMobile>
          <TabMobile
            isActive={activeTab === 'activity'}
            isDarkMode={isDarkMode}
            onClick={() => setActiveTab('activity')}
          >
            Activity
          </TabMobile>
        </TabsContainerMobile>

        {activeTab === 'overview' && (
          <DescriptionGridMobile>
            <InfoPopupDescription>{selectedCard.name}</InfoPopupDescription>
            <InfoPopupDescription>{selectedCard.description}</InfoPopupDescription>
            <InfoPopupPrice>
              {selectedCard.ethPrice} <FaEthereum />
            </InfoPopupPrice>
            <InfoPopupDescription>
              Floor Difference: {selectedCard.floorDifference}
            </InfoPopupDescription>
            <InfoPopupDescription>
              Last Sold: {selectedCard.lastSold} <FaEthereum />
            </InfoPopupDescription>
            <InfoPopupDescription>
              Listed Time: {selectedCard.listedTime}
            </InfoPopupDescription>
          </DescriptionGridMobile>
        )}

        {activeTab === 'bids' && (
          <BidsTableMobile>
            <Table isDarkMode={isDarkMode}>
              <TableHead isDarkMode={isDarkMode}>
                <TableRow>
                  <TableHeader>Unit Price</TableHeader>
                  <TableHeader>USD Price</TableHeader>
                  <TableHeader>Quantity</TableHeader>
                  <TableHeader>Floor Diff</TableHeader>
                  <TableHeader>Expiration</TableHeader>
                  <TableHeader>From</TableHeader>
                </TableRow>
              </TableHead>
              <tbody>
                {selectedCard.bids.map((bid, index) => (
                  <TableRow key={index} isDarkMode={isDarkMode}>
                    <TableData>{bid.unitPrice}</TableData>
                    <TableData>{bid.usdUnitPrice}</TableData>
                    <TableData>{bid.quantity}</TableData>
                    <TableData>{bid.floorDifference}</TableData>
                    <TableData>{bid.expiration}</TableData>
                    <TableData>{bid.from}</TableData>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </BidsTableMobile>
        )}

        {activeTab === 'activity' && (
          <ActivityTableMobile>
            <Table isDarkMode={isDarkMode}>
              <TableHead isDarkMode={isDarkMode}>
                <TableRow>
                  <TableHeader>Unit Price</TableHeader>
                  <TableHeader>USD Price</TableHeader>
                  <TableHeader>Quantity</TableHeader>
                  <TableHeader>Expiration</TableHeader>
                  <TableHeader>From</TableHeader>
                  <TableHeader>Action</TableHeader>
                </TableRow>
              </TableHead>
              <tbody>
                {selectedCard.activity.map((activities, index) => (
                  <TableRow key={index} isDarkMode={isDarkMode}>
                    <TableData>{activities.unitPrice}</TableData>
                    <TableData>{activities.usdUnitPrice}</TableData>
                    <TableData>{activities.quantity}</TableData>
                    <TableData>{activities.expiration}</TableData>
                    <TableData>{activities.from}</TableData>
                    <TableData>{activities.cartSymbol}</TableData>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </ActivityTableMobile>
        )}

        <TraitsSectionMobile>
          <TraitsTitle isDarkMode={isDarkMode}>Traits</TraitsTitle>
          <Traits currentDetail={selectedCard} isDarkMode={isDarkMode} />
        </TraitsSectionMobile>

        <PriceHistoryMobile>
          <SectionTitle isDarkMode={isDarkMode}>Price History</SectionTitle>
          <GraphPlaceholder isDarkMode={isDarkMode}>Graph here</GraphPlaceholder>
        </PriceHistoryMobile>

        <About currentDetail={selectedCard} isDarkMode={isDarkMode} />

        <ListedDetailsMobile>
          <SectionTitle isDarkMode={isDarkMode}>Listed Details</SectionTitle>
          <SectionContent isDarkMode={isDarkMode}>
            Connect wallet to see more
          </SectionContent>
        </ListedDetailsMobile>
      </InfoPopupContainer>
    </>
  );
};

export default InfoPopupMobile;
