import { motion } from 'framer-motion';
import { FaHandshake } from "react-icons/fa6";
import { MdCancel } from 'react-icons/md';
import styled, { css, keyframes } from 'styled-components';

//Exit Icon
export const ExitIcon = styled(MdCancel)` 
 cursor: pointer;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  border-radius: 100%;

 // transform: translate(10px, 10px) rotate()(180deg);
  transition: transform 3300ms ease-in-out;
  font-size: 32px; /* Adjust the font size as per your requirements */
//z-index: -10000000;
transform-origin: center;
display: flex;
  justify-content: center;
  align-items: center;
    //display: flex;
    &:hover {
   

}

`;



export const PExit = styled(motion.div)`
    position: absolute;

    right: 2.5%;
    transition: transform 30ms ease-in-out;
    top: 2.5%;
    transform-origin: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999999;
    cursor: pointer;
   color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
 

   &:hover {
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    //background-color: #000;
    //transform: translate(10px, 10px) rotate()(360deg);
      //right: -5px;
      //filter: drop-shadow(0 0 10px white);
  }
  &:hover ${ExitIcon} {
    transform-origin: center;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   // background-color: #000;
   // transform:  rotateZ(180deg);
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      filter: drop-shadow(0 0 5px white);
  }


`

export const ExitIconWrap = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center; /* Set the transform origin to the center */

cursor: pointer;
&:focus {
                    outline: 0 !important;
                    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none !important;
                    -moz-user-select: none !important;
                    -ms-user-select: none !important;
                    user-select: none !important; 
                    box-shadow: none !important;
                    border: none !important;
  outline: none;
                }
  `


///
///  Success Message popup
///


const successPrimary = "#3DC480";
const successSecondary = "#fff";
//const successSecondary = "#B8BABB";
export const MessageContainer = styled.div`
    position: fixed;
    left: 50%;
    top: 70%;
    
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: 9999999999999;
    overflow: hidden; 
`

export const SuccessMessage = styled(motion.div)` 
  text-align: center;
  width: 400px;
  height: 300px;
  position: relative;
  z-index: 9999999999999;
  border-radius: 20px;
 
  //transform: translate(-50%, -50%);
`;

export const SuccessMessageIcon = styled.div`
  max-width: 75px;

`;

export const SuccessMessageTitle = styled(motion.p)` 
  color: ${successPrimary};
  transform: translateY(-350px);
  //opacity: 0;
  transition: all 200ms ease;
z-index: 999999999999999999999999999;
  .active & {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const SuccessMessageContent = styled(motion.p)` 
  color: ${successSecondary};
  transform: translateY(-325px);
  //opacity: 0;
  transition: all 200ms ease;
  transition-delay: 50ms;

  .active & {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const IconCheckmark = styled(motion.svg)` 
  circle {
    fill: ${successPrimary};
    transform-origin: 50% 50%;
    transform: scale(0.15);
    
    transition: transform 200ms cubic-bezier(.22, .96, .38, .98);
  }
  path {
    transform: scale(0.15);
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 350ms ease;
    transition-delay: 100ms;
  }

  .active & {
    circle {
      transform: scale(1);
    }
  }
`;

///
/// Custom Tooltip Hover popup
///


export const PopUpText = styled.div`
  height: 5px;
  width: 240px;
  text-align: center;
  font-weight: 900;
  font-size: 1.5rem;
  border-radius: 3px;
  cursor: pointer;

 

`;

export const PopUpBox = styled.div`
  position: absolute;
  margin-top: 10%;
  margin-left: 25%;
  transform: translateX(-50%) scale(1);
  visibility: hidden;
  color: #000;
  //color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  background-color: #000;
  //background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  width: 150px;
  padding: 5px 5px;
  border-radius: 20px;
  z-index: 9999999999999;
  white-space: nowrap;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;

  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 150px;
    top: -10px;
    position: absolute;
    white-space: nowrap;
    z-index: 9999999999999;
    border: 10px solid transparent;
    transform: rotate(-225deg);
    transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;

  }

  @media screen and (max-width: 768px) {
   // zoom: 0.5;
   opacity: 0;
}

`;

export const PopUpCard = styled.div`
  position: relative;

  z-index: 9999999999999;
`;




///Blurred Background

export const BlurredBackground = styled.div`
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
    position: fixed;
    transition: opacity 0.5s ease-in-out;
    overflow: scroll;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25); /* Adjust the opacity as needed */
    backdrop-filter: blur(10px); /* Adjust the blur intensity as needed */
    background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(100, 100, 100, .7)')};
    backdrop-filter: ${({ isDarkMode }) => (isDarkMode ? 'blur(10px)' : 'blur(15px)')};
    z-index: -1; /* Make sure it's above other content */
    overflow: hidden; 
`;

export const BlurredBackgroundWelcome = styled.div`
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
    position: fixed;
    transition: opacity 0.5s ease-in-out;
    overflow: scroll;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25); /* Adjust the opacity as needed */
    backdrop-filter: blur(10px); /* Adjust the blur intensity as needed */
    background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(100, 100, 100, .7)')};
    backdrop-filter: ${({ isDarkMode }) => (isDarkMode ? 'blur(10px)' : 'blur(15px)')};
    z-index: 999999999; /* Make sure it's above other content */
    overflow: hidden; 
`;




export const BlurredBackgroundLight = styled.div`
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
    position: fixed;
    transition: opacity 0.5s ease-in-out;
    overflow: scroll;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 0.1)' : 'rgba(100, 100, 100, .1)')};
    backdrop-filter: ${({ isDarkMode }) => (isDarkMode ? 'blur(5px)' : 'blur(7.5px)')};
    z-index: 999999999; /* Make sure it's above other content */
    overflow: hidden; 
`;





///Fancy Button
///
/// Fancy Button
///

export const TextSpan = styled.span`
    color: #fff;
    padding: 10px 0px 30px 15px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};

`
export const ButtonText = styled.div`
   position: relative; /* Added position: relative */
   transition: transform 0.2s ease-in-out;
   // transform: ; /* Adjust the value as per your requirements */
    padding: 5px 0px 0px 0px;
    //transform: matrix(0,0,0,0,0,0);

    transform: translateY(-12px) matrix(1, 0, 0, .8, 0, 0);

`
export const JoinTeam = styled.div`
   position: absolute;
   //zoom: 0.85;
   left: 50%;
   transform: translateX(-50%) scale(0.95);

   & :hover + ${PopUpBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 230px;
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
  @media screen and (max-width: 1024px) {
    transform: translateX(-50%) scale(0.65);
    margin-top: 0.5%;
      }
      
`;
export const Icon = styled(FaHandshake)`
 // background-color: #fff;
 
  border-radius: 100%;
  padding: 8px; /* Adjust the padding as per your requirements */
  margin-top: 5px;
  margin-left: 20px;
  transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
  font-size: 40px; /* Adjust the font size as per your requirements */
 

      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    //display: flex;
    &:hover {
    background-color: #000;

      filter: drop-shadow(0 0 10px white);

}
@media screen and (max-width: 1024px) {
  &:hover {
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};

}
   }

`;


const black = "#000";
const white = "#fff";
const silver = "#c0c0c0";

export const Body = styled.body`
  font-size: 16px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  text-align: center;

  z-index: 999999999999;
  background-color: ${silver};
`;

export const BubblyButton = styled.button`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
      display: inline-block;
      //zoom: 1.25;
      -webkit-appearance: none;
      appearance: none;


      background-color: ${({ isDarkMode }) => (isDarkMode ? white : black)};
      
      border-radius: 4px;
      border: none;
      cursor: pointer;
      position: relative;
      transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
      box-shadow: 0 2px 25px rgba(192, 192, 192, 0.5);
      width: 200px;
      height: 50px;
      &:focus {
        outline: 0;
      }

      &:before,
      &:after {
        position: absolute;
        content: '';
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all ease-in-out 0.5s;
        background-repeat: no-repeat;
      }

      &:before {
        display: none;
        top: -120%;
        margin-top: -20px;
       // margin-left: 30px;
        zoom: 1.25;
        transform: scale(1.5);
        background-image:
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, transparent 20%, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 30%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, transparent 10%, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 15%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%);
        background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
      }

      &:after {
        display: none;
        zoom: 1.25;
        //margin-left: 30px;
        transform: scale(1.5);
        bottom: -120%;
        margin-top: 0px;
        background-image:
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, transparent 10%, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 15%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%);
        background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
      }
      &:active {

        transform: scale(0.9);
        background-color: ${black};
        box-shadow: 0 2px 25px rgba(192, 192, 192, 0.2);
  }
`;





export const animate = keyframes`
  0% {
    top: -30px;
    left: 20px;
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    top: -30px;
    left: 20px;
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    top: -30px;
    left: 20px;
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
`;

export const AnimateBubbles = styled(BubblyButton)`


    cursor: pointer;
    display: inline-block;
   
    width: 240px;
    height: 65px;
    border-radius: 90px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */

    color: #000;



  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  /*
  &:before {
    background-color: initial;
    background-image: ${({ isDarkMode }) =>
      isDarkMode
        ? 'linear-gradient(#A9A9A9 0, rgba(0, 0, 0, 0) 100%)' // Black background
        : 'linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%)' // Original white background
    };
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
  }


*/
  
  &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};

    }
    ${Icon} {
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    }
  }


  @media screen and (max-width: 1024px) {
    /*
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    */
    &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};


    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }
    ${Icon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(0px 0px 0px white);
    }
  }
  }
  @media screen and (max-width: 1024px) {
          //transform: scale(0.7);
          //top: 10px;
       
          //left: 75%;
    }

  
  &.animate {
    &:before {
      display: block;
      animation: ${animate} ease-in-out 0.75s forwards;
    }
    &:after {
      display: block;
      animation: ${animate} ease-in-out 0.75s forwards;
    }
  }

`;

//CIRCLE BUBBLES COMPONENT
/* Bubble animation keyframes (from AnimateBubbles) */
/* Bubble animation keyframes (from AnimateBubbles) */
const textAniation = keyframes`
  from {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
`;
const animateCirc = keyframes`
  0% {
    top: -75%;
    background-position: 0% 100%, 100% 0%;
  }
  50% {
    background-position: 50% 50%, 50% 50%;
  }
  100% {
    top: -75%;
    background-position: 100% 0%, 0% 100%;
    background-size: 0% 0%, 0% 0%;
  }
`;

// Keyframes for SVG in and out animation
const svgInOut = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
`;

// Keyframes for constant rotation
const constantRotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Keyframes for cursor attraction
const attract = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(var(--dx), var(--dy));
  }
`;

// CircleBubbles component
export const CircleBubbles = styled(motion.button)`
  position: relative;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  border: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  z-index: 99999;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.5s ease;
  //left: 50%;
   // transform: translateX(-50%);
  &:hover {
    background-color: #ff8c42; /* Burnt orange color */
  }

  /* Hide the SVG by default */
  svg {
    display: none;
  }

  /* Show and animate the SVG on hover */
  &:hover svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: center;
    width: 100%;
    height: 100%;

    ${({ svgAnimationDone }) =>
      svgAnimationDone
        ? css`
            animation: ${constantRotate} 5s linear infinite;
          `
        : css`
            animation: ${svgInOut} 1s ease-in-out forwards;
          `}
  }

    /* Rotating text around the circle */
    &:before {
    content: 'JOIN OUR TEAM • JOIN OUR TEAM • JOIN OUR TEAM • JOIN OUR TEAM • JOIN OUR TEAM • JOIN OUR TEAM • ';
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 18px;
    text-transform: uppercase;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    animation: ${textAniation} 10s linear infinite;
    transform-origin: center;
  }

/* Bubble animation (retained from AnimateBubbles) */
&.animate:before,
  &.animate:after {
    content: '';
    position: absolute;
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1;
    background-repeat: no-repeat;
  }

  &.animate:before {
    top: -75%;
    background-image:
      radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.3) 25%, transparent 25%),
      radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.3) 25%, transparent 25%);
    background-size: 50% 50%;
    animation: ${animateCirc} 0.75s forwards;
  }

  &.animate:after {
    bottom: -75%;
    background-image:
      radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.3) 25%, transparent 25%),
      radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.3) 25%, transparent 25%);
    background-size: 50% 50%;
    animation: ${animateCirc} 0.75s forwards;
  }
`;

// Inner text
export const CircleButtonText = styled.span`
  font-size: 24px;
  font-weight: bold;
  z-index: 1;
  pointer-events: none;
  transition: color 0.5s ease;

  ${CircleBubbles}:hover & {
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  }
`;












