//import { Canvas } from '@react-three/fiber';
import { motion } from 'framer-motion';
import { BiNetworkChart } from "react-icons/bi";
import { FaClipboardList, FaTimes } from 'react-icons/fa';
import { HiDocumentText } from 'react-icons/hi';
import { MdCancel, MdVerified } from 'react-icons/md';
import { RiGitRepositoryPrivateFill } from 'react-icons/ri';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import styled from 'styled-components';
export const StyledCanvasWrapper = styled.div`
  position: relative;
  top: -15%;
  width: 400px;
  height: 400px;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 600px) {
    width: 350px;
  height: 350px;
   // transform: scale(0.7); /* Scale down by 15% */
   top: -4%;
   transform: translateX(-92.5%);
  }
`;

/*
export const StyledCanvas = styled(Canvas)`
  //position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
`;
*/

export const UtilitiesContainerWrap = styled.div`
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
    position: fixed;
    z-index: 999;
    top: -10px;
    left: 0;
    width: 120vw;
    height: 120vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? 'block' : 'none')};
    transition: opacity 0.5s ease-in-out;
    z-index: 9999999;
   // opacity: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? '1' : '0')};
    backdrop-filter: blur(5px);
`;
export const UtilitiesContainer =  styled(motion.div)`
    //overflow: hidden;
    position: fixed;
    min-width: 685px;
    //width: ${({ maxWidth }) => `${maxWidth / 2}px`}; /* Set the width to half of max width */
 z-index: 9999999999;
    //min-width: 50%;
    height: 100vh;
    //border-radius: 30px;
    overflow-x: hidden;
    scrollbar-width: none;
    -webkit-overflow-scrolling: auto;
    
    &::-webkit-scrollbar {
        width: 0; /* Hide the scrollbar on WebKit browsers */
    }
    
    //transition: 0.3s ease-in-out;
    display: flex;
   // display: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? 'flex' : 'none')};
    z-index: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? '9999999999' : '0')};
   //opacity: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? '1' : '0')};
    left: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? '0' : '-200%')};
    margin-left: 0%;
    //transform: translateX(-50%) translateY(5%);
    box-shadow: ${({ isUtilitiesOpen }) =>
        isUtilitiesOpen ? '0 0 60px rgba(0, 0, 0, 0.5)' : 'none'}; /* 3x larger blur radius */
   @media screen and (max-width: 1024px) {

    min-width: 685px;
      //transform: translateY(0%);
      width: 100vw;
      height: 100vh;
      border-radius: 0;
           }

   @media screen and (max-width: 768px) {

 

       }

`;

export const UtilitiesContainerWrapper = styled.div`
   @media screen and (max-width: 1024px) {
    position: relative;
    left: 50%;
      transform: translateX(-50%);
      justify-content: center;
 align-items: center;

           }`
//Exit Icon Smaller Code

export const ExitIcon = styled(MdCancel)` 
 cursor: pointer;
 // background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  border-radius: 100%;

 // transform: translate(10px, 10px) rotate()(180deg);
  transition: transform 3300ms ease-in-out;
  font-size: 32px; /* Adjust the font size as per your requirements */
//z-index: -10000000;
transform-origin: center;
display: flex;
  justify-content: center;
  align-items: center;
    //display: flex;
    &:hover {
   

}
`;

export const PExit = styled(motion.div)`
  position: absolute;
  right: 1%;
  top: 2%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure it's above other content but within the UtilitiesContainer's context */
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};

  &:hover {
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    /* Add any hover effects you need */
  }

  &:hover ${ExitIcon} {
    /* Adjust hover effects for ExitIcon if necessary */
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    filter: drop-shadow(0 0 5px white);
  }

  @media screen and (max-width: 768px) {
    /* Adjust positioning for mobile or as needed */
    position: fixed;
  }
`;


export const ExitIconWrap = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center; /* Set the transform origin to the center */

cursor: pointer;
&:focus {
                    outline: 0 !important;
                    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none !important;
                    -moz-user-select: none !important;
                    -ms-user-select: none !important;
                    user-select: none !important; 
                    box-shadow: none !important;
                    border: none !important;
  outline: none;
                }
  `



//Close Icon


// COLORS
const softOrange = '#F4A259';
const tomatoRed = '#F25C66';
const mediumBlue = '#1E272D';

export const Body = styled.body`
  background-color: ${mediumBlue};
`;

export const CloseContainer = styled.div`

  width: 48px;
  height: 48px;
  position: absolute;
    top: 5.5%;
    z-index: 99999999999;
    left: 0.5rem;
    background: transparent;
    transform: scale(0.85);
    @media screen and (max-width: 1024px) {
      //position: fixed;
          //transform: scale(0.7);
         // transform: scale(0.75);
          //left: 2rem;
          top: 6.5%;
        //  top: 0.75rem;
 
    }
    @media screen and (max-width: 768px) {
      position: fixed;
          //transform: scale(0.7);
          transform: scale(0.75);
          left: 0.25rem;
          top: 0.6rem;
 
    }


`;

export const LeftRight = styled.div`
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;

  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;

  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
`;

export const RightLeft = styled.div`
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;

  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
`;

export const Label = styled.label`
 color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  font-family: Helvetica, Arial, sans-serif;
   
 font-style: normal;
    font-weight: 700;
    line-height: 20px;
  font-size: 8px !important;
display: block !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease-in;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
`;

export const Close = styled.div`
  margin: 60px 0 0 5px;
  position: absolute;
`;

export const CloseContainerHover = styled(CloseContainer)`
  &:hover ${LeftRight} {
    transform: rotate(-45deg);
    background-color: ${tomatoRed};
  }

  &:hover ${RightLeft} {
    transform: rotate(45deg);
    background-color: ${tomatoRed};
  }

  &:hover ${Label} {
    opacity: 1;
  }
`;


export const CloseIcon = styled(FaTimes)`
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;
    &:hover  {
    transition: all 0.2s ease-in-out;

    filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 0px white)' : 'drop-shadow(0 0 0px black)')};
    //backdrop-filter: blur(5px);
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};


  
  }
  @media screen and (max-width: 870px) {
          //transform: scale(0.7);
          zoom: 0.65;
          margin-bottom: 20px;
 
    }
  
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;

    right: 1.5rem;
    background: transparent;
    font-size: 3rem;
    cursor: pointer;
    outline: none;
`;


export const UtilitiesMenu = styled.div`

    position: relative;
    top: 5%;
    //left: 7.5%;
    transform-origin: left;
  
    @media screen and (max-width: 1100px) {
   // transform: scale(0.85); /* Scale down by 15% */
      zoom: 0.85;
  }

  @media screen and (max-width: 600px) {
   // transform: scale(0.7); /* Scale down by 15% */

   zoom: 0.65;
  }
  @media screen and (max-width: 500px) {
    transform: scale(0.345); /* Scale down by 70% */
   // transform-origin: top; /* Ensure the top of the element stays fixed */
    top: 12.5%;
  }
   /*
    @media screen and (max-width: 1100px) {
          transform: scale(0.8);
          //left: 0%;
       
    }
    @media screen and (max-width: 870px) {
      //position: relative;
      left: 50%;
          transform: scale(0.6) translateX(-50%);
         // margin-left: 0%;
        
    }
    @media screen and (max-width: 768px) {
      //position: relative;
          //transform: scale(0.35);
          transform: scale(0.35) translateX(-10%);
          top: 15%;
         // left: -30%;
        
    }
    */


`;

export const UtilitiesLink = styled(LinkS)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #660099;
        transition: 0.2 ease-in-out;
    }
`;
export const LineBreak = styled.hr`
`

export const UtilitiesBtnWrap = styled(LinkR)`
    display: flex;

    text-decoration: none;
    white-space: nowrap;
    transform: scale(0.8);

    filter: drop-shadow(0 0 0px white);

    &:hover {
       // zoom: 1.02;
       transform: scale(1.02);
       transition: 0.2 ease-in-out;
       filter: drop-shadow(0 10px 20px white);
    }

  
`;

export const UtilitiesBtnImg = styled.img`
    width: 150px;
    height: 150px;
    position: absolute;
    gap: 100px;
    cursor: pointer;
   // margin-top: 50px;

`



export const Dao = styled.img`
  margin-right: 25px;
     height: 3.5rem;
    width: 3.5rem;
    top: 7px;
    transition: all 0.2s ease-in-out;
    padding: 12px 12px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {
  //top: 45px;
           }



`


export const Privacy = styled(RiGitRepositoryPrivateFill)`
    transition: all 0.2s ease-in-out;
      font-size: 1rem;
    color: #fff;
    top: 3px;
    white-space: nowrap;
    position: relative;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
 
    @media screen and (max-width: 870px) {
      
        font-size: 1rem;
           }

`

export const Terms = styled(HiDocumentText)`
    transition: all 0.2s ease-in-out;
      font-size: 1rem;
    color: #fff;
    white-space: nowrap;
    top: 3px;
    position: relative;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {

  font-size: 1rem;
           }

`


export const Verified = styled(MdVerified)`
    font-size: 24px; 
    color: #68ceff;
    

`
/*
export const Whitelist = styled.img`
  margin-right: 25px;
    height: 3.5rem;
    width: 3.5rem;
    top: 7px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {
  //top: 45px;
           }
  
`
*/
export const UtilitiesImgWrap = styled.div`


`
export const UtilitiesRoute = styled(LinkR)`
    border-radius: 50px;
    width: 100%;
    height: 100%;
    font-size: 3.5rem;
    font-weight: 900;
    word-spacing: 5px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
    padding: ${({big}) => (big ? '10px 40px' : '10px 15px')} ;
    color: #000;
    letter-spacing: -2px;
    cursor: pointer;
    transform: matrix(1.2, 0, 0, 1, 0, 0);
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    
    margin-top: 5px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    //background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};


    &:hover  {
    transition: all 0.2s ease-in-out;
    //backdrop-filter: blur(5px);
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};



    
  
  }
  @media screen and (max-width: 500px) {
    margin-top: 25px;
    }
`;

export const UtilitiesRoute2 = styled.a`
    border-radius: 50px;
    width: 100%;
    height: 100%;
    font-size: 3.5rem;
    font-weight: 900;
    word-spacing: 5px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
    padding: ${({big}) => (big ? '10px 40px' : '10px 15px')} ;
    color: #000;
    letter-spacing: -2px;
    cursor: pointer;
    transform: matrix(1.2, 0, 0, 1, 0, 0);
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    
    margin-top: 5px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    //background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};


    &:hover  {
    transition: all 0.2s ease-in-out;
    //backdrop-filter: blur(5px);
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};



    
  
  }
  @media screen and (max-width: 500px) {
    margin-top: 25px;
    }
`;


export const ProgressBar = styled(motion.div)`
  width: 100%;
  height: 100%;
`

export const Header = styled(motion.h1)`
  position: relative;

    left: 4.5%;
    transition: transform 330ms ease-in-out;
  //  top: 4.5%;

///transform: translateY(10px) matrix(1.2, 0, 0, 1, 0, 0);
white-space: nowrap;
  width: 300px;
  text-align: left;

  grid-area: 'col1';
  font-size: 2.25rem;
  //filter: drop-shadow(-0.5px .75px 0.2px #000000) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05));
  filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(-0.5px .75px 0.2px #ffffff) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05))' : 'drop-shadow(-0.5px .75px 0.2px #000000) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05))')} !important;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#1e252b')} !important;
    
  @media screen and (max-width: 768px) {
       
    top: 5%;
        
         }
`

export const SubHeader = styled(motion.h2)`
  position: relative;

left: 5%;
//transform: translateX(-50%);
transition: transform 330ms ease-in-out;
//top: 15%;

///transform: translateY(10px) matrix(1.2, 0, 0, 1, 0, 0);
white-space: nowrap;
width: 300px;
text-align: left;

grid-area: 'col1';
font-weight: 500;
font-size: 0.95rem;
//filter: drop-shadow(-0.5px .75px 0.2px #000000) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05));
filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(-0.5px .75px 0.2px #ffffff) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05))' : 'drop-shadow(-0.5px .75px 0.2px #000000) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05))')} !important;
color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#1e252b')} !important;

@media screen and (max-width: 768px) {
  left: 4.5%;
transform: translateX(0);
top: 15%;
    
     }

`

export const SubHeaderCaption = styled(motion.div)`

  position: relative;
  left: 5%;
  transform:  matrix(1, 0, 0, 1, 0, 0);
  //top: 20%;
  width: 615px;
  text-align: left;
  align-items: center;
  justify-content: center;
  grid-area: 'col1';
  font-size: 0.9rem;
  //filter: drop-shadow(-0.5px .75px 0.2px #000000) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05));
  filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(-0.5px .75px 0.2px #ffffff) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05))' : 'drop-shadow(-0.5px .75px 0.2px #000000) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05))')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333e48')} ;
  //color: #fff;
  font-weight: 300;
  
  @media screen and (max-width: 768px) {
       
        width: 350px;
        left: 4.5%;
        top: 23%;
        transform:   matrix(1, 0, 0, 1, 0, 0);

        //white-space: nowrap;
        text-align: left;
        align-items: left;
        justify-content: left;
        font-size: 0.85rem;
         }
`


export const TopContainer = styled(LinkR)`
  //width: 100%;

  margin-left: 50%;
  transform: translateX(-50%);
  padding: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -20%;
  top: 5%;

  @media screen and (max-width: 768px) {
    left: -40%;
  top: 12.5%;
    }

`;



export const TopLogo = styled(motion.img)`
        position: absolute;

    left: 4.5%;
    transition: transform 330ms ease-in-out;
    top: 3%;

    width: 120px;
    height: 20px;

  font-size: 30px;
  font-weight: 700;
  line-height: 30px;

  color: black;
  letter-spacing: -2px;

  @media screen and (max-width: 768px) {
     // display: none;
    }
`;

export const TopIcon = styled(motion.img)`

  height: 55px;
  line-height: 55px;

  @media screen and (max-width: 768px) {
    transform: scale(0.85) ;
    }
`;



export const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

 @media screen and (max-width: 600px) {
  //margin-top: -0px;
  transform: translateY(-15px);

 }
//position: absolute;
`
export const BottomLeftContainer = styled.div`
/*
  position: relative;
  left: 35%;
  pointer-events: all;
  cursor: pointer;
  bottom: 0%;
  width: 100%;
  padding: 0;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  */

`;

export const FullWidthText = styled.p`
  white-space: nowrap;
  position: relative;
  top: 10px;
  flex: 1 1 0%;
  font-size: 12.5px;
  line-height: 1.5em;
  color: black;
  left: 2.5%;
  margin-top: -0.25%;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  & b {
    font-weight: bold;
  }
  @media screen and (max-width: 1024px) {
       
         // right: 0%;
    }
     @media screen and (max-width: 600px) {
      transform: scale(0.7);
      left: -10%;

    }
`;

export const CenteredTitle = styled.p`
  font-family: 'Antonio', sans-serif;
  //flex: 1 1 0%;
  position: relative;

  font-size: 16px;
  font-weight: 700;
  line-height: 1em;
  text-align: center;
  color: black;
  letter-spacing: -0.5px;
  white-space: nowrap;
  left: 13%;
  //transform: translate(-50%, -2.5px);
  bottom: 0%;

  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  @media screen and (max-width: 1024px) {
         // left: 0%;

   
    }
    @media screen and (max-width: 600px) {
  
      left: -55%;
      transform: translate(5%, 0%) scale(0.7);
 }
`;

export const BottomRightContainer = styled.div`
  position: relative;
  
  //left: 52.5%;
  right: 2.5%;
  pointer-events: all;
  cursor: pointer;
  margin-top: -2.5%;
  width: 100%;

  display: inline-flex;
  flex-direction: column;
  white-space: nowrap;

  justify-content: center;
  @media screen and (max-width: 1024px) {
/* 
    display: inline-flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    text-align: left;
          left: 90%;
          transform: translateX(0%);
          margin-top: 0%;
          width: 50px;
          height: 20px; */
    }
    @media screen and (max-width: 600px) {
      position: fixed;
     // bottom: 1%;
      margin-top: 2%;
      transform: scale(0.8) translateX(-240px);

 }
`;


export const BottomLine = styled.hr`
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
    position: relative;
    width: 800px;
    top: -17%;
    
`;

export const BottomLink = styled.a`
 // flex: 1 1 0%;
  font-size: 12px;
  line-height: 1em;
  text-align: right;
  color: black;
  text-decoration: none;
  width: 100%;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  &:hover  {
    transition: all 0.2s ease-in-out;
    //backdrop-filter: blur(5px);
   // color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};


    ${Privacy} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
      color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    
      filter: drop-shadow(2px 2px 2px white);
    }
    ${Terms}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      filter: drop-shadow(2px 2px 2px white);
    }

  
  }
  @media screen and (max-width: 870px) {
  /* top: 15px;
  font-size: 10px; */

           }
`;


export const LeftSpacer = styled.div`
   left: 50%;
  transform: translateX(-50%);
  position: absolute;
  
`;


export const TopLine = styled.hr`
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
    position: relative;
    top: 50.5%;
  
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
`;

export const Image = styled.img`
  width: 100px; /* Adjust the image width as needed */
  height: 100px; /* Adjust the image height as needed */
  border-radius: 50%; /* Make the image circular */
  margin-bottom: 10px;
`;

export const RightContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  max-height: 300px; /* Adjust the maximum height of the content area */
  padding-right: 10px; /* Add some padding to avoid overlap with the scrollbar */
`;



export const TimelineSection = styled.div`
  margin-bottom: 24px;
`;

export const TimelineTitle = styled.h2`
  margin-bottom: 1rem;
  font-size: 25px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
`;

export const TimelineList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const TimelineItem = styled.li`
  line-height: 0;
  padding-left: 40px;
  padding-bottom: 24px;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    left: 5px;
    transform: translateX(-50%);
    width: 1px;
    background-color: #f3f3f3;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #979797;
  }

  &:nth-child(2n)::after {
    background-color: #0067f6;
  }

  &:not(:first-child)::after {
    top: 5px;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const Badge = styled.span`
  padding: 6px 10px;
  font-size: 9px;
  font-weight: 500;
  line-height: 1;
  border-radius: 2px;
  margin-bottom: 8px;
`;

export const TimelineItemTitle = styled.h6`
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 2px;
`;

export const TimelineItemSubtitle = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #a7a7a7;
  margin-bottom: 3px;
`;

export const TimelineItemContent = styled.p`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 0;
`;

/// Utilities Timeline Guide Elements
export const UtilitiesTopFilter = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  pointer-events: none; // Allow click-through to the underlying content
  z-index: 10; // Place the overlay above the content

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px); // Adjust the blur amount as needed
    opacity: 0; // Make the overlay invisible
  }
`;
export const UtilitiesGuideContainer = styled.div`
    
//overflow: hidden;
//margin-top: 30%;
//background: linear-gradient(180deg, rgba(255, 255, 255, 0.486316) 0%, rgba(11, 37, 11, 0.4) 35.94%, rgba(23, 66, 23, 0.4) 52.6%, rgba(14, 80, 14, 0.5) 72.4%, rgba(8, 100, 9, 0.2) 100%);
//width: 50vw;
height: 100vh;
max-width: 675px;
left: 0;
position: relative;
z-index: 9;


@media screen and (max-width: 768px) {
    //overflow-x: hidden;
    margin-top: 35%;
}
`;



export const PrepImgWrap = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: row;
    width:  300px;
    transform: translate(20%, 0%) scale(0.8);
    height: 225px;
    border-radius: 60px;
    grid-area: 'col3';

    //background: #000;
    background: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#666')};
    box-shadow: ${({ isDarkMode }) => (isDarkMode ? '-2.5px -5px 2px 0px #000, 2.5px 5px 2px 0px #000' : '-2.5px -5px 2px 0px #FFF, 2.5px 5px 2px 0px #FFF')};
 
    top: 25px;
   // margin-top: -25px;
   @media screen and (max-width: 768px) {
       // right: 20px;
          width: 300px;
          height: 250px;
        top: 0px;
        zoom: 1;
        
         }

`;
export const PrepImg = styled.img`
    width: 300px;
    height: 325px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 0 0px 0;
    position: absolute;
    bottom: 10px;
    
    filter: drop-shadow(-0.5px .75px 0.2px #000000) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05));
    

`;

export const UtilitiesGuideSymbolHeader= styled(motion.h3)`
position: relative;
left: 50%;
transform:  translateX(-50%) matrix(1.2, 0, 0, 1, 0, 0);

  width: 500px;
  text-align: left;
  align-items: center;
  top: 0px;
  justify-content: center;
  grid-area: 'col1';
  font-size: 2.5rem;
  //filter: drop-shadow(-0.5px .75px 0.2px #000000) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05));
  filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(-0.5px .75px 0.2px #ffffff) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05))' : 'drop-shadow(-0.5px .75px 0.2px #000000) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05))')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333e48')} !important;
  //color: #fff;
  
  @media screen and (max-width: 768px) {
       top: 25px;
    transform:  translateX(-82.5%) matrix(1.2, 0, 0, 1, 0, 0) scale(0.6);

        
         }

`;


export const UtilitiesGuideTextWrap = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: left; // Centers items horizontally in the container
  justify-content: left; // Centers items vertically in the container
  font-family: 'Inter';
  text-align: center;
  width: 250px;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  position: absolute;
  right: 2.5%;
  top: 55%;
  transform: translate(-15%, -20%);
  gap: 0px; // Adjust the space between header and subtitle

  @media screen and (max-width: 768px) {
    transform: translate(-105%, 50%);
left: 50%;
top: 30%;
right: unset;
  }
`;

export const UtilitiesGuideHeader = styled(motion.h2)`
  width: 100%; // Take the full width of its parent
  text-align: left;
  left: 0%;
  position: relative;
  
  font-size: 1rem !important;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333e48')} !important;
  filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(-0.5px .75px 0.2px #ffffff) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05))' : 'drop-shadow(-0.5px .75px 0.2px #000000) drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.05))')};
  
  @media screen and (max-width: 600px) {
    font-size: 1.75rem;
    text-align: left;
  }
`;

export const UtilitiesGuideSubtitle = styled(motion.p)`
  width: 100%; // Take the full width of its parent
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  text-align: left;
  transform: translateY(-20px);
  @media screen and (max-width: 600px) {
    text-align: left;
  }
`;



export const UtilitiesGuideImg = styled.img`

    
    @media screen and (max-width: 600px) {
   
          height: 0px;
          width: 0px;
        
         }
    

`;

export const UtilitiesGuideImgWrap = styled.div`
   width: 245px;
   height: 45px;
   margin-left: 27.5%;
   transform: translateX(-50%);

`;


export const UtilitiesBottomBtnWrap = styled(motion.div)`
    display: flex;
    flex-direction: row;
    position: relative;
    left: 50%;
    transform: translateX(-45%) scale(0.9);
    top: 5%;
    
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 75px;

    filter: drop-shadow(0 0 0px white);

    &:hover {
       // zoom: 1.02;
 
      // filter: drop-shadow(0 10px 20px white);
    }

    @media screen and (max-width: 600px) {
        //position: absolute;
        flex-direction: column;
        left: 50%;
        transform: translate(-90%, 35px);
        width: 300px;
    }
 

  
`;
///Join Dao Button
export const DaoIcon = styled(BiNetworkChart)`
  //background-color: #fff;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   position: relative;
  //border-radius: 100%;
  padding: 8px; /* Adjust the padding as per your requirements */
  margin-top: 5px;
  left: 15px;
  transform: translate(10px, 12.5px) matrix(1, 0, 0, 1.2, 0, 0);
  font-size: 40px; /* Adjust the font size as per your requirements */

    //display: flex;
    &:hover {
   // background-color: #000;



}
@media screen and (max-width: 1024px) {
      margin-left: 20%;
       padding: 6px;
       border-radius: 0%;
     background-color: none;
     transform: translate(-50%, 12.5px) matrix(1, 0, 0, 1.2, 0, 0);
     background: none;
    }
`;
///Get Whitelisted Button
export const WhitelistIcon = styled(FaClipboardList)`
  //background-color: #fff;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   
  //border-radius: 100%;
  padding: 8px; /* Adjust the padding as per your requirements */
  margin-top: 5px;
  margin-left: 20px;
  transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
  font-size: 40px; /* Adjust the font size as per your requirements */

    //display: flex;
    &:hover {
   // background-color: #000;



}
@media screen and (max-width: 1024px) {
      margin-left: 20%;
       padding: 6px;
       border-radius: 0%;
     background-color: none;
     transform: translate(-50%, 12.5px) matrix(1, 0, 0, 1.2, 0, 0);
     background: none;
    }
`;

export const TextSpan = styled.span`
    color: #fff;
    padding: 0px 0px 0px 20px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    @media screen and (max-width: 600px) {

    }
`



export const ButtonText = styled.div`
   position: relative; /* Added position: relative */
   transition: transform 0.2s ease-in-out;
   // transform: ; /* Adjust the value as per your requirements */
    padding: 5px 0px 0px 0px;
    //transform: matrix(0,0,0,0,0,0);

    transform: translateY(-12px) matrix(1, 0, 0, .8, 0, 0);
    @media screen and (max-width: 600px) {
    
    }
`

export const Whitelist = styled(motion.div)`
 //width: 100%;

 display: flex;

  text-decoration: none;
  white-space: nowrap;

margin-left: 20px;
  filter: drop-shadow(0 0 0px white);
 cursor: pointer;

 color: ${({ isDarkMode }) => (isDarkMode ? `#000` : `#fff`)};
 &:hover {
  ${WhitelistIcon}{
      /* styles for the container when button is hovered */
     // background-color: #000;
     filter: drop-shadow(0 0 3px white);
    // background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
   //  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
   //   filter: drop-shadow(2px 2px 2px white);
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;
     filter: drop-shadow(0 0 3px white);
    // background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
   //  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
   //   filter: drop-shadow(2px 2px 2px white);
    }

        
    }
  @media screen and (max-width: 600px) {
    margin-top: 25px;
    margin-left: 0px;
      }

      


`;

export const DaoContainer = styled(motion.div)`
 //width: 100%;

 display: flex;
  
  text-decoration: none;
  white-space: nowrap;


  filter: drop-shadow(0 0 0px white);
 cursor: pointer;

 color: ${({ isDarkMode }) => (isDarkMode ? `#000` : `#fff`)};
 &:hover {
  ${DaoIcon}{
      /* styles for the container when button is hovered */
     // background-color: #000;
     filter: drop-shadow(0 0 3px white);
    // background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
   //  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
   //   filter: drop-shadow(2px 2px 2px white);
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;
     filter: drop-shadow(0 0 3px white);
    // background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
   //  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
   //   filter: drop-shadow(2px 2px 2px white);
    }

    }
  @media screen and (max-width: 1200px) {

      }
      


`;


export const ButtonContainer =  styled(motion.a)`


    font-size: 1.5rem;
    background-color: #000;
    color: #000;
    width: 300px;
    height: 50px;
    border-radius: 50px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 1.35rem;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */




  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  background-color: ${({ isDarkMode }) => (isDarkMode ? `#fff` : `#000`)};

    &:hover {
        color: #660099;
        transition: 0.2 ease-in-out;
    }

      
`;




///Blurred Background for elements

export const IframeContainer = styled.iframe`
  position: fixed;
  top: 5rem;
  width: 100vw;
  height: calc(100vh - 6rem);
  border: none;
  -webkit-mask-image: linear-gradient(
    transparent 1rem,
    #fff 5%,
    #fff 70%,
    transparent 90%
  );
  mask-image: linear-gradient(
    transparent 1rem,
    #fff 5%,
    #fff 70%,
    transparent 90%
  );
`;
export const MainContentContainer = styled.body`
  position: absolute;
  overflow-x: hidden;
  margin-top: 2rem;
  color: aliceblue;
  width: 80vw;
  left: 5vw;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover::-webkit-scrollbar {
    display: auto;
  }

  scrollbar-width: none;
`;

export const Paragraph = styled.p`
  padding: 2rem;
  font-size: 2rem;
`;




//Social Media Handles


export const SocialMediaWrap = styled.div`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    width: 100%;

   top: 4.5%;
    //adisplay: flex;

    //width: 300px;
    @media screen and (max-width: 600px) {
       
      // margin-top: 50px;
      top: 3.5%;
        transform: translateX(-70%) scale(0.85);
    
       }

 
`;

export const RoundedSocialButtons = styled(motion.div)`
  text-align: center;
  white-space: nowrap;
  

`;

export const SocialButton = styled.a`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  //margin-right: 0.25rem;
  margin-bottom: 2rem;
  transform: scale(0.85) rotate(0deg); /* Initial state */

  &:hover,
  &:focus {
    transform: scale(0.85) rotate(360deg); /* Rotate on hover */
  }
  @media screen and (max-width: 500px) {
    margin-right: 0.35rem;
    margin-left: 0.35rem;
    
       }
`;

// Define other styled components here...


export const FacebookButton = styled(SocialButton)`
  background: #3b5998;
  &:hover,
  &:focus {
    color: #3b5998;
    background: #fefefe;
    border-color: #3b5998;
  }
`;

export const TwitterButton = styled(SocialButton)`
  background: #55acee;
  &:hover,
  &:focus {
    color: #55acee;
    background: #fefefe;
    border-color: #55acee;
  }
`;

export const LinkedinButton = styled(SocialButton)`
  background: #007bb5;
  &:hover,
  &:focus {
    color: #007bb5;
    background: #fefefe;
    border-color: #007bb5;
  }
`;

export const RedditButton = styled(SocialButton)`
  background: #ff5700; /* Reddit's color */
  &:hover,
  &:focus {
    color: #ff5700;
    background: #fefefe;
    border-color: #ff5700;
  }
`;

export const YoutubeButton = styled(SocialButton)`
  background: #bb0000; /* YouTube's color */
  &:hover,
  &:focus {
    color: #bb0000;
    background: #fefefe;
    border-color: #bb0000;
  }
`;


export const DiscordButton = styled(SocialButton)`
  background: #5865f2; /* Discord's color */
  &:hover,
  &:focus {
    color: #5865f2;
    background: #fefefe;
    border-color: #5865f2;
  }
`;

export const XButton = styled(SocialButton)`
  background: #000; /* X's (formerly Twitter) color */
  &:hover,
  &:focus {
    color: #000;
    background: #fefefe;
    border-color: #000;
  }
`;
export const InstagramButton = styled(SocialButton)`
  background: #125688;
  &:hover,
  &:focus {
    color: #125688;
    background: #fefefe;
    border-color: #125688;
  }
`;



export const TikTokButton = styled(SocialButton)`
  background: #000; /* TikTok's color */
  &:hover,
  &:focus {
    color: #000;
    background: #fefefe;
    border-color: #000;
  }
`;


export const BottomSection = styled.div`

height: 75px;
position: absolute;
width: 100%;

`