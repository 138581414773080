import React, { useEffect, useRef, useState } from 'react';

//import styled, {createGlobalStyle, css} from 'styled-components';
import axios from 'axios';
import emailjs from 'emailjs-com';
import bg2 from '../../../images/w3oDayBg.svg';
import bg from '../../../images/w3oNightBg.svg';

import {
  IconCheckmark,
  MessageContainer,
  SuccessMessage,
  SuccessMessageContent, SuccessMessageTitle
} from '../../../components/Global/GlobalElements';
import {
  BlurredOverlay,
  ButtonsContainer,
  DaoFormButton,
  DaoFormContainer,
  DaoFormError,
  DaoFormFieldSet,
  DaoFormForm,
  DaoFormHeader,
  DaoFormLabel,
  DaoFormLegend,
  DaoFormTextArea,
  DaoFormTextInput,
  ExitIcon,
  ExitIconWrap,
  PExit,
  ProgressBall,
  ProgressContainer,

  initialState
} from "./DaoFormElements";
import { blockchainData, collaborationData, communicationData, deadlinesData, expertiseData, feedbackData, hoursData } from './Data';


const Step1 = ({ formData, setFormData, validationErrors, isDarkMode }) => {
  // Handle focus for Twitter and Discord inputs
  const handleTwitterFocus = () => {
    if (!formData.twitter.startsWith('www.twitter.com/')) {
      setFormData({ ...formData, twitter: 'www.twitter.com/' });
    }
  };

  const handleDiscordFocus = () => {
    if (!formData.discord.startsWith('@')) {
      setFormData({ ...formData, discord: '@' });
    }
  };

  return (
    <div>
      <DaoFormLabel isDarkMode={isDarkMode} htmlFor="name">Name</DaoFormLabel>
      <DaoFormTextInput isDarkMode={isDarkMode} type="text" id="name" name="name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
      {validationErrors.name && <DaoFormError>{validationErrors.name}</DaoFormError>}

      <DaoFormLabel isDarkMode={isDarkMode} htmlFor="twitter">Twitter</DaoFormLabel>
      <DaoFormTextInput maxLength={50} isDarkMode={isDarkMode} type="text" id="twitter" name="twitter" value={formData.twitter} placeholder='www.twitter.com/' onFocus={handleTwitterFocus} onChange={(e) => setFormData({ ...formData, twitter: e.target.value })} />
      {validationErrors.twitter && <DaoFormError>{validationErrors.twitter}</DaoFormError>}

      <DaoFormLabel isDarkMode={isDarkMode} htmlFor="discord">Discord (If Applicable)</DaoFormLabel>
      <DaoFormTextInput maxLength={50} isDarkMode={isDarkMode} type="text" id="discord" name="discord" value={formData.discord} placeholder='@discordName' onFocus={handleDiscordFocus} onChange={(e) => setFormData({ ...formData, discord: e.target.value })} />
      {validationErrors.discord && <DaoFormError>{validationErrors.discord}</DaoFormError>}

      <DaoFormLabel isDarkMode={isDarkMode} htmlFor="email">Email</DaoFormLabel>
      <DaoFormTextInput maxLength={50} isDarkMode={isDarkMode} type="text" id="email" name="email" value={formData.email} placeholder='example@email.com' onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
      {validationErrors.email && <DaoFormError>{validationErrors.email}</DaoFormError>}
    </div>
  );
};


const Step2 = ({ formData, setFormData, validationErrors, isDarkMode }) => {
  const handleExpertiseChange = (e) => {
    const selectedValue = e.target.value;
    const newExpertise = formData.expertise.includes(selectedValue)
      ? formData.expertise.filter(item => item !== selectedValue)
      : [...formData.expertise, selectedValue];
    setFormData({ ...formData, expertise: newExpertise });
  };

  return (
    <DaoFormFieldSet isDarkMode={isDarkMode}>
      <DaoFormLegend>Under which category do you identify your professional expertise? (Choose all that apply)</DaoFormLegend>
      {expertiseData.map(({ data }, index) => (
        <DaoFormLabel  style={{
            display: "initial",
            }}  key={index}>
          <input
            type="checkbox"
            name="expertise"
            value={data}
            id={`expertise-${data}`}
            checked={formData.expertise.includes(data)}
            onChange={handleExpertiseChange}
          />
          {data}
          {data === 'Other (Please Specify)' && formData.expertise.includes(data) && (
            <input
              type="text"
              name="otherExpertise"
              onChange={(e) => setFormData({ ...formData, otherExpertise: e.target.value })}
              placeholder="Specify your expertise"
              value={formData.otherExpertise}
            />
          )}
        </DaoFormLabel>
      ))}
      {validationErrors.expertise && <DaoFormError>{validationErrors.expertise}</DaoFormError>}
    </DaoFormFieldSet>
  );
};


const Step3 = ({ formData, setFormData, validationErrors, isDarkMode }) => {
  return (
    <>
      <DaoFormLabel isDarkMode={isDarkMode} htmlFor="motivation">What motivated you to seek membership in our DAO?</DaoFormLabel>
      <DaoFormTextInput maxLength={500} isDarkMode={isDarkMode} type="text" id="motivation" name="motivation" value={formData.motivation} onChange={(e) => setFormData({ ...formData, motivation: e.target.value })} />
      {validationErrors.motivation && <DaoFormError>{validationErrors.motivation}</DaoFormError>}

      <DaoFormLabel isDarkMode={isDarkMode} htmlFor="experience">Briefly describe the most significant projects or experiences in your professional journey.</DaoFormLabel>
      <DaoFormTextInput maxLength={500} isDarkMode={isDarkMode} type="text" id="experience" name="experience" value={formData.experience} onChange={(e) => setFormData({ ...formData, experience: e.target.value })} />
      {validationErrors.experience && <DaoFormError>{validationErrors.experience}</DaoFormError>}


      <DaoFormFieldSet isDarkMode={isDarkMode}>
      <DaoFormLegend>Which method of communication do you mostly use?</DaoFormLegend>
      {communicationData.map(({ data }) => (
        <DaoFormLabel style={{
            display: "initial",
            }} key={data}>
          <input
            type="radio"
            name="communication"
            value={data}
            id={`communication-${data}`}
            checked={formData.communication === data}
            onChange={(e) => setFormData({ ...formData, communication: e.target.value })}
          />
          {data}
          {data === 'Other (Please Specify)' && formData.communication === data && (
            <input
              type="text"
              name="otherCommunication"
              onChange={(e) => setFormData({ ...formData, otherCommunication: e.target.value })}
              placeholder="Specify your preferred communication method"
              value={formData.otherCommunication}
            />
          )}
        </DaoFormLabel>
      ))}
      {validationErrors.communication && <DaoFormError>{validationErrors.communication}</DaoFormError>}
    </DaoFormFieldSet>
    </>
  );
};







const Step4 = ({ formData, setFormData, validationErrors, isDarkMode }) => {
  return (
    <DaoFormFieldSet isDarkMode={isDarkMode}>
      <DaoFormLegend>How do you typically handle tight deadlines and unexpected challenges?</DaoFormLegend>
      {deadlinesData.map(({ data }) => (
        <DaoFormLabel key={data}>
          <input
            type="radio"
            name="deadlines"
            value={data}
            id={`deadlines-${data}`}
            checked={formData.deadlines === data}
            onChange={(e) => setFormData({ ...formData, deadlines: e.target.value })}
          />
          {data}
        </DaoFormLabel>
      ))}
      {validationErrors.deadlines && <DaoFormError>{validationErrors.deadlines}</DaoFormError>}
    </DaoFormFieldSet>
  );
};


const Step5 = ({ formData, setFormData, validationErrors, isDarkMode }) => {
  return (
    <DaoFormFieldSet isDarkMode={isDarkMode}>
      <DaoFormLegend>How would you describe your experience working collaboratively with other professionals or teams on shared projects?</DaoFormLegend>
      {collaborationData.map(({ data }) => (
        <DaoFormLabel key={data}>
          <input
            type="radio"
            name="collaboration"
            value={data}
            id={`collaboration-${data}`}
            checked={formData.collaboration === data}
            onChange={(e) => setFormData({ ...formData, collaboration: e.target.value })}
          />
          {data}
        </DaoFormLabel>
      ))}
      {validationErrors.collaboration && <DaoFormError>{validationErrors.collaboration}</DaoFormError>}
    </DaoFormFieldSet>
  );
};

const Step6 = ({ formData, setFormData, validationErrors, isDarkMode }) => {
  return (
    <DaoFormFieldSet isDarkMode={isDarkMode}>
      <DaoFormLegend>How would you rate your experience or understanding of blockchain technologies or NFTs?</DaoFormLegend>
      {blockchainData.map(({ data }) => (
        <DaoFormLabel key={data}>
          <input
            type="radio"
            name="blockchain"
            value={data}
            id={`blockchain-${data}`}
            checked={formData.blockchain === data}
            onChange={(e) => setFormData({ ...formData, blockchain: e.target.value })}
          />
          {data}
        </DaoFormLabel>
      ))}
      {validationErrors.blockchain && <DaoFormError>{validationErrors.blockchain}</DaoFormError>}
    </DaoFormFieldSet>
  );
};


const Step7 = ({ formData, setFormData, validationErrors, isDarkMode}) => {
  return (
    <DaoFormFieldSet isDarkMode={isDarkMode}>
      <DaoFormLegend>How do you typically react to feedback, especially if it's critical?</DaoFormLegend>
      {feedbackData.map(({ data }) => (
        <DaoFormLabel key={data}>
          <input
            type="radio"
            name="feedback"
            value={data}
            id={`feedback-${data}`}
            checked={formData.feedback === data}
            onChange={(e) => setFormData({ ...formData, feedback: e.target.value })}
          />
          {data}
        </DaoFormLabel>
      ))}
      {validationErrors.feedback && <DaoFormError>{validationErrors.feedback}</DaoFormError>}
    </DaoFormFieldSet>
  );
};



const Step8 = ({ formData, setFormData, validationErrors, isDarkMode }) => {
  return (
    <>
        <DaoFormFieldSet isDarkMode={isDarkMode}>
      <DaoFormLegend>On average, how many hours per week can you dedicate to projects within the DAO?</DaoFormLegend>
      {hoursData.map(({ data }) => (
        <DaoFormLabel style={{
            display: "initial",
            }} key={data}>
          <input
            type="radio"
            name="hours"
            value={data}
            id={`hours-${data}`}
            checked={formData.hours === data}
            onChange={(e) => setFormData({ ...formData, hours: e.target.value })}
          />
          {data}
        </DaoFormLabel>
      ))}
      {validationErrors.hours && <DaoFormError>{validationErrors.hours}</DaoFormError>}
    </DaoFormFieldSet>
      <DaoFormLabel isDarkMode={isDarkMode} htmlFor="portfolio">Please provide a link to your portfolio or any showcase of your past work.</DaoFormLabel>
      <DaoFormTextInput maxLength={500} isDarkMode={isDarkMode} type="text" id="portfolio" name="portfolio" value={formData.portfolio} onChange={(e) => setFormData({ ...formData, portfolio: e.target.value })} />
      {validationErrors.portfolio && <DaoFormError>{validationErrors.portfolio}</DaoFormError>}

      <DaoFormLabel isDarkMode={isDarkMode} htmlFor="message">Is there anything else you'd like us to know or consider about your application?</DaoFormLabel>
      <DaoFormTextArea maxLength={1200} isDarkMode={isDarkMode} id="message" name="message" value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
      {validationErrors.message && <DaoFormError>{validationErrors.message}</DaoFormError>}
    </>
  );
};





function DaoForm(props,) {
  const {daoPopup, isFooterVisible, setIsUtilitiesOpen, isDarkMode} = props;

  // State management
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 8;  // Adjust as per the total number of steps in your form
  const [formData, setFormData] = useState({ ...initialState });
  const [validationErrors, setValidationErrors] = useState({});

  const formRef = useRef(null);

  // Validation function (simplified)





  const formContainerRef = useRef(null);


  
// This function validates emails
const [twitter, setTwitter] = useState('');
const [discord, setDiscord] = useState('');
const [email, setEmail] = useState('');
const [name, setName] = useState('');
const [motivation, setMotivation] = useState('');
const [experience, setExperience] = useState('');
const [portfolio, setPortfolio] = useState('');
const [message, setMessage] = useState('');


const [communication, setCommunication] = useState('');
const [otherCommunication, setOtherCommunication] = useState('');
const [deadlines, setDeadlines] = useState('');
const [collaboration, setCollaboration] = useState('');
const [blockchain, setBlockchain] = useState('');
const [feedback, setFeedback] = useState('');
const [hours, setHours] = useState('');
const [expertise, setExpertise] = useState([]);
const [otherExpertise, setOtherExpertise] = useState('');


//Validation Error Message Setters
const [nameError, setNameError] = useState('');
const [twitterError, setTwitterError] = useState('');
const [discordError, setDiscordError] = useState('');
const [emailError, setEmailError] = useState('');
const [motivationError, setMotivationError] = useState('');
const [experienceError, setExperienceError] = useState('');
const [portfolioError, setPortfolioError] = useState('');
const [messageError, setMessageError] = useState('');
const [communicationError, setCommunicationError] = useState('');
const [deadlinesError, setDeadlinesError] = useState('');
const [collaborationError, setCollaborationError] = useState('');
const [blockchainError, setBlockchainError] = useState('');
const [feedbackError, setFeedbackError] = useState('');
const [hoursError, setHoursError] = useState('');
const [expertiseError, setExpertiseError] = useState('');
const [otherExpertiseError, setOtherExpertiseError] = useState('');
const [otherCommunicationError, setOtherCommunicationError] = useState('');

const initialValues = {   name: '',
twitter: '',
discord: '',
email: '',
motivation: '',
experience: '',
portfolio: '',
message: '',

//radio inputs
communication: '',
deadlines: '',
collaboration: '',
blockchain: '',
feedback: '',
hours: '',

//checkbox inputs
expertise: []};


const [formValues, setFormValues] = useState(initialValues);
const [error, setError] = useState('');
const [formErrors, setFormErrors] = useState({});
const [isSubmit, setIsSubmit] = useState(false);
//const [name, setName] = useState("");

const [state, setState] = useState({
  ...initialState,
  otherExpertise: "",          // Store manual input for expertise
  otherCommunication: "",      // Store manual input for communication
});

const form = useRef();
const validate = (values) => {
  const errors = {};

  // Regular expressions
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const nameRegex = /^[a-zA-Z\s'-]+$/;
  const discordRegex = /^@?[a-zA-Z0-9_]{1,31}$/;
  const twitterRegex = /^www\.twitter\.com\/[a-zA-Z0-9_]{1,15}$/;

  if ('name' in values) {
    if (!values.name) errors.name = "Name field can't be empty";
    else if (!nameRegex.test(values.name)) errors.name = "Invalid Name";
  }

  if ('twitter' in values) {
    if (!values.twitter) errors.twitter = "Twitter field can't be empty";
    else if (!twitterRegex.test(values.twitter)) errors.twitter = "Invalid Twitter Handle. Format: www.twitter.com/username";
  }

  if ('discord' in values) {
    if (values.discord && !discordRegex.test(values.discord)) errors.discord = "Invalid Discord Username. Format: @username";
  }

  if ('email' in values) {
    if (!values.email) errors.email = "Email field can't be empty";
    else if (!emailRegex.test(values.email)) errors.email = "Invalid email address";
  }

  if ('motivation' in values) {
    if (!values.motivation) errors.motivation = "Motivation field can't be empty";
  }

  if ('experience' in values) {
    if (!values.experience) errors.experience = "Experience field can't be empty";
  }

  if ('portfolio' in values) {
    if (!values.portfolio) errors.portfolio = "Portfolio field can't be empty";
  }

  if ('message' in values) {
    if (!values.message) errors.message = "Message field can't be empty";
  }

  if ('communication' in values) {
    if (!values.communication) errors.communication = "Please select a communication method";
  }

  if ('deadlines' in values) {
    if (!values.deadlines) errors.deadlines = "Please select a response to deadlines";
  }

  if ('collaboration' in values) {
    if (!values.collaboration) errors.collaboration = "Please select a response to collaboration";
  }

  if ('blockchain' in values) {
    if (!values.blockchain) errors.blockchain = "Please select a response to blockchain experience";
  }

  if ('feedback' in values) {
    if (!values.feedback) errors.feedback = "Please select a response to feedback handling";
  }

  if ('hours' in values) {
    if (!values.hours) errors.hours = "Please select a response to weekly dedication";
  }

  if ('expertise' in values) {
    if (!values.expertise || values.expertise.length === 0) errors.expertise = "Select at least one expertise";
  }

  return errors;
};

const validateStep = (step) => {
  let fieldsToValidate = {};

  switch (step) {
    case 1:
      fieldsToValidate = {
        name: formData.name,
        twitter: formData.twitter,
        discord: formData.discord,
        email: formData.email,
      };
      break;
    case 2:
      fieldsToValidate = {
        expertise: formData.expertise,
      };
      break;
    case 3:
      fieldsToValidate = {
        motivation: formData.motivation,
        experience: formData.experience,
        communication: formData.communication,
      };
      break;

    case 4:
      fieldsToValidate = {
        deadlines: formData.deadlines,
      };
      break;
    case 5:
      fieldsToValidate = {
        collaboration: formData.collaboration,
      };
      break;
    case 6:
      fieldsToValidate = {
        blockchain: formData.blockchain,
      };
      break;
    case 7:
      fieldsToValidate = {
        feedback: formData.feedback,
      };
      break;
    case 8:
      fieldsToValidate = {
        hours: formData.hours,
        portfolio: formData.portfolio,
        message: formData.message,
      };

  }

  const errors = validate(fieldsToValidate);
  setValidationErrors(errors);

  return Object.keys(errors).length === 0;
};




const renderCurrentStep = (isDarkMode) => {
  switch (currentStep) {
    case 1:
      return <Step1 formData={formData} setFormData={setFormData} validationErrors={validationErrors} isDarkMode={isDarkMode} />;
    case 2:
      return <Step2 formData={formData} setFormData={setFormData} validationErrors={validationErrors} isDarkMode={isDarkMode} />;
    case 3:
      return <Step3 formData={formData} setFormData={setFormData} validationErrors={validationErrors} isDarkMode={isDarkMode} />;
    case 4:
      return <Step4 formData={formData} setFormData={setFormData} validationErrors={validationErrors} isDarkMode={isDarkMode} />;
    case 5:
      return <Step5 formData={formData} setFormData={setFormData} validationErrors={validationErrors} isDarkMode={isDarkMode} />;
    case 6:
      return <Step6 formData={formData} setFormData={setFormData} validationErrors={validationErrors} isDarkMode={isDarkMode} />;
    case 7:
      return <Step7 formData={formData} setFormData={setFormData} validationErrors={validationErrors} isDarkMode={isDarkMode} />;
    case 8:
      return <Step8 formData={formData} setFormData={setFormData} validationErrors={validationErrors} isDarkMode={isDarkMode} />;
    default:
      return null;
  }
};


// Define animation variants for the progress balls
const ballVariants = {
  inactive: { scale: 1 },
  current: { 
    scale: 1.1, // Slightly less pop for gray to blue transition
    transition: { type: "spring", stiffness: 300, damping: 17 }
  },
  active: { 
    scale: 1.2, // More pop for blue to green transition
    transition: { type: "spring", stiffness: 300, damping: 12 }
  }
};

const renderProgressBalls = () => {
  let balls = [];
  for (let i = 1; i <= totalSteps; i++) {
    const variant = i === currentStep ? 'current' : i < currentStep ? 'active' : 'inactive';
    balls.push(
      <ProgressBall
        key={i}
        isActive={i < currentStep}
        isCurrent={i === currentStep}
        isInactive={i > currentStep}
        variants={ballVariants}
        initial="inactive"
        animate={variant}
      />
    );
  }
  return balls;
};



    const sendEmail = (e) => {
    console.log('sendEmail');
 
    //const isValid = validate();
          // Validate the form data
   


      //e.preventDefault();
     // setFormErrors(validate(state));
      //if (Object.keys(validate(state)).length === 0) {
  
   
      e.preventDefault();

      // Your EmailJS service ID, template ID, and Public Key
      const serviceId = "daoFormService";
      const templateId = "web3o_Dao";
      const publicKey = "8y9ziEfWJ76msEZ-8";

      //Excel const
      const data = {
        Name: formData.name,
        Twitter: formData.twitter,
        Discord: formData.discord,
        Email: formData.email,
        Motivation: formData.motivation,
        Experience: formData.experience,
        Portfolio: formData.portfolio,
        Message: formData.message,
        
        //radio inputs
        Communication: formData.communication,
        OtherCommunication: formData.otherCommunication,
        Deadlines: formData.deadlines,
        Collaboration: formData.collaboration,
        Blockchain: formData.blockchain,
        Feedback: formData.feedback,
        Hours: formData.hours,
        
        //checkbox inputs
        Expertise: formData.expertise,
        OtherExpertise: formData.otherExpertise,
      };

      // Create a new object that contains dynamic template params
      const templateParams = {
        name: formData.name,
        twitter: formData.twitter,
        discord: formData.discord,
        email: formData.email,
        motivation: formData.motivation,
        experience: formData.experience,
        portfolio: formData.portfolio,
        message: formData.message,
        
        //radio inputs
        communication: formData.communication,
        otherCommunication: formData.otherCommunication,
        deadlines: formData.deadlines,
        collaboration: formData.collaboration,
        blockchain: formData.blockchain,
        feedback: formData.feedback,
        hours: formData.hours,
        
        //checkbox inputs
        expertise: formData.expertise,
        otherExpertise: formData.otherExpertise,
        to_name: "Web 3 Outfitters",
      
      };
    
      // Validate the form data

     // console.log(validate());
      //console.log(isValid);
      if (Object.keys(validationErrors).length === 0) {

      // Send the email using EmailJS
      emailjs
      .send(serviceId, templateId, templateParams, publicKey)
        .then((response) => {
          console.log('Email sent successfully!', response);
          setName('');
          setEmail('');
          setDiscord('');
          setTwitter('');
          setMotivation('');
          setExperience('');
          setPortfolio('');
          setMessage('');
          setExpertise([]);
          setOtherExpertise('');
          setMessage('');
          setDeadlines('');
          setCollaboration('');
          setCommunication('');
          setOtherCommunication('');
          setHours('');
          setBlockchain('');
          setFeedback('');
        })


   
        axios.post('https://sheet.best/api/sheets/334f085c-943f-4465-acfe-ab4e9c2dfe57', data).then((response)=>{
          console.log(response);
        })
        .catch((error) => {
          console.error('Error sending email:', error);
        });

        // Your existing logic for sending the email...

        // Reset the form and state
        setFormValues(initialValues);
        setValidationErrors({});
        setIsSubmit(true);
        e.target.reset();
                } else {
                  // Handle validation errors (e.g., display error messages to the user)
                  console.log('Validation errors:', validationErrors);
                  // You can set validationErrors in the state and display them to the user
                }
              };

            
            const handleTwitterFocus = () => {
              if (!twitter.startsWith('www.twitter.com/')) {
                setTwitter('www.twitter.com/');
              }
            };
            const handleDiscordFocus= () => {
              if (!discord.startsWith('@')) {
                setDiscord('@');
              }
            };

            const handleNextClick = () => {
              if (validateStep(currentStep)) {
                setCurrentStep(currentStep + 1);
              }
            };
           // Function to advance to the next step
          const goToNextStep = () => {
            if (validateStep(currentStep)) {
              setCurrentStep(currentStep + 1);
            }
          };

          // Function to go back to the previous step
          const goToPreviousStep = () => {
            if (currentStep > 1) {
              setCurrentStep(currentStep - 1);
            }
          };

            useEffect(() => {
              const handleClickOutside = (event) => {
                if (form.current && !form.current.contains(event.target)) {
                  // Clicked outside the DaoFormContainer, close the form
                  props.setTrigger(false);
                }
              };
          
              // Add event listener when the form is open
            //  if (props.trigger && !isFooterVisible) {

              if (props.trigger) {
                document.addEventListener('mousedown', handleClickOutside);
              }
          
              // Remove event listener when the form is closed
              return () => {
                document.removeEventListener('mousedown', handleClickOutside);
              };
            }, [props.trigger, isFooterVisible, props.setTrigger]);
          
         //   return (props.trigger && !isFooterVisible) ? (
            
          return (props.trigger ) ? (
      <BlurredOverlay isDarkMode={isDarkMode}>
      
        <DaoFormContainer

          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1, transition: { duration: 0.5, ease: 'easeInOut' } }}
          exit={{ scale: 0, opacity: 0, transition: { duration: 0.5, ease: 'easeInOut' } }}
          isDarkMode={isDarkMode}
          id={'/DaoForm'}
        >
     
 
                <DaoFormForm      style={{
            backgroundImage: `url(${isDarkMode ? bg : bg2})`,
            backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
            
          
            }}  ref={form}    onSubmit={sendEmail} >

              

        <PExit isDarkMode={isDarkMode} onClick={() => props.setTrigger(false)}>
<ExitIconWrap
   initial={{scale: daoPopup ? 0 : 1, rotateZ: daoPopup ? 180 : 0, }}
          animate={{  scale: daoPopup ? 1 : 0,rotateZ: daoPopup ? 180 : 0,  }}
          transition={{ duration: 0.6 }}
        whileHover={{ rotate: 180, transition: { duration: 0.25 } }}
        whileTap={{ rotate: 180, transition: { duration: 0.25 } }}
      >
          <ExitIcon whileHover={{ color: isDarkMode ? '#000' : '#fff', backgroundColor: isDarkMode ? '#fff' : '#000' }} />
      </ExitIconWrap>
    </PExit>        
          
                
                  <DaoFormHeader isDarkMode={isDarkMode} style={{marginTop: `50px`, marginBottom: `50px`}}>DAO APPLICATION FORM</DaoFormHeader>

                  {renderCurrentStep(isDarkMode)}

<ProgressContainer>
  {renderProgressBalls()}
</ProgressContainer>

<ButtonsContainer>
      {currentStep > 1 && (
        <DaoFormButton type="Previous" value='Previous'  onClick={goToPreviousStep}/>
      )}
      {currentStep < totalSteps && (
        <DaoFormButton type="button" onClick={goToNextStep}  value='Next' />
      )}
      {currentStep === totalSteps && (
        <DaoFormButton type="submit"/>
      )}
    </ButtonsContainer>



    { isSubmit ? (
                    <>
                    <BlurredOverlay style={{borderRadius: '30px'}} isDarkMode={isDarkMode}/>
                    <PExit isDarkMode={isDarkMode} onClick={() => props.setTrigger(false)}>
<ExitIconWrap
   initial={{scale: daoPopup ? 0 : 1, rotateZ: daoPopup ? 180 : 0, }}
          animate={{  scale: daoPopup ? 1 : 0,rotateZ: daoPopup ? 180 : 0,  }}
          transition={{ duration: 0.6 }}
        whileHover={{ rotate: 180, transition: { duration: 0.25 } }}
        whileTap={{ rotate: 180, transition: { duration: 0.25 } }}
      >
          <ExitIcon whileHover={{ color: isDarkMode ? '#000' : '#fff', backgroundColor: isDarkMode ? '#fff' : '#000' }} />
      </ExitIconWrap>
    </PExit>        
          
                                    <MessageContainer>
                                <SuccessMessage initial={isSubmit ? { opacity: 0, scale: 0.8 } : { opacity: 1, scale: 1 }}
                                                animate={isSubmit ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
                                                transition={{ duration: 0.5 }} style={{ backgroundImage: `url(${isDarkMode ? bg2 : bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                                  <IconCheckmark initial={isSubmit ? { scale: 0, rotate: 360 } : { scale: 1, rotate: 0 }}
                  animate={isSubmit ? { scale: 1, rotate: 0 } : { scale: 0, rotate: 360 }}
                  transition={{ duration: 0.5, delay: 0.2 }} viewBox="0 0 76 76" >
                                    <circle cx="38" cy="38" r="36" />
                                    <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M17.7,40.9l10.9,10.9l28.7-28.7" />
                                  </IconCheckmark>
                                  <SuccessMessageTitle  initial={isSubmit ? { opacity: 0 } : { opacity: 1 }}
                                                        animate={isSubmit ? { opacity: 1 } : { opacity: 0 }}
                                                        transition={{ duration: 0.5, delay: 0.4 }}> Message Received </SuccessMessageTitle>

                                  <SuccessMessageContent initial={isSubmit ? { opacity: 0 } : { opacity: 1 }}
                                                          animate={isSubmit ? { opacity: 1 } : { opacity: 0 }}
                                                          transition={{ duration: 0.5, delay: 0.6 }}>
                                    <p>A confirmation email has been sent!</p>
                                  </SuccessMessageContent>
                                </SuccessMessage>
                                </MessageContainer>
                                </>
              ) : (
                <pre></pre>
              )}
</DaoFormForm>
</DaoFormContainer>
</BlurredOverlay>

    ) : null;
}

export default DaoForm;