// InfoPopup.js

import React, { useState } from 'react';
import {
  FaEthereum,
  FaEye,
  FaHeart,
  FaUser
} from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import {
  About,
  ActivityTable,
  BidsTable,
  BlurBackground,
  CloseIcon,
  DescriptionGridDesktop,
  GraphPlaceholder,
  InfoPopupContainer,
  InfoPopupDescription,
  InfoPopupImage,
  InfoPopupOwner,
  InfoPopupPrice,
  InfoPopupTitle,
  ListedDetailsDesktop,
  RarityMark,
  SectionContent,
  SectionTitle,
  SmallText,
  StatsColumnDesktop,
  StatsRowDesktop,
  TabDesktop,
  Table,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  TabsContainerDesktop,
  Traits,
  TraitsSectionDesktop,
  TraitsTitle
} from './InfoPopupElements';

const InfoPopup = ({
  selectedCard,
  handleClosePopup,
  handleOutsideClick,
  isDarkMode
}) => {
  const [activeTab, setActiveTab] = useState('overview');

  return (
    <>
      <BlurBackground
        id="blur-background"
        onClick={handleOutsideClick}
      />
      <InfoPopupContainer isDarkMode={isDarkMode}>
        <CloseIcon onClick={handleClosePopup}>
          &times;
        </CloseIcon>

        {/* Info Popup Title */}
        <InfoPopupTitle>
          {selectedCard.header} <MdVerified />
        </InfoPopupTitle>

        {/* Rarity */}
        <SmallText isDarkMode={isDarkMode}>
          RARITY{' '}
          <RarityMark
            rarity={selectedCard.rarityLevel}
            isDarkMode={isDarkMode}
          >
            #{selectedCard.rarity}
          </RarityMark>
        </SmallText>

        {/* Owner Information */}
        <InfoPopupOwner>
          Owner:{' '}
          <a
            href={`https://etherscan.io/address/${selectedCard.owner}`}
            style={{ color: 'lightblue' }}
          >
            {selectedCard.owner}
          </a>
        </InfoPopupOwner>

        {/* Main Image */}
        <InfoPopupImage
          src={selectedCard.logo}
          alt="Main Logo"
        />

        {/* Stats Row */}
        <StatsRowDesktop>
          <StatsColumnDesktop>
            <FaEye /> Views: {selectedCard.views}
          </StatsColumnDesktop>
          <StatsColumnDesktop>
            <FaHeart /> Favorites: {selectedCard.favorites}
          </StatsColumnDesktop>
          <StatsColumnDesktop>
            <FaUser /> PFPs
          </StatsColumnDesktop>
        </StatsRowDesktop>

        {/* Tabs for Overview, Bids, and Activity */}
        <TabsContainerDesktop isDarkMode={isDarkMode}>
          <TabDesktop
            isActive={activeTab === 'overview'}
            isDarkMode={isDarkMode}
            onClick={() => setActiveTab('overview')}
          >
            Overview
          </TabDesktop>
          <TabDesktop
            isActive={activeTab === 'bids'}
            isDarkMode={isDarkMode}
            onClick={() => setActiveTab('bids')}
          >
            Bids
          </TabDesktop>
          <TabDesktop
            isActive={activeTab === 'activity'}
            isDarkMode={isDarkMode}
            onClick={() => setActiveTab('activity')}
          >
            Activity
          </TabDesktop>
        </TabsContainerDesktop>

        {/* Overview Tab */}
        {activeTab === 'overview' && (
          <DescriptionGridDesktop>
            <InfoPopupDescription>
              {selectedCard.name}
            </InfoPopupDescription>
            <InfoPopupDescription>
              {selectedCard.description}
            </InfoPopupDescription>
            <InfoPopupPrice>
              {selectedCard.ethPrice} 
            </InfoPopupPrice>
            <InfoPopupDescription>
              Floor Difference:{' '}
              {selectedCard.floorDifference}
            </InfoPopupDescription>
            <InfoPopupDescription>
              Last Sold: {selectedCard.lastSold}{' '}
              <FaEthereum />g
            </InfoPopupDescription>
            <InfoPopupDescription>
              Listed Time: {selectedCard.listedTime}
            </InfoPopupDescription>
          </DescriptionGridDesktop>
        )}

        {/* Bids Tab */}
        {activeTab === 'bids' && (
          <BidsTable>
            <Table isDarkMode={isDarkMode}>
              <TableHead isDarkMode={isDarkMode}>
                <TableRow>
                  <TableHeader>Unit Price</TableHeader>
                  <TableHeader>USD Unit Price</TableHeader>
                  <TableHeader>Quantity</TableHeader>
                  <TableHeader>Floor Difference</TableHeader>
                  <TableHeader>Expiration</TableHeader>
                  <TableHeader>From</TableHeader>
                </TableRow>
              </TableHead>
              <tbody>
                {selectedCard.bids.map((bid, index) => (
                  <TableRow
                    key={index}
                    isDarkMode={isDarkMode}
                  >
                    <TableData>{bid.unitPrice}</TableData>
                    <TableData>
                      {bid.usdUnitPrice}
                    </TableData>
                    <TableData>{bid.quantity}</TableData>
                    <TableData>
                      {bid.floorDifference}
                    </TableData>
                    <TableData>
                      {bid.expiration}
                    </TableData>
                    <TableData>{bid.from}</TableData>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </BidsTable>
        )}

        {/* Activity Tab */}
        {activeTab === 'activity' && (
          <ActivityTable>
            <Table isDarkMode={isDarkMode}>
              <TableHead isDarkMode={isDarkMode}>
                <TableRow>
                  <TableHeader>Unit Price</TableHeader>
                  <TableHeader>USD Unit Price</TableHeader>
                  <TableHeader>Quantity</TableHeader>
                  <TableHeader>Expiration</TableHeader>
                  <TableHeader>From</TableHeader>
                  <TableHeader>Action</TableHeader>
                </TableRow>
              </TableHead>
              <tbody>
                {selectedCard.activity.map(
                  (activities, index) => (
                    <TableRow
                      key={index}
                      isDarkMode={isDarkMode}
                    >
                      <TableData>
                        {activities.unitPrice}
                      </TableData>
                      <TableData>
                        {activities.usdUnitPrice}
                      </TableData>
                      <TableData>
                        {activities.quantity}
                      </TableData>
                      <TableData>
                        {activities.expiration}
                      </TableData>
                      <TableData>{activities.from}</TableData>
                      <TableData>
                        {activities.cartSymbol}
                      </TableData>
                    </TableRow>
                  )
                )}
              </tbody>
            </Table>
          </ActivityTable>
        )}

        {/* Traits Section */}
        <TraitsSectionDesktop>
          <TraitsTitle isDarkMode={isDarkMode}>
            Traits
          </TraitsTitle>
          <Traits
            currentDetail={selectedCard}
            isDarkMode={isDarkMode}
          />
        </TraitsSectionDesktop>

        {/* Price History Section */}
        <SectionTitle isDarkMode={isDarkMode}>
          Price History
        </SectionTitle>
        <GraphPlaceholder isDarkMode={isDarkMode}>
          Graph here
        </GraphPlaceholder>

        {/* About Section */}
        <About
          currentDetail={selectedCard}
          isDarkMode={isDarkMode}
        />

        {/* Listed Details */}
        <ListedDetailsDesktop>
          <SectionTitle isDarkMode={isDarkMode}>
            Listed Details
          </SectionTitle>
          <SectionContent isDarkMode={isDarkMode}>
            Connect wallet to see more
          </SectionContent>
        </ListedDetailsDesktop>
      </InfoPopupContainer>
    </>
  );
};

export default InfoPopup;
