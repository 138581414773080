import { motion } from 'framer-motion';
import { MdCancel } from 'react-icons/md';
import styled, { css } from 'styled-components';

// Define sharedStyles
const sharedStyles = css`
  height: 30px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 5px 0;
`;

// Assuming isDarkMode is a prop to manage theme styles
const inputBackground = ({ isDarkMode }) => isDarkMode ? '#fff' : '#333';
const inputColor = ({ isDarkMode }) => isDarkMode ? '#000' : '#fff';

//Close Icon


// COLORS
const softOrange = '#F4A259';
const tomatoRed = '#F25C66';
const mediumBlue = '#1E272D';

export const Body = styled.body`
  background-color: ${mediumBlue};
`;

export const CloseContainer = styled.div`
z-index: 9999999;
  width: 48px;
  height: 48px;
  position: absolute;
    top: .5rem;

    right: 1.25rem;
    zoom: 0.75;
    background: transparent;
    @media screen and (max-width: 870px) {
          //transform: scale(0.7);
          //zoom: 0.75;
     
 
    }


`;

export const LeftRight = styled.div`
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;

  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;

  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
`;

export const RightLeft = styled.div`
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;

  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
`;

export const Label = styled.label`
 color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
 
 font-style: normal;
    font-weight: 700;
    line-height: 20px;
  font-size: 8px !important;
display: block !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease-in;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
`;

export const Close = styled.div`
  margin: 60px 0 0 5px;
  position: absolute;
`;

export const CloseContainerHover = styled(CloseContainer)`
  &:hover ${LeftRight} {
    transform: rotate(-45deg);
    background-color: ${tomatoRed};
  }

  &:hover ${RightLeft} {
    transform: rotate(45deg);
    background-color: ${tomatoRed};
  }

  &:hover ${Label} {
 
    opacity: 1;
  }
`;


export const PopupMessage = styled(motion.form)`
    box-sizing: border-box;
    position: fixed;
    width: 500px;
    height: 400px;
    left: 50%;
    transform: translateX(-50%);
    top: 200px;
    z-index: 9999999999;

    //border: 10px solid rgba(139, 159, 44, 0.75);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    @media screen and (max-width: 550px) {
       zoom: 0.8;
  
  

    }

`




export const PopupMTop = styled.div`
    position: absolute;
    height: 50px;
    width: 225px;
    left: 50%;
    transform: translateX(-50%);
    top: 7.5%;
    justify-content: center;
    text-align: center;
 
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 20px;
    white-space: nowrap;
    /* or 100% */
    //border-radius: 50%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    color: #FFFFFF;
   // background: #8B9F2C;

   color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
`

export const ExitIcon = styled(MdCancel)` 
 cursor: pointer;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  border-radius: 100%;

 // transform: translate(10px, 10px) rotate()(180deg);
  transition: transform 3300ms ease-in-out;
  font-size: 32px; /* Adjust the font size as per your requirements */
//z-index: -10000000;
transform-origin: center;
display: flex;
  justify-content: center;
  align-items: center;
    //display: flex;
    &:hover {
   

}
`;

export const PExit = styled(motion.div)`
  position: absolute;
  right: 1%;
  top: 2%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure it's above other content but within the UtilitiesContainer's context */
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};

  &:hover {
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    /* Add any hover effects you need */
  }

  &:hover ${ExitIcon} {
    /* Adjust hover effects for ExitIcon if necessary */
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    filter: drop-shadow(0 0 5px white);
  }

  @media screen and (max-width: 768px) {
    /* Adjust positioning for mobile or as needed */
    position: fixed;
  }
`;


export const ExitIconWrap = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center; /* Set the transform origin to the center */

cursor: pointer;
&:focus {
                    outline: 0 !important;
                    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none !important;
                    -moz-user-select: none !important;
                    -ms-user-select: none !important;
                    user-select: none !important; 
                    box-shadow: none !important;
                    border: none !important;
  outline: none;
                }
  `


export const PName = styled.input.attrs({ type: 'text' })`
  box-sizing: border-box;
  position: absolute;
  width: 275px;
  height: 32.5px;
  left: 50%;
  transform: translateX(-50%);
  top: 22.5%;
  z-index: 9999999;
  background: ${inputBackground};
  color: ${inputColor};
  border: 1px solid #707070;
  border-radius: 8px;
  ${sharedStyles} // Apply shared styles
`;

export const PPhone = styled.input`
  box-sizing: border-box;
  position: absolute;
  width: 275px;
  height: 32.5px;
  left: 50%;
  transform: translateX(-50%);
  top: 33.5%;
  z-index: 9999999;
  background: ${inputBackground};
  color: ${inputColor};
  border: 1px solid #707070;
  border-radius: 8px;
  ${sharedStyles} // Apply shared styles
`;

export const PEmail = styled.input`
  box-sizing: border-box;
  position: absolute;
  width: 275px;
  height: 32.5px;
  left: 50%;
  transform: translateX(-50%);
  top: 45%;
  z-index: 9999999;
  background: ${inputBackground};
  color: ${inputColor};
  border: 1px solid #707070;
  border-radius: 8px;
  ${sharedStyles} // Apply shared styles
`;

export const PImage = styled.img`
    box-sizing: border-box;

    position: absolute;
    width: 125px;
    height: 100px;
    left: 50%;
    transform: translateX(-50%);
    top: 215px;

    background-repeat: no-repeat;
    background-size: cover;

`

export const PButton = styled.button`


  display: block;



    position: absolute;
    height: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    top: 285px;
    border-radius: 20px;
 
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    justify-content: center;
    text-align: center;
    /* or 142% */

    display: flex;
    align-items: center;

    z-index: 9999999;
    color: #FFFFFF;
    //background: #8B9F2C;
    cursor: pointer;
    z-index: 5;
    background-color: #f7797d;
  color: #fff;
  font-size: 0.9rem;
  border: none;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px;
  margin-top: 50px;
 // right: 5%;
  cursor: pointer;
  box-sizing: border-box;

`




export const initialState = {
  //text inputs
  name: '',
  wallet: '',
  email: '',

};
