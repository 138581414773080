import { motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import { VariableSizeGrid as Grid } from 'react-window';
import styled from 'styled-components';
import InfoPopup from '../InfoCardPopup/InfoPopup.js';
import {
  Col1,
  Col2,
  FeaturedMainCard,
  MainCard,
  MainDescription,
  MainDescription2,
  MainImage,
  MainOwner,
  MainPrice,
  MainTitle,
  PlusButton,
  RarityMark,
  SmallText,
  TwoColumnSection,
  ViewDropsButton,
} from './SplitViewElements.js';

const HorizontalScrollWrapper = styled.div`
  width: 100%;
  height: 45vh;
  position: fixed;
  bottom: 2%;
  display: flex;
  overflow: hidden; /* Hide default scrollbars */
  padding: 10px;
  box-sizing: border-box;
  z-index: 99;
`;

const FeaturedMainCardWrapper = styled.div`
  margin-top: 30px;
  transform: scale(0.9);
`;

const MainCardWrapper = styled.div`
  margin-top: 65px;
`;

const ScrollIndicator = styled(motion.div)`
  position: absolute;
  bottom: 100px;
  left: 80%;
  transform: translateX(-50%) scale(0.5);
  text-align: center;
  z-index: 100;
`;

const ScrollArrow = styled(motion.div)`
  font-size: 40px;
  color: #ff8c42; /* Orange color for the arrow */
  background-color: black;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const BackToStartButton = styled.button`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: black;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  z-index: 99999;
  color: #ff8c42; /* Orange color */
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
`;

export const SplitCardViewMobile = ({ isDarkMode, tempGeneralData }) => {
  const isMobile = window.innerWidth <= 600;
  const [selectedCard, setSelectedCard] = useState(null);
  const gridRef = useRef(); // Reference to the Grid component
  const [hasScrolled, setHasScrolled] = useState(false); // Track if the user has scrolled

  const featuredMain = tempGeneralData[0];
  const otherMains = tempGeneralData.slice(1); // The rest of the main cards

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleClosePopup = () => {
    setSelectedCard(null);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'blur-background') {
      handleClosePopup();
    }
  };

  // Function to return the width of each column
  const getColumnWidth = (index) => {
    return index === 0 ? window.innerWidth : window.innerWidth / 3;
  };

  // Scroll back to start
  const handleBackToStart = () => {
    if (gridRef.current) {
      gridRef.current.scrollTo({
        scrollLeft: 5,
      });
      setHasScrolled(false); // Reset the scroll indicator when back to the start
    }
  };

  // Handle scroll event to detect if the user has scrolled horizontally
  const handleScroll = ({ scrollLeft }) => {
    if (scrollLeft > 0) {
      setHasScrolled(true); // User has started scrolling, hide the indicator
    } else {
      setHasScrolled(false); // User is at the beginning
    }
  };

  const scrollIndicatorVariants = {
    visible: { opacity: 1, y: 0, scale: 0.5 },
    hidden: { opacity: 0, y: 10, scale: 0.3 },
    hover: {
      y: [0, -5, 0],
      transition: { repeat: Infinity, duration: 3 },
    },
  };

  return (
    <>
      {selectedCard && (
        <InfoPopup
          selectedCard={selectedCard}
          handleClosePopup={handleClosePopup}
          handleOutsideClick={handleOutsideClick}
          isDarkMode={isDarkMode}
        />
      )}

      <HorizontalScrollWrapper>
        <Grid
          ref={gridRef}
          columnCount={otherMains.length + 1} // Include the featured card
          rowCount={1} // Single row for horizontal scrolling
          columnWidth={getColumnWidth} // Set dynamic width for each column
          rowHeight={() => 350} // Fixed row height
          height={350}
          width={window.innerWidth}
          overscanColumnsCount={2} // Pre-render some extra columns for smoother scroll
          onScroll={({ scrollLeft }) => handleScroll({ scrollLeft })} // Handle scroll event
        >
          {({ columnIndex, style }) => {
            let content;

            const adjustedStyle = {
              ...style,
              marginLeft: columnIndex === 0 ? '0px' : '10px', // Add spacing between cards
            };

            if (columnIndex === 0) {
              content = (
                <>
                <FeaturedMainCardWrapper key="featured">
                  <FeaturedMainCard isDarkMode={isDarkMode}>
                    <MainImage src={featuredMain.logo} alt="Featured Main Logo" />
                    <MainTitle>{featuredMain.header}</MainTitle>
                    <MainDescription>{featuredMain.name}</MainDescription>
                    <MainDescription>{featuredMain.description}</MainDescription>
                    <TwoColumnSection>
                      <Col1>
                        <MainDescription2>{featuredMain.ethPrice} </MainDescription2>
                        <MainDescription>
                          Owner:{' '}
                          <a
                            href={`https://etherscan.io/address/${featuredMain.owner}`}
                            style={{ color: 'lightblue' }}
                          >
                            {featuredMain.owner}
                          </a>
                        </MainDescription>
                        <MainDescription>Listed Time: {featuredMain.listedTime}</MainDescription>
                      </Col1>
                      <Col2>
                        <MainDescription>Last Sold: {featuredMain.lastSold} </MainDescription>
                        <MainDescription>
                          RARITY{' '}
                          <RarityMark rarity={featuredMain.rarityLevel} isDarkMode={isDarkMode}>
                            #{featuredMain.rarity}
                          </RarityMark>
                        </MainDescription>
                        <MainDescription>
                          Floor Difference: {featuredMain.floorDifference}
                        </MainDescription>
                      </Col2>
                    </TwoColumnSection>
                    <ViewDropsButton isDarkMode={isDarkMode}>View Latest Drops</ViewDropsButton>

                    {/* Scroll Prompt Indicator */}
        
                  </FeaturedMainCard>
                </FeaturedMainCardWrapper>
                {!hasScrolled && (
                      <ScrollIndicator
                        initial="hidden"
                        animate="visible"
                        variants={scrollIndicatorVariants}
                        transition={{ duration: 1, repeat: Infinity }}
                      >
                        <ScrollArrow variants={scrollIndicatorVariants}>
                          <FaArrowAltCircleRight />
                        </ScrollArrow>
                        <div>Swipe right to scroll</div>
                      </ScrollIndicator>
                    )}
                    </>
              );
            } else {
              const adjustedIndex = columnIndex - 1;
              const main = otherMains[adjustedIndex];

              if (!main) return null;

              content = (
                <MainCardWrapper key={main.id} style={{ marginLeft: '5px'}}>
                  <MainCard isMobile={isMobile} isDarkMode={isDarkMode}>
                    <MainImage src={main.logo} alt="Main Logo" />
                    <div>
                      <span>
                        <MainOwner>
                          Owner:{' '}
                          <a
                            href={`https://etherscan.io/address/${main.owner}`}
                            style={{ color: 'lightblue' }}
                          >
                            {main.owner}
                          </a>
                        </MainOwner>
                        <SmallText>
                          RARITY{' '}
                          <RarityMark rarity={main.rarityLevel} isDarkMode={isDarkMode}>
                            #{main.rarity}
                          </RarityMark>
                        </SmallText>
                        <MainPrice>{main.ethPrice} </MainPrice>
                        <MainDescription style={{ fontSize: '0.8em', color: '#999' }}>
                          Last sale: {main.lastSold}{' '}
                        </MainDescription>
                        <SmallText>{main.listedTime}</SmallText>
                      </span>
                    </div>
                    <PlusButton onClick={() => handleCardClick(main)} />
                  </MainCard>
                </MainCardWrapper>
              );
            }

            return <div style={adjustedStyle}>{content}</div>;
          }}
        </Grid>
      </HorizontalScrollWrapper>

      {/* Back to Start Button */}
      <BackToStartButton onClick={handleBackToStart}>
        <FaArrowAltCircleLeft />
      </BackToStartButton>
    </>
  );
};
