/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react';

export default function Symbol({ ...props }) {
const setWhitelistPopup = props;
const handleModelClick = () => {
  // Call the setWhitelistPopup function when the model is clicked
  console.log(setWhitelistPopup)
  setWhitelistPopup();

};
  useFrame(() => {
    // Rotate the group on every frame
    group.current.rotation.y += 0.01; // Adjust the rotation speed as needed
    
  });
    // State to manage the position based on window size
    const [position, setPosition] = useState(window.innerWidth <= 600 ? [0.085, -0.15, 0] : [0, 0, 0]);

    useEffect(() => {
      const handleResize = () => {
        // Update position based on window size
        setPosition(window.innerWidth <= 600 ? [0.085, -0.15, 0] : [0, 0, 0]);
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Cleanup event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  const group = useRef()
  const { nodes, materials } = useGLTF('/w3oSymbol.glb')
  return (
    <group   position={position}    scale={4} ref={group} {...props} dispose={null}>
      <group position={[0, 0, 0]} rotation={[0, 0, 0]} scale={[0.26, 0.25, 0.65]}>
        <mesh   geometry={nodes.Cube025.geometry} material={materials['Material.029']} />
        <mesh geometry={nodes.Cube025_1.geometry} material={materials['Material.032']} />
      </group>
    </group>
  )
}

useGLTF.preload('/w3oSymbol.glb')
