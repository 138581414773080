import React, { useState } from 'react';

//import emailjs from 'emailjs-com';



//import coinbaseWalletModule from '@web3-onboard/coinbase';
//import Onboard from '@web3-onboard/core';
//import injectedModule from '@web3-onboard/injected-wallets';

import {
  AnimateBubbles,
  ButtonText2,
  GoBack,
  GoBackWrap,
  LeftButtonWrapper,
  MasterPlan,
  OverlayButtonRow,
  OverlayButtonWrapper,
  OverlayContainer,
  PlanIcon,
  TextSpan2
} from './OverlayElements';
/*
const MAINNET_RPC_URL = "https://goerli.infura.io/v3/7c9d1c18f6244605b8d57b8dd612aa7e";
const injected = injectedModule();
const walletConnect = walletConnectModule()
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: false })


const onboard = Onboard({
  wallets: [
      injected,
      walletConnect,
      coinbaseWalletSdk
  ],
  chains: [
    {
      id: '0x5',
      token: 'gETH',
      label: 'Goerli Testnet',
      rpcUrl: MAINNET_RPC_URL
    }
  ]
});
*/

export function MeetTeamOverlay({ setIsMouseVisible, onEffectChange, orbitControlsEnabled, setOrbitControlsEnabled, buttonPopup, setCurrentState, currentState, toggleOrbitControls, toggleUtilities, isDarkMode, setModelSwitch, modelSwitch, buttonPopup2, buttonPopup3, setButtonPopup, setButtonPopup2, setButtonPopup3 ,props}) {
    const [walletAddress, setWalletAddress] = useState("");
    const [hover, setHover] = useState(false);
    const [isShopHovered, setIsShopHovered] = useState(false);
    const handleMouseVisibility = () => {
      setIsMouseVisible(true);
    };
   
    const width = window.innerWidth;
    //const mValue = width <= 600  ? 15 : 0;
    const opacityValue = orbitControlsEnabled ? 0 : 1;
    const baseYValue = orbitControlsEnabled ? (75) : 0;
    const yValue = (width <= 600 && orbitControlsEnabled) ? baseYValue - 35 : baseYValue;
   // const yValue = orbitControlsEnabled ? 75 : 0;
    const sValue = orbitControlsEnabled ? 1 : 0;

    const displayValue = orbitControlsEnabled ? 'none' : 'initial';
    const handleButtonClick = () => {
      // Call existing logic for toggleOrbitControls
      setOrbitControlsEnabled(false);
      
      // Call logic for toggling the modelSwitch state
      setModelSwitch(!modelSwitch);
    };
    const handleShopHover = () => {
      setIsShopHovered(true);
    };
    const handleShopMouseLeave = () => {
      setIsShopHovered(false);
    };


    const [isCustomizeHovered, setIsCustomizeHovered] = useState(false);
  
  
    const handleCustomizeHover = () => {
      setIsCustomizeHovered(true);
    };
    const handleCustomizetMouseLeave = () => {
      setIsCustomizeHovered(false);
    };


    const [buttonHovered, setButtonHovered] = useState(false);
    const onHover = () => {
        setHover(!hover);
    }
    const toggleEffect = () => {
      if (onEffectChange) {
        onEffectChange();
      }
    };
    const handleClick = () => {
      toggleOrbitControls();
      handleMouseVisibility();
    };

    /*

    async function loadWeb3() {
        try {
            const wallets = await onboard.connectWallet();
            let web3 = window.web3 = new Web3(wallets[0].provider);
            const account = wallets[0].accounts[0].address;
            console.log("account", account)
            const msgToSign = web3.utils.sha3("Wallet Verification Message for Web3 Outfitters.");
            const signature = await web3.eth.personal.sign(msgToSign, account);
            const signingAddress = web3.eth.accounts.recover(msgToSign, 
            signature);
            console.log("signingAddress", signingAddress)
            if (account.toLowerCase() === signingAddress.toLowerCase()) {
                window.account = account;
                setWalletAddress(account);
            }
            return [(account.toLowerCase() === signingAddress.toLowerCase()), account];
        }
        catch (e) {
            console.log(e);
            return [false, ""];
        }
    }

    var user = {
      name: walletAddress,
      avatar: 'https://www.w3schools.com/howto/img_avatar.png',
      bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      email: 'test@example.com'
    }
    // const user = useContext(UserContext);
    console.log(user);
    */
    return (
      <>
      
<OverlayContainer >
  <OverlayButtonWrapper>
    <OverlayButtonRow>





<LeftButtonWrapper  transition={  { delay: 0}}
                             initial ={ { scale: 0, opacity: 0, y:-20  }} // Start with flip
                            animate = {{ scale: 1 + sValue, opacity: 1, y:yValue }} // End with no flip
                            final = {{ scale: 1, opacity: 1, y:0}} >

<GoBackWrap     orbitControlsEnabled={orbitControlsEnabled}>
                       
                       <AnimateBubbles
                                 isDarkMode={isDarkMode}
                                 primary="true"
                                 dark="true"
                                 fontBig="true"
                                 onClick={() => setCurrentState(1)} 
                                 style={{ width: '100px' }}
                              
                               // className={buttonHovered ? 'animate' : ''}
                                 
                             
                                 //onMouseEnter={() => setButtonHovered(true)}
                                 //onMouseLeave={() => setButtonHovered(false)}
                               >
                     
     
                 
                           
                                 <GoBack     orbitControlsEnabled={orbitControlsEnabled} isDarkMode={isDarkMode} />
                             </AnimateBubbles>
          
             
                  </GoBackWrap>
      <MasterPlan 
      orbitControlsEnabled={orbitControlsEnabled}
             isDarkMode={isDarkMode}> 
  
      <AnimateBubbles  onMouseEnter={handleCustomizeHover}
                       onMouseLeave={handleCustomizetMouseLeave}
                       orbitControlsEnabled={orbitControlsEnabled}
                       isDarkMode={isDarkMode}
                       primary="true"
                       dark="true"
                       fontBig="true"
                       onClick={() => {
    setCurrentState(3);

  }} 
               // className={buttonHovered ? 'animate' : ''}
                
            
                //onMouseEnter={() => setButtonHovered(true)}
                //onMouseLeave={() => setButtonHovered(false)}
              >
    
        {/* Add more buttons if needed */}
 
            <ButtonText2> <TextSpan2  orbitControlsEnabled={orbitControlsEnabled} isDarkMode={isDarkMode}  style={{ marginLeft: '0px' }}>MASTER PLAN</TextSpan2>
            <PlanIcon     orbitControlsEnabled={orbitControlsEnabled} isDarkMode={isDarkMode} />
            </ButtonText2>
            </AnimateBubbles>
   

  


             </MasterPlan>
</LeftButtonWrapper>

    </OverlayButtonRow>
    
  </OverlayButtonWrapper>

</OverlayContainer>

</>
    );
  }
  
  export default MeetTeamOverlay;