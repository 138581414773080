/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import React from 'react'

export default function FloorBag(props) {
  const { nodes, materials } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/FloorBag.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI, -0.859, -Math.PI]} scale={0.03}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.bag_bag_0001.geometry}
          material={materials['material.003']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.bag_bag_0001_1.geometry}
          material={materials['belt.003']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.bag_bag_0001_2.geometry}
          material={materials.PaletteMaterial002}
        />
      </group>
    </group>
  )
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/FloorBag.glb')