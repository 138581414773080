import { motion } from 'framer-motion';
import styled from 'styled-components';


export const InfoWrapper = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 7.5%;
  //background-color: #f8f8f8; /* Change as needed */
  color: #333; /* Change as needed */
  overflow-y: auto; /* For scrolling if content overflows */
  overflow: hidden; /* Prevent child elements from overflowing */
  @media (max-width: 600px) {
    width: 100%;
    height: 50vh;
    margin-top: 40px;
    transform: scale(0.65);
  }
`;


export const ProfileImage = styled(motion.img)`
  width: 250px;
  height: 250px;
 // border-radius: 125px; /* Circular image */
  object-fit: cover;

  @media (max-width: 600px) {
    margin-top: 5px;
  }
`;

export const ProfileName = styled(motion.h3)`
  font-size: 24px;
  margin-bottom: 2.25px;
  font-weight: bold;
  color: #ddd; /* Change as needed */
  color: ${({ isDarkMode }) => (isDarkMode ? '#ddd' : '#222')};
  @media (max-width: 600px) {
    margin-top: 5px;
  }
`;

export const ProfileRole = styled(motion.p)`
  font-size: 18px;
  margin-bottom: 5px;
  color: #eee; /* Change as needed */
  color: ${({ isDarkMode }) => (isDarkMode ? '#eee' : '#111')};
  @media (max-width: 600px) {
    
    margin-top: 1.25px;
  }
`;

export const ProfileBio = styled(motion.p)`
  max-width: 380px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 16px;
  white-space: normal;
  color: #fff; /* Change as needed */
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  @media (max-width: 600px) {
    margin-top: -1.25px;
    max-width: 390px;
    //overflow-wrap: break-word;
  font-size: 14px;
  //width: 100%;

  }
`;