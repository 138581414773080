import React from 'react';
import { useNavigate } from 'react-router-dom';
import symbol from '../../images/w3oBlackLogo.png';
const Privacy = ({ isDarkMode }) => {
  const navigate = useNavigate(); // Hook to navigate

  // Adjusted styles for mobile friendliness and centering
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: isDarkMode ? '#FFF' : '#000',
    lineHeight: '1.6',
    width: '100vw',
    margin: '0 auto',
    padding: '60px 40px',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    overflowY: 'auto',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  };

  const contentStyle = {
    maxWidth: '1200px',
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
  };

  // Style for the symbol and "HOME" text
  const symbolStyle = {
    position: 'fixed',
    bottom: '.5%',
    right: '.5%',
    cursor: 'pointer', // Change cursor to pointer to indicate clickable
    textAlign: 'center', // Center align the text
    transform: 'scale(0.75)'
  };

  const homeTextStyle = {
    color: isDarkMode ? '#FFF' : '#000', // Match theme
    marginTop: '0px', // Space between symbol and text
  };

  // Navigate to home page
  const navigateHome = () => {
    navigate('/');
  };

  return (
    <>
      <div style={containerStyle}>
    <div style={contentStyle}>
      <h1>PRIVACY POLICY</h1>
      <p>Last updated: December 21, 2023</p>
      <p>
        Web3 Outfitters (the “Company,” “we,” “us,” or “our”) is committed to protecting your privacy.
        Your privacy is important to us. It is our policy to respect your privacy and comply with any
        applicable law and regulation regarding any personal information we may collect about you, on
        our website (the “Website”). This Privacy Policy is designed to help you understand how we
        collect, use, process, and share your personal information, and to help you understand and
        exercise your privacy rights.
      </p>
      <p>
        In the event our Website contains links to third-party sites and services, please be aware that
        those sites and services have their own privacy policies. After following a link to any third-party
        content, you should read their posted privacy policy information about how they collect and use
        personal information. This Privacy Policy does not apply to any of your activities after you leave
        our Website.
      </p>
      <h2>Information We Collect</h2>
      <p>
        The categories of personal information we collect depend on how you interact with us, our
        Website, and the requirements of applicable law. We collect information that you provide to us,
        information we obtain automatically when you use our Website, and information from other
        sources such as third-party services and organizations.
      </p>
      <p>
        Information you provide to us in order to use our Website may include:
      </p>
      <ul>
        <li>Identity information, such as your first name, last name, username or similar identifier,
        title, date of birth and gender;</li>
        <li>Contact information, such as your postal address, email address and telephone number;</li>
        <li>Profile information, such as your username and password, interests, preferences,
        feedback and survey responses;</li>
        <li>Feedback and correspondence, such as information you provide in your responses to
        surveys, when you participate in market research activities, report a problem with our
        Website, receive customer support or otherwise correspond with us;</li>
        <li>Transaction information, such details about transfers you make on our Website;</li>
        <li>Marketing information, such as your preferences for receiving marketing
        communications and details about how you engage with them;</li>
        <li>Digital Asset Wallet Address; and</li>
        <li>Other information necessary for Anti-Money Laundering (AML) and Know Your
        Customer (KYC) compliance purposes.</li>
      </ul>
      <p>
        As you use our Website, we may use automatic data collection technologies to collect certain
        information about your equipment, browsing actions, and patterns, including but not limited to,
        operating system, Internet Protocol (“IP”) address, mobile device ID, blockchain address, wallet
        type, and date/time stamps. See “Log Data” and “Device Data” below for details.
      </p>
      <h2>Log Data</h2>
      <p>
        When you use our Website, our servers may automatically log the standard data provided by
        your web browser. It may include your device’s Internet Protocol (IP) address, the pages you
        visit, the time and date of your visit, the time spent on each page, and other details about your
        visit.
      </p>
      <p>
        Additionally, if you encounter certain errors while using the Website, we may automatically
        collect data about the error and the circumstances surrounding its occurrence. This data may
        include technical details about your device, what you were trying to do when the error happened,
        and other technical information relating to the problem. You may or may not receive notice of
        such errors, even in the moment they occur, that they have occurred, or what the nature of the
        error is.
      </p>
      <p>
        Please be aware that while this information may not be personally identifying by itself, it may be
        possible to combine it with other data to personally identify individual persons.
      </p>
      <h2>Device Data</h2>
      <p>
        When you use our Website or interact with our services, we may automatically collect data about
        your device, such as:
      </p>
      <ul>
        <li>Device Type</li>
        <li>Operating System</li>
      </ul>
      <p>
        Data we collect can depend on the individual settings of your device and software. We
        recommend checking the policies of your device manufacturer or software provider to learn what
        information they make available to us.
      </p>
      <h2>Wallet Address Information</h2>
      <p>
        When you create a wallet on our Website, encrypted wallet public key and private key pairs are
        automatically generated by the Website and stored in your device. We have no control of or
        custody over your wallet keys.
      </p>
      <h2>Collection and Use of Information</h2>
      <p>
        We may collect information from you when you do any of the following on our Website:
      </p>
      <ul>
        <li>Register for an account</li>
        <li>Use a mobile device or web browser to access our content</li>
        <li>Contact us via email, social media, or on any similar technologies</li>
        <li>When you mention us on social media</li>
      </ul>
      <p>
        We may collect, hold, use, and disclose information for the following purposes, and personal
        information will not be further processed in a manner that is incompatible with these purposes:
      </p>
      <ul>
        <li>to provide you with our platform's core features and services</li>
        <li>to enable you to customize or personalize your experience of our Website</li>
        <li>to contact and communicate with you</li>
        <li>to enable you to access and use our Website, associated applications, and associated
        social media platforms</li>
        <li>to comply with our legal obligations and resolve any disputes that we may have</li>
        <li>for security and fraud prevention, and to ensure that our sites and apps are safe, secure,
        and used in line with our terms of use</li>
      </ul>
      <p>
        Specifically, with constantly changing regulatory regime over cryptocurrency, we may be
        required by law to keep record and disclose your personal information on certain transactions via
        our Website. For example, FinCEN in late 2020 proposed new rules to require banks and money
        service businesses to submit reports, keep records, and verify identity of customers in relation to
        transactions over a certain value with private wallets. Though we do not consider ourselves a
        money service business, we may nevertheless be subject to such recordkeeping and reporting
        requirements or similar rules and regulations in the near future.
      </p>
      <p>
        We may combine voluntarily provided and automatically collected personal information with
        general information or research data we receive from other trusted sources. For example, our
        marketing and market research activities may uncover data and insights, which we may combine
        with information about how visitors use our Website to improve our Website and your experience
        on it.
      </p>
      <h2>Security of Your Personal Information</h2>
      <p>
        When we collect and process personal information, and while we retain this information, we will
        protect it within commercially acceptable means to prevent loss and theft, as well as
        unauthorized access, disclosure, copying, use, or modification.
      </p>
      <p>
        Although we will do our best to protect the personal information you provide to us, we advise
        that no method of electronic transmission or storage is 100% secure, and no one can guarantee
        absolute data security.
      </p>
      <p>
        You are responsible for selecting any password and its overall security strength, ensuring the
        security of your own information within the bounds of our services. For example, ensuring any
        passwords associated with accessing your personal information and accounts are secure and
        confidential.
      </p>
      <h2>Children’s Privacy</h2>
      <p>
        We do not aim any of our products or services directly at children under the age of 18, and we do
        not knowingly collect personal information about children under 18.
      </p>
      <h2>Disclosure of Personal Information to Third Parties</h2>
      <p>
        We may disclose personal information to:
      </p>
      <ul>
        <li>a parent, subsidiary, or affiliate of the Company</li>
        <li>third-party service providers for the purpose of enabling them to provide their services,
        including (without limitation) IT service providers, data storage, hosting and server
        providers, analytics, error loggers, debt collectors, maintenance or problem-solving
        providers, professional advisors, and payment systems operators</li>
        <li>our employees, contractors, and/or related entities</li>
        <li>our existing or potential agents or business partners</li>
        <li>credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you
        fail to pay for goods or services we have provided to you</li>
        <li>courts, tribunals, regulatory authorities, and law enforcement officers, as required by law,
        in connection with any actual or prospective legal proceedings, or in order to establish,
        exercise, or defend our legal rights</li>
        <li>third parties, including agents or sub-contractors, who assist us in providing information,
        products, services, or direct marketing to you</li>
        <li>third parties to collect and process data</li>
        <li>an entity that buys, or to which we transfer all or substantially all of our assets and
        business</li>
        <li>With the designated blockchain, we will share your public wallet address and any
        transaction details to facilitate any transaction request you submit via the Website;</li>
        <li>With vendors, consultants and other service providers who need access to such
        information to carry out work on our behalf; it is our practice to require such parties to
        handle the information in a manner consistent with our policies;</li>
        <li>In response to a request for information if we believe disclosure is in accordance with, or
        as otherwise required by, any applicable law, regulation or legal process;</li>
        <li>If we believe your actions are inconsistent with the spirit or language of our user
        agreements or policies, or to protect the rights, property and safety of us or others;</li>
        <li>In connection with, or during negotiations of, any merger, sale of our assets, financing or
        acquisition of all or a portion of our business to another company;</li>
        <li>With your consent or at your direction, including if we notify you through our Website
        that the information you provide will be shared in a particular manner and you provide
        such information.</li>
      </ul>
      <h2>Your Rights and Controlling Your Personal Information</h2>
      <p>
        You have the right to control your personal information. Specifically:
      </p>
      <ul>
        <li>Your choice: If you provide us with Personal Information, you understand we will
        collect, hold, use, and disclose your personal information in accordance with this privacy
        policy. You do not have to provide personal information to us.</li>
        <li>Information from third parties: If we receive personal information about you from a
        third party, we will protect it as set out in this privacy policy. If you are a third party
        providing personal information about somebody else, you represent and warrant that you
        have such person’s consent to provide the personal information to us.</li>
        <li>Marketing permission: If you have previously agreed to us using your personal
        information for direct marketing purposes, you may change your mind at any time by
        contacting us using the details below.</li>
        <li>Access: You may request details of the personal information that we hold about you.</li>
        <li>Correction: If you believe that any information we hold about you is inaccurate, out of
        date, incomplete, irrelevant, or misleading, please contact us using the details provided in
        this privacy policy. We will take reasonable steps to correct any information found to be
        inaccurate, incomplete, misleading, or out of date.</li>
        <li>Non-discrimination: We will not discriminate against you for exercising any of your
        rights over your personal information. Unless your personal information is required to
        provide you with a particular service or offer (for example providing user support), we
        will not deny you goods or services and/or charge you different prices or rates for goods
        or services, including through granting discounts or other benefits, or imposing penalties,
        or provide you with a different level or quality of goods or services.</li>
        <li>Notification of data breaches: We will comply with laws applicable to us in respect of
        any data breach.</li>
        <li>Complaints: If you believe that we have breached a relevant data protection law and
        wish to make a complaint, please contact us using the details below and provide us with
        full details of the alleged breach. We will promptly investigate your complaint and
        respond to you, in writing, setting out the outcome of our investigation and the steps we
        will take to deal with your complaint. You also have the right to contact a regulatory body
        or data protection authority in relation to your complaint.</li>
        <li>Unsubscribe: To unsubscribe from our email database or opt-out of communications
        (including marketing communications), please contact us using the details provided in
        this privacy policy or opt-out using the opt-out facilities provided in the communication.
        We may need to request specific information from you to help us confirm your identity.</li>
      </ul>
      <h2>Business Transfers</h2>
      <p>
        If we or our assets are acquired, or in the unlikely event that we go out of business or enter
        bankruptcy, we would include data, including your personal information, among the assets
        transferred to any parties who acquire us. You acknowledge that such transfers may occur, and
        that any parties who acquire us may, to the extent permitted by applicable law, continue to use
        your personal information according to this policy, which they will be required to assume as it is
        the basis for any ownership or use rights we have over such information.
      </p>
      <h2>Limits of Our Policy</h2>
      <p>
        Our Website may link to external sites that are not operated by us. Please be aware that we have
        no control over the content and policies of those sites and cannot accept responsibility or liability
        for their respective privacy practices.
      </p>
      <h2>Changes to This Policy</h2>
      <p>
        At our discretion, we may change our privacy policy to reflect updates to our business processes,
        current acceptable practices, or legislative or regulatory changes. If we decide to change this
        privacy policy, we will post the changes here at the same link by which you are accessing this
        privacy policy.
      </p>
      <p>
        If the changes are significant, or if required by applicable law, we will contact you (based on
        your selected preferences for communications from us) and all our registered users with the new
        details and links to the updated or changed policy.
      </p>
      <p>
        If required by law, we will get your permission or give you the opportunity to opt in to or opt out
        of, as applicable, any new uses of your personal information.
      </p>

    </div>
    </div>

    <div style={symbolStyle} onClick={navigateHome}>
        <img src={symbol} alt="Home" style={{ width: '50px', height: 'auto' }} />
        <div style={homeTextStyle}>HOME</div>
      </div>
      </>
  );
};

export default Privacy;
