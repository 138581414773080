/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import React, { useRef } from 'react'

export default function Shoes(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/Shoes.glb')
  return (
    <group scale={4} position={[0, -1, 0]}   ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Cylinder004_Material_0001.geometry} material={materials['blinn5SG.002']} />
      <mesh geometry={nodes.Cylinder004_Material_0001_1.geometry} material={materials['blinn1SG.001']} />
      <mesh geometry={nodes.Cylinder004_Material_0001_2.geometry} material={materials.PaletteMaterial001} />
      <mesh geometry={nodes.Cylinder004_Material_0001_3.geometry} material={materials.PaletteMaterial003} />
      <mesh geometry={nodes.Cylinder004_Material_0001_4.geometry} material={materials['blinn2SG.004']} />
      <mesh geometry={nodes.Cylinder004_Material_0001_5.geometry} material={materials['blinn3SG.002']} />
      <mesh geometry={nodes.Cylinder004_Material_0001_6.geometry} material={materials['blinn2SG.001']} />
      <mesh geometry={nodes.Cylinder004_Material_0001_7.geometry} material={materials.PaletteMaterial002} />
    </group>
  )
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/Shoes.glb')
