import {
  AccumulativeShadows,
  Environment,
  OrbitControls,
  RandomizedLight,
  useGLTF
} from '@react-three/drei';
import { Canvas, useLoader } from "@react-three/fiber";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import emailjs from 'emailjs-com';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import * as THREE from "three";
import { RGBELoader } from 'three-stdlib';
//import gif from '../../images/diamondGif.gif';
import axios from 'axios';
import bg from '../../images/w3oDayBg.svg';
import bg2 from '../../images/w3oNightBg.svg';
import { BlurredBackground, IconCheckmark, MessageContainer, SuccessMessage, SuccessMessageContent, SuccessMessageTitle } from '../Global/GlobalElements';
import {
  ExitIcon,
  ExitIconWrap,
  PButton,
  PEmail,
  PExit,
  PName, PPhone,
  PopupMTop,
  PopupMessage
} from './PopUpElements';



THREE.ColorManagement.legacyMode = false

function Diamond(props) {
  const ref = useRef();
  const { nodes, materials } = useGLTF('/diamond.glb');
  // Use a custom envmap/scene-backdrop for the diamond material
  // This way we can have a clear BG while cube-cam can still film other objects
  const texture = useLoader(
    RGBELoader,
    'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr'
  );

  // Check if the browser is Safari
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <group position={[0, -1, 0]}>
      <mesh geometry={nodes.pCone1_DiamondOutside_0.geometry} material={materials.DiamondOutside}>
        {!isSafari && (
     <></>
        )}
      </mesh>
    </group>
  );
}


function WhitelistPopUp({ trigger, setTrigger, isDarkMode }) {
  const whitelistContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (whitelistContainerRef.current && !whitelistContainerRef.current.contains(event.target)) {
        setTrigger(false);
      }
    };

    if (trigger) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [trigger, setTrigger]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (whitelistContainerRef.current && !whitelistContainerRef.current.contains(event.target)) {
        // Clicked outside the UtilitiesContainer, close the pop-up
        setTrigger(false)
      }
    };

    // Add event listener when the pop-up is open
    if (trigger) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Remove event listener when the pop-up is closed
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [trigger, setTrigger]);
    const initialValues = { name: "",  wallet: "", email: "" };
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [wallet, setWallet] = useState('');
    const [errors, setErrors] = useState({
      name: "",
      email: "",
      wallet: "",
    });
     // const [form4Values, setFormValues] = useState(initialValues);
     // const [formErrors, setFormErrors] = useState({});
      const [isSubmit, setIsSubmit] = useState(false);
  
  
      const handleChange = (e) => {
    //    const { name, value } = e.target;
    //    setFormValues({ ...formValues, [name]: value });
      };
  
      const form = useRef();
  
  
  
  
   const sendEmail = (e) => {
// Prevent the default form submission
e.preventDefault();
      //Excel const
      const data = {
        Name: name,
        Wallet: wallet, //WARNING .phone is actually the Wallet string
        Email: email
      };
       // Your EmailJS service ID, template ID, and Public Key
       const serviceId = "newsletterFormService";
       const templateId = "web3o_Whitelist";
       const publicKey = "8y9ziEfWJ76msEZ-8";
     
       // Create a new object that contains dynamic template params
       const templateParams = {
        Name: name,
        Wallet: wallet,
        Email: email,
        to_name: "Web 3 Outfitters",
       };
     
       // Validate the form data
       const validationErrors = validate({ name, email, wallet });
     
       if (Object.keys(validationErrors).length === 0) {
         // No validation errors, proceed to send the email using EmailJS
         emailjs
           .send(serviceId, templateId, templateParams, publicKey)
           .then((response) => {
             console.log('Email sent successfully!', response);
             setIsSubmit(!isSubmit)
             setName('');
             setEmail('');
             setWallet('');
           })
           .catch((error) => {
             console.error('Error sending email:', error);
           });

           axios.post('https://sheet.best/api/sheets/ea47e262-306f-48c3-a16e-d490c3a475cd', data).then((response)=>{
             console.log(response);
           })

     
           // console.log(response);
      
       } else {
         // Handle validation errors (e.g., display error messages to the user)
         console.log('Validation errors:', validationErrors);
         // You can set validationErrors in the state and display them to the user
       }
     };
 
 
 
     const validate = (v) => {
       const errors = {};
     
       // Regular expression for a more comprehensive email validation
       const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
     
       if (!v.name) {
         errors.name = "Name field can't be empty";
       }
       if (!v.email) {
         errors.email = "Email field can't be empty";
       } else if (!emailRegex.test(v.email)) {
         errors.email = "Invalid email address";
       }
     
       if (!v.wallet) {
         errors.wallet = "Wallet field can't be empty";
       }
       setErrors(errors); 
       return errors;
     };
    return (trigger) ? (
      <>
      <BlurredBackground isDarkMode={isDarkMode}/>
      <div ref ={whitelistContainerRef}  >
                 <PopupMessage initial={{ opacity: 0 }}
                 animate={{ opacity: 1, transition: { duration: 1 } }}
                 exit={{ opacity: 0, transition: { duration: 1 } }} ref={form} onSubmit={sendEmail}  style={{ backgroundImage: `url(${isDarkMode ? bg2 : bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                         
                          <PopupMTop isDarkMode={isDarkMode} >
                         GET WHITELISTED
                          </PopupMTop>
                
          

    <PExit  onClick={() => setTrigger(false)}>
<ExitIconWrap
isDarkMode={isDarkMode}
    initial={{scale: trigger ? 0 : 1, rotateZ: trigger ? 180 : 0, }}
          animate={{  scale: trigger ? 1 : 0,rotateZ: trigger ? 180 : 0,  }}
          transition={{ duration: 0.6 }}
        whileHover={{ rotate: 180, transition: { duration: 0.25 } }}
        whileTap={{ rotate: 180, transition: { duration: 0.25 } }}
      >
          <ExitIcon isDarkMode={isDarkMode} whileHover={{ color: isDarkMode ? '#000' : '#fff', backgroundColor: isDarkMode ? '#fff' : '#000' }} />
      </ExitIconWrap>
    </PExit>
                         
                          { isSubmit ? (
                            <>    <PExit  onClick={() => setTrigger(false)}>
<ExitIconWrap
isDarkMode={isDarkMode}
    initial={{scale: trigger ? 0 : 1, rotateZ: trigger ? 180 : 0, }}
          animate={{  scale: trigger ? 1 : 0,rotateZ: trigger ? 180 : 0,  }}
          transition={{ duration: 0.6 }}
        whileHover={{ rotate: 180, transition: { duration: 0.25 } }}
        whileTap={{ rotate: 180, transition: { duration: 0.25 } }}
      >
          <ExitIcon isDarkMode={isDarkMode} whileHover={{ color: isDarkMode ? '#000' : '#fff', backgroundColor: isDarkMode ? '#fff' : '#000' }} />
      </ExitIconWrap>
    </PExit>
                            <MessageContainer >
                        <SuccessMessage initial={isSubmit ? { opacity: 0, scale: 0.8 } : { opacity: 1, scale: 1 }}
                                        animate={isSubmit ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
                                         transition={{ duration: 0.5 }} style={{ backgroundImage: `url(${isDarkMode ? bg2 : bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                          <IconCheckmark initial={isSubmit ? { scale: 0, rotate: 360 } : { scale: 1, rotate: 0 }}
          animate={isSubmit ? { scale: 1, rotate: 0 } : { scale: 0, rotate: 360 }}
          transition={{ duration: 0.5, delay: 0.2 }} viewBox="0 0 76 76" >
                            <circle cx="38" cy="38" r="36" />
                            <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M17.7,40.9l10.9,10.9l28.7-28.7" />
                          </IconCheckmark>
                          <SuccessMessageTitle  initial={isSubmit ? { opacity: 0 } : { opacity: 1 }}
                                                animate={isSubmit ? { opacity: 1 } : { opacity: 0 }}
                                                transition={{ duration: 0.5, delay: 0.4 }}> Message Received </SuccessMessageTitle>

                          <SuccessMessageContent initial={isSubmit ? { opacity: 0 } : { opacity: 1 }}
                                                  animate={isSubmit ? { opacity: 1 } : { opacity: 0 }}
                                                   transition={{ duration: 0.5, delay: 0.6 }}>
                            <p>A confirmation email has been sent!</p>
                          </SuccessMessageContent>
                        </SuccessMessage>
                        </MessageContainer>
                        </>
      ) : (
        <pre></pre>
      )}
                         
                         
                         <PName
                                onChange={(e) => {
                                  setName(e.target.value);
                                  setErrors({ ...errors, name: "" }); // Clear the error when input changes
                                }}
                              value={name}
                              id="name"
                              name="name"
                              type="text"
                              placeholder="*Name"
                              required={true}
                              isDarkMode={isDarkMode}
                            />
                            {errors.name && <span>{errors.name}</span>}
                            
                            <PPhone
                                onChange={(e) => {
                                  setWallet(e.target.value);
                                  setErrors({ ...errors, phone: "" }); // Clear the error when input changes
                                }}
                              value={wallet}
                              id="wallet"
                              name="wallet"
                              type="text"
                              placeholder="*ETH Wallet"
                              required={true}
                              isDarkMode={isDarkMode}
                            />
                            {errors.wallet && <span>{errors.wallet}</span>}
                            <PEmail
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  setErrors({ ...errors, email: "" }); // Clear the error when input changes
                                }}
                              value={email}
                              id="email"
                              name="email"
                              type="text"
                              placeholder="*Email"
                              required={true} 
                              isDarkMode={isDarkMode}
                            />
                            {errors.email && <span>{errors.email}</span>}


                         {/*<PImage src ={gif}/>*/}
                          <Suspense fallback={null}>
                            <Canvas shadows camera={{ position: [-5, 0.5, 5], fov: 45 }}>

                              <ambientLight intensity={0.5} />
                              <spotLight position={[5, 5, -10]} angle={0.15} penumbra={1} />
                              <pointLight position={[-10, -10, -10]} />
                              <Diamond rotation={[0, 0, 0.715]} position={[0, 5, 0]} />

                          
                              <AccumulativeShadows
                                temporal
                                frames={100}
                                color="orange"
                                colorBlend={2}
                                toneMapped={true}
                                alphaTest={0.8}
                                opacity={1}
                                scale={12}
                                position={[0, -0.5, 0]}>
                                <RandomizedLight amount={8} radius={10} ambient={0.5} intensity={1} position={[5, 5, -10]} bias={0.001} />
                              </AccumulativeShadows>
                              <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr" />
                              <OrbitControls makeDefault autoRotate autoRotateSpeed={0.1} minPolarAngle={0} maxPolarAngle={Math.PI / 2} />
                              <EffectComposer>
                                <Bloom luminanceThreshold={1} intensity={2} levels={9} mipmapBlur />
                              </EffectComposer>
                            </Canvas>
                          </Suspense>

    
                          <PButton type='submit' >
                            SEND
                          </PButton>

                      </PopupMessage>
</div>
        </>
    ) : "";
}

export default WhitelistPopUp;




