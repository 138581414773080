// BackgroundColorSetter.js
import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import * as THREE from 'three';

function BackgroundColorSetter({ isDarkMode }) {
  const { scene } = useThree();

  useEffect(() => {
    // Set the scene background color based on isDarkMode
    scene.background = new THREE.Color(isDarkMode ? '#000000' : '#FFFFFF');
  }, [isDarkMode, scene]);

  return null;
}

export default BackgroundColorSetter;
