import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ExitIcon, ExitIconWrap, PExit } from '../../../../../components/Global/GlobalElements';
import {
  blockchainIdMap,
  fetchLiveConversionRates,
  maxPrice,
  renderIconWithPrice,
  tempGeneralData,
  traitsCategories
} from '../../ClosetData/Data';
import BlockchainSelect, {
  categoryIconMap,
  customStatusOption,
  customStatusSingleValue,
  statusCustomStyles,
  statusOptions
} from './CustomSyles'; // Ensure BlockchainSelect is exported from CustomSyles
import {
  ApplyButton,
  ButtonWrapper,
  CategoryContainer,
  CategoryCount,
  CategoryHeader,
  CategoryIcon,
  CategoryInfo,
  CategoryName,
  Checkbox,
  DefaultButton,
  DropdownArrow,
  FilterContainer,
  FilterContent,
  FilterHeader,
  FilterSection,
  FilterTitle,
  HeaderContainer,
  InlineContainer,
  MinMaxContainer,
  MinMaxInput,
  MinMaxWrapper,
  StyledSlider,
  TraitItem,
  TraitName,
  TraitsList
} from './FilterVertElements';

const VertFilter = ({
  onApplyFilter,
  onResetFilter,
  originalData,
  setFilteredData,
  isDarkMode,
  isMobile, // If needed for conditional rendering
  isFilterVisible,
  maxRarity,
  availableTraits,
  onToggleFilter
}) => {
  const [selectedBlockchain, setSelectedBlockchain] = useState({ value: 'eth', label: 'ETH' }); // Define state for blockchain
  const [status, setStatus] = useState('All');

  // Separate slider boundaries and selected values
  const [sliderMin, setSliderMin] = useState(0); // Slider's minimum boundary
  const [sliderMax, setSliderMax] = useState(maxPrice); // Slider's maximum boundary
  const [selectedMinPrice, setSelectedMinPrice] = useState(0); // Selected minimum price
  const [selectedMaxPrice, setSelectedMaxPrice] = useState(maxPrice); // Selected maximum price

  const [currency, setCurrency] = useState('eth'); // Default currency is ETH
  const [minRarity, setMinRarity] = useState(1);
  const [maxRarityValue, setMaxRarityValue] = useState(maxRarity);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTraits, setSelectedTraits] = useState({}); // Store selected traits per category
  const [isTraitsVisible, setIsTraitsVisible] = useState(false); // Track if traits dropdown is open

  const [hoveredDropdown, setHoveredDropdown] = useState(''); // Track which dropdown is hovered
  const [conversionRates, setConversionRates] = useState({});

  const [closeTimeout, setCloseTimeout] = useState(null); // Timeout for closing dropdown
  const [traitsMenuIsOpen, setTraitsMenuIsOpen] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState([]);

  const toggleCategory = (category) => {
    setExpandedCategories((prev) =>
      prev.includes(category) ? prev.filter((c) => c !== category) : [...prev, category]
    );
  };

  const handleTraitToggle = (category, trait) => {
    setSelectedTraits((prevSelectedTraits) => {
      const traitsInCategory = prevSelectedTraits[category] || [];
      if (traitsInCategory.includes(trait)) {
        // Remove trait
        const updatedTraits = traitsInCategory.filter((t) => t !== trait);
        return {
          ...prevSelectedTraits,
          [category]: updatedTraits,
        };
      } else {
        // Add trait
        return {
          ...prevSelectedTraits,
          [category]: [...traitsInCategory, trait],
        };
      }
    });
  };

  // Fetch live conversion rates for blockchains
  useEffect(() => {
    const fetchRates = async () => {
      try {
        const rates = await fetchLiveConversionRates();
        setConversionRates(rates);
      } catch (error) {
        console.error('Error fetching conversion rates:', error);
      }
    };
    fetchRates();
  }, []);

  // Reset filter states to default
  const resetFilters = () => {
    setSelectedBlockchain({ value: 'eth', label: 'ETH' });
    setStatus('All');
    setSelectedMinPrice(0);
    setSelectedMaxPrice(maxPrice); // Reset price to original maxPrice
    setSliderMax(maxPrice); // Reset slider max
    setMinRarity(1);
    setMaxRarityValue(maxRarity);
    setSelectedCategory('');
    setSelectedTraits({});
    onResetFilter();
  };

  useEffect(() => {
    setMaxRarityValue(maxRarity);
    setSelectedMinPrice(0);
    setSelectedMaxPrice(maxPrice);
    setSliderMax(maxPrice);
  }, [maxRarity]);

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const handleCategoryChange = (selectedOption) => {
    const category = selectedOption ? selectedOption.value : '';
    setSelectedCategory(category);
  };

  const handleTraitsChange = (selectedOptions) => {
    setSelectedTraits((prevSelectedTraits) => ({
      ...prevSelectedTraits,
      [selectedCategory]: selectedOptions || [], // Update the correct category
    }));
  };

  const convertPriceCustom = (priceInEth, blockchain) => {
    const ethUSD = conversionRates['ethereum']?.usd || 1;
    const blockchainId = blockchainIdMap[blockchain.toLowerCase()];
    const targetUSD = conversionRates[blockchainId]?.usd || 1;

    if (blockchain.toLowerCase() === 'eth') {
      return priceInEth;
    }

    const priceInTargetCrypto = (priceInEth * ethUSD) / targetUSD;

    if (isNaN(priceInTargetCrypto)) {
      console.warn(`Conversion resulted in NaN for blockchain ${blockchain}`);
      return priceInEth; // Fallback to original price if conversion fails
    }

    return parseFloat(priceInTargetCrypto.toFixed(2));
  };

  const handleBlockchainChange = (selectedOption) => {
    const newBlockchain = selectedOption.value;

    setSelectedBlockchain(selectedOption);

    const updatedData = tempGeneralData.map((item) => {
      const newPrice = convertPriceCustom(parseFloat(item.price), newBlockchain);
      return {
        ...item,
        ethPrice: renderIconWithPrice(`${newPrice.toFixed(2)} ${newBlockchain.toUpperCase()}`, newBlockchain),
        convertedPrice: newPrice,
        chain: newBlockchain,
      };
    });

    const newMaxPrice = Math.ceil(Math.max(...updatedData.map(item => item.convertedPrice || 0)));

    setSliderMax(newMaxPrice);
    setSelectedMaxPrice(newMaxPrice);
    setSelectedMinPrice(0);
    setFilteredData(updatedData);
  };

  useEffect(() => {
    if (conversionRates && conversionRates['ethereum']?.usd) {
      const initialMaxPrice = Math.ceil(Math.max(...originalData.map(item => convertPriceCustom(parseFloat(item.price), selectedBlockchain.value) || 0)));
      setSliderMax(initialMaxPrice);
      setSelectedMaxPrice(initialMaxPrice);
      setSelectedMinPrice(0);
    }
  }, [conversionRates, selectedBlockchain.value, originalData]);

  const handleApplyFilter = () => {
    const filters = {
      blockchain: selectedBlockchain.value,
      status,
      minPrice: selectedMinPrice,
      maxPrice: selectedMaxPrice,
      minRarity,
      maxRarity: maxRarityValue,
      selectedTraits,
    };

    const filtered = originalData.filter((item) => {
      const itemPrice = parseFloat(item.convertedPrice) || 0;
      const matchesBlockchain = item.chain === selectedBlockchain.value;
      const matchesPrice = itemPrice >= selectedMinPrice && itemPrice <= selectedMaxPrice;
      const matchesStatus = status === 'All' || item.status === status;

      return matchesBlockchain && matchesPrice && matchesStatus;
    });

    setFilteredData(filtered);
    onApplyFilter(filters);
    onToggleFilter();
  };

  const formatPrice = (price) => {
    return price <= 10 ? price.toFixed(4) : price.toFixed(2);
  };

  const handleDropdownEnter = (dropdown) => {
    if (closeTimeout) clearTimeout(closeTimeout);
    setHoveredDropdown(dropdown);
  };

  const handleDropdownLeave = () => {
    setCloseTimeout(setTimeout(() => setHoveredDropdown(''), 200));
  };

  return (
    <AnimatePresence style={{zIndex: 999999999999, }}>
      {isFilterVisible && (
        <FilterContainer
          isMobile={isMobile}
          isDarkMode={isDarkMode}
          initial={{ y: '200%', filter: 'blur(15px)' }}
          animate={{ y: 0, filter: 'blur(0)' }}
          exit={{ y: '200%', filter: 'blur(15px)' }}
          transition={{ duration: 0.4, ease: 'easeInOut' }}
        >
          <HeaderContainer isDarkMode={isDarkMode}>
            <FilterHeader isDarkMode={isDarkMode}>ADVANCED FILTER</FilterHeader>
            <PExit 
  style={isMobile ? { top: '80px', transform: 'scale(0.5)' } : { top: '5px',transform: 'scale(0.5)' }}
  isDarkMode={isDarkMode}
  className="close-btn"
  onClick={onToggleFilter}
>


              <ExitIconWrap
                isDarkMode={isDarkMode}
                whileHover={{ rotate: 180, transition: { duration: 0.25 } }}
                whileTap={{ rotate: 180, transition: { duration: 0.25 } }}
              >
                <ExitIcon
                  isDarkMode={isDarkMode}
                  whileHover={{
                    color: isDarkMode ? '#000' : '#fff',
                    backgroundColor: isDarkMode ? '#fff' : '#000',
                  }}
                />
              </ExitIconWrap>
            </PExit>
          </HeaderContainer>

          <FilterContent>
            {/* Blockchain Filter */}
            <FilterSection isDarkMode={isDarkMode}>
              <FilterTitle>Blockchain</FilterTitle>
              <BlockchainSelect
                selectedBlockchain={selectedBlockchain}
                handleBlockchainChange={handleBlockchainChange}
                isDarkMode={isDarkMode}
              />
            </FilterSection>

            {/* Status Filter */}
            <FilterSection isDarkMode={isDarkMode}>
              <FilterTitle>Status</FilterTitle>
              <Select
                options={statusOptions}
                value={status}
                onChange={handleStatusChange}
                placeholder="Select Status"
                components={{ Option: customStatusOption, SingleValue: customStatusSingleValue }}
                styles={statusCustomStyles}
                isSearchable
                isDarkMode={isDarkMode}
              />
            </FilterSection>

            {/* Price Range Slider */}
            <FilterSection isDarkMode={isDarkMode}>
              <FilterTitle>Price Range</FilterTitle>
              <InlineContainer>
                <MinMaxContainer isDarkMode={isDarkMode}>
                  <MinMaxWrapper>
                    <MinMaxInput
                      type="number"
                      step="0.01"
                      value={formatPrice(selectedMinPrice)}
                      onChange={(e) => setSelectedMinPrice(parseFloat(e.target.value))}
                      isDarkMode={isDarkMode}
                    />
                    <StyledSlider
                      min={sliderMin}
                      max={sliderMax}
                      step={0.01}
                      value={[selectedMinPrice, selectedMaxPrice]}
                      onChange={(value) => {
                        setSelectedMinPrice(value[0]);
                        setSelectedMaxPrice(value[1]);
                      }}
                      isDarkMode={isDarkMode}
                    />
                    <MinMaxInput
                      type="number"
                      step="0.01"
                      value={formatPrice(selectedMaxPrice)}
                      onChange={(e) => setSelectedMaxPrice(parseFloat(e.target.value))}
                      isDarkMode={isDarkMode}
                    />
                  </MinMaxWrapper>
                </MinMaxContainer>
              </InlineContainer>
            </FilterSection>

            {/* Rarity Rank Slider */}
            <FilterSection isDarkMode={isDarkMode}>
              <FilterTitle>Rarity Rank</FilterTitle>
              <InlineContainer>
                <MinMaxContainer isDarkMode={isDarkMode}>
                  <MinMaxWrapper>
                    <MinMaxInput
                      type="number"
                      step={1}
                      value={minRarity}
                      onChange={(e) => setMinRarity(Number(e.target.value))}
                      isDarkMode={isDarkMode}
                    />
                    <StyledSlider
                      min={1}
                      max={maxRarity}
                      step={1}
                      value={[minRarity, maxRarityValue]}
                      onChange={(value) => {
                        setMinRarity(value[0]);
                        setMaxRarityValue(value[1]);
                      }}
                      isDarkMode={isDarkMode}
                    />
                    <MinMaxInput
                      type="number"
                      step={1}
                      value={maxRarityValue}
                      onChange={(e) => setMaxRarityValue(Number(e.target.value))}
                      isDarkMode={isDarkMode}
                    />
                  </MinMaxWrapper>
                </MinMaxContainer>
              </InlineContainer>
            </FilterSection>

            {/* Traits Filter */}
            <FilterSection isDarkMode={isDarkMode}>
              <FilterTitle>TRAIT CATEGORIES</FilterTitle>
              {Object.keys(traitsCategories).map((category) => (
                <CategoryContainer key={category} isDarkMode={isDarkMode}>
                  <CategoryHeader
                    onClick={() => toggleCategory(category)}
                    isDarkMode={isDarkMode}
                    isSelected={selectedTraits[category] && selectedTraits[category].length > 0}
                  >
                    <CategoryInfo>
                      <CategoryIcon src={categoryIconMap[category]} alt={`${category} icon`} />
                      <CategoryName
                        isSelected={selectedTraits[category] && selectedTraits[category].length > 0}
                      >
                        {category}
                      </CategoryName>
                      <CategoryCount>({traitsCategories[category].length})</CategoryCount>
                    </CategoryInfo>
                    <DropdownArrow
                      isExpanded={expandedCategories.includes(category)}
                      isDarkMode={isDarkMode}
                    />
                  </CategoryHeader>

                  <AnimatePresence>
                    {expandedCategories.includes(category) && (
                      <TraitsList
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                      >
                        {traitsCategories[category].map((trait) => (
                          <TraitItem
                            key={trait}
                            isDarkMode={isDarkMode}
                            isSelected={
                              selectedTraits[category]
                                ? selectedTraits[category].includes(trait)
                                : false
                            }
                          >
                            <Checkbox
                              checked={
                                selectedTraits[category]
                                  ? selectedTraits[category].includes(trait)
                                  : false
                              }
                              onChange={() => handleTraitToggle(category, trait)}
                              isDarkMode={isDarkMode}
                            />
                            <TraitName>{trait}</TraitName>
                          </TraitItem>
                        ))}
                      </TraitsList>
                    )}
                  </AnimatePresence>
                </CategoryContainer>
              ))}
            </FilterSection>

            <ButtonWrapper>
              <ApplyButton onClick={handleApplyFilter} isDarkMode={isDarkMode}>
                APPLY
              </ApplyButton>
              <DefaultButton onClick={resetFilters} isDarkMode={isDarkMode}>
                RESET
              </DefaultButton>
            </ButtonWrapper>
          </FilterContent>
        </FilterContainer>
      )}
    </AnimatePresence>
  );
};

export default VertFilter;
