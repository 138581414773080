import { motion } from 'framer-motion';
import { FaClipboardList, FaDiscord, FaHome, FaTimes } from 'react-icons/fa';

import { FaHandshake, FaXTwitter } from 'react-icons/fa6';
import { GiClothes, GiWallet } from "react-icons/gi";
import { HiDocumentText } from 'react-icons/hi';
import { MdVerified } from 'react-icons/md';
import { RiGitRepositoryPrivateFill, RiLightbulbFlashFill, RiTeamFill } from 'react-icons/ri';
import { Link as LinkR } from 'react-router-dom';
import styled from 'styled-components';
///
/// Coming Soon Tooltip Elements
///



export const Privacy = styled(RiGitRepositoryPrivateFill)`
    transition: all 0.2s ease-in-out;
      font-size: 1rem;
    color: #fff;
    top: 3px;
    white-space: nowrap;
    position: relative;
    border-radius: 100%;
    box-sizing: border-box;
    padding: 2px 2px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};

 
    @media screen and (max-width: 870px) {
      
        font-size: 1rem;
           }

`

export const Terms = styled(HiDocumentText)`
    transition: all 0.2s ease-in-out;
      font-size: 1rem;
    color: #fff;
    white-space: nowrap;
    top: 3px;
    position: relative;
    border-radius: 100%;
    box-sizing: border-box;
    padding: 2px 2px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {

  font-size: 1rem;
           }

`


export const ComingSoon = styled.div`
  width: 140px;
  height: 60px;
  position: absolute;
  
  top: 50%;
  transform: translate(-10%, 0%) scale(0.75);
  border-style: none;
  object-fit: cover; /* Ensure the image fits the container without overflowing */
  cursor: pointer;
  pointer-events: none;
  border:0;
  padding: 5px;
  pointer-events: none;

  @media screen and (max-width: 1200px) {
  
    transform: scale(0.65);
  }
  @media screen and (max-width: 960px) {

    transform: scale(0.55);
  }
  @media screen and (max-width: 768px) {
   
    transform: scale(0.45);
  }
  &:hover {
  }
`;

export const ComingSoonCont = styled.div`




//top: 15%;
pointer-events: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 999999999;
  text-decoration: none;
  &:hover {
    box-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  }
`;

export const LogoName = styled(motion.div)`
  display: flex;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);

  float: left;

  border-style: none;
  position: absolute;
  border:0;
  pointer-events: none;


  
  z-index: 2;
  left: 0;


  transform-origin: right center; /* Set the transform origin to the left edge */
  //transform: scale(0.85);


`;

export const LogoName2 = styled(motion.div)`
  display: flex;
  align-items: center;
  -webkit-perspective:700px;
  perspective: 700px;
  border-style: none;
  left: 52%;
  transform:  translate(-50%, -50%);
  pointer-events: none;
  float: left;
  position: relative;


  z-index: 3;
  //left: 30px;
  transform-origin: left center; /* Set the transform origin to the left edge */
  //transform: scale(0.85);

`;
export const PopUpText = styled.div`
  height: 100%;
  width: 240px;
  border-style: none;
  cursor: pointer;
  display:inline-block;
  border-style: none;

`;
export const PopUpText2 = styled(motion.div)`
text-align: center;
justify-content: center;
align-items: center;
//position: absolute;
font-size: 1.75rem;
font-weight: 500;
margin-top: 7.5%;
//left: 50%;
//transform: translate(-50%, 50%);
  border-style: none;
  cursor: pointer;
  letter-spacing: -2px;
  border-style: none;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
`;

export const PopUpBox = styled(motion.div)` 
  position: absolute;


  //margin-top: -40%;
  border-style: none;


  //visibility: hidden;

  //background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};

    //color: #fff;
   // background-color: rgba(0, 0, 0, 0.8);
    width: 240px;
    padding: 8px 8px;
    height: 75px;
    padding: 5px 5px;
    border-radius: 20px;
    z-index: 9999999999999999999999;
    white-space: nowrap;


  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 45%;
    //transform: translateX(-50%);
    //top: -10px;
    top: 75px;
    position: absolute;
    white-space: nowrap;
    z-index: 9999999999999;
    border: 10px solid;
    background: initial;
   // transform: rotate(-225deg);
   transform: rotate(180deg);


   border-color: ${({ isDarkMode }) => (isDarkMode ? 'transparent transparent rgba(250, 250, 250, 0.8)' : 'transparent transparent rgba(0, 0, 0, 0.8)')};

  }

  @media screen and (max-width: 768px) {
   // zoom: 0.5;
   opacity: 0;
   display: none;
}

`;

export const PopUpCard = styled.div`
  position: relative;

  z-index: 9999999999999;
`;


///
/// Sidebar Elements
///




export const SidebarContainerWrap = styled.div`
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
    position: fixed;
    transition: opacity 0.5s ease-in-out;
    overflow: scroll;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25); /* Adjust the opacity as needed */
    backdrop-filter: blur(10px); /* Adjust the blur intensity as needed */
    background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(100, 100, 100, .7)')};
    backdrop-filter: ${({ isDarkMode }) => (isDarkMode ? 'blur(10px)' : 'blur(15px)')};
    z-index: ${({ isOpen }) => (isOpen ? '99999' : '0')};
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
   // display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

`;

export const SidebarContainer = styled(motion.div)` 
    overflow: hidden;
    position: fixed;
    width: 20vw; /* Set your desired width */
    min-width: 350px; 
    height: 100%;
   // border-radius: 30px;
    z-index: 99999999;
   // transition: 0.25s ease-in-out;
   // z-index: ${({ isOpen }) => (isOpen ? '9999999' : '0')};
   // opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
   // left: ${({ isOpen }) => (isOpen ? '0' : '-200%')};
    margin-left: 0%;
    //display: ${({ isOpen }) => (isOpen ? 'initial' : 'none')};
    //transform: translateX(-50%) translateY(1%);
    //background-image: url(SO_texture_bg.jpg);
    background-repeat: repeat;
    
    box-shadow: ${({ isOpen }) =>
        isOpen ? '0 0 60px rgba(0, 0, 0, 0.5)' : 'none'}; /* 3x larger blur radius */
    @media screen and (max-width: 1024px)  {
      width: 100vw;
      min-width: 100%; 
    }

`;



//Close Icon

// Individual Strip
export const Strip = styled.div`
  height: 2px;
  border-radius: 2px;
  background: rgb(56, 56, 56);
  margin: 5px;
  transition: all 0.55s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 30px;




`;

// Burger Container
export const Burger = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 360px;
  position: fixed;
    top: 5%;
  //padding: 8px;
  background: white;
  box-shadow: rgba(136, 165, 191, 0.48) 3px 1px 8px 0px, rgba(255, 255, 255, 0.8) -3px -1px 8px 0px;
  cursor: pointer;
  display: flex;
  
  justify-content: center;
  align-items: center;
  //padding: 16px;
  transition: all 0.5s ease;

  display: flex;
    position: fixed;
    opacity: 1;
    right: 3%;
    top: 5%;
  ${Strip} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(2px 2px 2px white);
    }

  & :hover  {
    ${Strip} {
      &:nth-child(1) {
        transform: translateY(7.5px) scale(0.5);
       // width: 25px;
      }

    &:nth-child(2) {
       opacity: 0;
    }
    &:nth-child(3) {
      transform: translateY(-7.5px) scale(0.5);
       // width: 25px;
    }
}
    }
    @media screen and (max-width: 768px)  {
       top: 5%;
       transform: scale(1);
       right: 5%;
    }

    

`;

// Burger Strip
export const BurgerStrip = styled.div`
  transition: all 0.5s ease;

`;





// Hover Effect for Burger Strip
BurgerStrip.div = styled.div`
  margin: 10px auto;
`;

// Hover Effect - First Child
export const HoverEffectFirstChild = styled(Strip)`
  transform: translateY(13px);
  width: 25px;
  opacity: 0;

  ${Burger}:hover & {
    opacity: 1;
  }
`;

// Hover Effect - Second Child
export const HoverEffectSecondChild = styled(Strip)`
  opacity: 0;
`;

// Hover Effect - Last Child
export const HoverEffectLastChild = styled(Strip)`
  transform: translateY(-13px);
  width: 25px;
  opacity: 0;

  ${Burger}:hover & {
    opacity: 1;
  }
`;




// COLORS
const softOrange = '#F4A259';
const tomatoRed = '#F25C66';
const mediumBlue = '#1E272D';

export const Body = styled.body`
  background-color: ${mediumBlue};
`;

export const CloseContainer = styled.div`

    width: 48px;
    height: 48px;
    position: absolute;
    top: 1.2rem;
    right: 2.5rem;
    background: transparent;
    @media screen and (max-width: 870px) {
          //transform: scale(0.7);
          zoom: 0.75;
          right: 1.5rem;
          top: 0.75rem;
 
    }


`;

export const LeftRight = styled.div`
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;

  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;

  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
`;

export const RightLeft = styled.div`
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;

  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
`;

export const Label = styled.label`
 color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  font-family: Helvetica, Arial, sans-serif;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease-in;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
`;

export const Close = styled.div`
  margin: 60px 0 0 5px;
  position: absolute;
`;

export const CloseContainerHover = styled(CloseContainer)`
  &:hover ${LeftRight} {
    transform: rotate(-45deg);
    background-color: ${tomatoRed};
  }

  &:hover ${RightLeft} {
    transform: rotate(45deg);
    background-color: ${tomatoRed};
  }

  &:hover ${Label} {
    opacity: 1;
  }
`;


export const CloseIcon = styled(FaTimes)`
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    cursor: pointer;
    &:hover  {
    transition: all 0.2s ease-in-out;

    filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 0px white)' : 'drop-shadow(0 0 0px black)')};
    //backdrop-filter: blur(5px);
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};


  
  }
  @media screen and (max-width: 870px) {
          //transform: scale(0.7);
          zoom: 0.65;
          margin-bottom: 20px;
 
    }
  
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;

    right: 1.5rem;
    background: transparent;
    font-size: 3rem;
    cursor: pointer;
    outline: none;
`;


export const SidebarMenu = styled.div`

   position: absolute;
   top: 17%;
   left: 50%;
   transform: translate(-50%);
   transition: 0.3s ease-in-out;
  // transform-origin: left;



  @media screen and (max-width: 900px) {
 
  }
  @media screen and (max-width: 800px) {

  }
  @media screen and (max-width: 700px) {

  }
  @media screen and (max-width: 600px) {
  
  }
  @media screen and (max-width: 500px) {
    //transform: translate(-50%) matrix(1.5, 0, 0, 1.25, 0, 0);
    transform: translate(-50%);
   // top: 20%;
    left: 50%;
  }
   /*
    @media screen and (max-width: 1100px) {
          transform: scale(0.8);
          //left: 0%;
       
    }
    @media screen and (max-width: 870px) {
      //position: relative;
      left: 50%;
          transform: scale(0.6) translateX(-50%);
         // margin-left: 0%;
        
    }
    @media screen and (max-width: 768px) {
      //position: relative;
          //transform: scale(0.35);
          transform: scale(0.35) translateX(-10%);
          top: 15%;
         // left: -30%;
        
    }
    */


`;


export const LineBreak = styled.hr`
`

export const SideBtnWrap = styled(motion.div)`
    display: flex;
  
    text-decoration: none;
    white-space: nowrap;
 

    filter: drop-shadow(0 0 0px white);

    &:hover {
       // zoom: 1.02;
       transform: scale(1.02);
       transition: 0.2 ease-in-out;
      // filter: drop-shadow(0 10px 20px white);
    }

 

  
`;

export const SideBtnImg = styled.img`
    width: 150px;
    height: 150px;
    position: absolute;
    gap: 100px;
    cursor: pointer;
   // margin-top: 50px;

`



export const Dao = styled(FaHandshake)`
  margin-right: 25px;
  font-size: 2rem;
    top: 7px;
    transition: all 0.2s ease-in-out;
    padding: 6px 6px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {
  //top: 45px;
           }



`


export const Home = styled(FaHome)`
  margin-right: 25px;
  font-size: 2rem;
    top: 7px;
    transition: all 0.2s ease-in-out;
    padding: 6px 6px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {
  //top: 45px;
           }



`

export const Meet = styled(RiTeamFill)`
  margin-right: 25px;
  font-size: 2rem;
    top: 7px;
    transition: all 0.2s ease-in-out;
    padding: 6px 6px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {
  //top: 45px;
           }



`

export const Masterplan = styled(RiLightbulbFlashFill)`
  margin-right: 25px;
  font-size: 2rem;
    top: 7px;
    transition: all 0.2s ease-in-out;
    padding: 6px 6px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {
  //top: 45px;
           }



`



export const Discord = styled(FaDiscord)`
    margin-right: 25px;
    font-size: 2rem;
    color: #fff;
    top: 9px;
    transition: all 0.2s ease-in-out;
    padding: 6px 6px;
    position: relative;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
 
    @media screen and (max-width: 870px) {
 // top: 45px;
           }
`

export const X = styled(FaXTwitter)`
  margin-right: 25px;
  font-size: 2rem;
    top: 7px;
    padding: 6px 6px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.2s ease-in-out;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {
  //top: 45px;
           }


`



export const Verified = styled(MdVerified)`
    font-size: 24px; 
    color: #68ceff;
    

`
export const Whitelist = styled(FaClipboardList)`
  margin-right: 25px;
  font-size: 2rem;
    top: 7px;
    padding: 6px 6px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    @media screen and (max-width: 870px) {
  //top: 45px;
           }
  
`
export const SidebarImgWrap = styled.div`


`
export const SidebarRoute =  styled(motion.a)`
    border-radius: 50px;
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    font-weight: 900;
    word-spacing: 5px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
    padding: ${({big}) => (big ? '10px 40px' : '10px 15px')} ;
    color: #000;
    letter-spacing: -2px;
    cursor: pointer;
   // transform: matrix(1.2, 0, 0, 1, 0, 0);
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    
    margin-top: 5px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    //background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};


    &:hover  {
    transition: all 0.2s ease-in-out;
    //backdrop-filter: blur(5px);
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};


    ${Whitelist} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      filter: drop-shadow(2px 2px 2px white);
    }
    ${Meet}{
      /* styles for the container when button is hovered */
     // background-color: #000;
     background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')}; 
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
     filter: drop-shadow(2px 2px 2px white);
    }
    ${Home}{
      /* styles for the container when button is hovered */
     // background-color: #000;
     background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')}; 
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
     filter: drop-shadow(2px 2px 2px white);
    }
    ${Masterplan} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      filter: drop-shadow(2px 2px 2px white);
    }

    ${Dao} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      filter: drop-shadow(2px 2px 2px white);
    }

    
  
  }
  @media screen and (max-width: 500px) {
    margin-top: -10px;
    transform:" matrix(1.2, 0, 0, 1, 0, 0) "scale(0.85);
    }
`;

export const SidebarRoute2 =  styled(motion.a)`
    border-radius: 50px;
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    font-weight: 900;
    word-spacing: 5px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
    padding: ${({big}) => (big ? '10px 40px' : '10px 15px')} ;
    color: #000;
    letter-spacing: -2px;
    cursor: pointer;
   // transform: matrix(1.2, 0, 0, 1, 0, 0);
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    
    margin-top: 5px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    //background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};


    &:hover  {
    transition: all 0.2s ease-in-out;
    //backdrop-filter: blur(5px);
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};


    ${Whitelist} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
      color: #fff;
      filter: drop-shadow(2px 2px 2px white);
    }
    ${Meet}{
      /* styles for the container when button is hovered */
     // background-color: #000;
     background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  
    filter: drop-shadow(2px 2px 2px white);
    }
    ${Home}{
      /* styles for the container when button is hovered */
     // background-color: #000;
     background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  
    filter: drop-shadow(2px 2px 2px white);
    }

    ${Masterplan} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
      color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    
      filter: drop-shadow(2px 2px 2px white);
    }

    ${Dao} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
      color: #fff;
      filter: drop-shadow(2px 2px 2px white);
    }

    
  
  }
  @media screen and (max-width: 500px) {
    margin-top: -10px;
    transform:" matrix(1.2, 0, 0, 1, 0, 0)" scale(0.85);
    }
`;



export const TopContainer = styled(LinkR)`
  //width: 100%;

  margin-left: 35%;
  //transform: translate(-50%, 0px);


  @media screen and (max-width: 870px) {
      //zoom: 0.7;
      //margin-top: 2%;
      transform: translate(-50%, -75px);
      zoom: 0.825;
      //top: -7.5%;
   
    }
`;



export const TopLogo = styled.img`


  height: 250px;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  zoom: 1.5;
  color: black;
  letter-spacing: -2px;
 

`;

export const TopIcon = styled.img`
  width: 55px;
  height: 55px;
  line-height: 55px;

  margin-top: 1%;
  position: relative;

    @media screen and (max-width: 870px) {
   
      margin-top: 5%;
    }
`;


export const BottomLeftContainer = styled.div`
  position: fixed;
  left: 5%;
  pointer-events: all;
  cursor: pointer;
  bottom: 5%;
  width: 100%;
  padding: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  @media screen and (max-width: 870px) {
          bottom: 20%;
    }
`;

export const FullWidthText = styled.p`
  white-space: nowrap;
  position: relative;
  top: 10px;
  flex: 1 1 0%;
  font-size: 12px;
  line-height: 1.5em;
  color: black;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  & b {
    font-weight: bold;
  }
  @media screen and (max-width: 870px) {
          //transform: scale(0.7);
          zoom: 0.85;
    }
`;

export const CenteredTitle = styled.p`
  font-family: 'Antonio', sans-serif;
  //flex: 1 1 0%;
  position: absolute;

  font-size: 16px;
  font-weight: 700;
  line-height: 1em;
  text-align: center;
  color: black;
  letter-spacing: -0.5px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5%;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  @media screen and (max-width: 870px) {
          left: 75%;
          bottom: 20%;
          zoom: 0.75;
    }
`;

export const PrivacyTosContainer = styled(motion.div)`
   position: absolute;
   top: 80%;
   left: 50%;
   transform: translate(-50%, -50%);
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   text-align: center;
   gap: 50px;
   transition: transform 0.3s ease-in-out;
   
white-space: nowrap;
   @media screen and (max-width: 500px) {
   //  top: 77.5%;
     left: 50%;
    // transform: translate(-50%, -50%);
   }
`;




export const BottomLink = styled.a`
 // flex: 1 1 0%;
  font-size: 15px;


  line-height: 1em;
  text-align: right;
  color: black;
  text-decoration: none;
  width: 100%;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  &:hover  {
    transition: all 0.2s ease-in-out;
    //backdrop-filter: blur(5px);
   // color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};


    ${Privacy} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
      color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    
      filter: drop-shadow(2px 2px 2px white);
    }
    ${Terms}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      filter: drop-shadow(2px 2px 2px white);
    }

  
  }
  @media screen and (max-width: 870px) {
  top: 15px;
 // font-size: 1px;

           }
`;




export const LeftSpacer = styled.div`
   left: 50%;
  transform: translateX(-50%);
  position: absolute;
  
`;


export const SocialMediaWrap = styled(motion.div)`
    position: absolute;

    width: 100%;

    bottom: 2.5%;
    //adisplay: flex;

    //width: 300px;
    @media screen and (max-width: 768px) {
       
       margin-top: 50px;
   
    
       }
       @media screen and (max-width: 500px) {
       
       bottom: 0.5%;
  //display: flex;
      // width: 110%;
    
       }
 
`;

export const RoundedSocialButtons = styled(motion.div)`
  text-align: center;
  white-space: nowrap;
  @media screen and (max-width: 500px) {
    transform:  scale(1);
    
    
       }
`;

export const SocialButton = styled(motion.a)`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  //margin-right: 0.25rem;
  margin-bottom: 1rem;
  transform: scale(1) rotate(0deg); /* Initial state */
  margin-right: 0.35rem;
  margin-left: 0.35rem;
  &:hover,
  &:focus {
    transform: scale(1) rotate(360deg); /* Rotate on hover */
  }
  @media screen and (max-width: 500px) {
  //  margin-right: -0.2rem;
    
    
       }
`;

// Define other styled components here...


export const FacebookButton = styled(SocialButton)`
  background: #3b5998;
  &:hover,
  &:focus {
    color: #3b5998;
    background: #fefefe;
    border-color: #3b5998;
  }
`;

export const TwitterButton = styled(SocialButton)`
  background: #55acee;
  &:hover,
  &:focus {
    color: #55acee;
    background: #fefefe;
    border-color: #55acee;
  }
`;

export const LinkedinButton = styled(SocialButton)`
  background: #007bb5;
  &:hover,
  &:focus {
    color: #007bb5;
    background: #fefefe;
    border-color: #007bb5;
  }
`;

export const RedditButton = styled(SocialButton)`
  background: #ff5700; /* Reddit's color */
  &:hover,
  &:focus {
    color: #ff5700;
    background: #fefefe;
    border-color: #ff5700;
  }
`;

export const YoutubeButton = styled(SocialButton)`
  background: #bb0000; /* YouTube's color */
  &:hover,
  &:focus {
    color: #bb0000;
    background: #fefefe;
    border-color: #bb0000;
  }
`;


export const DiscordButton = styled(SocialButton)`
  background: #5865f2; /* Discord's color */
  &:hover,
  &:focus {
    color: #5865f2;
    background: #fefefe;
    border-color: #5865f2;
  }
`;

export const XButton = styled(SocialButton)`
  background: #000; /* X's (formerly Twitter) color */
  &:hover,
  &:focus {
    color: #000;
    background: #fefefe;
    border-color: #000;
  }
`;
export const InstagramButton = styled(SocialButton)`
  background: #125688;
  &:hover,
  &:focus {
    color: #125688;
    background: #fefefe;
    border-color: #125688;
  }
`;



export const TikTokButton = styled(SocialButton)`
  background: #000; /* TikTok's color */
  &:hover,
  &:focus {
    color: #000;
    background: #fefefe;
    border-color: #000;
  }
`;

export const TopLine = styled.hr`
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
    position: relative;
    top: 12.5%;
`;

export const BottomLine = styled.hr`
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
   //position: fixed;
   position: relative;
   top: 82.5%;
`;




export const TextSpan = styled.span`
    color: #fff;
    padding: 0px 0px 0px 15px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    @media screen and (max-width: 768px) {

    }
`



export const ButtonText = styled.div`
   position: relative; /* Added position: relative */
   transition: transform 0.2s ease-in-out;
   // transform: ; /* Adjust the value as per your requirements */
    padding: 0px 0px 0px 0px;
    //transform: matrix(0,0,0,0,0,0);

    transform: translateY(-12px) matrix(1, 0, 0, .8, 0, 0);
    @media screen and (max-width: 768px) {
    
    }
`



const black = "#000";
const white = "#fff";
const silver = "#c0c0c0";

export const SidebarButtonWrap = styled.li`
   position: absolute;
   width: 100%;
    top: 62.5%;
    transform: translate(-50%, -50%) scale(0.85);
    display: inline-flex;
    flex-direction: column;
    gap: 3em;
    justify-content: center;
    align-items: center;
    left: 50%;

`
export const Closet = styled(motion.a)`
 //width: 100%;
 color: ${({ isDarkMode }) => (isDarkMode ? `#000` : `#fff`)};
   & :hover + ${PopUpBox} {



  }
  @media screen and (max-width: 1200px) {
    margin-left: -100px;
       margin-right: -100px;
      }

      @media screen and (max-width: 768px) {
   
        margin-left: -125px;
       margin-right: -125px;
        
    }
      
`;

export const Wallet = styled(motion.a)`
margin-top: 25px;
color: ${({ isDarkMode }) => (isDarkMode ? `#000` : `#fff`)};
//width: 90%;
cursor: pointer;



      
`;


export const ClosetIcon = styled(GiClothes)`
  //background-color: #fff;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   
  //border-radius: 100%;
  padding: 8px; /* Adjust the padding as per your requirements */
  margin-top: 5px;
  margin-left: 20px;
  transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
  font-size: 40px; /* Adjust the font size as per your requirements */

    //display: flex;
    &:hover {
   // background-color: #000;

   filter: drop-shadow(0 0 10px white);

}
@media screen and (max-width: 1024px) {
      margin-left: 20%;
       padding: 0px;
       border-radius: 0%;
     background-color: none;
     transform: translate(-50%, 12.5px) matrix(1, 0, 0, 1.2, 0, 0);
     background: none;
    }
`;

export const WalletIcon = styled(GiWallet)`
  //background-color: #fff;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   
  //border-radius: 100%;
  padding: 8px; /* Adjust the padding as per your requirements */
  margin-top: 5px;
  margin-left: 20px;
  transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
  font-size: 40px; /* Adjust the font size as per your requirements */

    //display: flex;
    &:hover {
   // background-color: #000;
   //filter: drop-shadow(0 0 5px white);

}
@media screen and (max-width: 1024px) {
      margin-left: 20%;
       padding: 0px;
       border-radius: 0%;
     background-color: none;
     transform: translate(-50%, 12.5px) matrix(1, 0, 0, 1.2, 0, 0);
     background: none;
    }
`;

export const BubblyButton = styled.button`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -1px;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
      display: inline-block;
      //zoom: 1.25;
      -webkit-appearance: none;
      appearance: none;


      background-color: ${({ isDarkMode }) => (isDarkMode ? white : black)};
      
      border-radius: 4px;
      border: none;
      cursor: pointer;
      position: relative;
      transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
      box-shadow: 0 2px 25px rgba(192, 192, 192, 0.5);
      width: 200px;
      height: 50px;
      &:focus {
        outline: 0;
      }



      &:active {

        transform: scale(0.9);
        background-color: ${black};
        box-shadow: 0 2px 25px rgba(192, 192, 192, 0.2);
  }
`;







export const AnimateBubbles = styled(BubblyButton)`


    cursor: pointer;
    display: inline-block;
   
    width: 240px;
    height: 65px;
    border-radius: 90px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */
    background-color: #000;
    color: #000;



  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  /*
  &:before {
    background-color: initial;
    background-image: ${({ isDarkMode }) =>
      isDarkMode
        ? 'linear-gradient(#A9A9A9 0, rgba(0, 0, 0, 0) 100%)' // Black background
        : 'linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%)' // Original white background
    };
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
  }


*/
  
  &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${ClosetIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      color: #fff;

      filter: drop-shadow(0 0 10px white);
      //filter: drop-shadow(2px 2px 2px white);
    }
    ${WalletIcon} {
      /* styles for the container when button is hovered */
   
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      color: #fff;
      filter: ${({ isDarkMode }) => (isDarkMode ? ' filter: drop-shadow(0 0 10px black);' : ' filter: drop-shadow(0 0 10px white);')};

      //filter: drop-shadow(0 0 10px white);
      //filter: drop-shadow(2px 2px 2px white);
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }
  }


  @media screen and (max-width: 768px) {
    /*
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    */
    &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${WalletIcon} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : 'none')};
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${ClosetIcon} {
      /* styles for the container when button is hovered */
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }

  }
  }
  @media screen and (max-width: 768px) {
          //transform: scale(0.7);
          //top: 10px;
       
          //left: 75%;
    }



`;





export const SidebarLink = styled(motion.div)`

    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #000;
    width: 300px;
    height: 50px;
    font-size: 1.35rem;
    border-radius: 50px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 1.35rem;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */




  position: absolute;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  background-color: ${({ isDarkMode }) => (isDarkMode ? `#fff` : `#000`)};

  cursor: pointer;

  &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);

${WalletIcon} {

  box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
  filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 5px black)' : 'drop-shadow(0 0 5px white)')};

}
${ClosetIcon} {

  box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
  filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 5px black)' : 'drop-shadow(0 0 5px white)')};

}
${TextSpan}{
  /* styles for the container when button is hovered */
 // background-color: #000;

 // filter: drop-shadow(0px 0px 10px black);
 box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
 filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 5px black)' : 'drop-shadow(0 0 5px white)')};

}

}

`

///
/// Sidebar Privacy Tos
///

