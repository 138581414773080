import { useFrame } from '@react-three/fiber';
import { editable as e } from '@theatre/r3f';
import React, { useRef } from 'react';
import * as THREE from 'three';
import { Ringlight } from './Ringlight';
import { SpotlightModel } from './Spotlight';
const Lights = ({ rotation = [0, 0, 0], radius = 15, isMobile, isDarkMode, lights, meshes, mannequinRef }) => {
  // References for the spotlights and ring lights
  const lightRefs = useRef([...Array(6)].map(() => React.createRef()));

  // References for the SpotlightModel and Ringlight instances
  const modelRefs = useRef([...Array(6)].map(() => React.createRef()));

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();

    lightRefs.current.forEach((lightRef, index) => {
      const modelRef = modelRefs.current[index];
      if (modelRef.current) {
        // Calculate angle for each model; alternating spotlights and ringlights
        const angle = (index / 6) * Math.PI * 2 + time * 0.1; // Adjust time * 0.1 for rotation speed
        const x = radius * Math.cos(angle);
        const z = radius * Math.sin(angle);

        // Update model position same as the calculated light position
        modelRef.current.position.set(x, 5, z);

        // Make the model look at the mannequin
        if (mannequinRef && mannequinRef.current) {
          const mannequinPosition = mannequinRef.current.getWorldPosition(new THREE.Vector3());
          modelRef.current.lookAt(mannequinPosition);
        }
      }
    });
  });

  return (
 
    <group rotation={rotation}>
      <e.group
        theatreKey="SpotlightModel1"
        ref={modelRefs.current[0]}
        position={[radius, 5, 0]}
        isDarkMode={isDarkMode}
      >

        <SpotlightModel isMobile={isMobile} lights={lights} meshes={meshes} mannequinRef={mannequinRef} isDarkMode={isDarkMode} />

      </e.group>

      <e.group
        theatreKey="Ringlight1"
        ref={modelRefs.current[1]}
        position={[radius * Math.cos(Math.PI / 3), 5, radius * Math.sin(Math.PI / 3)]}
        isDarkMode={isDarkMode}
      >
        <Ringlight isMobile={isMobile}  lights={lights} meshes={meshes} mannequinRef={mannequinRef} isDarkMode={isDarkMode} />
      </e.group>

      <e.group
        theatreKey="SpotlightModel2"
        ref={modelRefs.current[2]}
        position={[
          radius * Math.cos((2 * Math.PI) / 3),
          5,
          radius * Math.sin((2 * Math.PI) / 3),
        ]}
        isDarkMode={isDarkMode}
      >
        <SpotlightModel isMobile={isMobile}  lights={lights} meshes={meshes} mannequinRef={mannequinRef} isDarkMode={isDarkMode} />
      </e.group>

      <e.group
        theatreKey="Ringlight2"
        ref={modelRefs.current[3]}
        position={[radius * Math.cos(Math.PI), 5, radius * Math.sin(Math.PI)]}
        isDarkMode={isDarkMode}
      >
        <Ringlight isMobile={isMobile}  lights={lights} meshes={meshes} mannequinRef={mannequinRef} isDarkMode={isDarkMode} />
      </e.group>

      <e.group
        theatreKey="SpotlightModel3"
        ref={modelRefs.current[4]}
        position={[
          radius * Math.cos((4 * Math.PI) / 3),
          5,
          radius * Math.sin((4 * Math.PI) / 3),
        ]}
        isDarkMode={isDarkMode}
      >
        <SpotlightModel isMobile={isMobile}  lights={lights} meshes={meshes} mannequinRef={mannequinRef} isDarkMode={isDarkMode} />
      </e.group>

      <e.group
        theatreKey="Ringlight3"
        ref={modelRefs.current[5]}
        position={[
          radius * Math.cos((5 * Math.PI) / 3),
          5,
          radius * Math.sin((5 * Math.PI) / 3),
        ]}
        isDarkMode={isDarkMode}
      >
        <Ringlight isMobile={isMobile}  lights={lights} meshes={meshes} mannequinRef={mannequinRef} isDarkMode={isDarkMode} />
      </e.group>
    </group>

  );
};

export default Lights;
