import { motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';
//import '../../model.css';

import InfoPopup from '../InfoCardPopup/InfoPopup';
import {
  CollectionHeaderWrapper,
  FeaturedListCard,
  FeaturedListDescription,
  FeaturedRow,
  ListCard,
  ListCollectionSubheader,
  ListCollectionTitle,
  ListDescription,
  ListImage,
  ListItem,
  ListOwner,
  ListPrice,
  ListTitle,
  PlusButton,
  RarityMark,
  SmallText,
  ViewDropsButton
} from './ListElements.js';

const GridItemWrapper = styled.div`
  padding: 5px 0; /* Reduce padding to minimize space */
  box-sizing: border-box;
  margin: 0; /* Remove margin to bring items closer */
`;


const AnimatedCollectionWrapper = styled(motion.div)`
  width: ${({ isMobile }) => (isMobile ? '100%' : '90%')};
  float: left;
  height: 85vh;
  transform: translateX(-50%) !important;
  left: ${({ shouldAdjustForFilter }) => (shouldAdjustForFilter ? '58.5%' : '50%')}; 
  bottom: 0;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 9;
`;


export const MainListView = ({ isDarkMode, shouldAdjustForFilter,tempGeneralData, isFilterVisible }) => {
  const isMobile = window.innerWidth <= 600;
  const [selectedCard, setSelectedCard] = useState(null);

  const listRef = useRef();

  const featuredMain = tempGeneralData[0];
  const otherMains = tempGeneralData.slice(1);

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleClosePopup = () => {
    setSelectedCard(null);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'blur-background') {
      handleClosePopup();
    }
  };

  const renderRow = (index) => {
    let content;

    if (index === 0) {
      // Collection Header
      content = (
        <GridItemWrapper key="header">
        <CollectionHeaderWrapper isMobile={isMobile}>
        <ListCollectionTitle isDarkMode={isDarkMode}>Our Collection</ListCollectionTitle>
        <ListCollectionSubheader isDarkMode={isDarkMode}>
          Explore the latest and greatest
        </ListCollectionSubheader>
      </CollectionHeaderWrapper>
      </GridItemWrapper>
      );
    } else if (index === 1) {
      // Featured List Card
      content = (
        <GridItemWrapper style={{marginTop: '50px'}} key="card">
        <FeaturedListCard isDarkMode={isDarkMode}>
          <FeaturedRow>
            <ListImage src={featuredMain.logo} alt="Featured List Logo" />
            <ListTitle>{featuredMain.header}</ListTitle>
            <FeaturedListDescription>{featuredMain.name}</FeaturedListDescription>
            <FeaturedListDescription>{featuredMain.description}</FeaturedListDescription>
          </FeaturedRow>
          <FeaturedRow>
            <ListPrice>{featuredMain.ethPrice} </ListPrice>
            <ListOwner>
              Owner: <a href={`https://etherscan.io/address/${featuredMain.owner}`} style={{ color: 'lightblue' }}>{featuredMain.owner}</a>
            </ListOwner>
            <RarityMark rarity={featuredMain.rarityLevel}>Rarity: &nbsp; #{featuredMain.rarity}</RarityMark>
            <FeaturedListDescription>Floor Difference: {featuredMain.floorDifference}</FeaturedListDescription>
            <FeaturedListDescription>Last Sold: {featuredMain.lastSold} </FeaturedListDescription>
            <FeaturedListDescription>Listed Time: {featuredMain.listedTime}</FeaturedListDescription>
          </FeaturedRow>
          <FeaturedRow>
            <ViewDropsButton isDarkMode={isDarkMode}>View Latest Drops</ViewDropsButton>
          </FeaturedRow>
        </FeaturedListCard>
        </GridItemWrapper>
      );
    } else {
      const adjustedIndex = index - 2;
      const main = otherMains[adjustedIndex];
      if (!main) return null;

      content = (
        <GridItemWrapper key={main.id}>
          <ListCard shouldAdjustForFilter={shouldAdjustForFilter} isMobile={isMobile} isDarkMode={isDarkMode}>
            <ListItem>
              <ListImage src={main.logo} alt="List Logo" />
            </ListItem>
            <ListItem>
              <ListOwner>
                Owner: <a href={`https://etherscan.io/address/${main.owner}`} style={{ color: 'lightblue' }}>{main.owner}</a>
              </ListOwner>
            </ListItem>
            <ListItem>
              <SmallText>RARITY</SmallText>
            </ListItem>
            <ListItem>
              <RarityMark rarity={main.rarityLevel}>#{main.rarity}</RarityMark>
            </ListItem>
            <ListItem style={{whiteSpace: 'nowrap'}}>
              <ListPrice>{main.ethPrice} </ListPrice>
            </ListItem>
            <ListItem>
              <ListDescription>Floor Difference: {main.floorDifference}</ListDescription>
            </ListItem>
            <ListItem>
              <ListDescription>Last sale: {main.lastSold} </ListDescription>
            </ListItem>
            <ListItem>
              <ListDescription>{main.listedTime}</ListDescription>
            </ListItem>
            <ListItem>
              <PlusButton onClick={() => handleCardClick(main)}>+</PlusButton>
            </ListItem>
          </ListCard>
        </GridItemWrapper>
      );
    }

    return content;
  };

  const itemCount = otherMains.length + 2; // Header + Featured item + other items


  return (
    <>
      {selectedCard && (
        <InfoPopup
          selectedCard={selectedCard}
          handleClosePopup={handleClosePopup}
          handleOutsideClick={handleOutsideClick}
          isDarkMode={isDarkMode}
        />
      )}

      <AnimatedCollectionWrapper
        isMobile={isMobile}
        shouldAdjustForFilter={shouldAdjustForFilter}
        initial={{ width: '90%' }}
        animate={{ width: (shouldAdjustForFilter && !isMobile) ? (shouldAdjustForFilter ? '72.5%' : '90%') : '90%' }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
 <Virtuoso
  ref={listRef}
  style={{
    height: isMobile ? window.innerHeight : window.innerHeight,
    width: isMobile
      ? window.innerWidth
      : shouldAdjustForFilter
      ? window.innerWidth * 0.725 // Adjust for 72.5% width when shouldAdjustForFilter is true
      : window.innerWidth * 0.9,   // Default to 90% width otherwise
      overflowX: 'hidden',
  }}
  totalCount={itemCount}
  itemContent={(index) => renderRow(index)}
  computeItemKey={(index) => `item-${index}`}
  initialItemCount={30}  /* Render only 50 items initially */
  overscan={5}  /* Set a moderate overscan to avoid excessive rendering */
  increaseViewportBy={{ top: 0, bottom: 500 }}  /* Reduce the amount of pre-rendered items */
/>

      </AnimatedCollectionWrapper>
    </>
  );
};
