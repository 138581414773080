import React from 'react';
import { useNavigate } from 'react-router-dom';
import symbol from '../../images/w3oBlackLogo.png';
const Tos = ({ isDarkMode }) => {
  const navigate = useNavigate(); // Hook to navigate

  // Adjusted styles for mobile friendliness and centering
  // Adjusted styles for mobile friendliness and centering
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: isDarkMode ? '#FFF' : '#000',
    lineHeight: '1.6',
    width: '100vw',
    margin: '0 auto',
    padding: '60px 40px',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    overflowY: 'auto',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  };

  const contentStyle = {
    maxWidth: '1200px',
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
  };

  // Style for the symbol and "HOME" text
  const symbolStyle = {
    position: 'fixed',
    bottom: '.5%',
    right: '.5%',
    cursor: 'pointer', // Change cursor to pointer to indicate clickable
    textAlign: 'center', // Center align the text
    transform: 'scale(0.75)'
  };

  const homeTextStyle = {
    color: isDarkMode ? '#FFF' : '#000', // Match theme
    marginTop: '0px', // Space between symbol and text
  };

  // Navigate to home page
  const navigateHome = () => {
    navigate('/');
  };

  return (
    <>
      <div style={containerStyle}>
    <div style={contentStyle}>
      <h1>Terms of Use</h1>
      <p>Last updated: December 21, 2023</p>
      <p>These Terms of Use (the “Terms”) govern your use of the website and application located at web3outfitters.io and other sites and subdomains (collectively, “Web3 Outfitters” or the “Website”)</p>
      <p>By accessing the Website, you agree to abide by these Terms and to comply with all applicable laws and regulations. We reserve the right to review and amend any of these Terms at our sole discretion and update this page from time to time. Any change to these Terms will take effect immediately from the date of publication. If you do not agree with these Terms, you are prohibited from using or accessing our Website or using any other services provided by us.</p>
      <p>PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE WEBSITE. THESE TERMS GOVERN YOUR USE OF THE WEBSITE UNLESS WE HAVE EXECUTED A SEPARATE WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE ONLY WILLING TO MAKE THE WEBSITE AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE TERMS. BY USING THE WEBSITE, YOU ARE CONFIRMING YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN WHICH CASE “YOU” WILL MEAN THAT ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE TERMS, THEN WE ARE UNWILLING TO MAKE THE WEBSITE AVAILABLE TO YOU. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE WEBSITE.</p>
      
      <h2>1. ACCOUNT & REGISTRATION</h2>
      <p>The game is available for users of all ages. However, to use certain products and services offered on the Website, including our application programming interface, platform software, tools, services, data, and documentation (collectively, the “Services” or the “Web3 Outfitters Platform”), you must be 18 years or older and able to form a binding contract with us. For users under the age of 18, parental controls will be in place, and a parent or guardian must provide consent and oversee the account. If you use the Services on behalf of another person or entity, you must have the authority to accept the Terms on their behalf. You must provide accurate and complete information to register for an account. You may not make your access credentials or account available to others outside your organization, and you are responsible for all activities that occur using your credentials.</p>

      <h2>2. SERVICES</h2>
      <p>To utilize the Services, users may need to provide an email and/or be verified on certain social media accounts. Some of the Services may require users to provide additional personal information or data, authorize or consent to interconnection with systems and/or services owned by third parties, and/or use identity verification mechanisms provided by third parties. If users don't provide the necessary consents, information, or use the verification mechanisms, Web3 Outfitters might not be able to offer certain Services. Some Services might also be restricted based on third-party conditions or policies. Whenever our Services involve connections with third-party systems or services, and their terms, conditions, or policies apply to our Services or our users, Web3 Outfitters will duly inform users in advance.</p>

      <h2>3. INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>We grant you a non-exclusive, non-transferable, non-sublicensable, revocable license to use the Website for both personal and commercial uses, as provided in these Terms. The Website and any related contents, features, and functionality, including but not limited to, all information, software, products, materials, APIs, text, displays, images, video, and audio, and the design, selection, and arrangement thereof, are owned by the Company, its licensors, or other providers of such material and are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. For the avoidance of doubt, rights, titles and interests in and to users’ Content are subject to and governed by this Section 4.</p>
      <p>You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows:</p>
      <ul>
        <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
        <li>You may store files that are automatically cached by your web browser for display enhancement purposes.</li>
        <li>You may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication, or distribution.</li>
        <li>If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</li>
        <li>If we provide social media features with certain content, you may take such actions as are enabled by such features.</li>
      </ul>
      <p>If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms, your right to use the Website will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms is a breach of these Terms and may violate copyright, trademark, and other laws.</p>

      <h2>4. TRADEMARKS</h2>
      <p>The Company name, the Company logo, and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs, and slogans on this Website are the trademarks of their respective owners.</p>
      <p>All other third-party trademarks, registered trademarks, and product names mentioned on the Website are the property of their respective owners and may not be copied, imitated or used, in whole or in part, without the permission of the applicable intellectual property rights holder. Reference to any products, services, processes or other information by name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship, or recommendation by Web3 Outfitters.</p>

      <h2>5. LIMITATIONS OF USE</h2>
      <p>By using the Website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:</p>
      <ol>
        <li>modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on the Website;</li>
        <li>remove any copyright or other proprietary notations from any materials and software on the Website;</li>
        <li>transfer the materials to another person or “mirror” the materials on any other server;</li>
        <li>knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service the Company provides;</li>
        <li>use the Website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;</li>
        <li>use the Website or its associated services in violation of any applicable laws or regulations;</li>
        <li>use the Website in conjunction with sending unauthorized advertising or spam;</li>
        <li>harvest, collect, or gather user data without the user’s consent; or</li>
        <li>use the Website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</li>
      </ol>

      <h2>6. TERM AND TERMINATION</h2>
      <p>These Terms shall remain in full force and effect so long as you access and use our Website. You have the right to terminate your use of our Website at any time and for any or no reason. We may terminate your access to or use of the Website at any time without prior notice if you breach any of these Terms or take any suspected fraudulent, abusive, or illegal activity, and termination of your access will be in addition to any other remedies we may have in law or equity.</p>

      <h2>7. DISCLAIMERS</h2>
      <p>You expressly understand and agree that your access to and use of the Website is at your sole risk, and that the Website is provided "as is" and "as available" without warranties of any kind, whether express or implied. To the fullest extent permissible pursuant to applicable law, we, our subsidiaries, affiliates, and licensors make no express warranties and hereby disclaim all implied warranties regarding the Website and any part of it (including, without limitation, the site, any smart contract, or any external websites), including the implied warranties of merchantability, fitness for a particular purpose, non-infringement, correctness, accuracy, or reliability. Without limiting the generality of the foregoing, we, our subsidiaries, affiliates, and licensors do not represent or warrant to you that: (i) your access to or use of the Website will meet your requirements, (ii) your access to or use of the Website will be uninterrupted, timely, secure or free from error, (iii) usage data provided through the Website will be accurate, (iii) the Website or any content, services, or features made available on or through the app are free of viruses or other harmful components, or (iv) that any data that you disclose when you use the Website will be secure. Some jurisdictions do not allow the exclusion of implied warranties in contracts with consumers, so some or all of the above exclusions may not apply to you.</p>
      <p>You accept the inherent security risks of providing information and dealing online over the internet and agree that we have no liability or responsibility for any breach of security unless it is due to our gross negligence.</p>

      <h2>8. LIMITATION OF LIABILITY</h2>
      <p>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR
USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY
CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY
INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF OPPORTUNITY, LOSS OF
REPUTATION, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN
IF FORESEEABLE.</p>
<p>OUR AGGREGATE LIABILITY UNDER THESE TERMS SHALL NOT EXCEED THE
GREATER OF THE AMOUNT YOU PAID FOR THE SERVICE THAT GAVE RISE TO THE
CLAIM DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE OR ONE
HUNDRED DOLLARS ($100).</p>
<p>YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE WEBSITE
AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE
WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN,
WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN THE
PARTIES AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US. WE
WOULD NOT BE ABLE TO PROVIDE THE WEBSITE TO YOU WITHOUT THESE
LIMITATIONS.</p>
<p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
<h2>9. INDEMNIFICATION</h2>
<p>To the fullest extent permitted by applicable law, you will indemnify, defend and hold harmless
the Company and its past, present and future employees, partners, contractors, consultants,
suppliers, vendors, service providers, agents, representatives, predecessors, successors and
assigns (the “Indemnified Parties”) from and against all claims, demands, actions, damages,
losses, costs and expenses (including attorneys’ fees) that arise from or relate to: (i) your use of
the Website or any services, (ii) your responsibilities or obligations under these Terms, (iii) your
violation of these Terms, (iv) your violation of any applicable laws or regulations, or (v) your
violation of any rights of any other person or entity.</p>
<p>The Company reserves the right to exercise sole control over the defense, at your expense, of any
claim subject to indemnification. This indemnity is in addition to, and not in lieu of, any other
indemnities set forth in a written agreement between you and the Company.</p>
<h2>10. RELIANCE ON INFORMATION POSTED</h2>
<p>The information presented on or through the Website is made available solely for general
information purposes. We do not warrant the accuracy, completeness, or usefulness of this
information. Any reliance you place on such information is strictly at your own risk. We disclaim
all liability and responsibility arising from any reliance placed on such materials by you or any
other visitor to the Website, or by anyone who may be informed of any of its contents.</p>
<p>This Website includes content provided by third parties, including materials provided by other
users, bloggers, and third-party licensors, syndicators, aggregators, and/or reporting services. All
statements and/or opinions expressed in these materials, and all articles and responses to
questions and other content, other than the content provided by the Company, are solely the
opinions and the responsibility of the person or entity providing those materials. These materials
do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or
any third party, for the content or accuracy of any materials provided by any third parties.</p>
<h2>11. LINKS FROM THE WEBSITE</h2>
<p>If the Website contains links to other sites and resources provided by third parties, these links are
provided for your convenience only. This includes links contained in advertisements, including
banner advertisements and sponsored links. We have no control over the contents of those sites
or resources and accept no responsibility for them or for any loss or damage that may arise from
your use of them. If you decide to access any of the third-party websites linked to this Website,
you do so entirely at your own risk and subject to the terms and conditions of use for such
websites.</p>
<h2>12. SEVERANCE</h2>
<p>Any term of these Terms which is wholly or partially void or unenforceable is severed to the
extent that it is void or unenforceable. The validity of the remainder of these Terms is not
affected.</p>
<h2>13. GOVERNING LAW</h2>
<p>These Terms and your access to and use of the Website shall be governed by and construed in
accordance with the laws of the United States without giving effect to any choice or conflict of
law provisions.</p>
<h2>14. DISPUTE RESOLUTION; ARBITRATION</h2>
<p>PLEASE READ THIS SECTION CAREFULLY. IT MAY SIGNIFICANTLY AFFECT YOUR
LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT. ALL
DISPUTES ARISING OUT OF OR RELATED TO THESE TERMS OF USE OR ANY
ASPECT OF THE RELATIONSHIP BETWEEN YOU AND US, WHETHER BASED IN
CONTRACT, TORT, STATUTE, FRAUD, MISREPRESENTATION, OR ANY OTHER
LEGAL THEORY, WILL BE RESOLVED THROUGH FINAL AND BINDING
ARBITRATION BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN A COURT BY A
JUDGE OR JURY, AND YOU AGREE THAT THE COMPANY AND YOU ARE EACH
WAIVING THE RIGHT TO SUE IN COURT AND TO HAVE A TRIAL BY A JURY. YOU
AGREE THAT ANY ARBITRATION WILL TAKE PLACE ON AN INDIVIDUAL BASIS;
CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED AND YOU ARE
AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ACTION.</p>
<h2>15. NO CLASS ACTION</h2>
<p>YOU AND THE COMPANY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF
OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
PROCEEDING.</p>
<h2>16. PRIVACY POLICY</h2>
<p>Our Privacy Policy web3outfitters.io explains how we collect and use personal information.</p>
<h2>17. CONTACT US</h2>
<p>All feedback, comments and questions on the Terms should be sent to
web3outfitters@gmail.com</p>
    </div>

      </div>
      <div style={symbolStyle} onClick={navigateHome}>
        <img src={symbol} alt="Home" style={{ width: '50px', height: 'auto' }} />
        <div style={homeTextStyle}>HOME</div>
      </div>
      </>
  );
};

export default Tos;
