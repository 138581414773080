import { useFrame } from '@react-three/fiber';
import { motion } from 'framer-motion';
import { easing } from 'maath';
import React, { useEffect, useRef, useState } from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { useSpring } from 'react-spring';
import icon from '../../images/w3oNameHorzBlack.png';
import icon2 from '../../images/w3oNameHorzWhite.png';
import "./Navbar.css";
//import "./Navbar.scss";
//import coinbaseWalletModule from '@web3-onboard/coinbase';
//import injectedModule from '@web3-onboard/injected-wallets';
//import walletConnectModule from '@web3-onboard/walletconnect';
import {
  BurgerContainer,
  BurgerStrip,
  ButtonText2,
  JoinTeam,
  JoinTeamIcon,
  LightingWrap,
  MoonWrapper,
  Nav,
  NavLinks,
  NavLinksContainer,
  NavLogo, NavLogoCont,
  NavMenu,
  NavbarRightIconWrap,
  SidebarHide,
  SunWrapper,
  TextSpan,
  Wallet, WalletIcon
} from './NavbarElements';


//const audio = new Audio(require("../../audio/W3Oday.mp3").default);
//const MAINNET_RPC_URL = "https://goerli.infura.io/v3/7c9d1c18f6244605b8d57b8dd612aa7e";

//const injected = injectedModule();
//const walletConnect = walletConnectModule()
//const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: false })
/*
const onboard = Onboard({
  wallets: [
      injected,
      walletConnect,
      coinbaseWalletSdk
  ],
  chains: [
    {
      id: '0x5',
      token: 'gETH',
      label: 'Goerli Testnet',
      rpcUrl: MAINNET_RPC_URL
    }
  ]
});

*/



function CameraRig({ children }) {
  const group = useRef()
 // const snap = useSnapshot()
  useFrame((state, delta) => {
    easing.damp3(state.camera.position, [0 ? -state.viewport.width / 4 : 0, 0, 2], 0.25, delta)
    easing.dampE(group.current.rotation, [-state.pointer.y / 3, state.pointer.x / 2, 0], 0.1, delta)
  })
  return <group ref={group}>{children}</group>
}

function Sphere() {
  return (
    <mesh>
      <sphereBufferGeometry args={[0.5, 32, 32]} />
      <meshBasicMaterial color={0x00ff00} />
    </mesh>
  );
}




function Lighting({orbitControlsEnabled,  onClick, isDarkMode }) {
  return (
    <>
      {isDarkMode ? (
        <MoonWrapper onClick={onClick}>
          <FaMoon />
        </MoonWrapper>
      ) : (
        <SunWrapper onClick={onClick}>
          <FaSun />
        </SunWrapper>
      )}
    </>
  );
}


function NavLogoContainer({ onClick, isDarkMode }) {
  return (
    <>
      {isDarkMode ? (
        <NavLogo to ='/Explore' src={icon2} alt="Icon"/>
                              
      ) : (
        <NavLogo to ='/Explore' src={icon} alt="Icon"  />
                              
      )}
    </>
  );
}
function NavbarAll({isOpen, isUtilitiesOpen, toggleDarkMode,     currentState, setCurrentState, isFooterVisible, toggle, started, isDarkMode, orbitControlsEnabled, setDaoPopup, setButtonPopup, setButtonPopup2, setButtonPopup3, buttonPopup, buttonPopup2, buttonPopup3}) {
    const [showNav, setShowNav] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    const barPositions = [1, 5, 9, 13, 17, 21, 25, 29, 33, 37];
    const animationDurations = [474, 433, 407, 458, 400, 427, 441, 419, 487, 442];
    //const audio = new Audio(require("../../audio/W3Oday.mp3").default);
    const [mousePosition, setMousePosition] = useState([0, 0]);
    const [perfSucks, degrade] = useState(false)
    const [isMusicPlaying, setIsMusicPlaying] = useState(false);
    const [isWalletHovered, setIsWalletHovered] = useState(false);
    const [isClosetHovered, setIsClosetHovered] = useState(false);
    const width = window.innerWidth;
    const isMobile = width <= 600; // Check if the window width is 600 or less
 
  
    const handleWalletHover = () => {
      setIsWalletHovered(true);
    };
    const handleWalletMouseLeave = () => {
      setIsWalletHovered(false);
    };
  
    const handleClosetMouseLeave = () => {
      setIsClosetHovered(false);
    };
    const handleClosetHover = () => {
      setIsClosetHovered(true);
    };
    const opacityValue = orbitControlsEnabled ? 0 : 1;
    const [isHovered, setIsHovered] = useState(false);
    const checkboxRef = useRef(null);
    const pageTransition = useSpring({
      from: { scale: 0, opacity: 0 },
      to: { scale: 1, opacity: 1 },
    });
  
  
    const handleLightingWrapClick = () => {
      if (checkboxRef.current) {
        checkboxRef.current.click();
      }
    };
    const handleClick = () => {
      // Toggle the state when the burger is clicked
      setIsHovered(!isHovered);
    };
    useEffect(() => {
      const handleMouseMove = (event) => {
        const { clientX, clientY } = event;
        setMousePosition([clientX, clientY]);
      };
  
      window.addEventListener('mousemove', handleMouseMove);
  
      return () => {
        // Remove the event listener when the component unmounts
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }, []);

    // Event handler for toggling music play/pause
    
    /*
    const handleMusicToggle = () => {
      const audioPlayer = document.getElementById('audio-player');
  
      if (audioPlayer.paused) {
        audioPlayer.play();
      } else {
        audioPlayer.pause();
      }
      setIsMusicPlaying(audioPlayer.paused);
    };*/
    /*
    async function loadWeb3() {
        try {
            const wallets = await onboard.connectWallet();
            let web3 = window.web3 = new Web3(wallets[0].provider);
            const account = wallets[0].accounts[0].address;
            console.log("account", account)
            const msgToSign = web3.utils.sha3("Wallet Verification Message for Web3 Outfitters.");
            const signature = await web3.eth.personal.sign(msgToSign, account);
            const signingAddress = web3.eth.accounts.recover(msgToSign, 
            signature);
            console.log("signingAddress", signingAddress)
            if (account.toLowerCase() === signingAddress.toLowerCase()) {
                window.account = account;
                setWalletAddress(account);
            }
            return [(account.toLowerCase() === signingAddress.toLowerCase()), account];
        }
        catch (e) {
            console.log(e);
            return [false, ""];
        }
    }

    var user = {
      name: walletAddress,
      avatar: 'https://www.w3schools.com/howto/img_avatar.png',
      bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      email: 'test@example.com'
    }
    */
    // const user = useContext(UserContext);
   // console.log(user);

 
    return(
      <>

{   !orbitControlsEnabled &&   
        <NavLogoCont  to={'/'}         onClick={() => setCurrentState(1)}    isUtilitiesOpen={isUtilitiesOpen}   isDarkMode={isDarkMode}>
                        {/*<NavIcon src = {symbol} />*/}
                                                        <NavLogoContainer    isDarkMode={isDarkMode} />
                                    
                                {/*<NavLogoTextWrap onCLick={toggleOrbitControls} > <NavLogoText >EXPLORE    CLOSETS </NavLogoText><ExploreCloset onCLick={toggleOrbitControls}  /></NavLogoTextWrap>*/}
                            </NavLogoCont>}

                            <BurgerContainer 
                        isMobile={isMobile}
                            isUtilitiesOpen={isUtilitiesOpen}
                            onClick={toggle}
                            isFooterVisible={isFooterVisible}
                            isDarkMode={isDarkMode} 
                            orbitControlsEnabled={orbitControlsEnabled} 
                             //toggle={toggle}
                             isHovered={isHovered}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            <BurgerStrip orbitControlsEnabled={orbitControlsEnabled} isDarkMode={isDarkMode}  isHovered={isHovered} isOpen={isOpen}>
                              <div></div>
                              <div></div>
                              <div></div>
                            </BurgerStrip>
                          </BurgerContainer>
                          <LightingWrap orbitControlsEnabled={orbitControlsEnabled} onClick={toggleDarkMode}  isDarkMode={isDarkMode} >
                       <motion.div whileHover={{ scale: 1.1, transition: { duration: 0.05 } }}  >
                        <Lighting  isDarkMode={isDarkMode}/>
                        </motion.div>
                     </LightingWrap>
        
        <Nav

        iaOpen={isOpen}
          style={{ opacity: opacityValue }}
          isUtilitiesOpen={isUtilitiesOpen} 
          initial={{ opacity: 0}}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0}}
          transition={{ duration: 0.2 }}
     
        >
          {/* Your animated elements */}
          <motion.div
          initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            exit={{ opacity: 1}}
          >
           { (!orbitControlsEnabled) && (
            <>
      
             <SidebarHide  isUtilitiesOpen={isUtilitiesOpen}  isOpen={isOpen}>
        
                <NavMenu >

                        
                   </NavMenu>
              </SidebarHide>
              </>
              )}
              <NavLinksContainer  >
{ (!orbitControlsEnabled) && (
                <SidebarHide isOpen={isOpen}>
        
                           
                              
              


                
            
                    <NavbarRightIconWrap>
                           
                                  <JoinTeam isDarkMode={isDarkMode}>
                                  <motion.div whileHover={{ scale: 1.1, transition: { duration: 0.05 } }}  >
                                      <NavLinks       onClick={() => {setDaoPopup(true)}} isDarkMode={isDarkMode}  smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>  
                                        <ButtonText2 isDarkMode={isDarkMode}>
                                          <TextSpan isDarkMode={isDarkMode}>Join Our Team</TextSpan>  
                                          <JoinTeamIcon isDarkMode={isDarkMode}/>
                                        </ButtonText2> 
                                      </NavLinks>
                                      </motion.div>
                              </JoinTeam>

                              
                              <Wallet isDarkMode={isDarkMode}>
                              <motion.div whileHover={{ scale: 1.1, transition: { duration: 0.05 } }}  >
                                      <NavLinks   onClick={() => {setButtonPopup2(true); setButtonPopup3(false);  setButtonPopup(false); }}  isDarkMode={isDarkMode} /*state = {user} onClick={loadWeb3}  */   smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>   
                                      <ButtonText2 isDarkMode={isDarkMode}>
                                            <TextSpan  isDarkMode={isDarkMode}>Connect Wallet</TextSpan>  
                                            <WalletIcon isDarkMode={isDarkMode} />
                                          </ButtonText2> 
                                      </NavLinks>
                                      </motion.div>
                              </Wallet>
                        
                              
                              </NavbarRightIconWrap>
                              
                              
                             
                  
                          </SidebarHide>
        )}
                       
            
        
                       </NavLinksContainer>
                 
            </motion.div>
            
            </Nav>
            
            </>
    )
}

export default NavbarAll;


