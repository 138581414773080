// ListElementsMobile.js

import { HiPlusCircle } from 'react-icons/hi2';
import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: absolute;
  
  top: 15%;
`;

export const Table = styled.div`
  display: table;
  width: max-content;
  min-width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.div`
  display: table-header-group;
  position: fixed;
  top: 0;
  z-index: 99999;
`;

export const TableBody = styled.div`
  display: table-row-group;
`;

export const TableRow = styled.div`
  display: table-row;
`;

export const TableHeader = styled.div`
  display: table-cell;
  padding: 10px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#444' : '#f1f1f1')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ddd')};
  min-width: 120px;

  ${(props) =>
    props.sticky &&
    `
      position: fixed; 
      z-index: 1000; /* Ensure a high z-index */
      background-color: ${props.isDarkMode ? '#555' : '#e1e1e1'};
  `}
`;
export const TableCell = styled.div`
  display: table-cell;
  padding: 10px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#fff')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  border-bottom: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ddd')};
  text-align: center;
  min-width: 120px;
  z-index: 1;
  left: 0;
  top: 0;
  
  position: relative;
  ${(props) =>
    props.sticky && 
    `
      position: sticky; 

      background-color: ${props.isDarkMode ? '#333' : '#fff'}; 

  `}
`;

export const ListImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 5px;
`;

export const ListTitle = styled.span`
  display: block;
  font-size: 0.9em;
  margin-top: 5px;
`;

export const ListDescription = styled.p`
  color: ${({ isDarkMode }) => (isDarkMode ? '#ddd' : '#666')};
  font-size: 0.8em;
  margin: 0;
`;

export const ListPrice = styled.p`
  font-weight: bold;
  margin: 0;
`;

export const ListOwner = styled.p`
  font-size: 0.8em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#aaa' : '#888')};
  margin: 0;
`;

export const SmallText = styled.span`
  font-size: 0.8em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#ccc' : '#999')};
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
`;

export const PlusButton = styled(HiPlusCircle)`
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  font-size: 1.5em;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

export const RarityMark = styled.mark`
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  font-size: 0.8em;
  background-image: ${({ rarity }) => {
    switch (rarity) {
      case 'legendary':
        return 'linear-gradient(to right, rgba(255, 225, 0, 0.1), rgba(255, 225, 0, 0.7), rgba(255, 180, 0, 0.3))';
      case 'epic':
        return 'linear-gradient(to right, rgba(255, 180, 0, 0.1), rgba(255, 127, 0, 0.7), rgba(255, 127, 0, 0.3))';
      case 'rare':
        return 'linear-gradient(to right, rgba(255, 127, 0, 0.1), rgba(255, 64, 0, 0.7), rgba(255, 64, 0, 0.3))';
      case 'uncommon':
        return 'linear-gradient(to right, rgba(255, 64, 0, 0.1), rgba(255, 0, 0, 0.7), rgba(255, 0, 0, 0.3))';
      case 'common':
        return 'linear-gradient(to right, rgba(255, 0, 0, 0.1), rgba(192, 192, 192, 0.7), rgba(192, 192, 192, 0.3))';
      default:
        return 'linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.3))';
    }
  }};
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
`;
