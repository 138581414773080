// Data.js

import axios from 'axios';
import React from 'react';

// Import the SVG files for blockchain icons
import adaIcon from './icons/ada.svg';
import algoIcon from './icons/algo.svg';
import apeIcon from './icons/ape.svg';
import avaxIcon from './icons/avax.svg';
import axieIcon from './icons/axie.svg';
import basedIcon from './icons/based.svg';
import bnbIcon from './icons/bnb.svg';
import btcIcon from './icons/btc.svg';
import croIcon from './icons/cro.svg';
import daiIcon from './icons/dai.svg';
import dotIcon from './icons/dot.svg';
import enjIcon from './icons/enj.svg';
import eosIcon from './icons/eos.svg';
import ethIcon from './icons/eth.svg';
import flowIcon from './icons/flow.svg';
import galaIcon from './icons/gala.svg';
import imxIcon from './icons/imx.svg';
import linkIcon from './icons/link.svg';
import manaIcon from './icons/mana.svg';
import maticIcon from './icons/matic.svg';
import monaIcon from './icons/mona.svg';
import pnkIcon from './icons/pnk.svg';
import rariIcon from './icons/rari.svg';
import sandIcon from './icons/sand.svg';
import solIcon from './icons/sol.svg';
import trxIcon from './icons/trx.svg';
import usdcIcon from './icons/usdc.svg';
import usdtIcon from './icons/usdt.svg';
import waxpIcon from './icons/waxp.svg';
import wethIcon from './icons/weth.svg';
import xtzIcon from './icons/xtz.svg';

// Export the blockchainIconMap
export const blockchainIconMap = {
  eth: ethIcon,
  btc: btcIcon,
  sol: solIcon,
  matic: maticIcon,
  flow: flowIcon,
  ada: adaIcon,
  eos: eosIcon,
  xtz: xtzIcon,
  waxp: waxpIcon,
  bnb: bnbIcon,
  avax: avaxIcon,
  cro: croIcon,
  algo: algoIcon,
  trx: trxIcon,
  usdc: usdcIcon,
  usdt: usdtIcon,
  weth: wethIcon,
  rari: rariIcon,
  dai: daiIcon,
  axie: axieIcon,
  enj: enjIcon,
  sand: sandIcon,
  pnk: pnkIcon,
  mana: manaIcon,
  based: basedIcon,
  ape: apeIcon,
  imx: imxIcon,
  dot: dotIcon,
  link: linkIcon,
  gala: galaIcon,
  mona: monaIcon,
};

// Export the blockchainIdMap
export const blockchainIdMap = {
  eth: 'ethereum',
  sol: 'solana',
  btc: 'bitcoin',
  matic: 'matic-network',
  flow: 'flow',
  ada: 'cardano',
  eos: 'eos',
  xtz: 'tezos',
  wax: 'wax',
  bnb: 'binancecoin',
  avax: 'avalanche-2',
  cro: 'cronos',
  algo: 'algorand',
  trx: 'tron',
  usdc: 'usd-coin',
  usdt: 'tether',
  weth: 'wrapped-ethereum',
  rari: 'rarible',
  dai: 'dai',
  axie: 'axie-infinity',
  enj: 'enjincoin',
  sand: 'the-sandbox',
  pnk: 'kleros',
  mana: 'decentraland',
  blur: 'blur',
  lux: 'lukso-token',
  based: 'based-coin',
  ape: 'bored-ape-yacht-club',
  imx: 'immutable-x',
  dot: 'polkadot',
  link: 'chainlink',
  gala: 'gala',
  mona: 'monacoin',
};

// Placeholder image with higher resolution
const getPlaceholderUrl = (index) => `https://picsum.photos/seed/${index}/600/900`;

// Utility functions
const rarityLevels = {
  legendary: 'legendary',
  epic: 'epic',
  rare: 'rare',
  uncommon: 'uncommon',
  common: 'common',
};

// Export getRarityLevel
export const getRarityLevel = (rank) => {
  if (rank <= 10) return rarityLevels.legendary;
  if (rank <= 25) return rarityLevels.epic;
  if (rank <= 43) return rarityLevels.rare;
  if (rank <= 69) return rarityLevels.uncommon;
  return rarityLevels.common;
};

// Export getRandomPrice, updated to generate prices up to 25 ETH
export const getRandomPrice = () => (Math.random() * 25).toFixed(2); // Random price between 0 and 25 ETH

// Function to get random floor difference
const getRandomFloorDifference = () => {
  const sign = Math.random() < 0.5 ? -1 : 1;
  return `${(sign * Math.random() * 25).toFixed(2)}%`; // Random difference between -25% and 25%
};

// Export getRandomLastSoldPrice
export const getRandomLastSoldPrice = (currentPrice) => {
  const adjustment = Math.random() * (Math.random() < 0.5 ? -1 : 1);
  return Math.max(0, currentPrice + adjustment).toFixed(2); // Last sold price within ±1 of the current price
};

// Export getRandomListedTime
export const getRandomListedTime = () => {
  const now = new Date();
  const timeFrames = [
    now.getTime() - (Math.floor(Math.random() * 12) + 1) * 60 * 60 * 1000, // 1-12 hours ago
    now.getTime() - (Math.floor(Math.random() * 30) + 1) * 24 * 60 * 60 * 1000, // 1-30 days ago
    now.getTime() - (Math.floor(Math.random() * 12) + 1) * 30 * 24 * 60 * 60 * 1000, // 1-12 months ago
  ];
  const randomTime = timeFrames[Math.floor(Math.random() * timeFrames.length)];
  return new Date(randomTime);
};

// Export formatListedTime
export const formatListedTime = (date) => {
  const now = new Date();
  const diff = now - date;
  const diffInMinutes = Math.floor(diff / (1000 * 60));
  const diffInHours = Math.floor(diff / (1000 * 60 * 60));
  const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  const diffInMonths = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));

  if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
  if (diffInHours < 24) return `${diffInHours} hours ago`;
  if (diffInDays < 30) return `${diffInDays} days ago`;
  return `${diffInMonths} months ago`;
};

// Export getRandomOwner
export const getRandomOwner = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let owner = '';
  for (let i = 0; i < 8; i++) {
    owner += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return owner;
};

// Traits categories definition
export const traitsCategories = {
  Back: ['Katana', 'Backpack', 'Red Katana', 'Yellow Katana'],
  Background: ['Sunset', 'Aquatic', 'Space', 'Red', 'Green', 'Purple', 'Blue', 'Pink', 'Orange', 'White', 'Grey', 'Violet', 'Yellow', 'Royal Rug'],
  Beard: ['Short Beard', 'Long Beard', 'Goatee', 'Stubble'],
  Catalyst: ['Crystal', 'Stone', 'Gem', 'Orb', 'Vial'],
  EyeAccessories: ['Glasses', 'Sunglasses', 'Monocle', 'Eye Patch', 'VR Headset'],
  Eyes: ['Blue Eyes', 'Green Eyes', 'Red Eyes', 'Yellow Eyes', 'Black Eyes'],
  Hair: ['Blonde', 'Brunette', 'Black', 'Red', 'Grey', 'Bald', 'Curly', 'Straight', 'Wavy', 'Ponytail', 'Braids', 'Dreadlocks', 'Mohawk'],
  Mouth: ['Smile', 'Frown', 'Open Mouth', 'Cigarette', 'Cigar', 'Pipe'],
  Pants: ['Jeans', 'Shorts', 'Cargo Pants', 'Sweatpants', 'Suit Pants', 'Leather Pants'],
  Skin: ['Light', 'Medium', 'Dark', 'Albino', 'Tanned'],
  Clothing: ['T-Shirt', 'Jacket', 'Hoodie', 'Blazer', 'Sweater', 'Tanktop', 'Dress', 'Skirt', 'Shorts', 'Tuxedo'],
  Expression: ['Happy', 'Sad', 'Angry', 'Surprised', 'Neutral'],
  Gender: ['Male', 'Female'],
  Glove: ['Leather Glove', 'Fingerless Glove', 'Winter Glove', 'Latex Glove', 'Combat Glove'],
  Hand: ['Ring', 'Bracelet', 'Watch', 'Tattoo', 'Glove'],
  Hat: ['Baseball Cap', 'Beanie', 'Cowboy Hat', 'Top Hat', 'Fedora', 'Helmet'],
  Mustache: ['Handlebar', 'Pencil', 'Horseshoe', 'Chevron'],
  Necklace: ['Gold Chain', 'Silver Chain', 'Pendant'],
  Smoke: ['Cigarette', 'Cigar', 'Pipe'],
  Smoking: ['Cigarette', 'Cigar', 'Pipe'],
};

// Rarity definition
export const traitRarities = {
  common: '3%',
  uncommon: '4%',
  rare: '5%',
  epic: '7%',
  legendary: '11%',
};

// Function to get a random status
export const statusOptions = ['Listed', 'On Auction', 'New', 'Has Offers'];
export const getRandomStatus = () => statusOptions[Math.floor(Math.random() * statusOptions.length)];

// Function to fetch live rates from CoinGecko API
export const fetchLiveConversionRates = async () => {
  const response = await axios.get(
    'https://api.coingecko.com/api/v3/simple/price?ids=ethereum,solana,bitcoin,matic-network,flow,cardano,eos,tezos,wax,binancecoin,avalanche-2,cronos,algorand,tron,usd-coin,wrapped-ethereum,rarible,dai,axie-infinity,polkadot,immutable-x,based-coin,bored-ape-yacht-club,chainlink,gala,monacoin&vs_currencies=usd'
  );
  return response.data;
};

// Cached rates and updating function
let cachedRates = {};
export const updateConversionRates = async () => {
  try {
    cachedRates = await fetchLiveConversionRates();
  } catch (error) {
    console.error('Error fetching conversion rates:', error);
  }
};

// Initial call to fetch rates
updateConversionRates();

// Function to convert price from ETH to the selected blockchain
export const convertPrice = (priceInEth, blockchain) => {
  const ethUSD = cachedRates.ethereum?.usd || 1; // ETH/USD rate
  const blockchainId = blockchainIdMap[blockchain.toLowerCase()];

  if (!blockchainId) {
    console.warn(`Unknown blockchain code: ${blockchain}`);
    return `${priceInEth.toFixed(2)} ${blockchain.toUpperCase()}`;
  }

  const targetUSD = cachedRates[blockchainId]?.usd || 1; // Target Crypto/USD rate

  if (blockchain.toLowerCase() === 'eth') {
    return `${priceInEth.toFixed(2)} ETH`;
  }

  // Calculate the price in the target cryptocurrency
  const priceInTargetCrypto = (priceInEth * ethUSD) / targetUSD;

  if (isNaN(priceInTargetCrypto)) {
    console.warn(`Conversion resulted in NaN for blockchain ${blockchain}`);
    return `${priceInEth.toFixed(2)} ${blockchain.toUpperCase()}`;
  }

  return `${priceInTargetCrypto.toFixed(2)} ${blockchain.toUpperCase()}`;
};

// Function to display icon and price concatenation
export const renderIconWithPrice = (price, blockchain) => {
  const lowerCaseBlockchain = blockchain.toLowerCase();
  const iconSrc = blockchainIconMap[lowerCaseBlockchain] || ethIcon;

  return (
    <span>
      {price} {iconSrc && <img src={iconSrc} alt={`${blockchain} icon`} style={{ width: '12px', height: '12px', marginLeft: '5px' }} />}
    </span>
  );
};

// Simplified NFT card details
export const itemBaseDetails = {
  description: 'This is a detailed description about the NFT item. It includes information about its origin, uniqueness, and value.',
  address: '0x3d0c...46f9',
  tokenStandard: 'ERC-721',
  chain: 'Ethereum', // Default, can be dynamic
  earnings: 'Creator Earnings - 5%',
  artType: 'Kind: Art Type',
  detailDescription: 'Description: Generative 100%',
  detailPrice: 'Price: 2.5 floor',
};

// Function to generate random traits
export function getRandomTraits(blockchain = 'eth') {
  const selectedCategories = Object.keys(traitsCategories)
    .sort(() => 0.5 - Math.random())
    .slice(0, Math.floor(Math.random() * 18) + 3);

  return selectedCategories.map((category) => {
    const traits = getRandomTraitAttributes(category);
    traits.price = renderIconWithPrice(parseFloat(traits.price), blockchain);
    return traits;
  });
}

// Function to get random trait attributes
export function getRandomTraitAttributes(category) {
  const attributes = traitsCategories[category];
  const attribute = attributes[Math.floor(Math.random() * attributes.length)];
  const rarity = Object.keys(traitRarities)[Math.floor(Math.random() * Object.keys(traitRarities).length)];
  const price = `${(Math.random() * 100).toFixed(2)}`;

  return {
    trait: category,
    attribute: attribute,
    price: price,
    rarity: traitRarities[rarity],
  };
}

// Function to create item details
export const createItemDetails = (name, id, blockchain = 'eth') => {
  const currentPrice = parseFloat(getRandomPrice());
  const convertedPrice = convertPrice(currentPrice, blockchain);

  return {
    group: name,
    header: 'Sample Closet',
    name: name,
    tokenId: `Token ID - ${id}`,
    ...itemBaseDetails,
    chain: blockchain,
    detailPrice: renderIconWithPrice(currentPrice.toFixed(2), blockchain), // Concatenating icon with price
    traits: getRandomTraits(blockchain),
    status: getRandomStatus(),
    convertedPrice: renderIconWithPrice(convertedPrice, blockchain), // Converted price with icon
  };
};

// Generate items based on names and IDs
export const itemDetailsList = [
  createItemDetails('Mirror', 172),
  createItemDetails('TopBag', 173),
  createItemDetails('Perfume', 174),
  createItemDetails('RedShirt', 175),
  createItemDetails('MiddleShirt', 176),
  createItemDetails('RightShirt', 177),
  createItemDetails('MiddleBag', 178),
  createItemDetails('FloorBag', 179),
  createItemDetails('GlassBalls', 180),
  createItemDetails('Hat', 181),
  createItemDetails('Chain', 182),
  createItemDetails('Shoes', 183),
];

let blockchain = 'eth'; // Default blockchain value


// Initialize maxPrice and currentMaxTemp
let maxPriceFinal = 0; // Initialize maxPrice

// Define possible array lengths
const possibleLengths = [1111, 2222, 3333, 6666, 8888, 10000, 12240, Math.floor(Math.random() * 25000) + 1];

// Select a random array length from the possible lengths
const totalItems = possibleLengths[Math.floor(Math.random() * possibleLengths.length)];

// Generate unique random numbers
const totalPossibleNumbers = 25000; // Maximum possible
const uniqueNumbers = Array.from({ length: totalPossibleNumbers }, (_, index) => index + 1);
for (let i = uniqueNumbers.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1));
  [uniqueNumbers[i], uniqueNumbers[j]] = [uniqueNumbers[j], uniqueNumbers[i]];
}

// Function to generate random listings data
export const generateActivity = (blockchain) => {
  const activity = [];
  const numActivity = Math.floor(Math.random() * 11) + 10; // 10-20 listings
  for (let i = 0; i < numActivity; i++) {
    const unitPrice = parseFloat(getRandomPrice());
    const usdUnitPrice = (unitPrice * (cachedRates[blockchainIdMap[blockchain.toLowerCase()]]?.usd || 1)).toFixed(2);
    const quantity = 1;
    const expirationDate = getRandomListedTime();
    const expiration = formatListedTime(expirationDate);
    const from = getRandomOwner();
    activity.push({
      unitPrice: renderIconWithPrice(`${unitPrice.toFixed(4)} ${blockchain.toUpperCase()}`, blockchain),
      usdUnitPrice: `$${usdUnitPrice}`,
      quantity,
      expiration,
      from,
      cartSymbol: '🛒',
    });
  }
  return activity;
};

// Function to generate random offers data
export const generateBids = (blockchain) => {
  const bids = [];
  const numBids = Math.floor(Math.random() * 11) + 10; // 10-20 offers
  for (let i = 0; i < numBids; i++) {
    const unitPrice = parseFloat(getRandomPrice());
    const usdUnitPrice = (unitPrice * (cachedRates[blockchainIdMap[blockchain.toLowerCase()]]?.usd || 1)).toFixed(2);
    const quantity = 1;
    const floorDifference = `${Math.floor(Math.random() * 50)}% below`;
    const expirationDate = getRandomListedTime();
    const expiration = formatListedTime(expirationDate);
    const from = getRandomOwner();
    bids.push({
      unitPrice: renderIconWithPrice(`${unitPrice.toFixed(4)} ${blockchain.toUpperCase()}`, blockchain),
      usdUnitPrice: `$${usdUnitPrice}`,
      quantity,
      floorDifference,
      expiration,
      from,
    });
  }
  return bids;
};

// Function to create tempGeneralData with dynamic array size and additional fields
export const tempGeneralData = (() => {
  let currentMaxTemp = 0; // Temporary variable to track the maximum price

  // Generate the data array
  const data = Array.from({ length: totalItems }, (_, index) => {
    const rank = uniqueNumbers[index];
    const currentPrice = parseFloat(getRandomPrice()); // 0-25 ETH

    // Update currentMaxTemp if currentPrice is higher
    if (currentPrice > currentMaxTemp) currentMaxTemp = currentPrice;

    const convertedPrice = convertPrice(currentPrice, blockchain);
    const listedTimeDate = getRandomListedTime();
    const listedTime = formatListedTime(listedTimeDate);
    const listedTimeType = listedTimeDate.getTime();

    const views = Math.floor(Math.random() * 100001); // 0-100,000
    const favorites = Math.floor(Math.random() * 10001); // 0-10,000
    const blockchainIcon = blockchainIconMap[blockchain.toLowerCase()] || ethIcon;

    return {
      id: index,
      logo: getPlaceholderUrl(index),
      header: `Project Samples`,
      name: `NFT ${index + 1}`,
      number: `#${rank}`,
      description: 'This is a temporary description for the Main.',
      ethPrice: renderIconWithPrice(currentPrice.toFixed(2), blockchain),
      price: currentPrice,
      convertedPrice: renderIconWithPrice(convertPrice(currentPrice, blockchain), blockchain),
      rarity: rank,
      rarityLevel: getRarityLevel(rank),
      floorDifference: getRandomFloorDifference(),
      lastSold: getRandomLastSoldPrice(currentPrice),
      owner: getRandomOwner(),
      listedTimeType: listedTimeType,
      listedTime: listedTime,
      traits: getRandomTraits(blockchain),
      chain: blockchain,
      status: getRandomStatus(),
      activity: generateActivity(blockchain),
      bids: generateBids(blockchain),
      maxRarity: totalItems,
      views,
      favorites,
      blockchainIcon,
    };
  }).sort(() => Math.random() - 0.5); // Shuffle the items randomly

  // Set maxPriceFinal to the highest price found
  maxPriceFinal = Math.ceil(currentMaxTemp); // Round up to nearest integer

  return data;
})();

export { maxPriceFinal as maxPrice };

// Function to set the blockchain based on user selection
export const setBlockchain = (selectedBlockchain) => {
  blockchain = selectedBlockchain;
};

// Function to update the price of all items to match the selected blockchain
export const setPrice = (selectedBlockchain) => {
  const updatedData = tempGeneralData.map((item) => {
    const convertedPrice = convertPrice(item.price, selectedBlockchain);
    return {
      ...item,
      convertedPrice: renderIconWithPrice(convertedPrice, selectedBlockchain),
      chain: selectedBlockchain,
      blockchainIcon: blockchainIconMap[selectedBlockchain.toLowerCase()] || ethIcon,
    };
  });

  // Update maxPriceFinal based on the new converted prices
  maxPriceFinal = Math.ceil(Math.max(...updatedData.map(item => parseFloat(item.price) || 0)));
  
  // Assuming you have a setState for filteredData or similar, update the state
  // setFilteredData(updatedData);
};
