// InfoPopupElements.js

import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import {
  FaDiscord,
  FaInfoCircle,
  FaInstagram,
  FaTiktok,
  FaYoutube
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import styled from 'styled-components';

////////////////////////////////////////////////////////////////////////////////////////
//                              Standard Components                                   //
////////////////////////////////////////////////////////////////////////////////////////

export const BlurBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 9999;
`;

export const InfoPopupContainer = styled.div`
  background: ${({ isDarkMode }) =>
    isDarkMode ? '#333' : '#fff'};
  color: ${({ isDarkMode }) =>
    isDarkMode ? '#fff' : '#333'};
  border-radius: 10px;
  padding: 20px;
  width: 70vw;
  height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 600px) {
    width: 90vw;
  }
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 99999999999;
`;

export const InfoPopupImage = styled.img`
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
  overflow: hidden;
`;

export const InfoPopupTitle = styled.h3`
  margin: 5px 0;
  font-size: 1.1em;
`;

export const InfoPopupDescription = styled.p`
  color: ${({ isDarkMode }) =>
    isDarkMode ? '#ddd' : '#666'};
  font-size: 0.9em;
  margin: 2px 0;
`;

export const InfoPopupPrice = styled.p`
  font-weight: bold;
  margin: 4px 0;
  display: flex;
  align-items: center;
  font-size: 0.9em;
`;

export const InfoPopupOwner = styled.p`
  font-size: 0.8em;
  color: ${({ isDarkMode }) =>
    isDarkMode ? '#aaa' : '#888'};
  margin: 4px 0;
`;

export const SmallText = styled.span`
  font-size: 0.7em;
  color: ${({ isDarkMode }) =>
    isDarkMode ? '#ccc' : '#999'};
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
`;

export const SectionTitle = styled.h4`
  font-size: 1.5em;
  margin-top: 30px;
  color: ${({ isDarkMode }) =>
    isDarkMode ? '#fff' : '#333'};
`;

export const SectionContent = styled.p`
  margin: 10px 0;
  color: ${({ isDarkMode }) =>
    isDarkMode ? '#ddd' : '#666'};
`;

export const GraphPlaceholder = styled.div`
  width: 100%;
  height: 200px;
  background-color: ${({ isDarkMode }) =>
    isDarkMode ? '#444' : '#ddd'};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isDarkMode }) =>
    isDarkMode ? '#fff' : '#333'};
  margin-top: 20px;
`;

export const RarityMark = styled.mark`
  margin: 0 0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  font-size: 0.6em;
  background-image: ${({ rarity }) => {
    switch (rarity) {
      case 'legendary':
        return 'linear-gradient(to right, rgba(255, 225, 0, 0.1), rgba(255, 225, 0, 0.7) 4%, rgba(255, 180, 0, 0.3))';
      case 'epic':
        return 'linear-gradient(to right, rgba(255, 180, 0, 0.1), rgba(255, 127, 0, 0.7) 4%, rgba(255, 127, 0, 0.3))';
      case 'rare':
        return 'linear-gradient(to right, rgba(255, 127, 0, 0.1), rgba(255, 64, 0, 0.7) 4%, rgba(255, 64, 0, 0.3))';
      case 'uncommon':
        return 'linear-gradient(to right, rgba(255, 64, 0, 0.1), rgba(255, 0, 0, 0.7) 4%, rgba(255, 0, 0, 0.3))';
      case 'common':
        return 'linear-gradient(to right, rgba(255, 0, 0, 0.1), rgba(192, 192, 192, 0.7) 4%, rgba(192, 192, 192, 0.3))';
      default:
        return 'linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.7) 4%, rgba(255, 255, 255, 0.3))';
    }
  }};
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
`;

////////////////////////////////////////////////////////////////////////////////////////
//                              Desktop Components                                    //
////////////////////////////////////////////////////////////////////////////////////////

export const StatsRowDesktop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

export const StatsColumnDesktop = styled.div`
  display: flex;
  align-items: center;
  font-size: 1em;
  color: ${({ isDarkMode }) =>
    isDarkMode ? '#fff' : '#333'};
  
  svg {
    margin-right: 5px;
  }
`;

export const TabsContainerDesktop = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid
    ${({ isDarkMode }) => (isDarkMode ? '#ccc' : '#333')};
  padding-bottom: 10px;
`;

export const TabDesktop = styled.div`
  padding: 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  border-bottom: ${({ isActive }) =>
    isActive ? '2px solid gold' : 'none'};
  color: ${({ isActive, isDarkMode }) =>
    isActive ? 'gold' : isDarkMode ? '#fff' : '#333'};
  text-shadow: ${({ isActive }) =>
    isActive ? '0 0 5px gold' : 'none'};
  
  &:hover {
    color: gold;
    text-shadow: 0 0 10px gold;
  }
`;

export const DescriptionGridDesktop = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  background: ${({ isDarkMode }) =>
    isDarkMode
      ? 'rgba(255, 255, 255, 0.05)'
      : 'rgba(0, 0, 0, 0.05)'};
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
`;

export const TraitsSectionDesktop = styled.div`
  background: ${({ isDarkMode }) =>
    isDarkMode
      ? 'rgba(255, 255, 255, 0.05)'
      : 'rgba(0, 0, 0, 0.05)'};
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
`;

export const ListedDetailsDesktop = styled.div`
  background: ${({ isDarkMode }) =>
    isDarkMode
      ? 'rgba(255, 255, 255, 0.05)'
      : 'rgba(0, 0, 0, 0.05)'};
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
`;

////////////////////////////////////////////////////////////////////////////////////////
//                              Table Components                                      //
////////////////////////////////////////////////////////////////////////////////////////

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: ${({ isDarkMode }) =>
    isDarkMode ? '#222' : '#f9f9f9'};
  color: ${({ isDarkMode }) =>
    isDarkMode ? '#fff' : '#333'};
`;

export const TableHead = styled.thead`
  background: ${({ isDarkMode }) =>
    isDarkMode ? '#333' : '#e9e9e9'};
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background: ${({ isDarkMode }) =>
      isDarkMode ? '#2a2a2a' : '#f1f1f1'};
  }
`;

export const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  font-weight: bold;
  font-size: 0.9em;
  border-bottom: 1px solid
    ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ccc')};
`;

export const TableData = styled.td`
  padding: 10px;
  font-size: 0.9em;
  border-bottom: 1px solid
    ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ccc')};
`;

export const OwnerLinkMobile = styled.a`
  color: lightblue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const StatsRowMobile = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

export const StatsColumnMobile = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};

  svg {
    margin-right: 5px;
  }
`;

export const TabsContainerMobile = styled(motion.div)`
  margin-bottom: 0px;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#ccc' : '#333')};
  padding-bottom: 10px;
`;

export const TabMobile = styled(motion.div)`
  padding: 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9em;
  border-bottom: ${({ isActive }) => (isActive ? '2px solid gold' : 'none')};
  color: ${({ isActive, isDarkMode }) => (isActive ? 'gold' : isDarkMode ? '#fff' : '#333')};
  text-shadow: ${({ isActive }) => (isActive ? '0 0 5px gold' : 'none')};

  &:hover {
    color: gold;
    text-shadow: 0 0 10px gold;
  }

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;

export const DescriptionGridMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  background: ${({ isDarkMode }) => (isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)')};
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
`;

export const TraitsSectionMobile = styled.div`
  background: ${({ isDarkMode }) => (isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)')};
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
`;

export const PriceHistoryMobile = styled.div`
  background: ${({ isDarkMode }) => (isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)')};
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
`;

export const ListedDetailsMobile = styled.div`
  background: ${({ isDarkMode }) => (isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)')};
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
`;

// Table Components for Mobile
export const TableContainerMobile = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
`;
export const ActivityTable = styled(TableContainer)``;
export const BidsTable = styled(TableContainer)``;

export const ActivityTableMobile = styled(TableContainerMobile)``;
export const BidsTableMobile = styled(TableContainerMobile)``;

////////////////////////////////////////////////////////////////////////////////////////
//                              Intricate Components                                  //
////////////////////////////////////////////////////////////////////////////////////////


export const About = ({ currentDetail, isDarkMode }) => (
  <div>
    <SectionTitle isDarkMode={isDarkMode}>
      About Me
    </SectionTitle>
    <AboutDescription>
      {currentDetail.aboutDescription}
    </AboutDescription>
    <SocialIconsMobile>
      <SocialIconLinkMobile
        href={currentDetail.website}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInfoCircle />
      </SocialIconLinkMobile>
      <SocialIconLinkMobile
        href={currentDetail.discord}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaDiscord />
      </SocialIconLinkMobile>
      <SocialIconLinkMobile
        href={currentDetail.instagram}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram />
      </SocialIconLinkMobile>
      <SocialIconLinkMobile
        href={currentDetail.tiktok}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaTiktok />
      </SocialIconLinkMobile>
      <SocialIconLinkMobile
        href={currentDetail.twitter}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaXTwitter />
      </SocialIconLinkMobile>
      <SocialIconLinkMobile
        href={currentDetail.youtube}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaYoutube />
      </SocialIconLinkMobile>
    </SocialIconsMobile>
    <SectionContent isDarkMode={isDarkMode}>
      Lorem ipsum dolor sit amet, consectetur adipiscing
      elit. Vivamus lacinia odio vitae vestibulum
      vestibulum.
    </SectionContent>
  </div>
);

export const Traits = ({ currentDetail, isDarkMode }) => {
  const [traitIndex, setTraitIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      document
        .querySelectorAll('.circle-indicator')
        .forEach(indicator => {
          indicator.style.animation = `fadeInScale 0.5s ease-in-out forwards`;
        });
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  const displayedTraits = currentDetail.traits.slice(
    traitIndex,
    traitIndex + 6
  );

  return (
    <TabContentMobile isDarkMode={isDarkMode}>
      <TraitContainer>
        {displayedTraits.map((trait, index) => (
          <TraitList
            key={index}
            isDarkMode={isDarkMode}
          >
            <TraitItem>{trait.trait}</TraitItem>
            <TraitItem2>{trait.attribute}</TraitItem2>
            <TraitItem3>
              {trait.price} | {trait.rarity}
            </TraitItem3>
          </TraitList>
        ))}
      </TraitContainer>
    </TabContentMobile>
  );
};

export const AboutDescription = styled.p`
  margin-top: 20px;
  font-size: 1em;
  line-height: 1.5;
  color: ${({ isDarkMode }) =>
    isDarkMode ? '#ddd' : '#666'};
  text-align: center;

  @media (max-width: 600px) {
    font-size: 0.9em;
  }
`;

export const SocialIconsMobile = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  justify-content: center;
`;

export const SocialIconLinkMobile = styled.a`
  font-size: 1.2em;
  color: ${({ isDarkMode }) =>
    isDarkMode ? '#fff' : '#333'};
  &:hover {
    color: yellow;
  }
`;

////////////////////////////////////////////////////////////////////////////////////////
//                              Traits Components                                     //
////////////////////////////////////////////////////////////////////////////////////////

export const TabContentMobile = styled(motion.div)`
  padding: 10px;
  overflow-y: auto;
  max-height: 50vh;
  @media screen and (max-width: 600px) {
    font-size: 0.7rem;
    margin-top: 10px;
  }
`;

export const TraitContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 15px;
`;

export const TraitList = styled(motion.div)`
  background: ${({ isDarkMode }) =>
    isDarkMode
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(0, 0, 0, 0.1)'};
  border-radius: 10px;
  padding: 6px;
  text-align: center;
  font-size: 0.8rem;
`;

export const TraitItem = styled(motion.p)`
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  @media screen and (max-width: 600px) {
    font-size: 8px;
  }
`;

export const TraitItem2 = styled(motion.p)`
  margin-bottom: 5px;
  font-size: 10px;
  @media screen and (max-width: 600px) {
    font-size: 8px;
  }
`;

export const TraitItem3 = styled(motion.p)`
  margin-bottom: 5px;
  font-size: 10px;
  font-weight: bold;
  @media screen and (max-width: 600px) {
    font-size: 8px;
  }
`;

export const TraitsTitle = styled.h4`
  font-size: 1.5em;
  margin-top: 20px;
  color: ${({ isDarkMode }) =>
    isDarkMode ? '#fff' : '#333'};
`;
