import { motion } from 'framer-motion';
import { FaSearch } from 'react-icons/fa';
import { MdCancel, MdVerified } from 'react-icons/md';
import styled, { keyframes } from 'styled-components';

export const lightTheme = {
  background: `
    background: -webkit-linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    background: -o-linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    background: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    color: #333332;
  `,
};

export const darkTheme = {
  background: `
    background: -webkit-linear-gradigent(to top, #283E51, #0A2342);
    background: linear-gradient(to right, #434343 0%, black 100%);
    background: -o-linear-gradient(to top, #283E51, #0A2342);
    background: -webkit-linear-gradient(to right, #434343 0%, black 100%);
    background: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
    color: #FAF4E5;
  `,
};

export const SubNavbar = styled(motion.div)`
  position: absolute;
  top: 10%;
  margin-left: 50%;
  transform: translate(-50%, 0%);


  height: ${({ isMobile }) => (isMobile ? '50px' : '65px')}; /* Placeholder color */
  background-color: ${({ isDarkMode }) => !isDarkMode ? darkTheme.background : lightTheme.background};
  z-index:   9999;
  color: ${({ isDarkMode }) => isDarkMode ? darkTheme.color : lightTheme.color};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
  flex-direction: row;
  max-width: 95%;

  @media (max-width: 600px) {
    top: 8.5%;
    //transform: translate(-50%, 0%) scale(0.75);
    min-height: 60px;
    height: auto;
    position: fixed;
    flex-direction: column;
  }
`;



export const SearchInputWrapper = styled.div`
  position: relative;
  flex: 1;
  margin-right: 1rem;
  @media (max-width: 600px) {
    width: 50%;
    margin: 0;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 15px 0px 10px 40px; /* Add space for the icon */
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1); /* Opaque background */
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')}; /* Text color */
  ::placeholder {
    color: ${({ isDarkMode }) => (isDarkMode ? '#ddd' : '#888')}; /* Placeholder color */
  }
  &:focus {
    outline: none;
  }
`;

export const SearchIcon = styled(FaSearch)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')}; /* Icon color */
`;

export const NavbarItem = styled(motion.div)`
  margin: 0 15px;
  padding: 0 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  white-space: nowrap;

  &.company-info {
    display: flex;
    align-items: center;
  }

  &:after {
    content: '';
    position: absolute;
    height: 20px;
    width: 1px;
    background: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    color: yellow;
    text-shadow: 0 0 5px yellow;
  }

  &:last-child:after {
    display: none;
  }
`;

export const NavbarItemLast = styled(motion.div)`
  margin: 0 15px;
  padding: 0 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  white-space: nowrap;

  &.company-info {
    display: flex;
    align-items: center;
  }

  &:hover {
    color: yellow;
    text-shadow: 0 0 5px yellow;
  }
`;

export const HorzLine = styled(motion.div)`
  width: 50%;
  margin: 10px auto;
  border-bottom: 0.5px solid ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
`;


export const DropdownToggle = styled(motion.div)`
  display: none;
  cursor: pointer;
  font-size: 24px;
  margin-top: -15px;
  @media (max-width: 600px) {
    margin-top: -15px;
    display: block;
  }
`;



export const DropdownMenu = styled(motion.div)`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  }
`;

export const FollowButton = styled(motion.button)`
  background-color: transparent;
  border: 2px solid ${({ isDarkMode }) => !isDarkMode ? '#FAF4E5' : '#333332'};
  color: ${({ isDarkMode }) => !isDarkMode ? '#FAF4E5' : '#333332'};
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${({ isDarkMode }) => isDarkMode ? '#FAF4E5' : '#333332'};
    color: ${({ isDarkMode }) => !isDarkMode ? '#0f0c29' : '#6e8efb'};
  }
`;

export const LastNavbarItem = styled(NavbarItem)`
  &:after {
    display: none;
  }
`;

export const Popup = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 20;
`;

export const CloseButton = styled(motion.button)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const SubscriberCount = styled(motion.span)`
  color: ${({ isSubscribed }) => isSubscribed ? '#00c5ff' : 'inherit'};
  font-size: 0.8em;
  margin-left: 5px;
`;

const animate = keyframes`
  0% {
    top: -30px;
    left: 20px;
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    top: -30px;
    left: 20px;
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    top: -30px;
    left: 20px;
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
`;

export const AnimateBubbles = styled(motion.div)`
  font-family: 'Inter';
  border-radius: 4px;

  &:focus {
    outline: 0;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }

  &:before {
    display: none;
    top: -120%;
    margin-top: -20px;
    transform: scale(1.5);
    background-image:
      radial-gradient(circle, transparent 10%, gold 60%, transparent 20%),
      radial-gradient(circle, transparent 20%, white 20%, transparent 30%),
      radial-gradient(circle, transparent 10%, gold 60%, transparent 20%),
      radial-gradient(circle, transparent 10%, gold 60%, transparent 20%),
      radial-gradient(circle, transparent 10%, white 15%, transparent 20%),
      radial-gradient(circle, transparent 10%,  gold 60%, transparent 20%),
      radial-gradient(circle,transparent 10%,  gold 60%, transparent 20%),
      radial-gradient(circle,transparent 10%,  gold 60%, transparent 20%),
      radial-gradient(circle,transparent 10%,  gold 60%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  }

  &:after {
    display: none;
    transform: scale(1.5);
    bottom: -120%;
    margin-top: 0px;
    background-image:
      radial-gradient(circle,transparent 10%,  gold 60%, transparent 20%),
      radial-gradient(circle,transparent 10%,  gold 60%, transparent 20%),
      radial-gradient(circle, transparent 10%, white 15%, transparent 20%),
      radial-gradient(circle,transparent 10%,  gold 60%, transparent 20%),
      radial-gradient(circle,transparent 10%,  gold 60%, transparent 20%),
      radial-gradient(circle,transparent 10%,  gold 60%, transparent 20%),
      radial-gradient(circle,transparent 10%,  gold 60%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
  }

  &:active {
    transform: scale(0.9);
    background-color: black;
    box-shadow: 0 2px 25px rgba(192, 192, 192, 0.2);
  }

  &.animate {
    &:before {
      display: block;
      animation: ${animate} ease-in-out 0.75s forwards;
    }
    &:after {
      display: block;
      animation: ${animate} ease-in-out 0.75s forwards;
    }
  }
`;

export const DiamondButton = styled(AnimateBubbles)`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${({ isDiamondClicked }) => isDiamondClicked ? 'gold' : 'inherit'};
`;

export const DiamondColorWrap = styled(motion.span)`
  color: ${({ isDiamondClicked }) => isDiamondClicked ? 'yellow' : 'inherit'};
`;

export const DiamondCount = styled(motion.span)`
  color: ${({ isDiamondClicked }) => isDiamondClicked ? 'yellow' : 'inherit'};
  font-size: 0.8em;
  margin-left: 5px;
`;

export const CompanyInfo = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CompanySymbol = styled(motion.img)`
  height: 60px; /* Adjust size as needed */
  width: 60px;
  cursor: pointer;
  border-radius: 100%;
  position: relative;
  margin-right: 20px;
  @media (max-width: 600px) {
    height: 60px; /* Adjust size as needed */
    width: 60px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;

  }
  
`;

export const CompanyContainer = styled(motion.div)`
  position: relative;
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 7.5px;
    right: 0px;
  }
`;




export const VerifiedSymbol = styled(MdVerified)`
  position: absolute;
  top: -3px;
  right: 15px;
  color: #68ceff;
  //background-color: white;
 // border-radius: 100%;
  padding: 2px;
  font-size: 18px;
  
`;



export const CompanyName = styled(motion.img)`
  width: 150px; // Adjust as needed
  height: auto;
  white-space: nowrap;
  //font-size: 1.2em; // Adjust size as needed
  font-weight: bold;
`;

export const ProjectName = styled(motion.span)`
  font-size: 1em; // Adjust size as needed
 // color: ${({ isDarkMode }) => !isDarkMode ? '#FAF4E5' : '#333332'};
 @media (max-width: 600px) {
    //transform: translate(-50%, 0%) scale(0.75);
    margin-top: 15px;
    font-size: 0.8em;
  }
`;

export const CompanyLink = styled(motion.a)`
  text-decoration: none; // Removes underline
  color: ${({ isDarkMode }) => !isDarkMode ? '#FAF4E5' : '#333332'};
  cursor: pointer;

  &:hover {
    text-decoration: underline; // Optional: underline on hover
  }
`;

export const WebsiteLabel = styled(motion.span)`
  margin-right: 5px;
  zoom: 0.8;
  color: ${({ isDarkMode }) => !isDarkMode ? '#FAF4E5' : '#333332'};
`;

// ExitIcon
export const ExitIcon = styled(MdCancel)` 
  cursor: pointer;
  // background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  border-radius: 100%;

  // transform: translate(10px, 10px) rotate()(180deg);
  transition: transform 3300ms ease-in-out;
  font-size: 32px; /* Adjust the font size as per your requirements */
  //z-index: -10000000;
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
  //display: flex;
  &:hover {
  }
`;

export const PExit = styled(motion.div)`
  position: absolute;
  right: 2.5%;
  transition: transform 30ms ease-in-out;
  top: 2.5%;
  cursor: pointer;
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};

  &:hover {
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    //background-color: #000;
    //transform: translate(10px, 10px) rotate()(360deg);
    //right: -5px;
    //filter: drop-shadow(0 0 10px white);
  }
  &:hover ${ExitIcon} {
    transform-origin: center;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    // background-color: #000;
    // transform:  rotateZ(180deg);
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    filter: drop-shadow(0 0 5px white);
  }
`;

export const ExitIconWrap = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center; /* Set the transform origin to the center */
  cursor: pointer;
`;
