import { Canvas, useFrame } from "@react-three/fiber";
import { EffectComposer, TiltShift2 } from "@react-three/postprocessing";
import React, { useRef } from 'react';
import symbol from '../../../../images/w3oBlackLogo.png';
import icon from '../../../../images/w3oNameHorzBlack.png';
import icon2 from '../../../../images/w3oNameHorzWhite.png';
// Closet Item Imports
import {
  CanvasWrapper,
  CardContainer,
  Description2,
  Header,
  LogoImage,
  SymbolImage
} from './ItemElements';

import { itemModels } from "../Closet/W3oUserDefaultCloset";
const RotatingModel = ({ hoveredItem }) => {
    const ModelComponent = itemModels[hoveredItem];
    const ref = useRef();
  
    useFrame(() => {
      if (ref.current) {
        ref.current.rotation.y += 0.01; // Adjust rotation speed as needed
      }
    });
  
    return ModelComponent ? <group scale={0.1} ref={ref}><ModelComponent /></group> : null;
  };
  


// Framer Motion animations
const rotateAnimation = {
    hidden: { rotateZ: 0 },
    visible: { rotateZ: 180 },
  };
  
  const scaleAnimation = {
    hidden: { scale: 1 },
    visible: { scale: 1.02 },
  };
  
  const fadeInScaleAnimation = {
    hidden: { opacity: 0, scale: 0, filter: 'blur(15px)' },
    visible: { opacity: 1, scale: 1, filter: 'blur(0)' },
  };
  
  const blurIn = {
    hidden: { opacity: 0, y: -2, filter: 'blur(15px)' },
    visible: { opacity: 1, y: 0, filter: 'blur(0)' },
  };
  
  const scaleIn = {
    hidden: { scale: 0.5, filter: 'blur(15px)' },
    visible: { scale: 1, filter: 'blur(0)' },
  };
  
  const fadeIn = {
    hidden: { opacity: 0, scale: 0.8, filter: 'blur(15px)' },
    visible: { opacity: 1, scale: 1, filter: 'blur(0px)' },
  };
  
  const rotateIn = {
    hidden: { opacity: 0, rotateX: -90, rotateZ: -90 },
    visible: { opacity: 1, rotateX: 0, rotateZ: 0 },
  };
  
  const slideIn = {
    hidden: { opacity: 0, x: 200, filter: 'blur(15px)' },
    visible: { opacity: 1, x: 0, filter: 'blur(0px)' },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.2 // stagger the animation of children
      }
    },
    exit: { opacity: 0, transition: { duration: 0.5 } }
  };
  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
  };


export const ItemDetailsCardHTML = ({ details, isDarkMode, objectRef, hoveredGroup, currentIndex, itemDetailsList }) => {
    if (!details) return null;
  
    const logoSrc = isDarkMode ? icon : icon2;
    const isSelected = details.group === itemDetailsList[currentIndex]?.group;
  
    return (
      <CardContainer
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        isDarkMode={isDarkMode}
        style={{ borderColor: isSelected ? 'yellow' : 'transparent', borderWidth: isSelected ? '2px' : '0', borderStyle: 'solid' }}
      >
        <CanvasWrapper style={{ width: '300px', height: '200px' }}>
          <Canvas style={{ width: '100%', height: '100%' }} camera={{ position: [2, 2, 2], fov: 50 }}>
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} intensity={0.8} />
            <spotLight position={[-10, 10, -10]} angle={0.15} penumbra={1} intensity={1} castShadow />
            <spotLight position={[10, -10, 10]} angle={0.15} penumbra={1} intensity={1} castShadow />
            <RotatingModel hoveredItem={hoveredGroup} />
            <EffectComposer stencilBuffer disableNormalPass autoClear={false} multisampling={4}>
            
              <TiltShift2 samples={5} blur={0.1} />
            </EffectComposer>
          </Canvas>
        </CanvasWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SymbolImage src={symbol} alt="Symbol" variants={childVariants} />
          <LogoImage src={logoSrc} alt="Logo" variants={childVariants} />
        </div>
        <Header variants={childVariants}>{details.header}</Header>
        <Description2 variants={childVariants}>{details.name}</Description2>
        <Description2 variants={childVariants}>{details.description}</Description2>
      </CardContainer>
    );
  };