
import { FaDiscord } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import styled from 'styled-components';
// Styled Underlay components
export const UnderlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  pointer-events: none;
  //z-index: 9;
`;

export const TitleContainer = styled.div`
  width: 100%;
  padding: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TitleText = styled.img`
 position: fixed;
 left: 9.5%;
 top: 1.5%;
   //display: flex;
   position: fixed;
    width: 235px;
    height: 40px;

   // transform: translateX(-50%) scale(1.25);
    text-decoration: none;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
`;

export const TitleIcon = styled.img`
 top: 3%;
  height: 35px;
  line-height: 35px;
  position: fixed;
  left: 2.5%;
`;

export const MiddleContainer = styled.div`
  height: 60px;
`;

export const CenteredContainer = styled.div`
  width: 100%;
  padding: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

export const LeftSpacer = styled.div`
  width: 10px;
`;

export const FullWidthContainer = styled.div`
  font-family: 'Antonio', sans-serif;
  width: 100%;
  flex: 1 1 0%;
  padding: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 568px) {

    zoom: 0.55;
  }
  
`;

export const ComingSoonLeft = styled.p`
  font-size: 100px;
  line-height: 1em;
  color: black;
  margin: 0;
  letter-spacing: -10px;
`;

export const ComingSoonRight = styled.p`
  font-size: 100px;
  line-height: 100%;
  text-align: right;
  color: black;
  letter-spacing: -10px;
`;

export const BottomContainer = styled.div`
  pointer-events: all;
  position: absolute;
  bottom: 3%;
  cursor: pointer;
  width: 95%;
  padding: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

export const FullWidthText = styled.p`
  white-space: nowrap;
  position: relative;
  top: 10px;
  flex: 1 1 0%;
  font-size: 12px;
  line-height: 1.5em;
  color: black;

  & b {
    font-weight: bold;
  }
`;

export const CenteredTitle = styled.p`
  font-family: 'Antonio', sans-serif;
  //flex: 1 1 0%;
  position: relative;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1em;
  text-align: center;
  color: black;
  letter-spacing: -0.5px;
  white-space: nowrap;
`;

export const OverlayContainer = styled.div`
  position: absolute;
 // bottom: 25px;
  right: 5%;
`;

export const OverlayLink = styled.a`
  flex: 1 1 0%;
  font-size: 12px;
  line-height: 1em;
  text-align: right;
  color: black;
  text-decoration: none;
`;

export const UnderlayBody = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: linear-gradient(180deg, #e6eaf5 0%, #f6f6f6 80%);
  overflow: hidden;
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: "Inter var", sans-serif;
  cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjUgMTZDMzAuNSAyNC4wMDgxIDI0LjAwODEgMzAuNSAxNiAzMC41QzcuOTkxODcgMzAuNSAxLjUgMjQuMDA4MSAxLjUgMTZDMS41IDcuOTkxODcgNy45OTE4NyAxLjUgMTYgMS41QzI0LjAwODEgMS41IDMwLjUgNy45OTE4NyAzMC41IDE2WiIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNiA5QzcuNjU2ODUgOSA5IDcuNjU2ODUgOSA2QzkgNC4zNDMxNSA3LjY1Njg1IDMgNiAzQzQuMzQzMTUgMyAzIDQuMzQzMTUgMyA2QzMgNy42NTY4NSA0LjM0MzE1IDkgNiA5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="),
    auto;

`

export const Verified = styled(MdVerified)`
    font-size: 24px; 
    color: #68ceff;
    

`

export const Discord = styled(FaDiscord)`
    font-size: 24px; 
    color: #68ceff;
    top: 9px;
    position: relative;
    border:0;
`
export const Whitelist = styled.img`
    height: 24px;
    width: 24px;
    top: 7px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;

`