
// ClosetModel.js

import { Environment, Lightformer } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { easing } from 'maath';
import React, { useRef } from 'react';




export function EnvDesktop({ perfSucks, orbitControlsEnabled, BLOOM_LAYER }) {
    const ref = useRef();
  
    useFrame((state, delta) => {
      if (!perfSucks && !orbitControlsEnabled) {
        easing.damp3(ref.current.rotation, [Math.PI / 2, 0, state.clock.elapsedTime / 50], 0.2, delta);
        easing.damp3(state.camera.position, [Math.sin(state.pointer.x / 400000) * 9, 1.25, Math.cos(state.pointer.x / 4) * 9], 0.5, delta);
        state.camera.lookAt(0, 0, 0);
        state.camera.layers.enable(BLOOM_LAYER);
      }
    });
  
    return (
      <Environment background={false} files="https://w3omedia.s3.us-east-2.amazonaws.com/w3o+hdr/metro_noord_1k.hdr" path="" preset={null} frames={perfSucks ? 1 : Infinity} resolution={256} blur={0}>
        <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
        <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
        <group rotation={[Math.PI / 2, 1, 0]}>
          {[2, -2, 2, -4, 2, -5, 2, -9].map((x, i) => (
            <Lightformer key={i} intensity={10} rotation={[Math.PI / 4, 0, 0]} position={[x, 4, i * 4]} scale={[4, 1, 1]} />
          ))}
          <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
          <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
          <Lightformer intensity={0.5} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[50, 2, 1]} />
        </group>
        <group ref={ref}>
          <Lightformer intensity={1} form="ring" color="ADD7F2" rotation-y={Math.PI / 2} position={[-5, 2, -1]} scale={[0, 0, 0]} />
        </group>
      </Environment>
    );
  }
  export function EnvMobile({ perfSucks, orbitControlsEnabled, BLOOM_LAYER }) {
    const { camera } = useThree();
  
    useFrame(() => {
      if (!perfSucks && !orbitControlsEnabled) {
        // Simplify camera animation for performance
        camera.position.lerp({ x: 0, y: 1.25, z: 9 }, 0.05);
        camera.lookAt(0, 0, 0);
        camera.layers.enable(BLOOM_LAYER);
      }
    });
  
    return (
      <Environment
        background={false}
        files="https://w3omedia.s3.us-east-2.amazonaws.com/w3o+hdr/metro_noord_1k.hdr"
        frames={perfSucks ? 1 : Infinity}
        resolution={64} // Lower resolution for better performance on mobile
        blur={0.5}      // Slight blur to reduce sharp edges and load
      >
        {/* Simplified lighting setup */}
        <ambientLight intensity={0.5} />
        <directionalLight intensity={0.7} position={[5, 10, 7.5]} />
      </Environment>
    );
  }





  export function EnvDao({ perfSucks }) {
    const ref = useRef();
    const width = window.innerWidth;
    const sensitivity = width <= 600 ? 0.2 : 1;
    const rangeFactor = width <= 600 ? 0.5 : 1; // Reducing the range for smaller screens
  
    useFrame((state, delta) => {
      if (!perfSucks) {
        easing.damp3(ref.current.rotation, [Math.PI / 2, 0, (state.clock.elapsedTime / 50 + state.pointer.x * sensitivity) * rangeFactor], 0.2, delta);
        easing.damp3(state.camera.position, [
          Math.sin(state.pointer.x / 30 * sensitivity) * 9 ,
          1.25 + state.pointer.y / 5 * sensitivity ,
          Math.cos(state.pointer.x / 4 * sensitivity) * 9 
        ], 0.5, delta);
        state.camera.lookAt(0, 0, 0);
      }
    });
  
    return (
      <Environment frames={perfSucks ? 1 : Infinity} resolution={256} background blur={0}>
        <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
        <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
        <group rotation={[Math.PI / 2, 1, 0]}>
          {[2, -2, 2, -4, 2, -5, 2, -9].map((x, i) => (
            <Lightformer key={i} intensity={10} rotation={[Math.PI / 4, 0, 0]} position={[x, 4, i * 4]} scale={[4, 1, 1]} />
          ))}
          <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
          <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
          <Lightformer intensity={0.5} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[50, 2, 1]} />
        </group>
        <group ref={ref}>
          <Lightformer intensity={1} form="ring" color="ADD7F2" rotation-y={Math.PI / 2} position={[-5, 2, -1]} scale={[0, 0, 0]} />
        </group>
      </Environment>
    );
  }
  
  
  
  export function EnvCloset({ perfSucks, orbitControlsEnabled }) {
    const ref = useRef();
  
    useFrame((state, delta) => {
      if (!perfSucks && !orbitControlsEnabled) {
        easing.damp3(ref.current.rotation, [Math.PI / 2, 0, state.clock.elapsedTime / 50 + state.pointer.x], 0.2, delta);
        easing.damp3(state.camera.position, [Math.sin(state.pointer.x / 4) * 9, 1.25 + state.pointer.y, Math.cos(state.pointer.x / 4) * 9], 0.5, delta);
        state.camera.lookAt(0, 0, 0);
      }
    });
  
    return (
      <Environment background={false} // Set to `true` if you want this to be visible as your scene's background
      files="https://w3omedia.s3.us-east-2.amazonaws.com/w3o+hdr/metro_noord_1k.hdr"
      path="" // Since you're using a direct URL, the path isn't needed.
      preset={null} // Not using a preset since you're loading a custom HDR
  frames={perfSucks ? 1 : Infinity} resolution={256}  blur={0}>
        <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
        <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
        <group rotation={[Math.PI / 2, 1, 0]}>
          {[2, -2, 2, -4, 2, -5, 2, -9].map((x, i) => (
            <Lightformer key={i} intensity={10} rotation={[Math.PI / 4, 0, 0]} position={[x, 4, i * 4]} scale={[4, 1, 1]} />
          ))}
          <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
          <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
          <Lightformer intensity={0.5} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[50, 2, 1]} />
        </group>
        <group ref={ref}>
          <Lightformer intensity={1} form="ring" color="ADD7F2" rotation-y={Math.PI / 2} position={[-5, 2, -1]} scale={[0, 0, 0]} />
        </group>
      </Environment>
    );
  }