
import styled, { keyframes } from 'styled-components';


export const CompanyAssetContainerWrapper = styled.div`
       height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999;
  overflow: hidden; /* Enable vertical scrolling */
  position: absolute;
  -webkit-overflow-scrolling: touch;
  //margin-bottom: 200px;


`

export const CompanyAssetContainer = styled.div`
       height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999;
  overflow: hidden; /* Enable vertical scrolling */
  position: absolute;
  -webkit-overflow-scrolling: touch;
  //margin-bottom: 200px;


`


/////////////////
// Sub Navbar // 
///////////////

// Define the themes with a more futuristic styling




//Scroll to Explore Components



///
/// Scroll to begin
///
export const colorBg = '#1E2A33'; // A dark blueish background
export const colorOutline = '#FF8C42'; // An orange outline color
export const colorOutlineFade = '#596268'; // A muted teal color for faded outlines


export const widthMouse = '52px';
export const heightMouse = '88px';
export const borderMouse = '6px';

export const posMouse = '8px';
export const posText = '2px';

export const sizeTrackball = '10px';
export const posTrackball = '20px';
export const shrinkTrackball = '0.4';

export const animDuration = '5s';



export const colorSlide = keyframes`
  0% { background-position: 0% 100%; }
  20% { background-position: 0% 0%; }
  21% { background-color: ${colorOutlineFade}; }
  29.99% { 
    background-color: ${colorOutline};
    background-position: 0% 0%;
  }
  30% { 
    background-color: ${colorOutlineFade};
    background-position: 0% 100%;
  }
  50% { background-position: 0% 0%; }
  51% { background-color: ${colorOutlineFade}; }
  59% { 
    background-color: ${colorOutline};
    background-position: 0% 0%;
  }
  60% { 
    background-color: ${colorOutlineFade};
    background-position: 0% 100%;
  }
  80% { background-position: 0% 0%; }
  81% { background-color: ${colorOutlineFade}; }
  90%, 100% { background-color: ${colorOutline}; }
`;

export const trackBallSlide = keyframes`
  0% {
    transform: scale(1) translateY(-${posTrackball});
    opacity: 1;
  }
  6% { 
    opacity: 1;
    transform: scale(0.9) translateY(${posTrackball / 4});
  }
  14% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateY(${posTrackball * 2});
  }
  15%, 19% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateY(-${posTrackball});
  }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-${posTrackball});
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-${posTrackball});
  }
  36% { 
    opacity: 1;
    transform: scale(0.9) translateY(${posTrackball / 4});
  }
  44% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateY(${posTrackball * 2});
  }
  45%, 49% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateY(-${posTrackball});
  }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateY(-${posTrackball});
  }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-${posTrackball});
  }
  66% { 
    opacity: 1;
    transform: scale(0.9) translateY(${posTrackball / 4});
  }
  74% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateY(${posTrackball * 2});
  }
  75%, 79% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateY(-${posTrackball});
  }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateY(-${posTrackball});
  }
`;

export const nudgeMouse = keyframes`
  0% { transform: translateY(0); }
  20% { transform: translateY(${posMouse}); }
  30% { transform: translateY(0); }
  50% { transform: translateY(${posMouse}); }
  60% { transform: translateY(0); }
  80% { transform: translateY(${posMouse}); }
  90% { transform: translateY(0); }
`;

export const nudgeText = keyframes`
  0% { transform: translateY(0); }
  20% { transform: translateY(${posText}); }
  30% { transform: translateY(0); }
  50% { transform: translateY(${posText}); }
  60% { transform: translateY(0); }
  80% { transform: translateY(${posText}); }
  90% { transform: translateY(0); }
`;

export const colorText = keyframes`
  21% { color: ${colorOutlineFade}; }
  30% { color: ${colorOutline}; }
  51% { color: ${colorOutlineFade}; }
  60% { color: ${colorOutline}; }
  81% { color: ${colorOutlineFade}; }
  90% { color: ${colorOutline}; }
`;

const bgGradient = () => `
  background:
    ${colorOutlineFade}
    linear-gradient(
      transparent 0%,
      transparent 50%,
      ${colorOutline} 50%,
      ${colorOutline} 100%
    );
`;

export const MainMouseBody = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: -1;
  left: 10%;
  white-space: nowrap;
  transform: translateX(-50%) scale(0.25);
  @media (max-width: 1200px) {
    grid-template-columns: ${({ isFullScreen }) => (isFullScreen ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)')}; // Adjust based on isFullScreen
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr); // 2 columns when width is <= 900px
  }

  @media (max-width: 600px) {
    left: 50%;
    transform: translateX(-50%) scale(0.25);
  }
`;

export const ScrollText = styled.p`
  margin-top: 50px;
  font-family: "Cabin", sans-serif;
  letter-spacing: 12px;
  text-indent: 12px;
  font-size: 32px;
  font-weight: 900;
  color: ${colorOutline};
  animation: 
    ${colorText} ${animDuration} ease-out infinite,
    ${nudgeText} ${animDuration} ease-out infinite;
`;




export const MouseIcon = styled.div`
  ${bgGradient()}
  position: relative;
  will-change: background-position, transform;
  width: ${widthMouse};
  height: ${heightMouse};
  zoom: 0.7;
  border-radius: 100px;
  background-size: 100% 200%;
  animation: 
    ${colorSlide} ${animDuration} linear infinite,
    ${nudgeMouse} ${animDuration} ease-out infinite;
    will-change: transform, background-color;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: auto;
  }
  
  &:before {
    width: calc(${widthMouse} - ${borderMouse});
    height: calc(${heightMouse} - ${borderMouse});
    background-color: ${colorBg};
    border-radius: 100px;
  }
  
  &:after {
    background-color: ${colorOutline};
    width: ${sizeTrackball};
    height: ${sizeTrackball};
    border-radius: 100%;
    animation: ${trackBallSlide} ${animDuration} linear infinite;
  }
`;

export const ScrollEffect = styled.div`
  opacity: ${props => props.opacity};
  transition: opacity 0.3s ease-in-out;
`;



export const BlurBackground = styled.div`
  backdrop-filter: ${props => (props.isMouseVisible ? 'blur(40px)' : 'none')};
  background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 0.85)' : 'rgba(255, 255, 255, 0.65)')};
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1; // Adjust z-index as needed
`;


