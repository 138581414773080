import { FaDiscord, FaEthereum, FaGlobe, FaTwitter } from 'react-icons/fa';
import symbol from '../../../../images/w3oBlackLogo.png';
import {
  ExitIcon, ExitIconWrap,
  IconWrapper,
  PExit,
  ProjectDescription,
  ProjectDetails,
  ProjectGraphic,
  ProjectInfoColumn,
  ProjectInfoGrid,
  ProjectPopup,
  ProjectTitle
} from "./ProjectElements";

export const ProjectPopUp = ({ isDarkMode, handleCloseAbout }) => {
  return (
    <ProjectPopup
      initial={{ opacity: 0, scale: 0.4, translateX: '-50%', translateY: '-50%' }} // Starting state
      animate={{ opacity: 1, scale: 1, translateX: '-50%', translateY: '-50%' }}   // Animation state
      exit={{ opacity: 0, scale: 0.4, translateX: '-50%', translateY: '-50%' }}    // Exit state
      transition={{ duration: 0.3 }}   
      isDarkMode={isDarkMode}                                             // Transition duration
    >
      <ProjectGraphic src={symbol} alt="Project Graphic" />
      <ProjectDetails>
        <div>
          <ProjectTitle>
            Exclusive NFT Collection
            <IconWrapper>
              <FaDiscord />
              <FaTwitter />
              <FaGlobe />
            </IconWrapper>
          </ProjectTitle>
          <h4 style={{ marginBottom: '10px' }}>Web³ Outfitters</h4>
          <ProjectDescription>
            This is a unique collection of exclusive NFTs by Web³ Outfitters, offering a range of digital items that are one-of-a-kind, each representing a piece of the future in fashion and technology.
          </ProjectDescription>
        </div>
        <hr style={{ margin: '20px auto', width: '900px' }} /> {/* Centered Horizontal line */}

        <ProjectInfoGrid>
          <ProjectInfoColumn>
            <ProjectDescription><strong>Items</strong></ProjectDescription>
            <ProjectDescription>21.7K</ProjectDescription>
          </ProjectInfoColumn>
          <span className="dot-divider">·</span>
          <ProjectInfoColumn>
            <ProjectDescription><strong>Created</strong></ProjectDescription>
            <ProjectDescription>Dec 2021</ProjectDescription>
          </ProjectInfoColumn>
          <span className="dot-divider">·</span>
          <ProjectInfoColumn>
            <ProjectDescription><strong>Creator earnings</strong></ProjectDescription>
            <ProjectDescription>5%</ProjectDescription>
          </ProjectInfoColumn>
          <span className="dot-divider">·</span>
          <ProjectInfoColumn>
            <ProjectDescription><strong>Chain</strong></ProjectDescription>
            <ProjectDescription>Ethereum</ProjectDescription>
          </ProjectInfoColumn>
        </ProjectInfoGrid>

        <hr style={{ margin: '5px auto', width: '500px' }} /> {/* Centered Horizontal line */}

        <ProjectInfoGrid className="smaller-text">
          <ProjectInfoColumn>
            <ProjectDescription><strong>79,415 ETH</strong></ProjectDescription>
            <ProjectDescription><FaEthereum style={{ marginRight: '3px' }} /></ProjectDescription>
          </ProjectInfoColumn>
          <span className="dot-divider">·</span>
          <ProjectInfoColumn>
            <ProjectDescription><strong>0.7649 ETH</strong></ProjectDescription>
            <ProjectDescription><strong>Floor price</strong></ProjectDescription>
          </ProjectInfoColumn>
          <span className="dot-divider">·</span>
          <ProjectInfoColumn>
            <ProjectDescription><strong>0.7116 WETH</strong></ProjectDescription>
            <ProjectDescription><strong>Best offer</strong></ProjectDescription>
          </ProjectInfoColumn>
          <span className="dot-divider">·</span>
          <ProjectInfoColumn>
            <ProjectDescription><strong>1%</strong></ProjectDescription>
            <ProjectDescription><strong>Listed</strong></ProjectDescription>
          </ProjectInfoColumn>

          <ProjectInfoColumn>
            <ProjectDescription><strong>8,282 (38%)</strong></ProjectDescription>
            <ProjectDescription><strong>Owners</strong></ProjectDescription>
          </ProjectInfoColumn>
        </ProjectInfoGrid>
      </ProjectDetails>
      <PExit isDarkMode={isDarkMode} className='close-btn' onClick={handleCloseAbout}>
        <ExitIconWrap
          whileHover={{ rotate: 180, transition: { duration: 0.25 } }}
          whileTap={{ rotate: 180, transition: { duration: 0.25 } }}
        >
          <ExitIcon whileHover={{ color: isDarkMode ? '#000' : '#fff', backgroundColor: isDarkMode ? '#fff' : '#000' }} />
        </ExitIconWrap>
      </PExit>
    </ProjectPopup>
  );
};
