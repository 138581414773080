import axios from 'axios';
import emailjs from 'emailjs-com';
import React, { useRef, useState } from 'react';
import { FaDiscord, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { IconCheckmark, MessageContainer, SuccessMessage, SuccessMessageContent, SuccessMessageTitle } from '../Global/GlobalElements';
import "./Footer.scss";
import {
  BottomLink,
  BottomRightContainer,
  ButtonText,
  CenteredTitle,
  Closet,
  ClosetIcon,
  Container,
  Description,
  DiscordButton,
  FooterBottomLine,
  FooterContainer,
  FooterContainerWrapper,
  FooterInfo,
  FooterLink,
  FooterLinksWrapper,
  FooterTopLine,
  FormBox,
  FullWidthText,
  Input,
  InputIcon,
  InputMain,
  InstagramButton,
  Privacy,
  RoundedSocialButtons,
  SocialMediaText,
  SocialMediaWrap,
  SubmitButton,
  Terms,
  TextSpan,
  TikTokButton,
  Title,
  Verified,
  Wallet,
  WalletIcon,
  XButton,
  YoutubeButton
} from './FooterElements';

import bg2 from '../../images/middleBG.png';
import bg from '../../images/w3oDayBg.svg';
  


  



const Footer = ({isDarkMode, setWhitelistPopup, whitelistPopup, setDaoPopup, daoPopup,  setButtonPopup, setButtonPopup2, setButtonPopup3}) => {
  const [isWalletHovered, setIsWalletHovered] = useState(false);
  const [isClosetHovered, setIsClosetHovered] = useState(false);


  const handleWalletHover = () => {
    setIsWalletHovered(true);
  };
  const handleWalletMouseLeave = () => {
    setIsWalletHovered(false);
  };

  const handleClosetMouseLeave = () => {
    setIsClosetHovered(false);
  };
  const handleClosetHover = () => {
    setIsClosetHovered(true);
  }

  const [email, setEmail] = useState('');
   // const [form4Values, setFormValues] = useState(initialValues);
   // const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const data = {
      Email: email
    };

    const handleChange = (e) => {
  //    const { name, value } = e.target;
  //    setFormValues({ ...formValues, [name]: value });
    };

    const form = useRef();




    const sendEmail = (e, isSubmit) => {
      // Prevent the default form submission
      e.preventDefault();
    
      // Your EmailJS service ID, template ID, and Public Key
      const serviceId = "newsletterFormService";
      const templateId = "web3o_Newsletter";
      const publicKey = "8y9ziEfWJ76msEZ-8";
    
      // Create a new object that contains dynamic template params
      const templateParams = {
        Email: email,
        to_name: "Web 3 Outfitters",
      };
    
      // Validate the form data
      const validationErrors = validate({  email });
    
      if (Object.keys(validationErrors).length === 0) {
        // No validation errors, proceed to send the email using EmailJS
        emailjs
          .send(serviceId, templateId, templateParams, publicKey)
          .then((response) => {
            console.log('Email sent successfully!', response);
            setEmail('');
          })
          .catch((error) => {
            console.error('Error sending email:', error);
          });

          setIsSubmit(true);

          axios.post('https://sheet.best/api/sheets/ab49fc75-bf83-4a2a-88a9-8514f1d6a1cf', data).then((response)=>{
            console.log(response);
          })


      } else {
        // Handle validation errors (e.g., display error messages to the user)
        console.log('Validation errors:', validationErrors);
        // You can set validationErrors in the state and display them to the user
      }
    };



    const validate = (v) => {
      const errors = {};
    
      // Regular expression for a more comprehensive email validation
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    
  
      if (!v.email) {
        errors.email = "Email field can't be empty";
      } else if (!emailRegex.test(v.email)) {
        errors.email = "Invalid email address";
      }
    

      return errors;
    };
   
 
    /*
    const handleClick1 = () => {
        window.open("https://www.facebook.com/kmrenovations2020");
      };*/


      //handles timed popup by eliminating rapid popup (useEffect helps)
      


    return (
      <>
        <FooterContainer style={{ backgroundImage: `url(${isDarkMode ? bg2 : bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}  >
        
          <FooterContainerWrapper>

          <Container >

            <Title  isDarkMode={isDarkMode} >subscribe</Title>
            <Description  isDarkMode={isDarkMode} >Subscribe to our newsletter & stay updated!</Description>
            <FormBox ref={form}  onSubmit={sendEmail} >
              <InputMain>
                <Input onChange={(e) => setEmail(e.target.value)} value={email} type="email" name="email" placeholder="Your Email" />
                <InputIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                </InputIcon>
              </InputMain>
              <SubmitButton type="submit">Submit</SubmitButton>
            </FormBox>

            { isSubmit ? (
                            <MessageContainer>
                        <SuccessMessage initial={isSubmit ? { opacity: 0, scale: 0.8 } : { opacity: 1, scale: 1 }}
                                        animate={isSubmit ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
                                         transition={{ duration: 0.5 }} style={{ backgroundImage: `url(${isDarkMode ? bg2 : bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                          <IconCheckmark initial={isSubmit ? { scale: 0, rotate: 360 } : { scale: 1, rotate: 0 }}
          animate={isSubmit ? { scale: 1, rotate: 0 } : { scale: 0, rotate: 360 }}
          transition={{ duration: 0.5, delay: 0.2 }} viewBox="0 0 76 76" >
                            <circle cx="38" cy="38" r="36" />
                            <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M17.7,40.9l10.9,10.9l28.7-28.7" />
                          </IconCheckmark>
                          <SuccessMessageTitle  initial={isSubmit ? { opacity: 0 } : { opacity: 1 }}
                                                animate={isSubmit ? { opacity: 1 } : { opacity: 0 }}
                                                transition={{ duration: 0.5, delay: 0.4 }}> Message Received </SuccessMessageTitle>

                          <SuccessMessageContent initial={isSubmit ? { opacity: 0 } : { opacity: 1 }}
                                                  animate={isSubmit ? { opacity: 1 } : { opacity: 0 }}
                                                   transition={{ duration: 0.5, delay: 0.6 }}>
                            <p>A confirmation email has been sent!</p>
                          </SuccessMessageContent>
                        </SuccessMessage>
                        </MessageContainer>
      ) : (
        <pre></pre>
      )}
          </Container>


         <FooterTopLine/>
 
          
        

           <FooterInfo>
       
           <FullWidthText isDarkMode={isDarkMode}>
            <b> Creative Collab <span style={{fontSize: `12px`}}>©</span> {new Date().getFullYear()}  </b>
            <br />
            Create. Collab. Together.
        </FullWidthText>

     
   

        <CenteredTitle isDarkMode={isDarkMode}>AUTHENTICATED FASHION<Verified/></CenteredTitle>


{/* ... other content of your app */}

<BottomRightContainer isDarkMode={isDarkMode}> 
    <BottomLink isDarkMode={isDarkMode} href="/Tos"><Terms isDarkMode={isDarkMode}/> Terms of Service</BottomLink>{' '}
    <BottomLink isDarkMode={isDarkMode} href="/PrivacyPolicy"><Privacy isDarkMode={isDarkMode}/> Privacy</BottomLink>{' '}
</BottomRightContainer>

      </FooterInfo>


      <FooterLinksWrapper>

                  <Closet                    whileHover={{ scale: 1.1}}      
                   onMouseEnter={handleClosetHover}
                     onMouseLeave={handleClosetMouseLeave}>
                         
                  
                                      <FooterLink     onClick={() => {setDaoPopup(true)}} isDarkMode={isDarkMode}  smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>  
                                        <ButtonText>
                                          <TextSpan style={{marginLeft: '5px'}} isDarkMode={isDarkMode}>Join the Team</TextSpan>  
                                          <ClosetIcon  style={{marginLeft: '40px'}} isDarkMode={isDarkMode} />
                                        </ButtonText> 
                                      </FooterLink>
                                
                   

                              </Closet>


                              <Wallet         onMouseEnter={handleWalletHover}
                                  onMouseLeave={handleWalletMouseLeave}
                                  whileHover={{ scale: 1.1}} 
                                  onClick={() => {setWhitelistPopup(true)}} >
                            
                           
                                      <FooterLink     isDarkMode={isDarkMode} /*state = {user} *//*  onClick={loadWeb3} */ smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>   
                                      <ButtonText>
                                            <TextSpan  isDarkMode={isDarkMode} >Get Whitelisted</TextSpan>  
                                            <WalletIcon isDarkMode={isDarkMode}  />
                                          </ButtonText> 
                                      </FooterLink>
                
                      
                              </Wallet>
               


</FooterLinksWrapper>

<SocialMediaText  isDarkMode={isDarkMode} >JOIN OUR COMMUNITY!</SocialMediaText>
                <SocialMediaWrap>
               
                <RoundedSocialButtons>

                <XButton   href="https://twitter.com/web3outfitters" target="_blank">
                    <FaXTwitter />
                  </XButton>

                  <DiscordButton   href="https://discord.gg/qSk7XKdtHa" target="_blank" >
                    <FaDiscord />
                  </DiscordButton>

                  <InstagramButton href="https://www.instagram.com/web3outfitters" target="_blank">
                    <FaInstagram />
                  </InstagramButton>

                  {  /*     <RedditButton  href="https://www.reddit.com/" target="_blank" >
                    <IoLogoReddit />
                  </RedditButton>

                  <LinkedinButton  href="https://www.linkedin.com/" target="_blank">
                    <FaLinkedin />
                  </LinkedinButton>

                  <FacebookButton    href="https://www.facebook.com/" target="_blank">
                    <FaFacebookF />
                  </FacebookButton>
                  */}

                  <YoutubeButton href="https://www.youtube.com/@web3outfitters" target="_blank">
                    <FaYoutube />
                  </YoutubeButton>


                  <TikTokButton href="https://www.tiktok.com/web3outfitters" target="_blank" >
                    <FaTiktok />
                  </TikTokButton>

                </RoundedSocialButtons>
                </SocialMediaWrap>

      
            <FooterBottomLine/>
            </FooterContainerWrapper>
        </FooterContainer>
   
        </>
        
    )
}

export default Footer;


/*

Social Links / Website Rights

<WebsiteRights>
<SocialMediaWrap>
  <SocialLogo onClick={handleClick1} style={{ backgroundImage:`url(${fb})` }} />
</SocialMediaWrap>

©2022 K & M Restoration & Renovation, LLC. All rights reserved.</WebsiteRights>




Original CLoset + wallet button w/animation


                  <Closet                    whileHover={{ scale: 1.1}}      
                   onMouseEnter={handleClosetHover}
                     onMouseLeave={handleClosetMouseLeave}>
                            <PopUpText isDarkMode={isDarkMode}>
                            {isClosetHovered && (
                                      <PopUpBox animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0 }} style={{ backgroundImage: `url(${isDarkMode ? bg : bg2})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}  isDarkMode={isDarkMode}>
                                

                                <ComingSoonCont>
                                <LogoName  transition={  { delay: 0.1}}
                         initial ={ { scale: 0, opacity: 0, rotateX: -360, y:10000  }} // Start with flip
                        animate = {{ scale: 1.02, opacity: 1, rotateX: 0, y:0 }} // End with no flip
                        final = {{ scale: 1, opacity: 1, rotateX: 0 }} >     <ComingSoon           style={{
       
        backgroundImage: `url(${isDarkMode ? logo3 : logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}/></LogoName>
                               
                              <LogoName2   transition={  { delay: 1}}
                        initial ={ { scale: 0, opacity: 0, rotateX: 180, y: -100 }} // Start with flip
                        animate = {{ scale: 1.02, opacity: 1, rotateX: 0, y: 0}} // End with no flip
                        final = {{ scale: 1, opacity: 1, rotateX: 0 }} >      <ComingSoon           style={{
                                   
                                      backgroundImage: `url(${isDarkMode ? logo4 : logo2})`,
                                      backgroundRepeat: 'no-repeat',
                                      backgroundSize: 'cover',
                                    }}/></LogoName2>
                              
                                </ComingSoonCont>
                              </PopUpBox>
                                      )}
                                      <FooterLink     onClick={() => {setButtonPopup(true); setButtonPopup3(false);  setButtonPopup2(false); }} isDarkMode={isDarkMode}  smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>  
                                        <ButtonText>
                                          <TextSpan isDarkMode={isDarkMode}>Your Closet</TextSpan>  
                                          <ClosetIcon isDarkMode={isDarkMode} />
                                        </ButtonText> 
                                      </FooterLink>
                                
                   
        </PopUpText>
                              </Closet>
                              <Wallet         onMouseEnter={handleWalletHover}
                                  onMouseLeave={handleWalletMouseLeave}
                                  whileHover={{ scale: 1.1}} >
                                  <PopUpText isDarkMode={isDarkMode}>
                                  {isWalletHovered && (
                                  <PopUpBox animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0 }} style={{ backgroundImage: `url(${isDarkMode ? bg : bg2})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}  isDarkMode={isDarkMode}>
                                

                                    <ComingSoonCont>
                                    <LogoName  transition={  { delay: 0.2}}
                             initial ={ { scale: 0, opacity: 0, rotateX: -360, y:10000  }} // Start with flip
                            animate = {{ scale: 1.02, opacity: 1, rotateX: 0, y:0 }} // End with no flip
                            final = {{ scale: 1, opacity: 1, rotateX: 0 }} >     <ComingSoon           style={{
           
            backgroundImage: `url(${isDarkMode ? logo3 : logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}/></LogoName>
                                   
                                  <LogoName2   transition={  { delay: 1}}
                            initial ={ { scale: 0, opacity: 0, rotateX: 180, y: -100 }} // Start with flip
                            animate = {{ scale: 1.02, opacity: 1, rotateX: 0, y: 0}} // End with no flip
                            final = {{ scale: 1, opacity: 1, rotateX: 0 }} >      <ComingSoon           style={{
                                       
                                          backgroundImage: `url(${isDarkMode ? logo4 : logo2})`,
                                          backgroundRepeat: 'no-repeat',
                                          backgroundSize: 'cover',
                                        }}/></LogoName2>
                                  
                                    </ComingSoonCont>
                                  </PopUpBox>
                                      )}
                                      <FooterLink  onClick={() => {setButtonPopup(true); setButtonPopup3(false);  setButtonPopup2(false); }}  isDarkMode={isDarkMode} /*state = {user} *//*  onClick={loadWeb3} smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>   
                                      <ButtonText>
                                            <TextSpan  isDarkMode={isDarkMode} >Your Wallet</TextSpan>  
                                            <WalletIcon isDarkMode={isDarkMode}  />
                                          </ButtonText> 
                                      </FooterLink>
                
                                </PopUpText>
                              </Wallet>

*/







