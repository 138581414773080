import { AnimatePresence, motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from 'react';
import { FaDiscord, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom'; // Add these hooks for navigation
import dao from '../../images/daoBlack.svg';
import dao2 from '../../images/daoWhite.svg';
import mbg2 from '../../images/middleBG.png';
import bg2 from '../../images/w3oDayBg.svg';
import white from '../../images/w3oWhitelistBlack.svg';
import white2 from '../../images/w3oWhitelistWhite.svg';
import x from '../../images/xLogoBlack.svg';
import x2 from '../../images/xLogoWhite.svg';
import PopUp2 from "../PopUp/PopUps/PopUp2";
import PopUp3 from "../PopUp/PopUps/PopUp3";
import {
  BottomLine,
  BottomLink,
  ButtonText,
  Closet,
  ClosetIcon,
  Dao,
  DiscordButton,
  Home,
  InstagramButton,
  Masterplan,
  Meet,
  Privacy,
  PrivacyTosContainer,
  RoundedSocialButtons,
  SideBtnWrap,
  SidebarButtonWrap,
  SidebarContainer,
  SidebarContainerWrap,
  SidebarLink,
  SidebarMenu,
  SidebarRoute,
  SocialMediaWrap,
  Terms,
  TextSpan,
  TikTokButton,
  TopLine,
  Wallet,
  WalletIcon,
  Whitelist,
  XButton,
  YoutubeButton
} from './SidebarElements';

const Sidebar = ({
  isFooterVisible,
  setIsFooterVisible,
  currentState,
  setCurrentState,
  isOpen,
  toggle,
  buttonPopup3,
  buttonPopup2,
  trigger5,
  setTrigger5,
  trigger4,
  setTrigger4,
  isDarkMode,
  setWhitelistPopup,
  setDaoPopup,
  whitelistPopup,
  setButtonPopup,
  setButtonPopup2,
  setButtonPopup3,
  daoPopup,
  props
}) => {
  const [isWhitelistHovered, setWhitelistHovered] = useState(false);
  const [isXHovered, setXHovered] = useState(false);
  const [isDaoHovered, setDaoHovered] = useState(false);
  const [isWalletHovered, setIsWalletHovered] = useState(false);
  const [isClosetHovered, setIsClosetHovered] = useState(false);
  const [showSidebar, setShowSidebar] = useState(isOpen); // State to handle when to show/hide sidebar

  const controls = useAnimation();
  const navigate = useNavigate(); // Initialize navigation
  const location = useLocation(); // Get the current route location

  // Handle the delayed removal of the sidebar when isOpen is false
  useEffect(() => {
    if (isOpen) {
      setShowSidebar(true); // Show sidebar immediately when opening
      controls.start({ opacity: 1, x: 0 });
    } else {
      controls.start({ opacity: 1, x: -1000 });
      setTimeout(() => setShowSidebar(false), 750); // Hide sidebar after 0.75 seconds when closing
    }
  }, [isOpen, controls]);

  const handleClosetHover = () => setIsClosetHovered(true);
  const handleClosetMouseLeave = () => setIsClosetHovered(false);
  const handleWalletHover = () => setIsWalletHovered(true);
  const handleWalletMouseLeave = () => setIsWalletHovered(false);

  return (
    <>
      <SidebarContainerWrap isDarkMode={isDarkMode} isOpen={isOpen} onClick={toggle} />
      <AnimatePresence>
        {isOpen && (
          <SidebarContainer
            initial={{ opacity: 0, x: '-200vw' }}
            animate={controls}
            exit={{ opacity: 0, x: '-200vw' }}
            transition={{ duration: 0.25 }}
            style={{
              backgroundImage: `url(${isDarkMode ? mbg2 : bg2})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
            isOpen={isOpen}
            showSidebar={showSidebar}
          >
            <SidebarMenu>
              {/* Conditionally render buttons based on the route */}
              {location.pathname === '/Closet-7zWxY8gH5pTkL1qMvD4rB2sJuNcK3aV' ? (
                <SideBtnWrap
                  initial={{ x: -100, opacity: 0, transform: "matrix(0, 0, 0, 0, 0, 0)" }}
                  transition={isOpen ? { staggerChildren: 2, staggerDirection: -1, delay: 0.4 } : { staggerChildren: 0.2, staggerDirection: 1 }}
                  animate={isOpen ? { x: 0, opacity: 1, filter: "blur(0px)", transform: "matrix(1, 0, 0, 1, 0, 0)" } : { x: -100, opacity: 0, filter: "blur(5px)", transform: "matrix(0, 0, 0, 0, 0, 0)" }}
                  isDarkMode={isDarkMode}
                >
                  <SidebarRoute
                    whileHover={{ scale: 1.1 }}
                    onClick={() => {
                      navigate('/'); // Navigate to Home
                      toggle(); // Close the sidebar
                    }}
                    isDarkMode={isDarkMode}
                  >
                    <Home isDarkMode={isDarkMode} />
                    Home
                  </SidebarRoute>
                </SideBtnWrap>
              ) : (
                <>
                  {/* Meet the Team */}
                  <SideBtnWrap
                    initial={{ x: -100, opacity: 0, transform: "matrix(0, 0, 0, 0, 0, 0)" }}
                    transition={isOpen ? { staggerChildren: 2, staggerDirection: -1, delay: 0.4 } : { staggerChildren: 0.2, staggerDirection: 1 }}
                    animate={isOpen ? { x: 0, opacity: 1, filter: "blur(0px)", transform: "matrix(1, 0, 0, 1, 0, 0)" } : { x: -100, opacity: 0, filter: "blur(5px)", transform: "matrix(0, 0, 0, 0, 0, 0)" }}
                    isDarkMode={isDarkMode}
                  >
                    <SidebarRoute
                      whileHover={{ scale: 1.1 }}
                      onClick={() => {
                        setCurrentState(4);
                        toggle(); // Close the sidebar
                      }}
                      isDarkMode={isDarkMode}
                    >
                      <Meet isDarkMode={isDarkMode} />
                      Meet the Team
                    </SidebarRoute>
                  </SideBtnWrap>

                  {/* Master Plan */}
                  <SideBtnWrap
                    initial={{ x: -100, opacity: 0, transform: "matrix(0, 0, 0, 0, 0, 0)" }}
                    transition={isOpen ? { staggerChildren: 2, staggerDirection: -1, delay: 0.425 } : { staggerChildren: 0.2, staggerDirection: 1 }}
                    animate={isOpen ? { x: 0, opacity: 1, filter: "blur(0px)", transform: "matrix(1, 0, 0, 1, 0, 0)" } : { x: -100, opacity: 0, filter: "blur(5px)", transform: "matrix(0, 0, 0, 0, 0, 0)" }}
                    isDarkMode={isDarkMode}
                  >
                    <SidebarRoute
                      whileHover={{ scale: 1.1 }}
                      onClick={() => {
                        setCurrentState(3);
                        toggle(); // Close the sidebar
                      }}
                      isDarkMode={isDarkMode}
                    >
                      <Masterplan src={isDarkMode ? (isXHovered ? x2 : x) : (isXHovered ? x : x2)} isDarkMode={isDarkMode} />
                      MASTER PLAN
                    </SidebarRoute>
                  </SideBtnWrap>
                </>
              )}

              {/* Other Buttons */}
              <SideBtnWrap
                initial={{ x: -100, opacity: 0, transform: "matrix(0, 0, 0, 0, 0, 0)" }}
                transition={isOpen ? { staggerChildren: 2, staggerDirection: -1, delay: 0.2 } : { staggerChildren: 0.2, staggerDirection: 1 }}
                animate={isOpen ? { x: 0, opacity: 1, filter: "blur(0px)", transform: "matrix(1, 0, 0, 1, 0, 0)" } : { x: -100, opacity: 0, filter: "blur(5px)", transform: "matrix(0, 0, 0, 0, 0, 0)" }}
                isDarkMode={isDarkMode}
              >
                <SidebarRoute
                  whileHover={{ scale: 1.1 }}
                  onClick={() => { setDaoPopup(true); }}
                  onMouseEnter={() => setDaoHovered(true)}
                  onMouseLeave={() => setDaoHovered(false)}
                  isDarkMode={isDarkMode}
                >
                  <Dao src={isDarkMode ? (isDaoHovered ? dao2 : dao) : (isDaoHovered ? dao : dao2)} isDarkMode={isDarkMode} />
                  Join Our Team
                </SidebarRoute>
              </SideBtnWrap>

              <SideBtnWrap
                initial={{ x: -100, opacity: 0, transform: "matrix(0, 0, 0, 0, 0, 0)" }}
                transition={isOpen ? { staggerChildren: 2, staggerDirection: -1, delay: 0.3 } : { staggerChildren: 0.2, staggerDirection: 1 }}
                animate={isOpen ? { x: 0, opacity: 1, filter: "blur(0px)", transform: "matrix(1, 0, 0, 1, 0, 0)" } : { x: -100, opacity: 0, filter: "blur(5px)", transform: "matrix(0, 0, 0, 0, 0, 0)" }}
                isDarkMode={isDarkMode}
              >
                <SidebarRoute
                  whileHover={{ scale: 1.1 }}
                  onClick={() => { setWhitelistPopup(true); }}
                  onMouseEnter={() => setWhitelistHovered(true)}
                  onMouseLeave={() => setWhitelistHovered(false)}
                  isDarkMode={isDarkMode}
                >
                  <Whitelist src={isDarkMode ? (isWhitelistHovered ? white2 : white) : (isWhitelistHovered ? white : white2)} isDarkMode={isDarkMode} />
                  Get Whitelisted
                </SidebarRoute>
              </SideBtnWrap>
            </SidebarMenu>

            {/* Other Sidebar elements remain unchanged */}
            <SidebarButtonWrap>
              <Closet
                onMouseEnter={handleClosetHover}
                onMouseLeave={handleClosetMouseLeave}
                initial={{ x: -150, opacity: 0 }}
                animate={isOpen ? { x: 0, opacity: 1 } : { x: -150, opacity: 0 }}
                transition={{ delay: isOpen ? 0.45 : 0 }}
                isDarkMode={isDarkMode}
              >
                <motion.div whileHover={{ scale: 1.1, transition: { duration: 0.05 } }}>
                  <SidebarLink
                    onClick={() => {
                      setButtonPopup3(true);
                      setButtonPopup(false);
                      setButtonPopup2(false);
                    }}
                    isDarkMode={isDarkMode}
                  >
                    <ButtonText>
                      <TextSpan isDarkMode={isDarkMode}>Your Closet</TextSpan>
                      <ClosetIcon isDarkMode={isDarkMode} />
                    </ButtonText>
                  </SidebarLink>
                </motion.div>
              </Closet>

              <Wallet
                onMouseEnter={handleWalletHover}
                onMouseLeave={handleWalletMouseLeave}
                initial={{ x: -150, opacity: 0 }}
                animate={isOpen ? { x: 0, opacity: 1 } : { x: -150, opacity: 0 }}
                transition={{ delay: isOpen ? 0.465 : 0 }}
                isDarkMode={isDarkMode}
              >
                <motion.div whileHover={{ scale: 1.1, transition: { duration: 0.05 } }}>
                  <SidebarLink
                    onClick={() => {
                      setButtonPopup2(true);
                      setButtonPopup3(false);
                      setButtonPopup(false);
                    }}
                    isDarkMode={isDarkMode}
                  >
                    <ButtonText>
                      <TextSpan isDarkMode={isDarkMode}>Your Wallet</TextSpan>
                      <WalletIcon isDarkMode={isDarkMode} />
                    </ButtonText>
                  </SidebarLink>
                </motion.div>
              </Wallet>
            </SidebarButtonWrap>

            <PrivacyTosContainer
              initial={{ x: -150, opacity: 0 }}
              animate={isOpen ? { x: "-50%", scale: 1, filter: "blur(0px)", opacity: 1 } : { x: -150, filter: "blur(5px)", opacity: 0, scale: 0 }}
              transition={{ delay: isOpen ? 0.475 : 0 }}
              isDarkMode={isDarkMode}
            >
              <BottomLink isDarkMode={isDarkMode} href="/Tos">
                <Terms isDarkMode={isDarkMode} /> Terms of Service
              </BottomLink>{' '}
              <BottomLink isDarkMode={isDarkMode} href="/PrivacyPolicy">
                <Privacy isDarkMode={isDarkMode} /> Privacy
              </BottomLink>{' '}
            </PrivacyTosContainer>

            <SocialMediaWrap
              initial={{ x: -150, opacity: 0 }}
              transition={isOpen ? { staggerChildren: 2, staggerDirection: -1, delay: 0.5 } : { staggerChildren: 0.2, staggerDirection: 1 }}
              animate={isOpen ? { x: 0, opacity: 1, filter: "blur(0px)" } : { x: -150, opacity: 0, filter: "blur(5px)" }}
              isDarkMode={isDarkMode}
            >
              <RoundedSocialButtons>
                <XButton href="https://twitter.com/web3outfitters" target="_blank">
                  <FaXTwitter />
                </XButton>
                <DiscordButton href="https://discord.gg/qSk7XKdtHa" target="_blank">
                  <FaDiscord />
                </DiscordButton>
                <InstagramButton href="https://www.instagram.com/web3outfitters" target="_blank">
                  <FaInstagram />
                </InstagramButton>
                <YoutubeButton href="https://www.youtube.com/@web3outfitters" target="_blank">
                  <FaYoutube />
                </YoutubeButton>
                <TikTokButton href="https://www.tiktok.com/web3outfitters" target="_blank">
                  <FaTiktok />
                </TikTokButton>
              </RoundedSocialButtons>
            </SocialMediaWrap>

            <TopLine />
            <BottomLine />

            <PopUp3 isDarkMode={isDarkMode} trigger3={buttonPopup3} setTrigger3={setButtonPopup3} />
            <PopUp2 isDarkMode={isDarkMode} trigger2={buttonPopup2} setTrigger2={setButtonPopup2} />
          </SidebarContainer>
        )}
      </AnimatePresence>
    </>
  );
};

export default Sidebar;
