import { motion } from 'framer-motion';
import React from 'react';
import {
  DropdownItem,
  DropdownWrapper,
  IoDropIcon
} from "./SearchComponents";

const dropdownVariants = {
  open: {
    opacity: 1,
    scaleY: 1,
    transition: { duration: 0.3 },
  },
  closed: {
    opacity: 0,
    scaleY: 0,
    transition: { duration: 0.3 },
  },
};
const OrderDropdown = ({
  isDarkMode,
  onFilterChange,
  setIsSubNavbarDropdownOpen,
  isSubNavbarDropdownOpen,
  toggleSubNavbarDropdown,
}) => {
  return (

           
    <DropdownWrapper
    className="sub-navbar-dropdown"
    isDarkMode={isDarkMode}
    isSubNavbarDropdownOpen={isSubNavbarDropdownOpen}
    style={{ position: 'relative' }} // Ensure proper positioning
  >
    <IoDropIcon   isDarkMode={isDarkMode} onClick={toggleSubNavbarDropdown} />

    <motion.div
      initial="closed"
      animate={isSubNavbarDropdownOpen ? 'open' : 'closed'}
      variants={dropdownVariants}
      style={{
        position: 'absolute',
        top: '100%',
        marginTop: '10px',
        minWidth: '150px',
        right: '-20px',
        backgroundColor: isDarkMode ? '#fff' : '#000',
        borderRadius: '5px',
        overflow: 'hidden',
        transformOrigin: 'top',
        zIndex: 9999999,
      }}
    >

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Price: Low to High');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Price: Low to High
                    </DropdownItem>

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Price: High to Low');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Price: High to Low
                    </DropdownItem>

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Recently Listed');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Recently Listed
                    </DropdownItem>

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Rare to Common');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Rare to Common
                    </DropdownItem>

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Common to Rare');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Common to Rare
                    </DropdownItem>

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Number: Low to High');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Number: Low to High
                    </DropdownItem>

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Number: High to Low');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Number: High to Low
                    </DropdownItem>
                    
        </motion.div>
  
  </DropdownWrapper>
  )
}

export default OrderDropdown
