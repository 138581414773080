/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import React, { useRef } from 'react'

export default function MiddleShirt(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/Middle+Shirt.glb')
  return (
    <group scale={2} ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Cylinder004_Material_0002.geometry} material={materials['woodd.002']} />
      <mesh geometry={nodes.Cylinder004_Material_0002_1.geometry} material={materials['Material.011']} />
      <mesh geometry={nodes.Cylinder004_Material_0002_2.geometry} material={materials['default.002']} />
    </group>
  )
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/Middle+Shirt.glb')
