export const expertiseData = [

    {
        data: "Artist",

    },
    {
        data: "Graphic Designer",

    },
    {
        data: "Digital Creator",

    },
    {
        data: "Innovator",

    },
    {
        data: "Social Media Marketing",

    },
    {
        data: "Influencer",

    },
    {
        data: "Illustrator",

    },
    {
        data: "Painter",

    },
    {
        data: "Clothing Designer",

    },
    {
        data: "3D Modeler",

    },
    {
        data: "Other (Please Specify)",

    },
  ];

  export const communicationData = [

    {
        data: "Discord",

    },
    {
        data: "Email",

    },
    {
        data: "Twitter",

    },
    {
        data: "Other (Please Specify)",

    },
  ];

  export const deadlinesData = [

    {
        data: "Prioritize tasks and execute systematically.",

    },
    {
        data: "Delegate tasks when possible and focus on critical issues.",

    },
    {
        data: "Often require external input to find solutions.",

    },
    {
        data: "I tend to get overwhelmed easily.",

    },
  ];


 

  export const collaborationData = [

    {
        data: "Very comfortable and have collaborated on numerous projects.",

    },
    {
        data: "Comfortable, but limited past collaboration experience.",

    },
    {
        data: "Prefer working independently but can collaborate when needed.",

    },
    {
        data: "I prefer to work exclusively on solo projects.",

    },
  ];

  export const blockchainData = [

    {
        data: "Expert: Have deep knowledge and practical experience.",

    },
    {
        data: "Intermediate: Have a good understanding and some experience.",

    },
    {
        data: "Basic: Have some awareness but limited hands-on experience.",

    },
    {
        data: "Novice: Little to no knowledge or experience.",

    },
  ];

  export const feedbackData = [

    {
        data: "Proactively seek feedback and use it for improvement.",

    },
    {
        data: "Accept feedback and make necessary adjustments.",

    },
    {
        data: "Defensive initially but will consider feedback over time.",

    },
    {
        data: "Often find it hard to accept critical feedback.",

    },
  ];

  
 
  export const hoursData = [

    {
        data: "0-5 hours",

    },
    {
        data: " 6-15 hours",

    },
    {
        data: "16-30 hours",

    },
    {
        data: " 31 hours or more",

    },
  ];