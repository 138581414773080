/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import React, { useRef } from 'react'

export default function Chain(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/Chain.glb')
  return (
    <group scale={4}  ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Chain001_1.geometry} material={materials['Material.006']} />
      <mesh geometry={nodes.Chain001_2.geometry} material={materials['Gold.002']} />
      <mesh geometry={nodes.Chain001_3.geometry} material={materials['Chrome.001']} />
      <mesh geometry={nodes.Chain001_4.geometry} material={materials['Ruby.001']} />
      <mesh geometry={nodes.Chain001_5.geometry} material={materials['Material.009']} />
      <mesh geometry={nodes.Chain001_6.geometry} material={materials['Material.010']} />
      <mesh geometry={nodes.Chain001_7.geometry} material={materials['Gold.003']} />
    </group>
  )
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/Chain.glb')
