import { useAnimation } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import mbg2 from '../../images/middleBG.png';
import bg2 from '../../images/w3oDayBg.svg';
import icon from '../../images/w3oNameHorzBlack.png';
import icon2 from '../../images/w3oNameHorzWhite.png';
import {
  BottomContainer,
  ExitIcon,
  ExitIconWrap,
  Header,
  PExit,
  SubHeader,
  SubHeaderCaption,
  TopContainer,
  TopIcon,
  TopLogo,
  UtilitiesContainer,
  UtilitiesContainerWrap,
  UtilitiesContainerWrapper
} from './UtilitiesElements';
import UtilitiesGuide from './UtilitiesGuide';

import "./UtilitiesGuide.css";
import { timelineData } from './UtilitiesGuideData';

function UtilitiesLogoContainer({ isUtilitiesOpen, onClick, isDarkMode }) {
  return (
    <>
      {isDarkMode ? (
        <TopLogo initial={{ opacity: 0, x: isUtilitiesOpen ? 0 : -300, filter: isUtilitiesOpen ? 'blur(10px)' : 'blur(0px)' }}
          animate={{ opacity: isUtilitiesOpen ? 1 : 0, x: isUtilitiesOpen ? 0 : -300, filter: 'blur(0px)' }}
          transition={{ duration: 0.5 }} to='/' src={icon2} alt="Icon" />
      ) : (
        <TopLogo initial={{ opacity: 0, x: isUtilitiesOpen ? 0 : -300, filter: isUtilitiesOpen ? 'blur(10px)' : 'blur(0px)' }}
          animate={{ opacity: isUtilitiesOpen ? 1 : 0, x: isUtilitiesOpen ? 0 : -300, filter: 'blur(0px)' }}
          transition={{ duration: 0.5 }} to='/' src={icon} alt="Icon" />
      )}
    </>
  );
}

const Utilities = ({ setDaoPopup, daoPopup, isUtilitiesOpen, toggleUtilities, isDarkMode, isFooterVisible, setWhitelistPopup }) => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [maxWidth, setMaxWidth] = useState(window.innerWidth);
  const controls = useAnimation();
  const utilitiesContainerRef = useRef(null);
  const isMobile = window.innerWidth <= 600;

  useEffect(() => {
    if (isUtilitiesOpen) {
      controls.start({ x: 0 });
    } else {
      controls.start({ x: -1250 });
    }
  }, [isUtilitiesOpen]);

  useEffect(() => {
    const handleResize = () => {
      setMaxWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const utilitiesGuideRef = useRef();

  const handleClickOutside = (event) => {
    if (utilitiesContainerRef.current && !utilitiesContainerRef.current.contains(event.target)) {
      toggleUtilities();
    }
  };

  useEffect(() => {
    if (isUtilitiesOpen && isMobile) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isUtilitiesOpen, toggleUtilities, isMobile]);

  const exitAnimation = async () => {
    await controls.start({ x: -1250 });
    toggleUtilities();
  };

  return (
    <>
      {isFooterVisible ? null : (
        <>
          <UtilitiesContainerWrap isUtilitiesOpen={isUtilitiesOpen} />
          <UtilitiesContainer
            ref={utilitiesContainerRef}
            initial={{ opacity: 1, x: -950 }}
            animate={controls}
            exit={isUtilitiesOpen ? { x: -950 } : { x: 0 }}
            transition={{ duration: 0.25 }}
            maxWidth={maxWidth}
            style={{
              backgroundImage: `url(${isDarkMode ? mbg2 : bg2})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
            isUtilitiesOpen={isUtilitiesOpen}
          >
            <PExit isDarkMode={isDarkMode} onClick={exitAnimation}>
              <ExitIconWrap
                initial={{ scale: isUtilitiesOpen ? 0 : 1, rotateZ: isUtilitiesOpen ? 180 : 0 }}
                animate={{ scale: isUtilitiesOpen ? 1 : 0, rotateZ: isUtilitiesOpen ? 180 : 0 }}
                transition={{ duration: 0.6 }}
                whileHover={{ rotate: 180, transition: { duration: 0.25 } }}
                whileTap={{ rotate: 180, transition: { duration: 0.25 } }}
              >
                <ExitIcon whileHover={{ color: isDarkMode ? '#000' : '#fff', backgroundColor: isDarkMode ? '#fff' : '#000' }} />
              </ExitIconWrap>
            </PExit>

            <UtilitiesContainerWrapper>
              <TopIcon />
              <UtilitiesLogoContainer isDarkMode={isDarkMode} isUtilitiesOpen={isUtilitiesOpen} />
              <TopContainer />

              <Header initial={{ opacity: 0, x: isUtilitiesOpen ? 0 : -600, filter: isUtilitiesOpen ? 'blur(10px)' : 'blur(0px)', rotateY: 90 }}
                animate={{ opacity: isUtilitiesOpen ? 1 : 0, x: isUtilitiesOpen ? 0 : -600, filter: 'blur(0px)', rotateY: isUtilitiesOpen ? 0 : 90 }}
                transition={{ duration: 0.55 }} isDarkMode={isDarkMode} >
                HOW IT WORKS
              </Header>

              <SubHeader initial={{ opacity: 0, x: isUtilitiesOpen ? 0 : -600, filter: isUtilitiesOpen ? 'blur(10px)' : 'blur(0px)', rotateY: 90 }}
                animate={{ opacity: isUtilitiesOpen ? 1 : 0, x: isUtilitiesOpen ? 0 : -600, filter: 'blur(0px)', rotateY: isUtilitiesOpen ? 0 : 90 }}
                transition={{ duration: 0.6, delay: 0.2 }} isDarkMode={isDarkMode} >
                OVERVIEW
              </SubHeader>

              <SubHeaderCaption initial={{ opacity: 0, x: isUtilitiesOpen ? 0 : -600, filter: isUtilitiesOpen ? 'blur(10px)' : 'blur(0px)', rotateY: 90 }}
                animate={{ opacity: isUtilitiesOpen ? 1 : 0, x: isUtilitiesOpen ? 0 : -600, filter: 'blur(0px)', rotateY: isUtilitiesOpen ? 0 : 90 }}
                transition={{ duration: 0.65, delay: 0.4 }} isDarkMode={isDarkMode}>
                Greetings and thank you for choosing Web 3 Outfitters! Allow us to introduce ourselves—an interconnected community of creatives united by a common vision. Our collective mission is to deliver exceptional merchandise to a global audience, utilizing cutting-edge technologies such as NFC chips, augmented reality, and digital assets. If you're ready to embark on a journey of creativity and innovation, we invite you to join our team. Let's collaborate and bring your ideas to life!
              </SubHeaderCaption>

              <BottomContainer>
                <UtilitiesGuide
                  setDaoPopup={setDaoPopup}
                  daoPopup={daoPopup}
                  ref={utilitiesGuideRef}
                  setWhitelistPopup={setWhitelistPopup}
                  {...timelineData}
                  isUtilitiesOpen={isUtilitiesOpen}
                  isDarkMode={isDarkMode}
                />
              </BottomContainer>
            </UtilitiesContainerWrapper>
          </UtilitiesContainer>
        </>
      )}
    </>
  );
};

export default Utilities;
