/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import React, { useRef } from 'react'

export default function Mirror(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/Mirror.glb')
  return (
    <group scale={0.75} ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Cube001.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Cube001_1.geometry} material={materials['Material.018']} />
      <mesh geometry={nodes.Cube001_2.geometry} material={materials['Material.019']} />
    </group>
  )
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/Mirror.glb')
