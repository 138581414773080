import { useGLTF } from '@react-three/drei';
import React, { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';

export function Ringlight({ isDarkMode, isMobile, ...props }) {
  const { nodes, materials } = useGLTF('/w3oRinglight.glb');

  // Adjust the glow material based on the theme and device
  const glowMaterial = useMemo(() => {
    return new THREE.MeshStandardMaterial({
   
      emissiveIntensity: isMobile ? 1 : 10, // Lower intensity for mobile
      color: isDarkMode ? new THREE.Color(8.0, 8.5, 10) : new THREE.Color(10, 9.5, 4.0),
      toneMapped: false,
      emissive: isDarkMode ? "white" : "blue",
    });
  }, [isDarkMode, isMobile]);
  const bloomMeshRef = useRef();

  useEffect(() => {
    if (bloomMeshRef.current) {
      bloomMeshRef.current.layers.enable(1); // Assign to bloom layer
    }
  }, []);
  return (
    <group {...props} rotation={[0, Math.PI / 2, 0]} dispose={null} scale={1}>
      <group
        position={[-0.1, -0.364, 0.733]}
        rotation={[-Math.PI / 2, 0, 0.185]}
        scale={[0.048, 0.109, 0.092]}
      >
        <mesh
          castShadow
          ref={bloomMeshRef}
          receiveShadow
          geometry={nodes.Lights.geometry}
          material={glowMaterial}
         // layers={0}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Ring.geometry}
          material={materials['Mat.2_1']}
        />
      </group>
    </group>
  );
}

useGLTF.preload('/w3oRinglight.glb');
