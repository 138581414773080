// ClosetModel.js

import { OrbitControls, useScroll } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { EffectComposer, Outline, Selection } from '@react-three/postprocessing';
import React, { useEffect, useRef, useState } from 'react';
import W3oDefaultCloset from './W3oUserDefaultCloset';
import W3oDefaultClosetMobile from './W3oUserDefaultClosetMobile';





function Effects() {
  const { size } = useThree();

  return (
    <EffectComposer stencilBuffer disableNormalPass autoClear={false} multisampling={4}>
      <Outline
        visibleEdgeColor="blue"
        hiddenEdgeColor="blue"
        blur
        width={size.width * 0.5}
        edgeStrength={10}
      />
      <Outline
        visibleEdgeColor="blue"
        edgeStrength={100}
        pulseSpeed={0}
        width={100000}
        selectedObjects={[]}
      />
    </EffectComposer>
  );
}

export function Scene({ homeToggle, setOrbitControlsEnabled, isMobile,  lastClickedItemName, setLastClickedItemName,selectedObject, setSelectedObject, hoveredItemDetails, setHoveredItemDetails, isScrolling, cardToggle, isSelected, browseMode, setBrowseMode, hoveredGroup, setHoveredGroup, setCurrentIndex, perfSucks, onPrev, onNext, onClose, currentIndex, orbitControlsEnabled, setIsSelected, mobile3Dcollecion, setMobile3Dcollecion, BLOOM_LAYER, setIsScrolling, setIsMouseVisible, setWhitelistPopup, orbitControlsRef, setIsFooterVisible, isDarkMode, setButtonPopup, buttonPopup, openWhitelistLink, props, scroll }) {
  const { size } = useThree();
  const [scale, setScale] = useState(1);
  const [scale2, setScale2] = useState(1);

  useEffect(() => {
    const updateScale = () => {
      const width = size.width;
      let newScale = 1;

      if (width <= 600) {
        newScale = 0.4;
      } else if (width > 600 && width <= 1200) {
        newScale = 0.71428571;
      }
      setScale2(newScale);
      setScale(newScale);
    };

    updateScale();

    window.addEventListener('resize', updateScale);

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [size?.width]);

  return (
    <>


      {setButtonPopup && (isMobile ? (    <W3oDefaultClosetMobile
          BLOOM_LAYER={BLOOM_LAYER} isMobile={isMobile}  lastClickedItemName={lastClickedItemName} setLastClickedItemName={setLastClickedItemName} isDarkMode={isDarkMode} isScrolling={isScrolling} setIsScrolling={setIsScrolling} scroll={scroll} mobile3Dcollecion={mobile3Dcollecion} setMobile3Dcollecion={setMobile3Dcollecion} setWhitelistPopup={setWhitelistPopup} setIsMouseVisible={setIsMouseVisible} orbitControlsRef={orbitControlsRef} setIsFooterVisible={setIsFooterVisible} openWhitelistLink={openWhitelistLink} setButtonPopup={setButtonPopup} setOrbitControlsEnabled={setOrbitControlsEnabled} buttonPopup={buttonPopup} scale={scale} isSelected={isSelected} orbitControlsEnabled={orbitControlsEnabled} perfSucks={perfSucks} setIsSelected={setIsSelected} props={props} currentIndex={currentIndex} onClose={onClose} onNext={onNext} onPrev={onPrev} setCurrentIndex={setCurrentIndex} browseMode={browseMode} setBrowseMode={setBrowseMode} hoveredGroup={hoveredGroup} setHoveredGroup={setHoveredGroup} cardToggle={cardToggle} hoveredItemDetails={hoveredItemDetails} setHoveredItemDetails={setHoveredItemDetails} selectedObject={selectedObject} setSelectedObject={setSelectedObject} homeToggle={homeToggle}
        />)
      :
      (
        <W3oDefaultCloset
          BLOOM_LAYER={BLOOM_LAYER} isMobile={isMobile}  lastClickedItemName={lastClickedItemName} setLastClickedItemName={setLastClickedItemName} isDarkMode={isDarkMode} isScrolling={isScrolling} setIsScrolling={setIsScrolling} scroll={scroll} mobile3Dcollecion={mobile3Dcollecion} setMobile3Dcollecion={setMobile3Dcollecion} setWhitelistPopup={setWhitelistPopup} setIsMouseVisible={setIsMouseVisible} orbitControlsRef={orbitControlsRef} setIsFooterVisible={setIsFooterVisible} openWhitelistLink={openWhitelistLink} setButtonPopup={setButtonPopup} setOrbitControlsEnabled={setOrbitControlsEnabled} buttonPopup={buttonPopup} scale={scale} isSelected={isSelected} orbitControlsEnabled={orbitControlsEnabled} perfSucks={perfSucks} setIsSelected={setIsSelected} props={props} currentIndex={currentIndex} onClose={onClose} onNext={onNext} onPrev={onPrev} setCurrentIndex={setCurrentIndex} browseMode={browseMode} setBrowseMode={setBrowseMode} hoveredGroup={hoveredGroup} setHoveredGroup={setHoveredGroup} cardToggle={cardToggle} hoveredItemDetails={hoveredItemDetails} setHoveredItemDetails={setHoveredItemDetails} selectedObject={selectedObject} setSelectedObject={setSelectedObject} homeToggle={homeToggle}
        />
      ))}
    </>
  );
}


export default function ClosetModel({isMobile, whitelistPopup, lastClickedItemName, setLastClickedItemName,browseMode, setBrowseMode, setCurrentIndex, onPrev, onNext, onClose, currentIndex, mobile3Dcollecion, isSelected, setIsSelected, setMobile3Dcollecion, setWhitelistPopup, isScrolling, setIsScrolling, isMouseVisible, setIsMouseVisible, orbitControlsRef, setIsFooterVisible, isDarkMode, orbitControlsEnabled, setButtonPopup, buttonPopup, setOrbitControlsEnabled, started, toggleOrbitControls, modelSwitch, setModelSwitch, cardToggle, hoveredGroup, setHoveredGroup, props, hoveredItemDetails, setHoveredItemDetails, homeToggle, selectedObject, setSelectedObject }) {
  const { camera, gl } = useThree();
  const BLOOM_LAYER = 1;
  const [showAbout, setShowAbout] = useState(false);

  const handleCloseAbout = () => setShowAbout(false);
  const ref = useRef();
  const controlsRef = useRef();
  const [perfSucks, degrade] = useState(false);
  const [position, setPosition] = useState([0, -1.5, 0]);
  const scroll = useScroll();

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width <= 600) {
        setPosition([0, -0.25, 0]);
      } else {
        setPosition([0, -1, 0]);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openWhitelistLink = () => {
    setButtonPopup(true);
  };

  const controls = useThree((s) => s.controls);
  useEffect(() => {
    if (controls) {
      const handler = (event) => {
        setIsMouseVisible(false);
      };

      controls.addEventListener('start', handler);
      return () => controls.removeEventListener('start', handler);
    }
  });

  return (
    <>
      {(orbitControlsEnabled && !browseMode && !isSelected) && (
        <OrbitControls
          makeDefault
          args={[camera, gl.domElement]}
          enableZoom
          enableRotate
          minDistance={5}
          maxDistance={25}
          zoomSpeed={0.6}
          panSpeed={0.5}
          rotateSpeed={0.4}
        />
      )}
 
      <color attach="background" args={['#000']} />
      <group position={position} rotation={[0, -0.75, 0]}>

        <Selection>
        {!isMobile && <Effects />}
          <Scene
            setOrbitControlsEnabled={setOrbitControlsEnabled} isMobile={isMobile}  BLOOM_LAYER={BLOOM_LAYER} lastClickedItemName={lastClickedItemName} setLastClickedItemName={setLastClickedItemName} hoveredItemDetails={hoveredItemDetails} setHoveredItemDetails={setHoveredItemDetails} scroll={scroll} isScrolling={isScrolling} setIsScrolling={setIsScrolling} setWhitelistPopup={setWhitelistPopup} setIsMouseVisible={setIsMouseVisible} orbitControlsRef={orbitControlsRef} orbitControlsEnabled={orbitControlsEnabled} perfSucks={perfSucks} setIsFooterVisible={setIsFooterVisible} openWhitelistLink={openWhitelistLink} setButtonPopup={setButtonPopup} buttonPopup={buttonPopup} isDarkMode={isDarkMode} props={props} isSelected={isSelected} setIsSelected={setIsSelected} mobile3Dcollecion={mobile3Dcollecion} setMobile3Dcollecion={setMobile3Dcollecion} currentIndex={currentIndex} onClose={onClose} onNext={onNext} onPrev={onPrev} setCurrentIndex={setCurrentIndex} browseMode={browseMode} setBrowseMode={setBrowseMode} hoveredGroup={hoveredGroup} setHoveredGroup={setHoveredGroup} cardToggle={cardToggle}  selectedObject={selectedObject} setSelectedObject={setSelectedObject} homeToggle={homeToggle}
          />
        </Selection>
      </group>
    </>
  );
}
