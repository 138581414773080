import React, { useState } from 'react';
//import '../../model.css';
import {
  BrowseCollectionButton,
  BsFillGrid3X3GapFillIcon,
  BsGrid1X2FillIcon,
  BsGridFillIcon,
  Dropdown,
  DropdownItem,
  DropdownWrapper,
  FaHomeIcon,
  FaTagsIcon,
  FaThLargeIcon,
  FaTshirtIcon,
  IconText,
  IoDropIcon,
  IoFilterIcon,
  IoListIcon,
  NavbarItem,
  NavbarItemLast,
  SearchIcon,
  SearchInput,
  SearchInputWrapper,
  SubNavbarContainer,
  SubNavbarWrap
} from "./SearchComponents";

export const SearchNavbar = ({
  onSearch, onBrowseAllClick, setHoveredGroup, setHoveredItemDetails, setIsSelected,
  setIsBrowseAllMode, onWearablesClick, viewState, onNFTsClick, isDarkMode, homeToggle,
  browseMode, setBrowseMode, setViewState, onFilterChange, setSelectedObject, selectedFilter, browseToggle, onToggleFilter, ...props
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const isMobile = window.innerWidth <= 600;

  // Set the initial filter to "Recently Listed"
  const [currentFilter, setCurrentFilter] = useState('Recently Listed');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = () => {
    onSearch(searchTerm); 
    onFilterChange(currentFilter); 
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchSubmit();
    }
  };

 

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);  // Update the state with selected filter
    onFilterChange(filter);    // Notify parent component to apply the selected filter
  };
  
  return (
    <>
      <SubNavbarContainer
        initial={{ opacity: 0, y: browseMode ? -100 : 100, filter: 'blur(15px)' }}
        animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
        transition={{ duration: 0.5 }}
        isDarkMode={isDarkMode}
        browseMode={browseMode}
        isMobile={isMobile}
      >
        {browseMode ? (
          <>
            <SubNavbarWrap         isDarkMode={isDarkMode} isMobile={isMobile} browseMode={browseMode}>
              <IoFilterIcon         isDarkMode={isDarkMode} onClick={onToggleFilter} />
            </SubNavbarWrap>

            <SubNavbarWrap         isDarkMode={isDarkMode} isMobile={isMobile} browseMode={browseMode}>
              <SearchInputWrapper isFocused={isFocused}>
                <SearchInput
                  type="text"
                  placeholder="Search"
                  isDarkMode={isDarkMode}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyPress}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
                <SearchIcon isDarkMode={isDarkMode} onClick={handleSearchSubmit} />
              </SearchInputWrapper>

              <NavbarItem isDarkMode={isDarkMode} onClick={homeToggle}>
                <FaHomeIcon />
                <IconText>{isMobile ? 'HOME' : ''}</IconText>
                {!isMobile && 'HOME'}
              </NavbarItem>

              <NavbarItem isDarkMode={isDarkMode} onClick={onNFTsClick}>
                <FaTagsIcon />
                <IconText>{isMobile ? 'NFTs' : ''}</IconText>
                {!isMobile && 'NFTs'}
              </NavbarItem>

              <NavbarItemLast onClick={onWearablesClick}>
                <FaTshirtIcon />
                <IconText>{isMobile ? 'APPAREL' : ''}</IconText>
                {!isMobile && 'APPAREL'}
              </NavbarItemLast>
            </SubNavbarWrap>
            <SubNavbarWrap         isDarkMode={isDarkMode} isMobile={isMobile} style={{ width: isMobile ? 'auto' : '160px' }}>
              <NavbarItem isDarkMode={isDarkMode} onClick={() => setViewState(0)}>
                <BsGrid1X2FillIcon style={{ color: viewState === 0 ? 'gold' : 'inherit' }} />
              </NavbarItem>

              <NavbarItem isDarkMode={isDarkMode} onClick={() => setViewState(1)}>
                <BsGridFillIcon style={{ color: viewState === 1 ? 'gold' : 'inherit' }} />
              </NavbarItem>

              <NavbarItem isDarkMode={isDarkMode} onClick={() => setViewState(2)}>
                <BsFillGrid3X3GapFillIcon style={{ color: viewState === 2 ? 'gold' : 'inherit' }} />
              </NavbarItem>

              <NavbarItemLast isDarkMode={isDarkMode} onClick={() => setViewState(3)}>
                <IoListIcon style={{ color: viewState === 3 ? 'gold' : 'inherit' }} />
              </NavbarItemLast>
            </SubNavbarWrap>


            <SubNavbarWrap         isDarkMode={isDarkMode} isMobile={isMobile} browseMode={browseMode}>
              <DropdownWrapper>
                <span style={{fontWeight: 'bold', textAlign: 'center', marginRight: '12px', fontSize: '10px', textTransform: 'uppercase' }}>{currentFilter}</span> 
                <IoDropIcon />
                <Dropdown>
                  <DropdownItem onClick={() => handleFilterChange('Price: Low to High')}>Price: Low to High</DropdownItem>
                  <DropdownItem onClick={() => handleFilterChange('Price: High to Low')}>Price: High to Low</DropdownItem>
                  <DropdownItem onClick={() => handleFilterChange('Recently Listed')}>Recently Listed</DropdownItem>
                  <DropdownItem onClick={() => handleFilterChange('Oldest Listed')}>Oldest Listed</DropdownItem>
                  <DropdownItem onClick={() => handleFilterChange('Rare to Common')}>Rare to Common</DropdownItem>
                  <DropdownItem onClick={() => handleFilterChange('Common to Rare')}>Common to Rare</DropdownItem>
                  <DropdownItem onClick={() => handleFilterChange('Number: Low to High')}>Number: Low to High</DropdownItem>
                  <DropdownItem onClick={() => handleFilterChange('Number: High to Low')}>Number: High to Low</DropdownItem>
                </Dropdown>
              </DropdownWrapper>
            </SubNavbarWrap>
          </>
        ) : (
          <BrowseCollectionButton isDarkMode={isDarkMode} onClick={browseToggle}>
         
            <IconText>{isMobile ? 'BROWSE COLLECTION' : ''}</IconText>
    
            {!isMobile && 'BROWSE COLLECTION'}
            <FaThLargeIcon />
          </BrowseCollectionButton>
        )}
      </SubNavbarContainer>
    </>
  );
};
